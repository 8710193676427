import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import Dropdown from 'react-select';
import { searchCommonList } from '../../services/Fee-API';
import useHttp from '../../services/use-http';
import { searchMyTrip } from '../../services/Booking-API';
import MyTripsList from './Components/MyTripsList';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "../../components/Common/Loading/LoadingSpinner";
import { getConfigurationByValue, getConfigurationByBoolen } from '../../utils/ConfigurationsFunction';
import secureLocalStorage from 'react-secure-storage';
import { searchRoe } from '../../services/ROE-API';
import { searchWorkflowStatus } from '../../services/Approver-API';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { AUTHENTICATION } from '../../navigation/CONSTANTS.js';

const MyTrips = (props) => {
    useDocumentTitle('My Trips');

    const location = useLocation();
    const queryParam = queryString.parse(location.search);
    const tempstatusOptions = getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" ? [
        { value: "1", label: "All" },
        { value: "2", label: "Booked" },
        { value: "3", label: "Booking Request" },
        { value: "4", label: "Canceled" },
        { value: "8", label: "Cancelation Requested" },
        { value: "6", label: "Failed" },
        { value: "7", label: "Modified" },
        { value: "5", label: "On Hold" }
    ] : [
        { value: "1", label: "All" },
        { value: "2", label: "Booked" },
        { value: "3", label: "Booking Request" },
        { value: "4", label: "Canceled" },
        { value: "8", label: "Cancelation Requested" },
        { value: "6", label: "Failed" }
    ];
    const [statusOptions, setStatusOption] = useState(tempstatusOptions);

    const { t } = useTranslation(['translation.MyTrips']);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const enteredConfirmationNoInputRef = useRef('');
    const enteredPnrNoInputRef = useRef('');
    const enteredLastNameInputRef = useRef('');
    const enteredBookingDateFromInputRef = useRef('');
    const enteredBookingDateToInputRef = useRef('');
    const enteredTravelDateFromInputRef = useRef('');
    const enteredTravelDateToInputRef = useRef('');

    const [enteredConfirmationNo, setEnteredConfirmationNo] = useState("");
    const [enteredPnrNo, setEnteredPnrNo] = useState("");
    const [enteredLastName, setEnteredLastName] = useState("");
    const [serviceOptions, setServiceOptions] = useState([]);
    const [serviceCode, setServiceCode] = useState('');
    const [enteredBookingDateFrom, setEnteredBookingDateFrom] = useState("");
    const [enteredBookingDateTo, setEnteredBookingDateTo] = useState("");
    const [enteredTravelDateFrom, setEnteredTravelDateFrom] = useState("");
    const [enteredTravelDateTo, setEnteredTravelDateTo] = useState("");

    const { sendRequest: sendRequestCommonListService, data: loadedService } = useHttp(searchCommonList);
    const { sendRequest, status, data: loadedMyTrip, error } = useHttp(searchMyTrip);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const [error1, setError] = useState();

    const [bookingStatusOptions, setBookingStatusOptions] = useState([]);
    const [selectedBookingStatus, setSelectedBookingStatus] = useState(null);

    const [bookingDateFromInvalidInputError, setBookingDateFromInvalidInputError] = useState(false);
    const [bookingDateToInvalidInputError, setBookingDateToInvalidInputError] = useState(false);

    const { sendRequest: sendSearchWorkflowStatus, data: workflowStatusResponse, status: searchWorkflowRequestStatus } = useHttp(searchWorkflowStatus);
    const isCorporate = JSON.parse(localStorage.getItem("isCorporate"));
    const bookingDateLabel = isCorporate ? 'Request Date' : 'Booking Date';
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [tripsResult, setTripsResult] = useState();

    //Check if logged-in user is an approver
    const isApproverLogin = useMemo(() => {
        let isApprover = false;
        const loginData = JSON.parse(localStorage.getItem("loginData"));

        if (loginData && loginData.approvalType && loginData.approvalType.length) {
            isApprover = true;
        }

        return isApprover;
    }, []);

    const [isMyQueue, setIsMyQueue] = useState(() => {
        if (queryParam.target || (!isApproverLogin && checkSecurityDetails("My Trips"))) {
            return false;
        }
        return true;
    });

    const showTabbedLayout = useMemo(() => {

        let showTab = false;

        const isMyTripsEnabled = checkSecurityDetails("My Trips");
        const isMyQueueEnabled = checkSecurityDetails("My Queue");

        if (isApproverLogin && isMyTripsEnabled && isMyQueueEnabled) {
            showTab = true;
        }

        return showTab;

    }, [isApproverLogin]);

    const isServiceWiseApproval = useMemo(() => {

        const configValue = getConfigurationByValue("APPROVER_TYPE");

        //All service-wise approval flows have '-1' postfix in their code
        if (configValue.indexOf("-1") >= 0) {
            return true;
        }
        return false;

    }, []);

    function errorHandler() {
        setError(null);
    };

    function handleAdvancedSearchToggle() {
        setShowAdvancedSearch(currentValue => !currentValue);
    }

    function onSearchHandler(event) {
        event.preventDefault();
        setIsSubmitClicked(true);

        var today = new Date().toISOString().split('T')[0];
        if (enteredBookingDateFromInputRef.current.value !== '') {
            if (enteredBookingDateFromInputRef.current.value > today) {
                setBookingDateFromInvalidInputError(true);
                return;
            }
            else {
                setBookingDateFromInvalidInputError(false);
            }
        }

        if (enteredBookingDateToInputRef.current.value !== '') {
            if (enteredBookingDateToInputRef.current.value > today) {
                setBookingDateToInvalidInputError(true);
                return;
            }
            else {
                setBookingDateToInvalidInputError(false);
            }
        }

        if (enteredBookingDateFromInputRef.current.value !== '' && enteredBookingDateToInputRef.current.value !== '') {
            if (parseInt(enteredBookingDateToInputRef.current.value.replaceAll('-', '')) < parseInt(enteredBookingDateFromInputRef.current.value.replaceAll('-', ''))) {
                setError({
                    title: "Message",
                    message: `${bookingDateLabel} To cannot be less than ${bookingDateLabel} From.`,
                });
                return;
            }
        }

        if (enteredTravelDateFromInputRef.current.value !== '' && enteredTravelDateToInputRef.current.value !== '') {
            if (parseInt(enteredTravelDateToInputRef.current.value.replaceAll('-', '')) < parseInt(enteredTravelDateFromInputRef.current.value.replaceAll('-', ''))) {
                setError({
                    title: "Message",
                    message: `${t('Travel Date To cannot be less than Travel Date From.')}`,
                });
                return;
            }
        }

        const onSearchMyTrip = {
            "confirmationNo": enteredConfirmationNoInputRef.current.value,
            "pnrNo": enteredPnrNoInputRef.current.value,
            "lastName": enteredLastNameInputRef.current.value,
            "service": serviceCode !== '' ? serviceCode === 'Flight + Hotel' ? 'FLHT' : serviceCode === 'Flight' ? 'FL' : serviceCode === 'Hotel' ? 'HT' : serviceCode === 'Car' ? 'CR' : 'SR' : '',
            "bookingDateFrom": enteredBookingDateFromInputRef.current.value !== '' ? enteredBookingDateFromInputRef.current.value.replaceAll('-', '') : '',
            "bookingDateTo": enteredBookingDateToInputRef.current.value !== '' ? enteredBookingDateToInputRef.current.value.replaceAll('-', '') : '',
            "travelDateFrom": enteredTravelDateFromInputRef.current.value !== '' ? enteredTravelDateFromInputRef.current.value.replaceAll('-', '') : '',
            "travelDateTo": enteredTravelDateToInputRef.current.value !== '' ? enteredTravelDateToInputRef.current.value.replaceAll('-', '') : '',
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "officeName": JSON.parse(localStorage.getItem('companyName')),
            "userID": JSON.parse(localStorage.getItem('userID')),
            "status": (isCorporate) ? selectedBookingStatus.value : selectedBookingStatus.label,
            "ModuleType": getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" ? 'B2B' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "2" ? 'API' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? 'B2C' : 'B2E',
            "IsCorporate": isCorporate,
            "CustomerLoginID": secureLocalStorage.getItem('CustomerLoginEmailID'),
            isMyQueue: isMyQueue,
        };

        setTripsResult(null);

        sendRequest(onSearchMyTrip);

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
    }

    function initialTripSearch(isMyQueueTab) {
        let status = 'All';
        if (isMyQueueTab) {
            status = 'PD';
        }

        const onSearchMyTrip = {
            "confirmationNo": '',
            "pnrNo": '',
            "lastName": '',
            "service": '',
            "bookingDateFrom": '',
            "bookingDateTo": '',
            "travelDateFrom": '',
            "travelDateTo": '',
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "officeName": JSON.parse(localStorage.getItem('companyName')),
            "userID": JSON.parse(localStorage.getItem('userID')),
            "status": status,
            "ModuleType": getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" ? 'B2B' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "2" ? 'API' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? 'B2C' : 'B2E',
            "IsCorporate": isCorporate,
            "CustomerLoginID": secureLocalStorage.getItem("CustomerLoginEmailID"),
            isMyQueue: isMyQueueTab,
        };

        sendRequest(onSearchMyTrip);

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
    }

    function handleTabSwitch(isMyQueueTab) {
        resetForm(isMyQueueTab);
        setIsMyQueue(isMyQueueTab);
        initialTripSearch(isMyQueueTab);
    }

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        sessionStorage.setItem('isHotelFailed', '');
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        var today = new Date().toISOString().split('T')[0];

        if (document.getElementById("bookingDateFrom") !== null) {
            document.getElementById("bookingDateFrom").setAttribute('max', today);
        }

        if (document.getElementById("bookingDateTo") !== null) {
            document.getElementById("bookingDateTo").setAttribute('max', today);
        }

        const onSearchCommonListService = ({
            methodName: "ServiceBoth",
            methodCode: "SERB",
            listID: "",
            listValue: "",
            subListID: "",
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });

        sendRequestCommonListService(onSearchCommonListService);

    }, [sendRequestCommonListService]);

    useEffect(() => {
        if (loadedService) {
            if (loadedService.errors.status === 'FALSE') {

                let tempServiceOptions = [];
                loadedService.data.forEach(service => {
                    tempServiceOptions.push({
                        value: service.listValue,
                        label: service.listValue,
                        id: service.listID
                    });
                });

                tempServiceOptions.sort(sortByProperty('id'));

                setServiceOptions(() => tempServiceOptions);
            }
        }
    }, [loadedService]);

    useEffect(() => {

        initialTripSearch(isMyQueue);

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }

        if (isCorporate) {
            sendSearchWorkflowStatus({ workflowTypeID: '2' });
        }
        else {
            initializeBookingStatusOptions(statusOptions);
        }

    }, []);

    useEffect(() => {
        if (workflowStatusResponse) {
            if (workflowStatusResponse.errors.status === 'FALSE') {

                const options = workflowStatusResponse.data.map((status) => {
                    return {
                        label: status.statusName,
                        value: status.statusCode
                    };
                });

                initializeBookingStatusOptions(options);
            }
        }
    }, [workflowStatusResponse]);
    useEffect(() => {

    }, [tempstatusOptions])

    function initializeBookingStatusOptions(options) {
        setBookingStatusOptions(() => options);

        if (isMyQueue) {
            setSelectedBookingStatus(() => options.find((option) => option.value === 'PD'));
        }
        else {
            setSelectedBookingStatus(() => options[0]);
        }
    }

    function sortByProperty(property) {
        return function (a, b) {
            if (a[property] > b[property])
                return 1;
            else if (a[property] < b[property])
                return -1;

            return 0;
        }
    }

    const confirmationNoChangeHandler = (event) => {
        setEnteredConfirmationNo(event.target.value);
    };

    const pnrNoChangeHandler = (event) => {
        setEnteredPnrNo(event.target.value);
    };

    const lastNameChangeHandler = (event) => {
        setEnteredLastName(event.target.value);
    };

    const serviceChangeHandler = (selectedOption) => {
        if (!isMyQueue) {
            setSelectedBookingStatus({ value: '1', label: 'All' });
        }

        if (selectedOption !== null) {
            setServiceCode(selectedOption.value);

            if (selectedOption.value === 'Hotel') {
                statusOptions.forEach((element, index) => {
                    if (element.value === '8') {
                        statusOptions.splice(index, 1);
                    }
                });

                if (!getConfigurationByBoolen("HOTEL_ALLOW_BOOKING_ITINERARY")) {
                    statusOptions.forEach((element, index) => {
                        if (element.value === '3') {
                            statusOptions.splice(index, 1);
                        }
                    });
                }
                else {
                    const found = statusOptions.find(el => el.value === "3");
                    if (!found) {
                        statusOptions.push({ value: "3", label: "Booking Request" })
                        statusOptions.sort((a, b) => a.label.localeCompare(b.label));
                    }
                }

            } else if (selectedOption.value === 'Car') {
                statusOptions.forEach((element, index) => {
                    if (element.value === '8') {
                        statusOptions.splice(index, 1);
                    }
                });

                if (!getConfigurationByBoolen("CAR_ALLOW_BOOKING_ITINERARY")) {
                    statusOptions.forEach((element, index) => {
                        if (element.value === '3') {
                            statusOptions.splice(index, 1);
                        }
                    });
                }
                else {
                    const found = statusOptions.find(el => el.value === "3");
                    if (!found) {
                        statusOptions.push({ value: "3", label: "Booking Request" })
                        statusOptions.sort((a, b) => a.label.localeCompare(b.label));
                    }
                }
            }
            else {
                if (!getConfigurationByBoolen("FLIGHT_ALLOW_BOOKING_ITINERARY")) {
                    statusOptions.forEach((element, index) => {
                        if (element.value === '3') {
                            statusOptions.splice(index, 1);
                        }
                    });
                }
                else {
                    const found = statusOptions.find(el => el.value === "3");
                    if (!found) {
                        statusOptions.push({ value: "3", label: "Booking Request" })
                        statusOptions.sort((a, b) => a.label.localeCompare(b.label));
                    }
                }

                if (statusOptions.filter(option => option.value === '8').length !== 0) {
                    return;
                } else {
                    statusOptions.push({ value: "8", label: "Cancelation Requested" })
                    statusOptions.sort((a, b) => a.label.localeCompare(b.label));
                }
            }
        }
        else {
            setServiceCode('');
        }
    };

    const bookingDateFromChangeHandler = (event) => {
        setEnteredBookingDateFrom(event.target.value);
    };

    const bookingDateToChangeHandler = (event) => {
        setEnteredBookingDateTo(event.target.value);
    };

    const travelDateFromChangeHandler = (event) => {
        setEnteredTravelDateFrom(event.target.value);
    };

    const travelDateToChangeHandler = (event) => {
        setEnteredTravelDateTo(event.target.value);
    };

    function statusChangeHandler(option) {
        setSelectedBookingStatus(option);
    }

    function handleFormReset(event) {
        if (event) {
            event.preventDefault();
        }
        resetForm(isMyQueue);
        initialTripSearch(isMyQueue);
    }

    function resetForm(isMyQueueTab) {
        document.querySelector("#frmMyTrips").reset();
        setTripsResult(null);
        setShowAdvancedSearch(false);
        setEnteredConfirmationNo('');
        setEnteredPnrNo('');
        setEnteredLastName('');
        setServiceCode('');
        if (isMyQueueTab) {
            setSelectedBookingStatus(() => bookingStatusOptions.find((option) => option.value === 'PD'));
        }
        else {
            setSelectedBookingStatus(() => bookingStatusOptions[0]);
        }
        setEnteredBookingDateFrom('');
        setEnteredBookingDateTo('');
        setEnteredTravelDateFrom('');
        setEnteredTravelDateTo('');
    }

    useEffect(() => {
        if (loadedMyTrip) {
            if (loadedMyTrip.errors.status === 'FALSE') {
                setTripsResult(() => loadedMyTrip.data.filter(d => d.product.flightDetails || d.product.hotelDetails || d.product.carDetails || d.product.serviceRequest));
            }
        }
    }, [loadedMyTrip]);

    useEffect(() => {
        if (status === 'completed') {
            setIsSubmitClicked(true);
            if (loadedMyTrip === null) {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
            else if (loadedMyTrip['errors'].status === 'TRUE') {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
        }

    }, [status, loadedMyTrip, t]);

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    const ErrorShow = (isSubmitClicked && error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const MyTripsListItems = (tripsResult && tripsResult.length > 0) ?
        <MyTripsList
            MyTrips={tripsResult}
            isMyQueue={isMyQueue}
            isServiceWiseApproval={isServiceWiseApproval}
            onResetPage={handleFormReset} /> : (status !== 'pending' && ErrorShow)


    return <Fragment>
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div  className="common_bannner_text">
                        <h2>Flight search result</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li><span><i  className="fas fa-circle"></i></span> Flight search result</li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>
        {/*   <!-- Form Area --> */}
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <form id="frmMyTrips" onSubmit={onSearchHandler}>
                        <div className="col-lg-12">
                            <div className="theme_search_form_area position-relative mb-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row position-relative">
                                            {status === 'pending' &&
                                                <LoadingSpinner className={''}></LoadingSpinner>
                                            }
                                            {
                                                (getConfigurationByValue("ACCESS_GLOBETROTTER") !== '3') &&
                                                <div className="col-lg-12">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label className="h5 text-primary mb-3">
                                                                <i className="fas fa-globe-asia"></i> {isMyQueue ? "My Queue" : "My Trips"}
                                                            </label>
                                                        </div>
                                                        {/*<div className='col-md-6'>*/}
                                                        {/*    <div className='float-lg-end'>*/}
                                                        {/*        <span title="Send a Mail"*/}
                                                        {/*            className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'>*/}
                                                        {/*            <i className="fas fa-envelope"></i>*/}
                                                        {/*        </span>*/}
                                                        {/*        <span title="Take a Print"*/}
                                                        {/*            className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'>*/}
                                                        {/*            <i className="fa-solid fa-print"></i>*/}
                                                        {/*        </span>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div>
                                                        {showTabbedLayout &&
                                                            <div className="col-md-12 pop-ups-tab-border-parent">
                                                                <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                    {checkSecurityDetails("My Trips") &&
                                                                        <li className="nav-item pb-0" role="presentation">
                                                                            <button className={"tabs-btn nav-link" + (isMyQueue ? "" : " active")} data-bs-toggle="tab"
                                                                                data-bs-target="#MyTrips" type="button" role="tab" aria-controls="MyTrips"
                                                                                id='btnSalesChart' aria-selected="true" onClick={() => handleTabSwitch(false)}>My Trips</button>
                                                                        </li>
                                                                    }
                                                                    {checkSecurityDetails("My Queue") && isApproverLogin &&
                                                                        <li className="nav-item pb-0" role="presentation">
                                                                            <button className={"tabs-btn nav-link" + (isMyQueue ? " active" : "")} data-bs-toggle="tab"
                                                                                data-bs-target="#MyTrips" type="button" role="tab" aria-controls="MyQueueTab"
                                                                                id='btnSalesTable' aria-selected="true" onClick={() => handleTabSwitch(true)}>My Queue</button>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        }
                                                        <div className="col-lg-12" >
                                                            <div className={"p-2" + (showTabbedLayout ? " tab-content include-exclude-tab" : "")} id="myTabContent">
                                                                <div className="tab-pane show active" id="MyTrips" role="tabpanel" aria-labelledby="salesChart">
                                                                    <div className='row'>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2'>Confirmation No.</label>
                                                                                    <input type="text" className="form-control bg_input" ref={enteredConfirmationNoInputRef} value={enteredConfirmationNo} onChange={confirmationNoChangeHandler} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {!isMyQueue &&
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>PNR</label>
                                                                                        <input type="text"
                                                                                            className="form-control bg_input"
                                                                                            ref={enteredPnrNoInputRef} value={enteredPnrNo}
                                                                                            onChange={pnrNoChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {isMyQueue &&
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                                                    <Dropdown onChange={statusChangeHandler}
                                                                                        value={selectedBookingStatus}
                                                                                        options={bookingStatusOptions}
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        isLoading={searchWorkflowRequestStatus === 'pending'}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2'>Last Name</label>
                                                                                    <input type="text" className="form-control bg_input" ref={enteredLastNameInputRef} value={enteredLastName} onChange={lastNameChangeHandler} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="control col-md-3 mb-0 mt-2">
                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                <label htmlFor="advance" className="mb-2 h6 text-primary me-2">Advanced</label>
                                                                                <input id="mytrip-advance-toggle"
                                                                                    className="form-check-input ms-1"
                                                                                    type="checkbox"
                                                                                    checked={showAdvancedSearch}
                                                                                    onChange={handleAdvancedSearchToggle} />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {<div className='mt-2 ' id='myDIV' style={{ display: showAdvancedSearch ? '' : 'none' }}>
                                                                        <div className='row '>
                                                                            <div className='col-md-6'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>Service</label>
                                                                                    <Dropdown onChange={serviceChangeHandler}
                                                                                        value={serviceOptions.filter(option => option.value === serviceCode)}
                                                                                        options={serviceOptions}
                                                                                        textField="listValue"
                                                                                        dataItemKey="id"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        placeholder="Please Select"
                                                                                        isClearable={true}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {isMyQueue &&
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2'>PNR</label>
                                                                                            <input type="text"
                                                                                                className="form-control bg_input"
                                                                                                ref={enteredPnrNoInputRef}
                                                                                                value={enteredPnrNo}
                                                                                                onChange={pnrNoChangeHandler} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {!isMyQueue &&
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                                                        <Dropdown onChange={statusChangeHandler}
                                                                                            value={selectedBookingStatus}
                                                                                            options={bookingStatusOptions}
                                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                                            arrowOpen={<span className="arrow-open" />}
                                                                                            controlClassName='form-control control form-select bg_input'
                                                                                            isLoading={searchWorkflowRequestStatus === 'pending'}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>{bookingDateLabel} From</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredBookingDateFromInputRef} value={enteredBookingDateFrom} onChange={bookingDateFromChangeHandler} id='bookingDateFrom' />
                                                                                        {bookingDateFromInvalidInputError && <p className="error-text">{`${bookingDateLabel} From cannot be greater than current date.`}</p>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>{bookingDateLabel} To</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredBookingDateToInputRef} value={enteredBookingDateTo} onChange={bookingDateToChangeHandler} id='bookingDateTo' />
                                                                                        {bookingDateToInvalidInputError && <p className="error-text">{`${bookingDateLabel} To cannot be greater than current date.`}</p>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>Travel Date From</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredTravelDateFromInputRef} value={enteredTravelDateFrom} onChange={travelDateFromChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>Travel Date To</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredTravelDateToInputRef} value={enteredTravelDateTo} onChange={travelDateToChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                {/*content panel for my queue not required*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 top_form_search_button mt-3">
                                                        <div className=''>
                                                            <a href="/"
                                                                className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                                onClick={handleFormReset}>
                                                                Reset
                                                            </a>
                                                            <button
                                                                type="submit"
                                                                className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'>
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' && JSON.parse(secureLocalStorage.getItem("isCustomerAuthentication"))
                                                && JSON.parse(localStorage.getItem('loginEmailID')).includes('b2c@') &&
                                                <div className="col-lg-12">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label className="h5 text-primary mb-3">
                                                                <i className="fas fa-globe-asia"></i> {isMyQueue ? "My Queue" : "My Trips"}
                                                            </label>
                                                        </div>
                                                        {/*<div className='col-md-6'>*/}
                                                        {/*    <div className='float-lg-end'>*/}
                                                        {/*        <span title="Send a Mail"*/}
                                                        {/*            className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'>*/}
                                                        {/*            <i className="fas fa-envelope"></i>*/}
                                                        {/*        </span>*/}
                                                        {/*        <span title="Take a Print"*/}
                                                        {/*            className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'>*/}
                                                        {/*            <i className="fa-solid fa-print"></i>*/}
                                                        {/*        </span>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div>
                                                        {showTabbedLayout &&
                                                            <div className="col-md-12 pop-ups-tab-border-parent">
                                                                <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                    {checkSecurityDetails("My Trips") &&
                                                                        <li className="nav-item pb-0" role="presentation">
                                                                            <button className={"tabs-btn nav-link" + (isMyQueue ? "" : " active")} data-bs-toggle="tab"
                                                                                data-bs-target="#MyTrips" type="button" role="tab" aria-controls="MyTrips"
                                                                                id='btnSalesChart' aria-selected="true" onClick={() => handleTabSwitch(false)}>My Trips</button>
                                                                        </li>
                                                                    }
                                                                    {checkSecurityDetails("My Queue") && isApproverLogin &&
                                                                        <li className="nav-item pb-0" role="presentation">
                                                                            <button className={"tabs-btn nav-link" + (isMyQueue ? " active" : "")} data-bs-toggle="tab"
                                                                                data-bs-target="#MyTrips" type="button" role="tab" aria-controls="MyQueueTab"
                                                                                id='btnSalesTable' aria-selected="true" onClick={() => handleTabSwitch(true)}>My Queue</button>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        }
                                                        <div className="col-lg-12" >
                                                            <div className={"p-2" + (showTabbedLayout ? " tab-content include-exclude-tab" : "")} id="myTabContent">
                                                                <div className="tab-pane show active" id="MyTrips" role="tabpanel" aria-labelledby="salesChart">
                                                                    <div className='row'>
                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2'>Confirmation No.</label>
                                                                                    <input type="text" className="form-control bg_input" ref={enteredConfirmationNoInputRef} value={enteredConfirmationNo} onChange={confirmationNoChangeHandler} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {!isMyQueue &&
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>PNR</label>
                                                                                        <input type="text"
                                                                                            className="form-control bg_input"
                                                                                            ref={enteredPnrNoInputRef} value={enteredPnrNo}
                                                                                            onChange={pnrNoChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {isMyQueue &&
                                                                            <div className='col-md-4'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                                                    <Dropdown onChange={statusChangeHandler}
                                                                                        value={selectedBookingStatus}
                                                                                        options={bookingStatusOptions}
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        isLoading={searchWorkflowRequestStatus === 'pending'}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className='col-md-4'>
                                                                            <div className='control'>
                                                                                <div className="form-group">
                                                                                    <label className='h6 text-primary mb-2'>Last Name</label>
                                                                                    <input type="text" className="form-control bg_input" ref={enteredLastNameInputRef} value={enteredLastName} onChange={lastNameChangeHandler} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="control col-md-3 mb-0 mt-2">
                                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                                <label htmlFor="advance" className="mb-2 h6 text-primary me-2">Advanced</label>
                                                                                <input id="mytrip-advance-toggle"
                                                                                    className="form-check-input ms-1"
                                                                                    type="checkbox"
                                                                                    checked={showAdvancedSearch}
                                                                                    onChange={handleAdvancedSearchToggle} />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {<div className='mt-2 ' id='myDIV' style={{ display: showAdvancedSearch ? '' : 'none' }}>
                                                                        <div className='row '>
                                                                            <div className='col-md-6'>
                                                                                <div className='control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='service'>Service</label>
                                                                                    <Dropdown onChange={serviceChangeHandler}
                                                                                        value={serviceOptions.filter(option => option.value === serviceCode)}
                                                                                        options={serviceOptions}
                                                                                        textField="listValue"
                                                                                        dataItemKey="id"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        placeholder="Please Select"
                                                                                        isClearable={true}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {isMyQueue &&
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2'>PNR</label>
                                                                                            <input type="text"
                                                                                                className="form-control bg_input"
                                                                                                ref={enteredPnrNoInputRef}
                                                                                                value={enteredPnrNo}
                                                                                                onChange={pnrNoChangeHandler} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {!isMyQueue &&
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                                                        <Dropdown onChange={statusChangeHandler}
                                                                                            value={selectedBookingStatus}
                                                                                            options={bookingStatusOptions}
                                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                                            arrowOpen={<span className="arrow-open" />}
                                                                                            controlClassName='form-control control form-select bg_input'
                                                                                            isLoading={searchWorkflowRequestStatus === 'pending'}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>{bookingDateLabel} From</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredBookingDateFromInputRef} value={enteredBookingDateFrom} onChange={bookingDateFromChangeHandler} id='bookingDateFrom' />
                                                                                        {bookingDateFromInvalidInputError && <p className="error-text">{`${bookingDateLabel} From cannot be greater than current date.`}</p>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>{bookingDateLabel} To</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredBookingDateToInputRef} value={enteredBookingDateTo} onChange={bookingDateToChangeHandler} id='bookingDateTo' />
                                                                                        {bookingDateToInvalidInputError && <p className="error-text">{`${bookingDateLabel} To cannot be greater than current date.`}</p>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>Travel Date From</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredTravelDateFromInputRef} value={enteredTravelDateFrom} onChange={travelDateFromChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <div className='control'>
                                                                                    <div className="form-group">
                                                                                        <label className='h6 text-primary mb-2'>Travel Date To</label>
                                                                                        <input type="date" className="form-control bg_input" ref={enteredTravelDateToInputRef} value={enteredTravelDateTo} onChange={travelDateToChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                {/*content panel for my queue not required*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 top_form_search_button mt-3">
                                                        <div className=''>
                                                            <a href="/"
                                                                className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                                onClick={handleFormReset}>
                                                                Reset
                                                            </a>
                                                            <button
                                                                type="submit"
                                                                className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'>
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {MyTripsListItems}
                </div>
            </div>
        </section>

    </Fragment>
};

export default MyTrips;