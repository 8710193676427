import { Fragment, useState, useRef, useContext, useEffect, useCallback, useMemo } from 'react';
import AuthContext from '../../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { regEmailID } from '../../../../config/RegularExpression'
import {
    LOGIN, VALIDATE
} from '../../../../navigation/CONSTANTS.js'
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../../utils/ConfigurationsFunction.js';
const AuthenticationForm = (props) => {
    const loginEmailIDRef = useRef('');

    //const [loginEmailIDHasError, setloginEmailIDHasError] = useState(false);
    const [loginEmailIDError, setLoginEmailIDError] = useState('');

    const validEmailDomains = useMemo(() => {
        const emailDomainsFromConfig = (getConfigurationByValue('ACCESS_GLOBETROTTER') === '3' &&
            getConfigurationByBoolen('ALLOW_BOOKING_WITH_DOMAIN')) ?
            getConfigurationByValue('ALLOW_BOOKING_FROM_DOMAIN') : '';
        let emailDomains = [];
        if (emailDomainsFromConfig && emailDomainsFromConfig.length) {
            emailDomains = emailDomainsFromConfig.split(',').map(email => {
                return email.trim().toLowerCase();
            });
        }

        return emailDomains;
    }, []);

    const [shouldValidateEmailDomain] = useState(validEmailDomains && validEmailDomains.length > 0);

    const invalidEmailIdErrorMsg = "Please specify your valid email ID.";
    const invalidEmailDomainErrorMsg = "Please enter valid " +
        (shouldValidateEmailDomain ? validEmailDomains.join(', ') : '') +
        " email ID.";

    function isValidEmailDomain(email) {
        if (email.indexOf('@') >= 0) {
            const emailDomain = email.split('@')[1];
            if (validEmailDomains.indexOf(emailDomain.toLowerCase()) >= 0) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        loginEmailIDRef.current.focus();
    }, [])

    function submitFormHandler(event) {
        event.preventDefault();

        setLoginEmailIDError('');

        const enteredloginEmailID = loginEmailIDRef.current.value;

        // optional: Could validate here
        if (enteredloginEmailID === '') {
            loginEmailIDRef.current.focus();
            setLoginEmailIDError(invalidEmailIdErrorMsg);
            return;
        }

        if (!enteredloginEmailID.match(regEmailID)) {
            loginEmailIDRef.current.focus();
            setLoginEmailIDError(invalidEmailIdErrorMsg);
            return;
        }

        if (shouldValidateEmailDomain) {
            if (!isValidEmailDomain(enteredloginEmailID)) {
                loginEmailIDRef.current.focus();
                setLoginEmailIDError(invalidEmailDomainErrorMsg);
                return;
            }
        }

        props.onSendOTP({
            id: "",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: enteredloginEmailID,
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });

    }

    function onBlurCheck(event) {
        // const enteredInputsecuritycode = loginEmailIDRef.current.value;
        // // optional: Could validate here
        // if (enteredInputsecuritycode === '') {
        //     setLoginEmailIDError(invalidEmailIdErrorMsg);
        //     return;
        // }
        // else {
        //     setLoginEmailIDError('');
        // }

        // if (enteredInputsecuritycode.length === 6)
        //     submitFormHandler(event);
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                submitFormHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <Fragment>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    {/* {props.isLoading && (
                                        <div className='loading'>
                                            <LoadingSpinner />
                                        </div>
                                    )} */}
                                    <div className="card-body">
                                        <div className="col-md-9 mx-auto gt-country-details-form my-5">
                                            <div className="col-md-12 position-relative">
                                                <label className="mb-2  h5 text-primary col-lg-12"><i className="fas fa-globe-asia"></i>  Login</label>
                                                <div className='mt-0'><label class="mb-2 h6 text-primary col-lg-12" for="country">Please log in using your Email ID</label></div>
                                                <div className="form-group-signin col-lg-12 mx-auto control">
                                                    <div className='input-group'>
                                                        <span className="input-group-text login-input-icon gt-50px" id="basic-addon1"><i className="fas fa-envelope text-primary"></i></span>
                                                        <input type='text'
                                                            className="form-control form-control-sm mb-0 text-login-placeholder" tabIndex={1} id='email'
                                                            maxLength={100}
                                                            onChange={onBlurCheck}
                                                            ref={loginEmailIDRef}
                                                            placeholder='Email ID'
                                                        />
                                                    </div>
                                                    {loginEmailIDError && loginEmailIDError.length > 0 &&
                                                        <p className="error-text">
                                                            {loginEmailIDError}
                                                        </p>
                                                    }
                                                </div>
                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">
                                                            <button type="button"
                                                                className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1"
                                                                tabIndex={3}
                                                                onClick={props.isLoading ? '' : submitFormHandler}>
                                                                {(props.isLoading) ?
                                                                    <i className="fa fa-spinner fa-spin" ></i>
                                                                    : 'Continue with Email ID'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3 col-md-12">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>
    );
};
export default AuthenticationForm;