const locaterNumber = localStorage.getItem("profileLocaterNumber") !== 'undefined' ? localStorage.getItem("profileLocaterNumber") : ''
const dkNumber = localStorage.getItem("dkNumber") !== 'undefined' ? localStorage.getItem("dkNumber") : "\"\""

let paxInformation = JSON.stringify({
    "personName": {
        "namePrefix": "",
        "givenName": "",
        "surname": "",
        "middleName": "",
        "nameTitle": "",
        "nameSuffix": null,
        "nameType": null
    },
    "gender": "M",
    "telephone": [
        {
            "phoneNumber": "",
            "mobile": ""
        }
    ],
    "email": [
        {
            "emailId": "",
            "isPrimary": "TRUE",
            "emailType": null
        }
    ],
    "dateOfBirth": {
        "date": "00000000"
    },
    "isHolder": true,
    "age": null,
    "passengerTypeCode": "ADT",
    "travelerRefNumber": "1",
    "customerIdentifierNumber": JSON.parse(dkNumber)
})


export const CreateBookingJSON = () => {
    let carSearchRequestJson = JSON.parse(sessionStorage.getItem("carSearchRequestJson"));
    let carDetailsResponse = JSON.parse(sessionStorage.getItem('CarDetails'));

    let createPaxInformation = [];
    let paxInfromationDetails = JSON.parse(paxInformation);
    createPaxInformation.push(paxInfromationDetails);

    const BookingJSON = ({
        "officeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "userID": JSON.parse(localStorage.getItem('userID')),
            "traceId": JSON.parse(localStorage.getItem('traceID')),
            "timeStamp": null,
            "environment": "PRODUCTION",
            "version": "PRODUCTION",
            "ipAddress": localStorage.getItem("IPAddress"),
            "bookingReferenceNumber": null,
            "travelAgentName": localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null ? JSON.parse(localStorage.getItem('displayName')) : "",
            "pos": null,
            "travelerInfo": {
                "traveler": createPaxInformation
            },
            "carResults": [
                {
                    "sourceId": "1S",
                    "sourceName": "Sabre",
                    "vehRentalCore": {
                        "vehLocationInfo": carDetailsResponse.Data.carResults[0].vehRentalCore.vehLocationInfo,
                        "vehVendorAvail": carDetailsResponse.Data.carResults[0].vehRentalCore.vehVendorAvail,
                        "paymentInfo": {
                            "paymentMode": [
                                {
                                    "type": null,
                                    "name": "CreditCard",
                                    "paymentID": null,
                                    "amount": "0",
                                    "paymentModeInfo": [
                                        {
                                            "fieldName": "CARDTYPE",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "CARDNO",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "FIRSTNAME",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "LASTNAME",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "EXPIRYEAR",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "EXPIRMONTH",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "ADDRESS",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "COUNTRY",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "STATE",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "CITY",
                                            "fieldValue": ""
                                        },
                                        {
                                            "fieldName": "POSTALCODE",
                                            "fieldValue": ""
                                        }
                                    ],
                                    "cardIsEncrypt": true
                                }
                            ]
                        }
                    }
                }
            ],
            "bookingReferenceID": {
                "type": "",
                "id": ""
            },
            "carMiscellaneousInfo": {
                "miscellaneousInfo": [
                    {
                        "code": "",
                        "text": "",
                        "category": null,
                        "remarkType": "InternalRemarks"
                    },
                    {
                        "code": "",
                        "text": "",
                        "category": null,
                        "remarkType": "General"
                    }
                ]
            },
            "sequencenumber": carDetailsResponse.Data.carResults[0].sequencenumber !== undefined && carDetailsResponse.Data.carResults[0].sequencenumber !== null && carDetailsResponse.Data.carResults[0].sequencenumber !== '' ? carDetailsResponse.Data.carResults[0].sequencenumber : '1',
            "pnrRetention": null,
            "requestType": "OF"
        }
    })

    return BookingJSON;
}