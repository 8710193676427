import { Fragment, useEffect, useState } from "react";
import useDocumentTitle from "../../components/Common/layout/useDocumentTitle";
import useHttp from "../../services/use-http";
import { detailsCar } from "../../services/Car-API";
import LoadingCar from "../Cars/Components/LoadingCar";
import ErrorModal from "../../components/Common/Error/ErrorModal";
import CarDetailPolicyList from "./Components/CarDetailPolicyList";
import CarItem from "../Cars/Components/CarItem";
import { checkSecurityDetails } from "../../utils/RoleUtils";
import { useHistory } from 'react-router-dom';
import { CreateBookingJSON } from '../../utils/CarBookingFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';

const CarDetails = () => {
    useDocumentTitle('Car Details');

    const [error, setError] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [carDetails, setCarDetails] = useState(null);
    const history = useHistory();

    const { sendRequest: sendCarDetailsRequest,
        status: carDetailsRequestStatus,
        data: carDetailsResponse } = useHttp(detailsCar);

    function errorHandler() {
        setError(null);
    }

    function handleBookNowClick(event) {
        event.preventDefault();

        let bookingJSON = CreateBookingJSON()
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" && localStorage.getItem("ProfileData") !== null) {
            bookingJSON = updateBookingJSONB2CProfile(bookingJSON);
        }
        sessionStorage.setItem("CarBookingData", JSON.stringify(bookingJSON));
        sessionStorage.removeItem("CarIntialTotalAmount");

        history.push(`CarBookings`);
    }

    useEffect(() => {
        if (carDetailsResponse) {
            if (carDetailsResponse.data.errors?.status === 'FALSE') {
                setCarDetails(carDetailsResponse.data.vehVendorAvail);
                sessionStorage.setItem("CarDetailsResponse", JSON.stringify(carDetailsResponse))
            }
            else {
                //handle error
                setError({
                    title: 'Message',
                    message: 'There was an error while fetching car details.'
                });
            }
        }
    }, [carDetailsResponse]);

    useEffect(() => {
        const carDetails = JSON.parse(sessionStorage.getItem("CarDetails"));
        setSelectedCar(carDetails.Data.carResults[0]);
        const carDetailsRequest = {
            officeID: carDetails.officeID,
            data: {
                traceId: localStorage.getItem("traceID"),
                vehRentalCore: carDetails.Data.carResults[0].vehRentalCore
            }
        };
        sendCarDetailsRequest(carDetailsRequest);
        //getCarDetails();
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

    }, []);

    const updateBookingJSONB2CProfile = (bookingJSON) => {
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));
        bookingJSON.data.travelerInfo.traveler[0].personName.namePrefix = profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix;
        bookingJSON.data.travelerInfo.traveler[0].personName.givenName = profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName;
        bookingJSON.data.travelerInfo.traveler[0].personName.middleName = profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName;
        bookingJSON.data.travelerInfo.traveler[0].personName.surname = profileJSON.data.travelerInfo.traveler[0].PersonName.Surname;

        bookingJSON.data.travelerInfo.traveler[0].gender = profileJSON.data.travelerInfo.traveler[0].Gender;
        bookingJSON.data.travelerInfo.traveler[0].email[0].emailId = profileJSON.data.travelerInfo.traveler[0].email[0].emailId;
        bookingJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber = profileJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber;

        return bookingJSON;
    }

    return <Fragment>

        {error &&
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        }

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12"></div>
                </div>
            </div>
        </section>

        {/* <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3"> */}
        {/* <section id="tour_details_main" className="section_padding"> */}
        {carDetailsRequestStatus === 'pending' &&
            <section id="theme_search_form_tour" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area">
                                <div className="row  position-relative gt-h-325">
                                    <LoadingCar action='details'></LoadingCar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

        {carDetailsRequestStatus === 'completed' &&
            <section
                id="theme_search_form_tour"
                className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-3 carListItemDetails">
                                <label for="" class="h5 text-primary mb-3">
                                    <i class="fas fa-globe-asia"></i> Car Details
                                </label>
                                {selectedCar &&
                                    <CarItem
                                        id={selectedCar.sequencenumber}
                                        carResults={selectedCar}
                                        isFromDetailPage={true}
                                        showData="1"
                                    />
                                }
                                {carDetails &&
                                    <CarDetailPolicyList data={carDetails}></CarDetailPolicyList>
                                }

                                <div class="my-2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-end text-end">
                                                {checkSecurityDetails("Book", "Car", "Book_Car_Select") &&
                                                    <button className="my-2 btn btn_theme btn_sm"
                                                        onClick={handleBookNowClick}>
                                                        Reserve
                                                    </button>
                                                }
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

    </Fragment>;
};

export default CarDetails;