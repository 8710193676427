import { Fragment } from 'react';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';

const ContactPerson = (props) => {
    return <Fragment>
        <div className="pb-1">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingsix" style={{ color: " #38c1f5" }}>
                Booking Contact
            </h5>
            <div className='col-md-12'>
                <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                        <div className='h6 print-primary-title' style={{ color: "#12688B", }}> Phone</div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                        <div className='h6'>
                            {getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' ? props.carResults.traveler[0].telephone[0].mobile : props.carResults.traveler[0].telephone[0].phoneNumber}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-12'>
                <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                        <div className='h6 print-primary-title' style={{ color: "#12688B", }}> Email</div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                        <div className='h6'>
                            {props.carResults.traveler[0].email[0].emailId}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Fragment>

};

export default ContactPerson;