// import { Fragment } from 'react';
import { Fragment } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import BookingDetails from './Components/BookingDetails';
import ContactPerson from './Components/ContactPerson';
import GuestInformation from './Components/DriverDetails';
import BillingDetails from './Components/BillingDetails';
import BookingRemarks from './Components/BookingRemarks';
import Booking from './Components/Booking';
import PrintCarItem from '../Cars/Components/PrintCarItem';
import PrintBookingDetails from './Components/PrintBookingDetails';

const CarPrintConfirmation = (props) => {
    //useDocumentTitle('Car Confirmation')
    let CarBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));
    const defaultCarImage = process.env.REACT_APP_CAR_LOG_URL + "default-confirm-car.png"


    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper carListItemDetails">
            <div className={props.print == '1' ? "container-fluid px-1" : "container"}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="col-lg-12">
                                <div className='row'>
                                    <PrintBookingDetails CarBookingJSON={CarBookingJSON} hide="1"></PrintBookingDetails>
                                    <PrintCarItem
                                        key={CarBookingJSON.sequencenumber}
                                        id={CarBookingJSON.sequencenumber}
                                        carResults={CarBookingJSON.data.carResults[0]}
                                        isFromDetailPage={true}
                                        showData="1"
                                    />


                                    <Booking carResults={CarBookingJSON.data.carResults[0]}></Booking>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <ContactPerson carResults={CarBookingJSON.data.travelerInfo}></ContactPerson>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <GuestInformation carResults={CarBookingJSON.data.travelerInfo}></GuestInformation>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <BillingDetails CarBookingJSON={CarBookingJSON} print="1"></BillingDetails>
                                    {CarBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.find(remarks => remarks.remarkType === 'General').text !== '' ? <Fragment><div className='col-md-12'> <hr></hr></div>
                                        <div className="">
                                            <BookingRemarks CarBookingJSON={CarBookingJSON}></BookingRemarks>
                                        </div></Fragment> : ''}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment >

};

export default CarPrintConfirmation;