// import { Fragment } from 'react';
import { Fragment,useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import PopupModal from '../../components/Common/Flight/PrintPopupModal';
import { Link, animateScroll as scroll } from "react-scroll";
import HotelImagePopup from '../HotelDetails/Components/HotelImagePopup';

const CarDetail = () => {
    useDocumentTitle('Hotel Search')
    const [error1, setError] = useState();
    const errorHandler = () => {
        setError(null);
   
    };

    const HotelImage = () => {
        setError({
            title: "Fare Rules",
            message: <div className=''>
                 <header className='header'>
                    <h4 className="text-white">Bulgari Hotel London</h4>
                </header>
                <div className='content'>
                    <p>rjhtgjk</p>
                </div>
                {/* Bulgari Hotel London 
                <div className='gt-h-400px'>
                    <div className='row'>
                        
                     
                        <div className='text-12px'>
                            note - the following text is informational and not validated for autopricing.
                            air canada restricted fare //-a-// application area these fares apply from
                            area 2area 3 to area 1. class of service these fares apply for economy class
                            service. capacity limitations the carrier shall limit the number of passengers
                            carried on any one flight at fares governed by this rule and such fares will
                            not necessarily be available on all fights. the number of seats, which the
                            cartier shall make available on a given flight, will be determined by the
                            carrier's best judgement. other conditions sequential use of flight coupons
                            ~ this fare is only valid if the flights are taken in the booked sequence.
                            ‘otherwise the fare will be recalculated based on the actual flight routing.

                        </div>

                    </div>

                </div> */}

            </div>,
            // onConfirm={errorHandler}
        });
    }
    return <Fragment>
        {error1 && (
            <HotelImagePopup
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></HotelImagePopup>
        )}


        <section className="sub-banner1">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">

            </div>
        </section>

        <section id="home_two_banner" className='gt_login_banner2' onClick={HotelImage}>
            {/* <div className="home_two_banner_slider_wrapper owl-carousel owl-theme">
                <div className="banner_two_slider_item fadeInUp gt_login_banner5" data-wow-duration="2s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner_two_text">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner_two_slider_item fadeInUp gt_login_banner2" data-wow-duration="2s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner_two_text">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner_two_slider_item fadeInUp gt_login_banner3" data-wow-duration="2s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner_two_text">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <span className="btn btn-sm btn-primary images-btnslider">
                <i class="fas fa-images"></i>
            </span>
        </section>

        <section id="tour_details_main" class="section_padding">
            <div class="container">
                <div className='row'>
                    <div className='col-lg-5 col-md-5 col-sm-12 postion-relative'>
                        <div class="tour_details_right_sidebar_wrapper hotel-detail-name-slider">
                            <div class="tour_detail_right_sidebar">
                                <div class="tour_details_right_boxed">
                                    <div class="tour_details_right_box_heading">
                                        <h3>Bulgari Hotel London</h3>
                                        <h6 className='text-muted mt-2'>53-61 SOUTHWARK BRIDGE ROAD LONDON SE1 9HH  GB</h6>
                                    </div>
                                    {/* <div class="tour_package_bar_price">
                                        <span class="text-warning ms-1 text-12px"><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></span>
                                    </div> */}

                                    <div class="tour_package_details_bar_list pt-1">
                                        {/* <div className='h6 text-primary'>Hotel facilities -</div> */}
                                        <ul>
                                           
                                            <li><i class="fas fa-circle text-10px"></i><Link className='text-skyblue' to="overview_section">Overview</Link></li>
                                            <li><i class="fas fa-circle text-10px"></i>
                                                <Link className='text-skyblue' to="room_section">Room</Link>
                                            </li>

                                            <li><i class="fas fa-circle text-10px"></i><Link className='text-skyblue' to="location_section">Location</Link>
                                            </li>
                                           
                                            <li><i class="fas fa-circle text-10px"></i><Link className='text-skyblue' to="amenities_section">Amenities</Link>
                                            </li>
                                            <li><i class="fas fa-circle text-10px"></i><Link className='text-skyblue' to="policies_section">Policies</Link>
                                            </li>
                                           
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div class="tour_select_offer_bar_bottom">
                                    <button class="btn btn_theme btn_md w-100" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Reserve Room</button>
                                </div>
                            </div>
                        </div>
                    </div>
                   
               
               <div className='col-md-12'>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="tour_details_leftside_wrapper" id='overview_section'>
                                <div class="tour_details_heading_wrapper">
                                    <div class="tour_details_top_heading">
                                        <h2>Bulgari Hotel London</h2>
                                        <h5><i class="fas fa-map-marker-alt"></i> 53-61 SOUTHWARK BRIDGE ROAD LONDON SE1 9HH  GB</h5>
                                    </div>
                                    <div class="tour_details_top_heading_right">
                                        {/* <h4>Excellent</h4>
                                        <h6>4.8/5</h6>
                                        <p>(1214 reviewes)</p> */}
                                    </div>
                                </div>

                            <div class="tour_details_boxed">
                                <h3 class="heading_theme">Overview</h3>
                                <div className='col-md-12 mb-2'>
                                    Truly state of the art modern contemporary hotel which offers best of the facilities where the discerning business and leisure travelers would want to stay. Large convention spaces guest room inventory and service apartments coupled with a business club heated pool, fitness facilities, SPA and a joining premium shopping mall, all under one roof makes it a preferred international meetings and conference destination.
                                </div>
                                <div class="tour_details_boxed_inner">
                                    {/* <h5 className='text-primary mb-1 h5'><i class="fas fa-magic"></i> Popular Amenities</h5> */}
                                    <div className='row'>
                                        {/* <div className='col-md-12'>
                                            <div className='row my-2'>
                                                <div className='col-md-4 mt-2'><span><i class="fa-solid fa-wifi"></i> Free WiFi</span></div>
                                                <div className='col-md-4 mt-2'><span><i class="fas fa-dumbbell"></i> GYM</span></div>
                                                <div className='col-md-4 mt-2'><span><i class="fas fa-smoking-ban"></i> Non Smoking</span></div>
                                                <div className='col-md-4 mt-2'><span><i class="fa fa-fan"></i> Air Conditioning</span></div>
                                                <div className='col-md-4 mt-2'><span><i class="fa-solid fa-bath"></i> Bathtub</span></div>
                                                <div className='col-md-4 mt-2'><span><i class="fa-solid fa-door-open"></i> Connecting rooms available</span></div>
                                            </div>
                                        </div>
                                        <hr></hr> */}
                                        <span id='room_section'></span>
                                        <div className='row'> 
                                            <div className='col-md-7'>
                                                <div className='row'>
                                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-map-marker-alt"></i> What's Nearby</h4>
                                                    <div className='col-md-7'>London</div>
                                                    <div className='col-md-5'>0.00 KM</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7'>Knightsbridge - Piccadilly</div>
                                                    <div className='col-md-5'>0.10 KM</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7'>Personal shopper</div>
                                                    <div className='col-md-5'>0.10 KM</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7'>Harrods</div>
                                                    <div className='col-md-5'>0.70 KM</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-7'>British Museum</div>
                                                    <div className='col-md-5'>2.30 KM</div>
                                                </div>
                                            </div>
                                            <div className='col-md-5'>
                                               
                                                <div class="map_area1">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"></iframe>
                                                </div>
                                            </div>   
                                        </div>
                                    </div>  
                                </div>   
                            </div>    
                    </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="tour_detail_right_sidebar">
                                <div class="tour_details_right_boxed">
                                    <div class="tour_details_right_box_heading">
                                        <h3>Services</h3>
                                    </div>
                                    <div class="tour_package_details_bar_list first_child_padding_none">
                                        <ul>
                                            <li><i class="fas fa-check"></i>On-Site Services</li>
                                            <li><i class="fas fa-check"></i>24-hour front desk</li>
                                            <li><i class="fas fa-check"></i>24-hour room service</li>
                                            <li><i class="fas fa-check"></i>Baby sitting Carryout breakfast</li>
                                            <li><i class="fas fa-check"></i>Complimentary newspaper in lo Complimentary shoeshine</li>
                                            <li><i class="fas fa-check"></i>Full service housekeeping</li>
                                            <li><i class="fas fa-check"></i>Pets allowed Room service</li>
                                            <li><i class="fas fa-check"></i>Safe deposit box Turndown service</li>
                                            <li><i class="fas fa-check"></i>Valet cleaning</li>
                                            <li><i class="fas fa-check"></i>Ethernet connectivity Fax machine</li>
                                            {/* <li><i class="fas fa-check"></i>Incoming fax complimentary Meeting facilities</li> */}
                                            <li><i class="fas fa-check"></i>internet printing ava Printer</li>    
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    
                    </div>
                    <div className='row mt-3'>
                        <div className='col-lg-4 col-md-4 col-sm-4'>
                            <div class="card">
                                <span className="btn btn-sm btn-primary more-detail-btnslider">
                                     <i class="fa-solid fa-up-right-from-square px-1"></i>
                                </span>
                                <span className="btn btn-sm btn-primary card-images-btnslider">
                                    <i class="fas fa-images"></i>
                                </span>
                                <img src="assets/images/hotel/img22.jpg" class="card-img-top" alt="..."/>
                                {/* <div id="carouselExampleFade" class="carousel slide carousel-fade slider-icon-next" data-bs-ride="carousel" data-bs-interval="false">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="assets/images/hotel/img-1.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                            </div>
                                            <div class="carousel-item">
                                            <img src="assets/images/hotel/img22.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                            </div>
                                            <div class="carousel-item">
                                            <img src="assets/images/hotel/img-1.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                        </div>
                                        
                                    </div>
                                    
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div> */}
                                <div class="card-body">
                                    {/* <hr></hr> */}
                                    <h5 class="card-title text-primary h5">Superior Room</h5>
                                    {/* <p class="card-text"><i class="fa-solid fa-user"></i> Sleeps 2</p> */}
                                    <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> 1 King Bed</p>
                                    {/* <p class="card-text text-black"><i class="fa-solid fa-star"></i> Collect and Redeem</p> */}
                                    <div class="text-12px text-sky-blue"><i class="fas fa-money-check-alt"></i>  Refundable</div>
                                    {/* <p class="card-text text-black"><span class="btn gt-seat-btn"><a><i class="fas fa-hotel"></i> <span class="mouseHover">Room Details</span></a></span></p> */}
                                    
                                </div>
                                <hr className='my-1'></hr>
                                <div className='h4 text-primary col-md-12 ms-2 fw-bold pt-1 mb-0'>$28,644</div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='text-8px ms-2'>
                                                {/* <p>$34,373 total</p> */}
                                                <p>Includes taxes</p>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-5 my-auto'>
                                            <p className='text-danger'>We have 7 left</p>
                                        </div> */}
                                        <div className='col-md-12 float-end my-2 ms-2'>
                                            <button class="btn btn_theme btn_sm">Reserve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4'>
                            <div class="card">
                            <span className="btn btn-sm btn-primary more-detail-btnslider">
                                     <i class="fa-solid fa-up-right-from-square px-1"></i>
                                </span>
                                <span className="btn btn-sm btn-primary card-images-btnslider">
                                    <i class="fas fa-images"></i>
                                </span>
                                <img src="assets/images/hotel/img22.jpg" class="card-img-top" alt="..."/>
                                <div class="card-body">
                                    {/* <hr></hr> */}
                                    <h5 class="card-title text-primary h5">Deluxe Room</h5>
                                    {/* <p class="card-text"><i class="fa-solid fa-user"></i> Sleeps 2</p> */}
                                    <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> 1 King Bed</p>
                                    {/* <p class="card-text text-black"><i class="fa-solid fa-star"></i> Collect and Redeem</p> */}
                                    <div class="text-12px text-danger"><i class="fas fa-money-check-alt"></i> Non Refundable</div>
                                    {/* <p class="card-text text-black"><span class="btn gt-seat-btn"><a><i class="fas fa-hotel"></i> <span class="mouseHover">Room Details</span></a></span></p> */}
                                    
                                </div>
                                <hr className='my-1'></hr>
                                <div className='h4 text-primary col-md-12 ms-2 fw-bold pt-1 mb-0'>$28,644</div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='text-8px ms-2'>
                                                {/* <p>$34,373 total</p> */}
                                                <p>Includes taxes</p>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-5 my-auto'>
                                            <p className='text-danger'>We have 7 left</p>
                                        </div> */}
                                        <div className='col-md-12 float-end my-2 ms-2'>
                                            <button class="btn btn_theme btn_sm">Reserve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4'>
                            <div class="card">
                                <span className="btn btn-sm btn-primary more-detail-btnslider">
                                     <i class="fa-solid fa-up-right-from-square px-1"></i>
                                </span>
                                <span className="btn btn-sm btn-primary card-images-btnslider">
                                    <i class="fas fa-images"></i>
                                </span>
                                <img src="assets/images/hotel/img22.jpg" class="card-img-top" alt="..."/>
                                {/* <div id="carouselExampleControls3" class="carousel slide slider-icon-next" data-bs-ride="carousel" data-bs-interval="false">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                        <img src="assets/images/hotel/img22.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                        </div>
                                        <div class="carousel-item">
                                        <img src="assets/images/hotel/img22.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                        </div>
                                        <div class="carousel-item">
                                        <img src="assets/images/hotel/img22.jpg" class="d-block w-100 slider-height-230" alt="slider-images"/>
                                        </div>
                                    </div>
                                    <span className="btn btn-sm btn-primary images-btnslider">
                                        <i class="fas fa-images"></i>
                                    </span>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div> */}
                                <div class="card-body">
                                    {/* <hr></hr> */}
                                    <h5 class="card-title text-primary h5">Deluxe Twin Room</h5>
                                    {/* <p class="card-text"><i class="fa-solid fa-user"></i> Sleeps 2</p> */}
                                    <p class="card-text text-black"><i class="fa-solid fa-wifi"></i> Free WiFi</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-tree-city"></i> City view</p>
                                    <p class="card-text text-black"><i class="fa-solid fa-bed"></i> 1 King Bed</p>
                                    {/* <p class="card-text text-black"><i class="fa-solid fa-star"></i> Collect and Redeem</p> */}
                                    <div class="text-12px text-danger"><i class="fas fa-money-check-alt"></i> Non Refundable</div>
                                    {/* <p class="card-text"><span class="btn gt-seat-btn"><a><i class="fas fa-hotel"></i> <span class="mouseHover">Room Details</span></a></span></p> */}
                                    
                                </div>
                                <hr className='my-1'></hr>
                                <span id='amenities_section'></span> 
                                <div className='h4 text-primary col-md-12 ms-2 fw-bold pt-1 mb-0'>$28,644</div>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='text-8px ms-2'>
                                                {/* <p>$34,373 total</p> */}
                                                <p>Includes taxes</p>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-5 my-auto'>
                                            <p className='text-danger'>We have 7 left</p>
                                        </div> */}
                                        <div className='col-md-12 float-end my-2 ms-2'>
                                            <button class="btn btn_theme btn_sm">Reserve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="tour_details_boxed" >
                        <h3 class="heading_theme">Property Amenities</h3>
                        <div className='row'>
                                <div className='col-md-6'>
                                    <h5 class="h5 mt-1 text-primary"><i class="fas fa-hotel h6"></i> Food and drink</h5>
                                    {/* <div className='row h6'>
                                        <div className='col-md-12'>
                                            English breakfast (surcharge) 6:30 AM–10 AM on weekdays and 7 AM–11 AM on weekends
                                        </div>
                                    </div> */}
                                    <div class="tour_detail_right_sidebar">
                                        <div class="">
                                            <div class="tour_package_details_bar_list first_child_padding_none pt-2">
                                                <ul>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i> Restaurants</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i> Cuisine - Italian</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Meals Served - Breakfast - Lunch - Dinner</li>
                                                </ul>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <h4 class="h5 mt-1 text-primary"><i class="fas fa-hotel h6"></i> Services</h4>
                                    
                                    <div class="tour_detail_right_sidebar">
                                        <div class="">
                                            <div class="tour_package_details_bar_list first_child_padding_none pt-2">
                                                <ul>
                                                    <li className='py-1'><i class="fas fa-check  py-1"></i>Full service housekeeping</li>
                                                    <li className='py-1'><i class="fas fa-check  py-1"></i>Pets allowed Room service</li>
                                                    <li className='py-1'><i class="fas fa-check  py-1"></i>Safe deposit box Turndown service</li>


                                                </ul>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-hotel h6"></i> Facilities</h4>
                                <div className='col-md-6'>
                                    <div class="tour_detail_right_sidebar">
                                        <div class="">
                                            <div class="tour_package_details_bar_list first_child_padding_none pt-2">
                                                <ul>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>On-Site Facilities Description</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Parking 75.00 GBP Daily</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Accessible facilities Cocktail lounge with entertain</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Cocktail lounge with light fa Coffee shop</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Food and beverage outlets</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Health club High speed internet access</li>
                                                </ul>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="tour_detail_right_sidebar">
                                        <div class="">
                                            <div class="tour_package_details_bar_list first_child_padding_none pt-2">
                                                <ul>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i> Lounges/bars </li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Non-smoking rooms (generic)</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Restaurant</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Barber shop</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Drugstore/pharmacy</li>
                                                    <li className='py-1'><i class="fas fa-check py-1"></i>Meeting and Convention Facilities</li>
                                                </ul>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    <div class="tour_details_boxed" >
                        <h3 class="heading_theme">About this Area</h3>
                        <div class="map_area">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"></iframe>
                        </div>
                        <hr className='my-2'></hr>
                        <div className='row'>
                            <span id='policies_section'></span> 
                            <div className='col-md-6'>
                                <div className='row'>
                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-map-marker-alt"></i> What's Nearby</h4>
                                    <div className='col-md-7'>London</div>
                                    <div className='col-md-5'>0.00 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>Knightsbridge - Piccadilly</div>
                                    <div className='col-md-5'>0.10 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>Personal shopper</div>
                                    <div className='col-md-5'>0.10 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>Harrods</div>
                                    <div className='col-md-5'>0.70 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>British Museum</div>
                                    <div className='col-md-5'>2.30 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>Green Park</div>
                                    <div className='col-md-5'>2.90 KM</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-7'>London eye</div>
                                    <div className='col-md-5'>3.30 KM</div>
                                </div>
                            </div>
                            {/* <hr className='my-2'></hr> */}
                            <div className='col-md-6'>
                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-car"></i> Getting Around</h4>
                                <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-walking"></i> Transportation from City Center LON </div>
                                    <div className='col-md-4'>Walk </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-plane-departure"></i> Heathrow Airport (LHR)  </div>
                                    <div className='col-md-4'>44 min drive </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-plane-departure"></i> Heathrow Airport (LHR)  </div>
                                    <div className='col-md-4'>48 min drive </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-train"></i> Vauxhall Station </div>
                                    <div className='col-md-4'>50 min drive</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-train"></i> Waterloo Station </div>
                                    <div className='col-md-4'>25 min drive</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'><i class="fas fa-train"></i> London (QQW-Waterloo Train Station)</div>
                                    <div className='col-md-4'>21 min walk </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    <div class="tour_details_boxed" >
                        <h3 class="heading_theme mb-2" >Policies</h3>
                        <div class="tour_details_boxed_inner">
                            {/* <div class="room_details_facilities" >
                                <div class="tour_package_details_bar_list first_child_padding_none">
                                    <h4 class="h5 mt-1 text-primary"><i class="fas fa-square h6"></i>  Optional extras</h4> 
                                    <div className=''>English breakfast is offered for an extra charge of approximately GBP 26.00 per person</div>
                                </div>
                                
                                
                            </div>
                            <div class="tour_package_details_bar_list first_child_padding_none">
                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-swimming-pool"></i> Pool, spa, & gym (if applicable)</h4> 
                                <div className=''>Guests under 18 years old are not allowed in the fitness facility</div>
                            </div>
                            <div class="tour_package_details_bar_list first_child_padding_none">
                                <h4 class="h5 mt-1 text-primary"><i class="fas fa-square h6"></i>  Hygiene & cleanliness</h4> 
                                <div className=''>This property advises that enhanced cleaning and guest safety measures are currently in place.<br></br> Disinfectant is used to clean the property; commonly-touched surfaces are cleaned with disinfectant between stays; bed sheets and towels are laundered at a temperature of at least 60°C/140°F.<br></br>

    Staff at the property wear personal protective equipment; a shield is in place between staff and guests in main contact areas; periodic temperature checks are conducted on staff; temperature checks are available to guests; guests are provided with hand sanitizer; cashless payment methods are available for all transactions; contactless room service is available; masks are required in public areas.</div>
                            </div> */}
                            <div class="tour_package_details_bar_list first_child_padding_none">
                                {/* <h4 class="h5 mt-1 text-primary"><i class="fas fa-file-alt"></i> Policies</h4>  */}
                                <div className=''>

                                <div className='fw-bold text-primary'>
                                    Early Checkout  
                                </div>
                                Room retention is applicable<br></br>
                                <div className='fw-bold text-primary mt-1'>
                                Late Checkout 
                                </div>
                                Full room rate charged<br></br>
                                <div className='fw-bold text-primary mt-1'>
                                Pet Policy </div>
                                No Pets allowed at the hotel<br></br>
                                <div className='fw-bold text-primary mt-1'>
                                Family Policy </div>
                                Two children up to 6 years can stay free in parents' room when using existing bedding in room. A max of one extra bed can be provided in room on a chargeable basis; children aged 7 years and older are fully charged and accommodated in a separate room. Breakfast charges will be applicable for children and is not included in the room rates.<br></br>
                                <div className='fw-bold text-primary mt-1'>
                                Group Policy </div>
                                A total of 5 or more rooms when booked through one/several online and voice channels irrespective of the no. of room/s booked at the same hotel and/or when they are booked-either in the name of one guest and/or several individual names and for stays overlapping on at least one common date shall be considered as group travel and shall be treated in contravention to the hotel policy of distributing rates through online and voice channels for individual stays only. Such reservations whenever identified prior to check-in will be treated as null and void and will be cancelled instantly without any prior intimation.<br></br>
                                <div className='fw-bold text-primary mt-1'>
                                Meal Plans</div>
                                MAP
                                Breakfast and Lunch or Dinner
                                All Meals Plan
                                Full Board - AP Plan
                                Breakfast
                                Breakfast<br></br>
                               
                                <div className='fw-bold text-primary mt-1'>
                                Cancellation Policy</div>
                                28MAY12 - 31DEC99<br></br>
                                Cancel by 2 DAYS prior to arrival<br></br>
                                To avoid a 1 NIGHT cancellation penalty<br></br>
                                Cancel policy may be more restrictive based on rate / room<br></br>
                                Booked, season, special events
                                </div>
                            </div>
                            
                        </div>
                    </div>
               </div>
            </div>
        </div>
        
    </section>



    </Fragment>

};

export default CarDetail;