import { Fragment } from 'react';
import CarBookingItem from './CarBookingItem';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const CarBookingList = (props) => {

    return <Fragment>

        <div className="col-lg-12">
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row position-relative'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='text-end w-15'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='min-width-185'>Transaction Date</th>
                                            <th scope="col" className='w-15'>Company Name</th>
                                            <th scope="col" className='w-15'>Vendor Name</th>
                                            <th scope="col" className='w-15'>Category & Type</th>
                                            <th scope="col" className='w-15'>Pick-up Address</th>
                                            <th scope="col" className='w-15'>Drop-off Address</th>
                                            <th scope="col" className='min-width-210' align={'left'}>Driver Name</th>
                                            <th scope="col" className='min-width-125'>Length of car rental</th>
                                            <th scope="col" className='min-width-125'>Pick-up Date</th>
                                            <th scope="col" className='min-width-125'>Drop-off Date</th>
                                            {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                                <th scope="col">Source</th>
                                            }
                                            <th scope="col" className='w-15'>Source PNR</th>
                                            <th scope="col" className='w-15'>Car Conf. No.</th>
                                            <th scope="col" className='w-15'>Status</th>
                                            <th scope="col" className='w-15 text-center'>Currency</th>
                                            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                                                <th scope="col" className='w-15'>Service Fee</th>
                                            }
                                            <th scope="col" className='w-15'>Txn. Charges</th>
                                            <th scope="col" className='text-end w-25'>Total Price</th>
                                            {getConfigurationByBoolen("ALLOW_COMISSION") &&
                                                <th scope="col" className='w-15'>Commission</th>
                                            }
                                            <th scope="col" className='min-width-185'>Form of Payment</th>
                                            <th scope="col" className='min-width-165' align={'left'}>Done By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.currentItems.map((currentItems) =>
                                            <CarBookingItem key={currentItems.sequenceNo}
                                                id={currentItems.sequenceNo}
                                                reportJSON={currentItems}></CarBookingItem>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default CarBookingList;