import { Fragment, useEffect, useState } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import * as React from "react";
import SearchCarBookingForm from './Components/SearchCarBookingForm';
import SearchCarBookingResult from './Components/SearchCarBookingResult';
const SearchCarBooking = () => {
    useDocumentTitle('Car Booking Report')

    const [reportData, setReportData] = useState(null);
    const [searchFormKey, setSearchFormKey] = useState(1);

    function resetForm() {
        setReportData(null);
        setSearchFormKey(key => key + 1);
    }

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, []);

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {!reportData ?
            <SearchCarBookingForm
                key={searchFormKey}
                onResetForm={resetForm}
                onSetReportData={setReportData}
            /> :
            <SearchCarBookingResult
                onResetForm={resetForm}
                reportData={reportData}
            />
        }
    </Fragment>
};

export default SearchCarBooking
