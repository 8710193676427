import { sortCarResult } from '../utils/CarSort';
import { getConfigurationByBoolen, getConfigurationByValue } from '../utils/ConfigurationsFunction';

export const filterCarResults = (filterOptions, carPrice, sortValue, sortBy, manualFilter = '') => {
    let carResultTemp = '';
    if (manualFilter === '') {
        let carSearchResponseData = JSON.parse(sessionStorage.getItem('carSearchResponse'));
        carResultTemp = carSearchResponseData.data.carResults;
    }
    else {
        carResultTemp = JSON.parse(sessionStorage.getItem('initialCarResults'));
    }

    carResultTemp = filterCarPrice(carResultTemp, carPrice);
    carResultTemp = filterTransmissionType(carResultTemp);
    carResultTemp = filterFuelType(carResultTemp);
    carResultTemp = sortCarResult(sortValue, carResultTemp, sortBy);

    return carResultTemp;
}

export const filterCarPrice = (carResult, carPrice) => {
    let minCarPrice = parseFloat(carPrice[0]);
    let maxCarPrice = Math.ceil(carPrice[1]);

    var arrayTemp = [];
    var carResultTemp = [];

    carResult.forEach(element1 => {
        if (element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount >= minCarPrice && element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount <= maxCarPrice) {
            const found = arrayTemp.find(el => el.sequencenumber === element1.sequencenumber);
            if (!found) {
                carResultTemp.push(element1);
                arrayTemp.push({
                    sequencenumber: element1.sequencenumber
                });
            }
        }
    });

    if (carPrice.length > 0) {
        carResult = carResultTemp;
    }

    return carResult;
}

export const filterTransmissionType = (carResult) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.transmissiontypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var carResultTemp = [];

    carResult.forEach(element1 => {
        if (element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType !== undefined && element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType !== null) {
            if (filterOptions.includes(element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType)) {
                const found = arrayTemp.find(el => el.sequencenumber === element1.sequencenumber);
                if (!found) {
                    carResultTemp.push(element1);
                    arrayTemp.push({
                        sequencenumber: element1.sequencenumber
                    });
                }
            }
        }

    });

    if (filterOptions.length !== 0) {
        carResult = carResultTemp;
    }

    return carResult;
}

export const filterFuelType = (carResult) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.fueltypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var carResultTemp = [];

    carResult.forEach(element1 => {
        if (element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType !== undefined && element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType !== null) {
            if (filterOptions.includes(element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType)) {
                const found = arrayTemp.find(el => el.sequencenumber === element1.sequencenumber);
                if (!found) {
                    carResultTemp.push(element1);
                    arrayTemp.push({
                        sequencenumber: element1.sequencenumber
                    });
                }
            }
        }

    });

    if (filterOptions.length !== 0) {
        carResult = carResultTemp;
    }

    return carResult;
}