

import { Fragment, useState } from 'react';
import { CarDetailsJSON } from './../../../utils/CarDetails';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { FLIGHTSEARCH } from '../../../navigation/CONSTANTS.js';
import ErrorModal from '../../../components/Common/Error/ErrorModal.js';

const PrintCarItem = (props) => {

    const [error, setError] = useState(null);

    const getVehicleImage = (vehicle) => {
        const defaultCarImage = process.env.REACT_APP_CAR_LOG_URL + "default-confirm-car.png"
        // Safely access the array of images using optional chaining
        const images = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.images?.image;

        // Use Array.find to locate the first image of type 'LARGE'
        const largeImage = images?.find(image => image.type === 'LARGE' || image.type === 'Large' || image.type === 'large' || image.type === undefined || image.type === null || image.type === '');
        // Return the found image URL or the default car image
        return largeImage?.url || defaultCarImage;
    };

    const getSeats = (vehicle) => {
        // Safely access the seat quantity using optional chaining
        const noOfSeats = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.seatBeltsInfo?.seatBelts?.quantity;

        // Return JSX or null if no seats are defined
        return noOfSeats ? (
            <span className="fw-bold mt-2 me-4">
                <i className="fas fa-user print-primary-title" style={{ color: "#12688B" }}></i> {`${noOfSeats} Seats`}
            </span>
        ) : null;
    };

    const getTransMissionType = (vehicle) => {
        // Safely access the seat quantity using optional chaining
        const type = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.transmissionType;

        // Return JSX or null if no transmission are defined
        return type ? (
            <span className="fw-bold mt-2 me-4"> <i
                className="fas fa-cog print-primary-title" aria-hidden="true" style={{ color: "#12688B" }}></i><span> {`${type}`}</span>
            </span>
        ) : null;
    };

    const getBaggage = (vehicle) => {
        // Safely access the baggage array using optional chaining
        const bags = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.baggageInfo?.baggage;
        // Find the first 'Large' bag using Array.find
        const largeBag = bags?.find(bag => bag.size === 'Large' || bag.size === 'LARGE' || bag.size === 'large');

        // Determine bag information if a large bag exists
        const bagInfo = largeBag && parseInt(largeBag.quantity) > 0
            ? `${largeBag.quantity} Large bag${parseInt(largeBag.quantity) > 1 ? 's' : ''}`
            : null;

        // Return JSX or null if no large bag is found
        return bagInfo ? (
            <span className="fw-bold mt-2 me-4">
                <i className="fa fa-suitcase print-primary-title" aria-hidden="true" style={{ color: "#12688B" }}></i>
                <span> {bagInfo}</span>
            </span>
        ) : null;
    };

    const getMileage = (vehicle) => {
        // Safely access the mileage array using optional chaining

        const mileage = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.convertedRentalRate?.vehicleCharges?.vehicleCharge?.[0]?.mileageAllowance;
        // Return JSX or null if no large bag is found
        return mileage ? (
            <div className=" text-muted text-14px mt-2 mb-1" style={{ height: "auto" }}><span className="fw-bold"> <i
                className="fa fa-tachometer-alt print-primary-title" style={{ color: "#12688B" }}></i>
                <span> Unlimited Mileage</span></span>
            </div>
        ) : '';
    };

    const getRentalDays = (vehicle) => {
        const rentalDays = vehicle?.vehRentalCore?.vehLocationInfo?.rentalDays;
        const rentalHours = vehicle?.vehRentalCore?.vehLocationInfo?.rentalHours;
        if (rentalDays && rentalDays !== '0') {
            return `Price for ${rentalDays} day${parseInt(rentalDays) > 1 ? 's' : ''}`
        } else if (rentalHours && rentalHours !== '0') {
            return `Price for ${rentalHours} hour${parseInt(rentalHours) > 1 ? 's' : ''}`
        }
    }

    const getVechileCompanyLogo = (vehicle) => {
        //const defaultLogo = 'https://uat.fareladder.com/assets/logos/cars/comapny-car.png'
        const defaultLogo = process.env.REACT_APP_CAR_LOG_URL + "comapny-car.png";
        const mileage = vehicle?.vehRentalCore?.vehVendorAvail?.vendorInfo?.logo;
        return mileage ? mileage : defaultLogo;

    }
    const getFuelType = (vehicle) => {
        const fuelType = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.fuelType;
        return fuelType ? (
            <span class="fw-bold mt-2"> <i class="fa fa-gas-pump print-primary-title" style={{ color: "#12688B" }}></i><span>&nbsp;
                {fuelType}</span>
            </span>
        ) : '';

    }
    const onBookNow = (id) => {
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' && localStorage.getItem('SessionLoginDateTime') === null) {
            window.location.pathname = `${FLIGHTSEARCH}`;
        }
        else {

            let detailsJSON = CarDetailsJSON(props.carResults)
            sessionStorage.setItem("CarDetails", JSON.stringify(detailsJSON))
            if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
                openInNewTab('CarDetails?travelreq=true');
            }
            else {
                openInNewTab('CarDetails');
            }
        }
    }

    const openInNewTab = url => {
        window.open(url);
    };

    const getSpecialTag = (vehicle) => {
        // Safely access the special code using optional chaining
        const specialCode = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.convertedRentalRate?.corporateDiscountApplied;
        // Return JSX or null if no special code is found
        return specialCode ? (
            <span class="badge bg-primary" style={{ left: "10px", position: "absolute", bottom: "20px", fontSize: "18px" }}>Special Fare</span>
        ) : '';
    }

    const getLocation = (vehicle) => {
        const pickUp = vehicle?.vehRentalCore?.vehLocationInfo?.pickUpLocation?.locationInfo?.addressDetail;
        const dropOff = vehicle?.vehRentalCore?.vehLocationInfo?.returnLocation?.locationInfo?.addressDetail;

        // Compare pickup and drop-off details
        if (
            pickUp?.city?.name !== dropOff?.city?.name ||
            pickUp?.address1 !== dropOff?.address1 ||
            pickUp?.address2 !== dropOff?.address2
        ) {
            return (<>
                <div className="hotel-star-address">
                    <address className="hotel-address"><span className="fw-bold text-muted"> Pick-up </span>&nbsp;
                        {`${pickUp?.address1 || ''}, `}
                        {`${pickUp?.address2 || ''}${pickUp.address2 === undefined ? '' : ', '}`}
                        {`${pickUp?.city?.name || ''}, `}
                        {`${pickUp?.country?.name || ''}`}
                    </address>
                </div>
                <div className="hotel-star-address">
                    <address className="hotel-address">
                        <span className="fw-bold text-muted">Drop-off</span>&nbsp;
                        {`${dropOff?.address1 || ''}, `}
                        {`${dropOff?.address2 || ''}${dropOff.address2 === undefined ? '' : ', '}`}
                        {`${dropOff?.city?.name || ''}, `}
                        {dropOff?.country?.name || ''}
                    </address>
                </div>
            </>)

        } else {

            return (
                <div className="hotel-star-address">
                    <address className="hotel-address">
                        <span className="fw-bold text-muted">Pick-up & Drop-off</span>&nbsp;
                        {`${dropOff?.address1 || ''}, `}
                        {`${dropOff?.address2 || ''}${dropOff.address2 === undefined ? '' : ', '}`}
                        {`${dropOff?.city?.name || ''}, `}
                        {dropOff?.country?.name || ''}
                    </address>
                </div>
            );


        }
    };
    function errorHandler() {
        setError(null);
    }



    return <Fragment>

        {error &&
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        }

        <div className="hotel-list-cn clearfix gt-car-section carListItemDetails">
            <div className="hotel-list-item  carListItem row position-relative" style={{ border: "1px solid #ddd" }} >
                <figure className="hotel-img float-left col-md-4 position-relative">
                    <a className='gt-car-img' title="">
                        <img
                            style={{ maxWidth: "100%", height: "240px", marginLeft: "5px", marginTop: "5px" }}
                            src={getVehicleImage(props.carResults)}
                            alt="" lazy={'load'} /> </a>
                    {getSpecialTag(props.carResults)}
                </figure>

                <div className="hotel-text ml-0 col-md-8 position-relative" style={{ marginLeft: "0px" }}>
                    <span style={{ position: "absolute", top: "5px", right: "5px", }}>
                        <img
                            style={{ width: "65px", height: "auto" }}
                            src={getVechileCompanyLogo(props.carResults)}
                            lazy={'load'}
                            alt=""
                        />
                    </span>
                    <div class="hotel-name  my-2 " style={{ marginTop: '10px' }}>
                        <h4 className="" style={{ fontSize: "25px", paddingTop: '15px' }}
                        >{props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.category || ''} {props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.type || ''}
                        </h4><span class="h6 text-muted">{props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.vehMakeAndModel || ''}</span>
                    </div>
                    <div className=" text-muted text-14px CarItemFeaturesIcon ">
                        {getSeats(props.carResults)}
                        {getTransMissionType(props.carResults)}
                        {getBaggage(props.carResults)}
                        {getFuelType(props.carResults)}
                    </div>
                    {getMileage(props.carResults)}
                    {getLocation(props.carResults)}
                    <div className="row">
                        <div className="col-md-7 col-sm-12 my-auto">
                        </div>
                        <div className="col-md-5  col-sm-12 hotel-service text-end">
                            <div className="price special-price print-primary-title fw-bold h3 mb-0 px-2" style={{ color: "#12688B", fontSize: "28px", }}>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: props.carResults.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode
                                }).format(props.carResults.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount)}
                            </div>
                            <div className="text-muted text-14px px-2" >{getRentalDays(props.carResults)}<br></br></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};
export default PrintCarItem;
