import { useRef, useState, Fragment, useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { AUTHORIZETWOFACTOR, CHANGEPASSWORD, ENABLETWOFACTOR, FORGOTPASSWORD, PASSWORDCHANGE, LOADING } from '../../../navigation/CONSTANTS';
import { getAuthentication } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http'
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction'
import { useTranslation } from 'react-i18next';
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import { regEmailID } from '../../../config/RegularExpression'
import { encrypted } from '../../../utils'
import AuthContext from '../../../redux/contexts/auth-context'
import secureLocalStorage from 'react-secure-storage';
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';

const LoginForm = () => {
  //alert("login")
  const { t } = useTranslation(['translation.LoginForm']);
  const history = useHistory();
  const loginIDInputRef = useRef();
  const passwordInputRef = useRef();
  const [errors, setErrors] = useState();
  const [loginIDInputHasError, setLoginIDInputHasError] = useState(false);
  const [loginIDValidInputHasError, setLoginIDValidInputHasError] = useState(false);
  const [passwordInputHasError, setPasswordInputHasError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const authCtx = useContext(AuthContext);

  const errorHandler = () => {
    setErrors(null);
  };

  function customizePage() {
    if (getCustomizeDetails("forgotPwdBackColor")) {
      document.documentElement.style.setProperty('--bg-prime', getCustomizeDetails("forgotPwdBackColor"));
    }

    if (getCustomizeDetails('loginBtnGradientColor1')) {
      document.documentElement.style.setProperty('--login-custom-btn', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails('loginBtnGradientColor1')} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails('loginBtnGradientColor2')}`);
    }
  }

  useEffect(() => {

    customizePage();

    loginIDInputRef.current.focus();
  }, [])

  const { sendRequest, status, data: loadedAuthenticationDetails, error } = useHttp(getAuthentication);

  function onloginIDChangeCheck() {
    // optional: Could validate here   
    if (loginIDInputRef.current.value.trim() === '' && !loginIDInputHasError) {
      setTimeout(setLoginIDInputHasError(true), 200)
      setTimeout(setLoginIDValidInputHasError(false), 200)
      return;
    }
    else if (loginIDInputHasError) {
      setTimeout(setLoginIDInputHasError(false), 200)
    }
    if (!loginIDValidInputHasError && !loginIDInputRef.current.value.trim().match(regEmailID)) {
      setTimeout(setLoginIDInputHasError(false), 200)
      setTimeout(setLoginIDValidInputHasError(true), 200)

      return;
    } else if (loginIDValidInputHasError) {
      setTimeout(setLoginIDValidInputHasError(false), 200)
    }


  }
  function onPaswordChangeCheck() {
    if (passwordInputRef.current.value.trim() === '') {
      setPasswordInputHasError(true); return;
    }
    else {
      setPasswordInputHasError(false);
    }
  }


  const submitHandler = useCallback(async (event) => {
    event.preventDefault();

    const enteredLoginID = loginIDInputRef.current.value.trim();
    let enteredPassword = passwordInputRef.current.value.trim();

    // optional: Could  validate here
    if (enteredLoginID === '') {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(true);
      setLoginIDValidInputHasError(false);
      return;
    }

    if (!enteredLoginID.match(regEmailID)) {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(false);
      setLoginIDValidInputHasError(true);
      return;
    }
    if (enteredPassword === '') {
      passwordInputRef.current.focus();
      setPasswordInputHasError(true);
      return;
    }
    setIsLoader(true);
    enteredPassword = await encrypted(enteredPassword);
    sendRequest({ userName: enteredLoginID, password: enteredPassword });
    setIsLoader(false);
    // optional: Add validation 
  }, [sendRequest]);


  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in loadedAuthenticationDetails) {
        if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            console.log(loadedAuthenticationDetails);
            loadedData = loadedAuthenticationDetails[i];
            console.log(loadedAuthenticationDetails);
            const expirationTime = new Date(
              new Date().getTime() + +loadedData.expireIn * 1000
            );
            localStorage.setItem('idToken', loadedData.idToken);
            localStorage.setItem('token', loadedData.idToken);
            localStorage.setItem('loginEmailIDLogin', loadedData.loginEmailID);
            localStorage.setItem('loginData', JSON.stringify(loadedData));
            localStorage.setItem('userIDLogin', loadedData.loginEmailID);
            // sessionStorage.setItem("LoaderHeader", "1");
            //sessionStorage.setItem("LoaderHeader", "1");
            if (getConfigurationByBoolen("ALLOW_MFA")) {
              if (loadedData.twoFactorEnabled) {
                console.log(loadedAuthenticationDetails);
                localStorage.setItem('enable2FA', true);
                window.location.href = `${AUTHORIZETWOFACTOR}`;
              }
              else {
                if (loadedData.isPasswordUpdate) {
                  localStorage.setItem('enable2FA', false);
                  window.location.href = `${ENABLETWOFACTOR}`;

                } else {
                  localStorage.setItem('enable2FA', false);
                  window.location.href = `${PASSWORDCHANGE}`;
                }
              }
            } else {
              authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                loadedData.defaultCity, loadedData.isCorporate, loadedData.isTravelAgent,
                loadedData.email, loadedData.phoneCode, loadedData.phoneNumber, loadedData);


              if (loadedData.isPasswordUpdate) {
                window.location.pathname = `${LOADING}`;

              } else {
                window.location.href = `${PASSWORDCHANGE}`;
              }

            }
          }
        }
        else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
          localStorage.removeItem('token');
          localStorage.removeItem('expirationTime');
          localStorage.removeItem('idToken');
          localStorage.removeItem('userIDLogin');
          localStorage.removeItem('loginEmailID');
          localStorage.removeItem('userID');
          localStorage.removeItem('officeID');
          localStorage.removeItem('displayName');
          localStorage.removeItem('roleDetails');
          localStorage.removeItem('customizeDetails');
          localStorage.removeItem('enable2FA');
          localStorage.removeItem('loginEmailIDLogin');
          localStorage.removeItem('BookingDataTravelRequest');
          localStorage.removeItem('TravelRequestInput');
          sessionStorage.clear();
          if (loadedAuthenticationDetails.errors.error.code === "9002") {
            setErrors({
              title: "Message",
              message: 'Please check with admin for define the policy.',
            });
          } else {
            setErrors({
              title: "Message",
              message: 'Please check the Email ID and Password.',
            });
          }
        }
      }
    }
    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, loadedAuthenticationDetails, history])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);




  return (
    <Fragment>
      {errors && (
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      <div className="row remSpaceMob">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 mb-1 position-relative">

          <div className="gt-common_author_boxed position-absolute width-100 gt-login-banner-div">
            <div className="card-body">
              <div className="col-md-10 col-sm-12 mx-auto signin-log">
                {/* <label className="gt-heading_theme h4 text-white mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Globetrotter Login </label> */}
                <div className="form-group  col-lg-12 mx-auto">
                  <div className='control'>
                    <div className='input-group'>
                      <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-envelope text-primary"></i></span>
                      {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                      <input type='text'
                        className="form-control form-control-sm mb-0 text-login-placeholder" tabIndex={1} id='email'
                        placeholder='Email ID'
                        maxLength={100}
                        onBlur={onloginIDChangeCheck}
                        ref={loginIDInputRef}
                      />
                    </div>
                    <div>
                      {loginIDInputHasError && <p className="error-text">{t('validationLoginid')}</p>}
                      {loginIDValidInputHasError && <p className="error-text">{t('validationValidLoginid')}</p>}

                    </div>
                  </div>
                  <div className='control'>
                    <div className='input-group position-relative'>
                      {/* <label className="h6 text-primary mb-2 col-lg-12">{t('password')}</label> */}
                      <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-lock text-primary"></i></span>
                      <input
                        type='password'
                        tabIndex={2}
                        maxLength={50}
                        className="form-control form-control-sm mb-0 text-login-placeholder"
                        id='password'

                        placeholder='Password'
                        onBlur={onPaswordChangeCheck}
                        ref={passwordInputRef}
                      />
                      <span className="input-group-text w-50px">
                        <i className="fa fa-eye-slash text-primary" id="togglePassword"></i>
                      </span>


                    </div>
                    <div>{passwordInputHasError && <p className="error-text">{t('validationpassword')}</p>}</div>


                  </div>
                  <div className="row my-3">
                    <div className="col-md-6 my-auto col-sm-12 col-12">
                      <Link className="fw-bold  forgot-pass-bg h5 d-none d-sm-block d-sm-none d-md-block" to={`${FORGOTPASSWORD}`}><span className='badge bg-prime'>Forgot Password ?</span></Link>
                    </div>
                    <div className=" col-md-6 col-sm-12 mt-2">
                      <Link className="fw-bold forgot-pass-bg h5 d-md-none d-lg-none d-xl-none" to={`${FORGOTPASSWORD}`}><span className='badge bg-prime'>Forgot Password ?</span></Link>
                      <button type="button" className="btn gt-btn_md text-white gt-main-btn-WithoutAuth float-end " onClick={submitHandler} tabIndex={3}>
                        {(status === 'pending' || isLoader) ?
                          <i className="fa fa-spinner fa-spin" ></i>
                          : 'Login'}
                      </button>
                    </div>
                  </div>

                  <div className='row'>
                    {/* <p>
                          <label>Password:</label>
                          <input type="password" name="password" id="password" />
                          <i className="fa fa-eye-slash" id="togglePassword"></i>
                      </p> */}
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12 mx-auto">
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>

        </div>
      </div>


    </Fragment>
  );
};

export default LoginForm;