import { Fragment } from 'react';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';

const LoadingSpinnerWithCard = (props) => {

    let showBanner = true;

    if (props && props.showBanner !== undefined) {
        showBanner = props.showBanner;
    }

    return (
        <Fragment>
            {showBanner &&
                <section id='common_banner'>
                    <div className='container'></div>
                </section>
            }
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                <div className="card-body h-251">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12 position-relative">
                                            <LoadingSpinner className={'top'}></LoadingSpinner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>)
}

export default LoadingSpinnerWithCard;