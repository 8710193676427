//https://dexie.org/
import Dexie from 'dexie';

const gtDB = new Dexie('gtIndexedDB');

//TABLE NAMING: Since data will reside in client, make sure the same table is not used in multiple locations.
//Even if you want to re-use the same table, make sure there's no possible conflict or side-effects.

//id is the primary key
//prefix ++ if you want auto-incremented primary key, e.g., ++id

gtDB.version(3).stores({
    travelRequest_document_upload: 'id, documentSortKey',//put only primary key and columns which you want in 'where' clause or sort
    travelrequestdata: 'id',
    airportSuggestions: 'airportCode'
});


export default gtDB;