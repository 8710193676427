import { getPolicyID } from '../.../../utils/OBTFunction';

export const CarDetailsJSON = (selectCar) => {
    //console.log(JSON.stringify(createPaxInformation))
    const CarSerach = JSON.parse(sessionStorage.getItem('carSearchRequestJson'))

    let travelRequestFieldsDataJson = "";
    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
    }

    const carImageSize = ({
        "Images": [
            {
                "ImageType": "ORIGINAL"
            },
            {
                "ImageType": "SMALL"
            }
        ]
    });
    const DetailsJSON = (
        {
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "Data": {
                "POS": null,
                "requestDetails": {
                    "Environment": "PRODUCTION",
                    "primaryLanguageId": "EN",
                    "traceId": JSON.parse(localStorage.getItem('traceID')),
                },
                "cars": CarSerach.Data,
                "carResults": [selectCar],
                "Images": [
                    {
                        "ImageType": "LARGE"
                    }
                ],
                "TPAExtensions": null,
                "policyApplyID": getPolicyID(),
                "tripReason": travelRequestFieldsDataJson.tripReason !== null ? travelRequestFieldsDataJson.tripReason : '',
            }
        }
    )

    return DetailsJSON;
}