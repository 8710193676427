import { Fragment, useEffect, useState, React } from 'react';
import Dropdown from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import useHttp from '../../../../services/use-http';
import { searchCorporateMaster } from '../../../../services/CorporateMaster-API';
import { searchCurrency } from '../../../../services/Currency-API';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { getApprovalHistory } from '../../../../services/Approver-API';
import ApprovalHistory from '../../../MyTrips/Components/SubComponents/ApprovalHistory';
import PopupMedium from "../../../../components/Common/Flight/PopupMedium";
import { setSanitizedHtml } from '../../../../utils';

const ServiceRequest = (props) => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [serviceID, setServiceID] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [remarks, setRemarks] = useState('');
    const [existingConfirmationNumber, setExistingConfirmationNumber] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [totalRateOfService, setTotalRateOfService] = useState('');

    const [serviceSelectionErrorMsg, setServiceSelectionErrorMsg] = useState('');
    const [remarksErrorMsg, setRemarksErrorMsg] = useState('');
    const [existingConfirmationNumberErrorMsg, setExistingConfirmationNumberErrorMsg] = useState('');
    const [currencyErrorMsg, setCurrencyErrorMsg] = useState('');
    const [totalRateofServiceErrorMsg, setTotalRateofServiceErrorMsg] = useState('');

    const { sendRequest: sendRequestCorproateMaster, data: loadedCorproateMaster, status: requestServiceStatus } = useHttp(searchCorporateMaster);

    const { sendRequest: sendSearchCurrency, data: currencyResponse, status: searchCurrencyStatus } = useHttp(searchCurrency);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const [isAddMode, setIsAddMode] = useState(true);
    const [serviceRequests, setServiceRequests] = useState([]);
    const [isServiceSelectionDisabled, setIsServiceSelectionDisabled] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState();

    const { sendRequest: sendGetApprovalHistory, data: approvalHistoryResponse, status: approvalHistoryRequestStatus } = useHttp(getApprovalHistory);
    const [alertModal, setAlertModal] = useState(null);

    let serviceRequestOptions = [];

    const isFlightRequestPresent = JSON.parse(localStorage.getItem("BookingDataTravelRequest")) || false;
    const isHotelRequestPresent = JSON.parse(sessionStorage.getItem('travelreqhotelsearch')) || false;

    //const remarksValidRegex = '^[a-zA-Z.()!0-9/ ,-/<>\n\'=":_]+$';

    //regex to match all characters
    const remarksValidRegex = '^[^]+$';
    const confirmationNoValidRegex = '^[a-zA-Z0-9]+$';

    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }

    loadedData.forEach(element => {
        serviceRequestOptions.push({
            value: element.id,
            label: element.name
        });
    });

    function handleServiceRequestChange(selectedOption) {
        setServiceID(selectedOption.value);
        setServiceName(selectedOption.label);

        setServiceSelectionErrorMsg('');
    }

    function handleRemarksChange(e) {
        let tempRemarks = e.trim();
        tempRemarks = (tempRemarks === '<p><br></p>' || tempRemarks === '<p></p>') ? '' : tempRemarks;
        setRemarks(tempRemarks);
    }

    function handleRemarksBlur(e) {
        setRemarksErrorMsg('');
        if (remarks !== '') {
            //if (!remarks.match(remarksValidRegex)) {
            //    setRemarksErrorMsg(t('errorInvalidData'));
            //    return;
            //}
        }
    }

    function handleExistingConfirmationNumberChange(e) {
        setExistingConfirmationNumber(e.target.value.trim());
    }

    function handleExistingConfirmationNumberBlur() {
        setExistingConfirmationNumberErrorMsg('');
        if (existingConfirmationNumber !== '') {
            if (!existingConfirmationNumber.match(confirmationNoValidRegex)) {
                setExistingConfirmationNumberErrorMsg(t('errorInvalidData'));
            }
        }
    }

    function handleCurrencyChange(selectedOption) {
        if (selectedOption) {
            setSelectedCurrency(selectedOption.value);
        }
        else {
            setSelectedCurrency(null);
        }

        setCurrencyErrorMsg('');
    }

    function handleTotalRateOfServiceChange(e) {
        setTotalRateOfService(e.target.value.trim());
    }

    function handleTotalRateOfServiceBlur() {
        setTotalRateofServiceErrorMsg('');
        if (totalRateOfService !== '') {
            if (isNaN(totalRateOfService)) {
                setTotalRateofServiceErrorMsg(t('errorInvalidData'));
            }
        }
        else {
            setCurrencyErrorMsg('');
        }
    }

    function handleAddServiceRequest(event) {
        event.preventDefault();

        if (!validateServiceRequest()) {
            return;
        }

        let currentRequest = {
            serviceID: serviceID,
            serviceName: serviceName,
            remarks: remarks,
            existingConfirmationNumber: existingConfirmationNumber,
            currencyCode: totalRateOfService.length ? selectedCurrency : '',
            totalRateOfService: totalRateOfService
        };

        let tempServiceRequests = [...serviceRequests];
        if (isAddMode) {
            tempServiceRequests.push(currentRequest);
        }
        else {
            tempServiceRequests.forEach(request => {
                if (request.serviceID === currentRequest.serviceID) {
                    request.remarks = currentRequest.remarks;
                    request.existingConfirmationNumber = currentRequest.existingConfirmationNumber;
                    request.currencyCode = currentRequest.currencyCode;
                    request.totalRateOfService = currentRequest.totalRateOfService;
                }
            });

            setIsAddMode(true);
        }

        updateTravelRequestFieldsDataJson(tempServiceRequests);
        setServiceRequests(tempServiceRequests);
        resetServiceRequestForm();
        toggleSubmitTripReason();
    }

    function handleServiceRequestEdit(event, request) {
        event.preventDefault();

        setServiceID(request.serviceID);
        setRemarks(request.remarks);
        setExistingConfirmationNumber(request.existingConfirmationNumber);
        setSelectedCurrency(request.currencyCode);
        setTotalRateOfService(request.totalRateOfService);

        setIsServiceSelectionDisabled(true);

        setIsAddMode(false);
    }

    function handleServiceRequestDelete(event, serviceID) {
        event.preventDefault();

        setDeleteConfirmModal({
            title: t('labelConfirmationMessage'),
            message: t('labelDeletePrompt'),
            serviceID: serviceID
        });
    }

    function handleOkDeleteConfirm(serviceID) {
        let tempServiceRequests = [...serviceRequests];
        tempServiceRequests = tempServiceRequests.filter(request => request.serviceID !== serviceID);

        updateTravelRequestFieldsDataJson(tempServiceRequests);
        setServiceRequests(tempServiceRequests);
        setDeleteConfirmModal(null);
        toggleSubmitTripReason();
    }

    function handleCancelDelete() {
        setDeleteConfirmModal(null);
    }

    function resetServiceRequestForm() {
        setIsServiceSelectionDisabled(false);
        setServiceID('');
        setRemarks('');
        setSelectedCurrency('');
        setExistingConfirmationNumber('');
        setTotalRateOfService('');
    }

    function validateServiceRequest() {

        setServiceSelectionErrorMsg('');
        if (!serviceID || serviceID === '') {
            setServiceSelectionErrorMsg(t('errorServiceRequired'));
            return false;
        }

        if (isAddMode && serviceRequests && serviceRequests.length) {
            if (serviceRequests.some(request => request.serviceID === serviceID)) {
                setServiceSelectionErrorMsg(t('errorServiceAlreadyAdded'));
                return false;
            }
        }

        setRemarksErrorMsg('');
        if (remarks === '') {
            setRemarksErrorMsg(t('errorRemarksRequired'));
            return false;
        }
        else {
            //if (!remarks.match(remarksValidRegex)) {
            //    setRemarksErrorMsg(t('errorInvalidData'));
            //    return false;
            //}
        }

        setExistingConfirmationNumberErrorMsg('');
        if (existingConfirmationNumber !== '') {
            if (!existingConfirmationNumber.match(confirmationNoValidRegex)) {
                setExistingConfirmationNumberErrorMsg(t('errorInvalidData'));
                return false;
            }
        }

        setCurrencyErrorMsg('');
        setTotalRateofServiceErrorMsg('');
        if (totalRateOfService !== '') {
            if (!selectedCurrency || selectedCurrency.length === 0) {
                setCurrencyErrorMsg(t('errorCurrencyRequired'))
                return false;
            }

            if (isNaN(totalRateOfService)) {
                setTotalRateofServiceErrorMsg(t('errorInvalidData'));
                return false;
            }
        }

        return true;
    }

    function showSubmitButton() {
        if (document.querySelector('#btnSubmit') !== null) {
            document.querySelector('#btnSubmit').classList.remove("d-none");
        }
        if (document.querySelector('#btnReset') !== null) {
            document.querySelector('#btnReset').classList.remove("d-none");
        }
    }

    function hideSubmitButton() {
        if (document.querySelector('#btnSubmit') !== null) {
            document.querySelector('#btnSubmit').classList.add("d-none");
        }
        if (document.querySelector('#btnReset') !== null) {
            document.querySelector('#btnReset').classList.add("d-none");
        }
    }

    function updateTravelRequestFieldsDataJson(serviceRequests) {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.serviceRequests = serviceRequests;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    }

    function toggleSubmitTripReason() {

        let isServiceRequestPresent = false;

        if (sessionStorage.getItem("travelRequestFieldsDataJson")) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length) {
                isServiceRequestPresent = true;
            }
        }

        if (isFlightRequestPresent || isHotelRequestPresent || isServiceRequestPresent) {
            showSubmitButton();
        }
        else {
            hideSubmitButton();
        }

        if (props.toggleTripReason) {
            props.toggleTripReason();
        }
    }

    function handleViewHistory(e) {
        e.preventDefault();

        const request = {
            officeID: props.editedServiceInfo.officeID,
            approvalMasterID: props.editedServiceInfo.approvalMasterID,
            shoppingID: props.editedServiceInfo.shoppingID
        };

        sendGetApprovalHistory(request);
    }

    function handleAlertConfirm() {
        setAlertModal(null);
    }

    useEffect(() => {
        if (currencyResponse) {
            if (currencyResponse.errors.status === 'FALSE' && currencyResponse.data.length) {
                setCurrencyOptions(() =>
                    currencyResponse.data.map((currency) => {
                        return {
                            label: `${currency.currName} (${currency.currCode})`,
                            value: currency.currCode
                        }
                    })
                );
            }
        }
    }, [currencyResponse]);

    useEffect(() => {

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests) {
                setServiceRequests(() => travelRequestFieldsDataJson.serviceRequests);
            }
        }

        const onSearchCorporateMaster = {
            id: '',
            userID: JSON.parse(localStorage.getItem('userID')),
            name: '',
            code: '',
            keyCode: 'SIX',
            status: '2',
            officeID: JSON.parse(localStorage.getItem('officeID'))
        };
        sendRequestCorproateMaster(onSearchCorporateMaster);

        sendSearchCurrency({
            currCode: '',
            currName: ''
        });

    }, []);

    useEffect(() => {
        if (approvalHistoryResponse) {
            if (approvalHistoryResponse.errors.status === 'TRUE') {
                setAlertModal(() => {
                    return {
                        title: t('labelMessage'),
                        message: t('errorGeneric'),
                    }
                });
            }
            else {
                setAlertModal(() => {
                    return {
                        title: "History",
                        message: <ApprovalHistory
                            approvalTypeName={approvalHistoryResponse.approvalTypeName}
                            data={approvalHistoryResponse.data}>
                        </ApprovalHistory>
                    }
                });
            }
        }
    }, [approvalHistoryResponse])

    return (
        <Fragment>

            {alertModal && (
                <PopupMedium
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={handleAlertConfirm}
                ></PopupMedium>
            )}

            {deleteConfirmModal && (
                <ConfirmationModal
                    title={deleteConfirmModal.title}
                    message={deleteConfirmModal.message}
                    onOk={() => handleOkDeleteConfirm(deleteConfirmModal.serviceID)}
                    onCancel={handleCancelDelete}
                ></ConfirmationModal>
            )}

            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <div className='row mb-4'>
                            <div className='col-md-12'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor="Service">{t('labelService')}<span className="error-icon"> *</span></label>
                                <div className="dropdown pb-2 col-md-12">
                                    <Dropdown onChange={handleServiceRequestChange}
                                        value={serviceRequestOptions.filter(option => option.value === serviceID)}
                                        options={serviceRequestOptions}
                                        textField="value"
                                        dataItemKey="label"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />}
                                        controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        placeholder="Please Select"
                                        isDisabled={isServiceSelectionDisabled}
                                        isLoading={requestServiceStatus === 'pending'}
                                    />
                                    {serviceSelectionErrorMsg && serviceSelectionErrorMsg.length > 0 &&
                                        <p className="error-text">{serviceSelectionErrorMsg}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12 dropdown-search-text'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor="Remarks">{t('labelRemarks')}<span className="error-icon"> *</span></label>
                                <div className="dropdown pb-2 col-md-12">                                    
                                    <div style={{ height: 120 }}>
                                        <ReactQuill
                                            theme="snow"
                                            value={remarks}
                                            onChange={handleRemarksChange}
                                            onBlur={handleRemarksBlur}
                                            style={{ height: 70 }} />
                                    </div>
                                    {remarksErrorMsg && remarksErrorMsg.length > 0 &&
                                        <p className="error-text">{remarksErrorMsg}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 dropdown-search-text'>
                                <label className="h6 text-primary mb-2 col-lg-12"
                                    htmlFor="existingConfirmationNumber">
                                    {t('labelConfirmationNo')}
                                </label>
                                <input maxLength={6}
                                    value={existingConfirmationNumber}
                                    className="form-control bg_input"
                                    type='text'
                                    id='existingConfirmationNumber'
                                    onChange={handleExistingConfirmationNumberChange}
                                    onBlur={handleExistingConfirmationNumberBlur}
                                />

                                {existingConfirmationNumberErrorMsg && existingConfirmationNumberErrorMsg.length > 0 &&
                                    <p className="error-text">{existingConfirmationNumberErrorMsg}</p>
                                }
                            </div>
                            <div className='col-md-4'>
                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor="serviceCurrency">
                                    {t('labelCurrency')}
                                    {totalRateOfService && totalRateOfService.length > 0 &&
                                        <span className="error-icon"> *</span>
                                    }
                                </label>
                                <div className="dropdown pb-2 col-md-12">
                                    <Dropdown id="serviceCurrency"
                                        onChange={handleCurrencyChange}
                                        value={currencyOptions.filter(option => option.value === selectedCurrency)}
                                        options={currencyOptions}
                                        textField="value"
                                        dataItemKey="label"
                                        isClearable
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />}
                                        controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        placeholder="Please Select"
                                        isLoading={searchCurrencyStatus === 'pending'}
                                    />
                                </div>
                                {currencyErrorMsg && currencyErrorMsg.length > 0 &&
                                    <p className="error-text">{currencyErrorMsg}</p>
                                }
                            </div>
                            <div className='col-md-4 dropdown-search-text'>
                                <label className="h6 text-primary mb-2 col-lg-12"
                                    htmlFor="totalRateOfService">
                                    {t('labelPriceIfAvail')}
                                </label>
                                <input maxLength={10}
                                    value={totalRateOfService}
                                    className="form-control bg_input"
                                    type='text'
                                    id='totalRateOfService'
                                    onChange={handleTotalRateOfServiceChange}
                                    onBlur={handleTotalRateOfServiceBlur}
                                />

                                {totalRateofServiceErrorMsg && totalRateofServiceErrorMsg.length > 0 &&
                                    <p className="error-text">{totalRateofServiceErrorMsg}</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 accordion-header text-black fw-bold mb-4">
                            <i className="fas fa-info-circle"></i> Note : <span className="text-primary fw-500" >{t('noteConfirmationNo')}</span>
                        </div>
                        <div className="row">
                            <div className='col-md-12'>
                                <a href="/#"
                                    className="btn text-white gt-main-btn btn_md float-end"
                                    onClick={handleAddServiceRequest}>
                                    {isAddMode ? t('labelAdd') : t('labelUpdate')}
                                </a>
                            </div>
                            {props.editedServiceInfo && props.editedServiceInfo.shoppingID > 0 &&
                                <div className='d-none'>
                                    <button id="viewHistory"
                                        className="btn btn-sm  text-white ms-1 gt-border-radius-50  btn-secondary"
                                        data bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        onClick={handleViewHistory}>
                                        {approvalHistoryRequestStatus === 'pending' ?
                                            <i className="fa fa-spinner fa-spin" ></i> :
                                            <i className="fas fa-history"></i>
                                        }
                                    </button>
                                    <ReactTooltip
                                        anchorId="viewHistory"
                                        place="bottom"
                                        variant="info"
                                        multiline={true}
                                        className="tooltip"
                                        html={"View History"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {serviceRequests && serviceRequests.length > 0 &&
                        <div className="table-responsive mt-4 table-head-bg gt-temp table-ui-list">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="" style={{ width: "24%"}} >{t('labelService') }</th>
                                        <th scope="col" className="col-md-4">{t('labelRemarks')}</th>
                                        <th scope="col" className="col-md-2">{t('labelConfirmationNo')}</th>
                                        <th scope="col" className="col-md-2 text-center">{t('labelPrice')}</th>
                                        <th scope="col" className="col-md-1 text-center" style={{ width: "10%"}}>{t('labelAction')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceRequests.map(request => (
                                        <tr key={request.serviceID}>
                                            <td>
                                                {request.serviceName}
                                            </td>
                                            <td>
                                                {setSanitizedHtml(request.remarks)}
                                            </td>
                                            <td>
                                                {request.existingConfirmationNumber}
                                            </td>
                                            <td className="text-end">
                                                {request.totalRateOfService &&
                                                    request.totalRateOfService.length > 0 &&
                                                    <Fragment>
                                                        {
                                                            new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: request.currencyCode,
                                                            }).format(request.totalRateOfService)
                                                        }
                                                    </Fragment>
                                                }
                                                {/*{request.currency + ' ' + request.totalRateOfService}*/}
                                            </td>
                                            <td className='text-center'>
                                                <span>
                                                    <a href="/#"
                                                        id={"edit" + request.serviceID}
                                                        onClick={(event) => { handleServiceRequestEdit(event, request) }}
                                                        title="Edit"
                                                        className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn">
                                                        <i className="fa-solid fa-pen"></i>
                                                    </a>
                                                    <ReactTooltip
                                                        anchorId={"edit" + request.serviceID}
                                                        place="bottom"
                                                        variant="info"
                                                        className="tooltip"
                                                        html={"Edit"}
                                                    />
                                                </span>
                                                &nbsp;
                                                <span>
                                                    <a href="/#"
                                                        id={"delete" + request.serviceID}
                                                        onClick={(event) => { handleServiceRequestDelete(event, request.serviceID) }}
                                                        title="Edit"
                                                        className="btn btn-sm btn-danger gt-border-radius-50 gt-red-btn text-white">
                                                        <i className="fas fa-trash-alt"></i>
                                                    </a>
                                                    <ReactTooltip
                                                        anchorId={"delete" + request.serviceID}
                                                        place="bottom"
                                                        variant="info"
                                                        className="tooltip"
                                                        html={"Delete"}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </Fragment>)

};

export default ServiceRequest;

