import { Fragment, useEffect } from "react";
import { getFormatDate } from "../../../utils/CommonFunction";
import WorldMap from "./WorldMap";
import RotatingPlan from "./RotatingPlan";
const LoadingFlight = ({props,className}) => {
  function LoaderMenuData() {
    var show = [];
    show.push(
      <div>
        Searching for: <br />
      </div>
    );

    props.searchFlight.Data.OriginDestinationInformation.forEach((element) => {
      show.push(
        <div>
          {element.OriginLocation.Location +
            " to " +
            element.DestinationLocation.Location +
            " on " +
            getFormatDate(element.DepartureDateTime.WindowAfter)}
        </div>
      );
    });
    return show;
  }

  return (
    <Fragment>
      <div className="loading">
        <div className={`loader flight-loader-width globeLoader ${className}`}>
          <div className="loaderContaner">
            <div className="plane">
              {/* <img src="assets/img/loader5.gif" className="plane-img" /> */}
              <RotatingPlan />
            </div>
            <div className="postion-relative flight-loaders">
              <div className="earth-wrapper cstm">
                {/* <div className="earth">
              </div> */}
                <div className="worldMap">
                  <div className="worldMap__inner">
                    <WorldMap className={"img1"} />
                    <WorldMap className={"img2"} />
                    <WorldMap className={"img3"} />
                    <WorldMap className={"img4"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="iata_code arrival_city">Globetrotter</div>
          <div className="loaderContent text-center w-100">
            {/* <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div> */}
            <div>
              We are searching for <i>live</i> flights and fares
              <i> in real-time.</i>
              <br></br>
              Please wait for a few seconds…
            </div>
          </div>
        </div>
      </div>
      {/* <div className='loading'>
        <div className='flight-loader2'>
        </div>
        <div className='position-absolute bottom-0 text-center'>
            <div >
              We are looking for the best available flights for your search.
              <br></br>
              This might take a few seconds. Please do not close this window.
            </div>
            <div className='fw-bold mt-2'>
              <LoaderMenuData></LoaderMenuData>
            </div>
          </div>
      </div> */}
    </Fragment>
  );
};

export default LoadingFlight;
