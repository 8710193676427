import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import { searchCar } from '../../services/Car-API';
import useHttp from '../../services/use-http';
import CarList from './Components/CarList';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingCar from './Components/LoadingCar';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../services/CONSTANTS';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../utils/ConfigurationsFunction';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { Tooltip as ReactTooltip } from "react-tooltip";

import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import sorter from "sort-nested-json";
import secureLocalStorage from 'react-secure-storage';
import { searchRoe } from '../../services/ROE-API';
import HomePageCar from './Components/HomePageCar';
import { v4 as uuidv4 } from 'uuid';

const SearchCar = () => {
    useDocumentTitle('Car Search');
    const { sendRequest, status, data: loadedCarSearch, error } = useHttp(searchCar);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const { t } = useTranslation(['translation.SearchCar']);
    const history = useHistory();
    const [minToDateChange, setMinToDateChange] = useState('');
    const [error1, setError] = useState('');
    const [pageFocus, setPageFocus] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [items, setItems] = useState([]);

    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    const isCorporate = JSON.parse(localStorage.getItem("isCorporate"));

    const refSource = useRef(null);
    useOutsideAlerter(refSource);

    const refDestination = useRef(null);
    useOutsideAlerter(refDestination);

    var fromTodayDate = new Date();
    fromTodayDate.setDate(fromTodayDate.getDate() + 14);

    var toTodayDate = new Date();
    toTodayDate.setDate(toTodayDate.getDate() + 15);

    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Price' }
    ];

    if (sessionStorage.getItem("carSessionDataJson") === null) {
        const carSessionDataJson = ({
            "txtSource": {},
            "spnSourceAirportDetails": '',
            "sourceCityCode": {},
            "txtDestination": {},
            "spnDestinationAirportDetails": '',
            "destinationCityCode": {},
            "calCheckInDateDB": {},
            "calCheckOutDateDB": {},
            "calCheckIn": {},
            "calCheckOut": {},
            "departureTime": {},
            "arrivalTime": {}
        });
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionDataJson));
    }

    if (sessionStorage.getItem("carSessionDataJson") !== null) {
        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

        if (Object.keys(carSessionJson.calCheckIn).length > 0) {
            //let minToDateChangeTemp = new Date(carSessionJson.calCheckIn);            
            let minToDateChangeTemp = moment(carSessionJson.calCheckIn, "ddd, MMM DD, 'YY").toDate();
            minToDateChangeTemp.setDate(minToDateChangeTemp.getDate());
            sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
        }
        else {
            //let minToDateChangeTemp = new Date(fromTodayDate);
            let minToDateChangeTemp = moment(carSessionJson.calCheckIn, "ddd, MMM DD, 'YY").toDate();
            minToDateChangeTemp.setDate(minToDateChangeTemp.getDate());
            sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
        }

        if (valueParam.addhotel !== "1") {
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");
        }
    }

    useEffect(() => {

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }

    }, [])

    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

        if (isCorporate && (valueParam.travelreq === undefined || valueParam.travelreq === null)) {
            history.push('/TravelRequest')
        }

    }, [])

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref.current.innerText === "Pick-up Location") {
                        document.querySelector('#dvSource').style.display = "none";
                        document.querySelector('#txtSource').style.display = "";
                        document.querySelector('#spnSourceAirportDetails').style.display = "";
                    }
                    if (ref.current.innerText === "Drop-off Location") {
                        document.querySelector('#dvDestination').style.display = "none";
                        document.querySelector('#txtDestination').style.display = "";
                        document.querySelector('#spnDestinationAirportDetails').style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const switchRoute = (event) => {
        let txtSource = document.querySelector('#txtSource').value;
        let spnSourceAirportDetails = document.querySelector('#spnSourceAirportDetails').innerHTML;

        document.querySelector('#txtSource').value = document.querySelector('#txtDestination').value;
        document.querySelector('#spnSourceAirportDetails').innerHTML = document.querySelector('#spnDestinationAirportDetails').innerHTML;
        document.querySelector('#spnSourceCityCode').innerHTML = document.querySelector('#spnDestinationCityCode').innerHTML;
        carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
        let sourceCityCodeTemp = carSessionJson.sourceCityCode;
        carSessionJson.txtSource = document.querySelector('#txtSource').value;
        carSessionJson.spnSourceAirportDetails = document.querySelector('#spnSourceAirportDetails').innerHTML;
        carSessionJson.sourceCityCode = carSessionJson.destinationCityCode;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));

        document.querySelector('#txtDestination').value = txtSource;
        document.querySelector('#spnDestinationAirportDetails').innerHTML = spnSourceAirportDetails;
        document.querySelector('#spnDestinationCityCode').innerHTML = sourceCityCodeTemp;
        carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
        carSessionJson.txtDestination = txtSource;
        carSessionJson.spnDestinationAirportDetails = spnSourceAirportDetails;
        carSessionJson.destinationCityCode = sourceCityCodeTemp;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
    }

    const handleOnClickSource = () => {
        document.querySelector("#txtSource").style.display = "none";
        document.querySelector("#dvSource").style.display = "";
        document.querySelector('#spnSourceAirportDetails').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[0] !== undefined) {
                controlCount[0].focus();
            }
        }
    }

    const handleOnClickDestination = () => {
        document.querySelector("#txtDestination").style.display = "none";
        document.querySelector("#dvDestination").style.display = "";
        document.querySelector('#spnDestinationAirportDetails').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[1] !== undefined) {
                controlCount[1].focus();
            }
        }
    }

    const handleOnSourceSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")).filter(a => a.airportName !== 'All Airports'));
            }
        }
    }

    const handleOnDestinationSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")).filter(a => a.airportName !== 'All Airports'));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.airportCode} - {item.airportName}</span>
            </>
        )
    }

    function allowOnlyNumbers(event) {
        var input = event.target.value;
        var pattern = /^[1-9]\d{0,2}$/;
        if (!pattern.test(input)) {
            event.target.value = input.slice(0, 3);
        }

    }

    const handleOnSelectSource = (item) => {
        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
        carSessionJson.txtSource = item.cityName;
        carSessionJson.spnSourceAirportDetails = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        carSessionJson.sourceCityCode = item.cityCode;

        document.querySelector("#txtSource").style.display = "";
        document.querySelector('#txtSource').value = item.cityName;
        document.querySelector("#dvSource").style.display = "none";
        document.querySelector('#spnSourceAirportDetails').style.display = "";
        document.querySelector('#spnSourceAirportDetails').innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;

        //auto select source details in drop-off fields, on change of pick-up location        
        carSessionJson.txtDestination = item.cityName;
        carSessionJson.spnDestinationAirportDetails = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        carSessionJson.destinationCityCode = item.cityCode;

        document.querySelector("#txtDestination").style.display = "";
        document.querySelector('#txtDestination').value = item.cityName;
        document.querySelector("#dvDestination").style.display = "none";
        document.querySelector('#spnDestinationAirportDetails').style.display = "";
        document.querySelector('#spnDestinationAirportDetails').innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
    }

    const handleOnSelectDestination = (item) => {
        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
        carSessionJson.txtDestination = item.cityName;
        carSessionJson.spnDestinationAirportDetails = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        carSessionJson.destinationCityCode = item.cityCode;

        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));


        document.querySelector("#txtDestination").style.display = "";
        document.querySelector('#txtDestination').value = item.cityName;
        document.querySelector("#dvDestination").style.display = "none";
        document.querySelector('#spnDestinationAirportDetails').style.display = "";
        document.querySelector('#spnDestinationAirportDetails').innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
    }

    const onSearchHandler = useCallback((event) => {
        localStorage.setItem('traceID', JSON.stringify(uuidv4()));

        let blockedCountryCity = [];
        blockedCountryCity = localStorage.getItem("blockedCountryCity") !== null ? JSON.parse(localStorage.getItem("blockedCountryCity")) : '';

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        event.preventDefault();
        setIsSubmitClicked(true);
        setPageFocus(true);

        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

        let checkInDate = carSessionJson.calCheckInDateDB;
        let checkOutDate = carSessionJson.calCheckOutDateDB;

        if (parseInt(checkOutDate) < parseInt(checkInDate)) {
            setError({
                title: "Message",
                message: `${t('Check-out cannot be less than Check-in.')}`,
            });
            return;
        }

        let departureTimeTemp = document.querySelector('#departureTime').value.replaceAll(':', '') === '0000' ? '2400' : parseInt(document.querySelector('#departureTime').value.replaceAll(':', ''))
        let arrivalTimeTemp = document.querySelector('#arrivalTime').value.replaceAll(':', '') === '0000' ? '2400' : parseInt(document.querySelector('#arrivalTime').value.replaceAll(':', ''))

        if (document.querySelector('#departureTime').value === '' || document.querySelector('#arrivalTime').value === '') {
            setError({
                title: "Message",
                message: `${t('Pick-up time and Drop-off time can not be blank.')}`,
            });
            return;
        }

        if (carSessionJson.calCheckInDateDB === carSessionJson.calCheckOutDateDB && document.querySelector('#departureTime').value.replace(':', '') === document.querySelector('#arrivalTime').value.replace(':', '')) {
            setError({
                title: "Message",
                message: `${t('Pick-up time and Drop-off time can not be same.')}`,
            });
            return;
        }

        if (parseInt(carSessionJson.calCheckInDateDB) === parseInt(new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString() + new Date().getDate().toString())) {
            if ((parseInt(departureTimeTemp) < parseInt(new Date().getHours().toString() + new Date().getMinutes().toString())) || (parseInt(carSessionJson.calCheckOutDateDB) <= parseInt(carSessionJson.calCheckInDateDB) && parseInt(arrivalTimeTemp) < parseInt(new Date().getHours().toString() + new Date().getMinutes().toString()))) {
                setError({
                    title: "Message",
                    message: `${t('Pick-up time or Drop-off time can not be past time.')}`,
                });
                return;
            }
        }

        if (carSessionJson.calCheckInDateDB === carSessionJson.calCheckOutDateDB) {
            if (parseInt(parseInt(document.querySelector('#arrivalTime').value.replaceAll(':', '')) - parseInt(document.querySelector('#departureTime').value.replaceAll(':', ''))) < 100) {
                setError({
                    title: "Message",
                    message: `${t('Drop-off time must be at least 1 hours after Pick-up time.')}`,
                });
                return;
            }
        }

        if (blockedCountryCity.length !== 0) {
            let selectedSourceCity = carSessionJson.spnSourceAirportDetails.split('-')[0].trim();
            let selectedSourceCountry = carSessionJson.spnSourceAirportDetails.split(',')[2].trim();

            let selectedDestinationCity = carSessionJson.spnDestinationAirportDetails.split('-')[0].trim();
            let selectedDestinationCountry = carSessionJson.spnDestinationAirportDetails.split(',')[2].trim();

            const found = blockedCountryCity.find(el => el.code === selectedSourceCity || el.code === selectedSourceCountry || el.code === selectedDestinationCity || el.code === selectedDestinationCountry);
            if (found) {
                if (found.service === 'FL' || found.service === 'BOTH') {
                    setError({
                        title: "Message",
                        message: `${t('Sorry, search for this pick-up or drop-off location is not allowed. Please contact the Support desk.')}`,
                    });
                    return;
                }
            }
        }

        let travelRequestFieldsDataJson = "";
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        }
        sessionStorage.setItem('carMailSend', 'false');
        const onSearchCar = (
            {
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "data": {
                    "traceId": JSON.parse(localStorage.getItem('traceID')),
                    "ipAddress": null,
                    "primaryLangID": "EN",
                    "vehAvailRQCore": {
                        "vehRentalCore": {
                            "pickUpLocation": {
                                "locationCode": carSessionJson.spnSourceAirportDetails.split('-')[0].trim().toUpperCase(),
                                "locationType": "AIRPORT"
                            },
                            "returnLocation": {
                                "locationCode": carSessionJson.spnDestinationAirportDetails.split('-')[0].trim().toUpperCase(),
                                "locationType": "AIRPORT"
                            },
                            "pickupDate": carSessionJson.calCheckInDateDB,
                            "pickupTime": document.querySelector('#departureTime').value === '' ? '' : document.querySelector('#departureTime').value.replace(':', ''),
                            "returnDate": carSessionJson.calCheckOutDateDB,
                            "returnTime": document.querySelector('#arrivalTime').value === '' ? '' : document.querySelector('#arrivalTime').value.replace(':', '')
                        }
                    }
                }
            }
        );
        sessionStorage.setItem("carSearchRequestJson", JSON.stringify(onSearchCar));

        sendRequest(onSearchCar);

    }, [sendRequest])

    const errorHandler = () => {
        setError(false);
        setPageFocus(false);
        setloadedData([]);
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
    };

    //let loadedData = [];
    const [loadedData, setloadedData] = useState([]);
    useEffect(() => {
        for (let i in loadedCarSearch) {
            if (loadedCarSearch.data.errors.status === 'FALSE') {
                var arrayTemp = [];
                loadedCarSearch.data.vehAvailInfo.forEach(element1 => {
                    const found = arrayTemp.find(el => el.sequencenumber === element1.sequencenumber);
                    if (!found) {
                        arrayTemp.push({
                            vehicleCharges: parseFloat(element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount),
                            sequencenumber: element1.sequencenumber
                        });
                    }
                });
                let list = sorter.sort(arrayTemp).asc("vehicleCharges");

                let carResultsTemp = [];
                list.forEach(element => {
                    carResultsTemp.push(
                        loadedCarSearch.data.vehAvailInfo.filter(x => x.sequencenumber === element.sequencenumber)[0]
                    );
                });
                const carResponse = (
                    {
                        "data": {
                            "requestDetails": loadedCarSearch.data.requestDetails,
                            "carResults": carResultsTemp
                        }
                    });

                setloadedData(carResponse);
                sessionStorage.setItem("carSearchResponse", JSON.stringify(carResponse));
            }
            else {
                setloadedData([]);
            }
        }
    }, [loadedCarSearch])

    let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        sessionStorage.setItem("myTripIsCarCancelled", '');
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        if (document.querySelector("#lblFlights") !== null) {
            document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights").classList.add("text-primary");
        }

        if (document.querySelector("#lblHotel") !== null) {
            document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblHotel").classList.add("text-primary");
        }

        document.querySelector("#lblCar").classList.remove("text-primary");
        document.querySelector("#lblCar").classList.add("flight-active-tab");

        if (document.querySelector("#lblFlightPlusHotel") !== null) {
            document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights").classList.add("text-primary");
            }

            document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblHotel").classList.add("text-primary");

            document.querySelector("#lblCar").classList.remove("text-primary");
            document.querySelector("#lblCar").classList.add("flight-active-tab");
        }

        if (document.querySelector("#lblSightseeing") !== null) {
            document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
            document.querySelector("#lblSightseeing").classList.add("text-primary");
        }

        if (document.querySelector("#lblHolidays") !== null) {
            document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
            document.querySelector("#lblHolidays").classList.add("text-primary");
        }
        if (document.querySelector("#lblVisa") !== null) {
            document.querySelector("#lblVisa").classList.add("text-primary");
            document.querySelector("#lblVisa").classList.remove("flight-active-tab");
        }

        if (valueParam.addhotel !== "1" || valueParam.addhotel === undefined) {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");
        }

    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedCarSearch !== null && loadedCarSearch.data !== null && loadedCarSearch.data.errors.status === 'TRUE') {
                setError({
                    title: "Message",
                    message: loadedCarSearch.data.errors.error.description,
                });
            }
        }

    }, [status, loadedCarSearch])

    const [searchCarList, setSearchCarList] = useState();

    useEffect(() => {
        setSearchCarList(((status === 'pending') ? '' : loadedData && loadedData.length !== 0) ?
            <Fragment>
                <CarList searchCar={loadedData} pageFocus={pageFocus} />
            </Fragment> : '')
    }, [loadedData, status])


    useEffect(() => {
        document.querySelector('#dvSourceSpinner').style.display = "none";
        document.querySelector('#dvDestinationSpinner').style.display = "none";

        if (localStorage.getItem("airportSuggestion") != null) {
            //const filteredData = JSON.parse(localStorage.getItem("airportSuggestion")).filter(a => a.airportName !== 'All Airports');
            setItems(JSON.parse(localStorage.getItem("airportSuggestion")).filter(a => a.airportName !== 'All Airports'));
        }

        document.querySelector("#dvSource").style.display = "none";
        document.querySelector("#dvDestination").style.display = "none";

        if (Object.keys(carSessionJson.txtSource).length > 0) {
            document.querySelector("#txtSource").value = carSessionJson.txtSource;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
            document.querySelector("#txtSource").value = strSightData.cityName;
            document.querySelector('#spnSourceAirportDetails').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"JFK - John F Kennedy, United States of America, US";

            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.txtSource = strSightData.cityName;
            carSessionJson.spnSourceAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            carSessionJson.sourceCityCode = strSightData.cityCode;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (carSessionJson.spnSourceAirportDetails && carSessionJson.spnSourceAirportDetails.length > 0) {
            document.querySelector('#spnSourceAirportDetails').innerHTML = carSessionJson.spnSourceAirportDetails;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
            document.querySelector("#txtSource").value = strSightData.cityName;
            document.querySelector('#spnSourceAirportDetails').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"JFK - John F Kennedy, United States of America, US";

            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.txtSource = strSightData.cityName;
            carSessionJson.spnSourceAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            carSessionJson.sourceCityCode = strSightData.cityCode;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (Object.keys(carSessionJson.calCheckIn).length > 0) {
            document.querySelector('#calCheckIn').value = carSessionJson.calCheckIn;
        }
        else {
            carSessionJson.calCheckIn = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckIn').value = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
            const checkInDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.calCheckInDateDB = checkInDateDB;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (Object.keys(carSessionJson.calCheckOut).length > 0) {
            document.querySelector('#calCheckOut').value = carSessionJson.calCheckOut;

            if ((minToDateChange === undefined || minToDateChange == '') && Object.keys(carSessionJson.calCheckIn).length > 0) {
                //let minToDateChangeTemp = new Date(carSessionJson.calCheckIn.replace("'", ""));
                let minToDateChangeTemp = moment(carSessionJson.calCheckIn, "ddd, MMM DD, 'YY").toDate();
                minToDateChangeTemp.setDate(minToDateChangeTemp.getDate());
                sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
            }
        }
        else {
            carSessionJson.calCheckOut = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckOut').value = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
            const checkOutDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.calCheckOutDateDB = checkOutDateDB;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (Object.keys(carSessionJson.txtDestination).length > 0) {
            document.querySelector("#txtDestination").value = carSessionJson.txtDestination;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
            document.querySelector("#txtDestination").value = strSightData.cityName;
            document.querySelector('#spnDestinationAirportDetails').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"JFK - John F Kennedy, United States of America, US";

            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.txtDestination = strSightData.cityName;
            carSessionJson.spnDestinationAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            carSessionJson.destinationCityCode = strSightData.cityCode;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (carSessionJson.spnDestinationAirportDetails && carSessionJson.spnDestinationAirportDetails.length > 0) {
            document.querySelector('#spnDestinationAirportDetails').innerHTML = carSessionJson.spnDestinationAirportDetails;
        }
        else {
            var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
            var strSightData = strSight?.fromToListResponse.find(x => x.service == "FL" && x.direction == "FROM");
            document.querySelector("#txtDestination").value = strSightData.cityName;
            document.querySelector('#spnDestinationAirportDetails').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"JFK - John F Kennedy, United States of America, US";

            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.txtDestination = strSightData.cityName;
            carSessionJson.spnDestinationAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;
            carSessionJson.destinationCityCode = strSightData.cityCode;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (Object.keys(carSessionJson.calCheckIn).length > 0) {
            document.querySelector('#calCheckIn').value = carSessionJson.calCheckIn;
        }
        else {
            carSessionJson.calCheckIn = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckIn').value = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
            const checkInDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.calCheckInDateDB = checkInDateDB;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (Object.keys(carSessionJson.calCheckOut).length > 0) {
            document.querySelector('#calCheckOut').value = carSessionJson.calCheckOut;

            if ((minToDateChange === undefined || minToDateChange == '') && Object.keys(carSessionJson.calCheckIn).length > 0) {
                //let minToDateChangeTemp = new Date(carSessionJson.calCheckIn.replace("'", ""));
                let minToDateChangeTemp = moment(carSessionJson.calCheckIn, "ddd, MMM DD, 'YY").toDate();
                minToDateChangeTemp.setDate(minToDateChangeTemp.getDate());
                sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
            }
        }
        else {
            carSessionJson.calCheckOut = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckOut').value = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
            const checkOutDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));
            carSessionJson.calCheckOutDateDB = checkOutDateDB;
            sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
        }

        if (document.querySelector('#departureTime').value === '') {
            document.querySelector('#departureTime').value = "10:30";
        }

        if (document.querySelector('#arrivalTime').value === '') {
            document.querySelector('#arrivalTime').value = "10:30";
        }

        if (minToDateChange !== undefined && minToDateChange !== '' && document.querySelector('#calCheckOut') !== null) {
            document.querySelector('#calCheckOut').focus();
        }

    }, [minToDateChange])

    const calGetDateCheckIn = (inputDate) => {
        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();
        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

        if (Object.keys(carSessionJson.calCheckOut).length > 0) {
            let destinationDateTemp = new Date(inputDate.target.value.trim());
            destinationDateTemp.setDate(destinationDateTemp.getDate());
            destinationDate = new Date(carSessionJson.calCheckOut.replace("'", "")) > new Date(inputDate.target.value.trim()) ? new Date(carSessionJson.calCheckOut.replace("'", "")) : new Date(destinationDateTemp)
        }
        else {
            let destinationDateTemp = new Date(inputDate.target.value.trim());
            destinationDateTemp.setDate(destinationDateTemp.getDate());
            destinationDate = destinationDateTemp;
        }

        carSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        const checkInDate = new Date(sourceDate);
        var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        carSessionJson.calCheckInDateDB = checkInDateDB;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));

        carSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        carSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));

        let minToDateChangeTemp = new Date(carSessionJson.calCheckIn.replace("'", ""));
        minToDateChangeTemp.setDate(minToDateChangeTemp.getDate());
        setMinToDateChange(new Date(minToDateChangeTemp));
    }

    const calGetDateCheckOut = (inputDate) => {
        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();

        let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

        // carSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // const checkInDate = new Date(sourceDate);
        // var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        // carSessionJson.calCheckInDateDB = checkInDateDB;
        // sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));

        carSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        carSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("carSessionDataJson", JSON.stringify(carSessionJson));
    }

    window.addEventListener('click', function (e) {
        if (document.querySelector('#dvPassengers1') !== null) {
            if (document.querySelector('#dvPassengers1').contains(e.target)) {
                if (e.target.id === "ancHidePassengerDropDown") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
            } else {
                if (e.target.parentNode.id === "btnPassengerDropDown1" || e.target.id === "btnPassengerDropDown1") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
            }
        }
    });


    const controlHandlerFightSearch = (event) => {
        if (event.target.id === 'lblFlights') {
            history.push('/FlightSearch?Flights=1');
        }
        else if (event.target.id === 'lblHotel') {
            history.push('/SearchHotel');
        }
        else if (event.target.id === 'lblFlightPlusHotel') {
            history.push('/FlightSearch?FlightPlusHotel=1');
        }
        else if (event.target.id === 'lblSightseeing') {
            history.push('/SearchSightseeing');
        }
        else if (event.target.id === 'lblHolidays') {
            //history.push('/SearchHolidays');
            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
        else if (event.target.id === 'lblVisa') {
            history.push('/SearchVisa')
        }
        else if (event.target.id === 'lblCar') {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.add("text-primary");
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel") !== null) {
                document.querySelector("#lblHotel").classList.add("text-primary");
                document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblCar") !== null) {
                document.querySelector("#lblCar").classList.add("flight-active-tab");
                document.querySelector("#lblCar").classList.remove("text-primary");
            }

            if (document.querySelector("#lblSightseeing") !== null) {
                document.querySelector("#lblSightseeing").classList.add("text-primary");
                document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays") !== null) {
                document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays").classList.add("text-primary");
            }
        }
    }

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    function getCheckOutMinDate() {

        let minDate = minToDateChange !== undefined &&
            minToDateChange !== '' ? new Date(minToDateChange) :
            Object.keys(carSessionJson.calCheckIn).length > 0 ?
                sessionStorage.getItem("InitialLoadCheckOutMinvalue") !== null &&
                    sessionStorage.getItem("InitialLoadCheckOutMinvalue") !== '' ?
                    new Date(sessionStorage.getItem("InitialLoadCheckOutMinvalue").replace("'", "")) :
                    new Date(carSessionJson.calCheckIn.replace("'", "")) :
                valueParam.addhotel === "1" ? sessionStorage.getItem("singlePnrCheckOutMinvalue") !== null &&
                    sessionStorage.getItem("singlePnrCheckOutMinvalue") !== '' ?
                    new Date(sessionStorage.getItem("singlePnrCheckOutMinvalue").replace("'", "")) :
                    new Date(fromTodayDate) : new Date(fromTodayDate);


        return minDate;
    }

    return <Fragment>
        {
            error1 && <ErrorModal
                title={error1.title}
                message={t(error1.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }
        <section className="sub-banner">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">
            </div>
        </section>

        <section id="theme_search_form_tour" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative my-2">
                            <div className="row position-relative">
                                {status === 'pending' &&
                                    <LoadingCar action='search'></LoadingCar>
                                }

                                <div className="col-lg-12">
                                    <div className="tour_search_form">
                                        <form action="!#">
                                            <div className="row position-relative">
                                                <label htmlFor="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                                                <div className='text-sm-center1 mb-2'>
                                                    <span>
                                                        {
                                                            (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) && (valueParam.src === undefined || valueParam.src === '' || valueParam.src === 'flight') &&
                                                            <label htmlFor="" className="h6 mb-2" role="button" id='lblFlights' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                                        }
                                                        {
                                                            (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                                                getConfigurationByBoolen("ALLOW_HOTEL") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                                                && getConfigurationByBoolen("ALLOW_FLIGHT")) && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") ?
                                                                <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblFlightPlusHotel' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                                                <label id='lblFlightPlusHotel' ></label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                                            <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblHotel' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Car") && checkSecurityDetails("Book", "Car", "Book_Car_Search") && getConfigurationByBoolen("ALLOW_CAR") === true) &&
                                                            <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblCar' onClick={controlHandlerFightSearch}><i className="fas fa-car"></i> Cars</label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                                            <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                                            <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblHolidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                                        }
                                                        {getConfigurationByBoolen("ALLOW_VISA") === true && checkSecurityDetails("Book", "e-Visa") && checkSecurityDetails("Book", "e-Visa", "Book_e-Visa_Allow e-Visa") &&
                                                            <label htmlFor="" className="h6 mb-2 ms-3" role="button" id='lblVisa' onClick={controlHandlerFightSearch}><i className="fas fa-passport"></i> e-Visa</label>
                                                        }
                                                    </span>
                                                </div>
                                                <div className={!isCorporate ? "col-lg-4 col-md-12 col-sm-12 col-12" : "col-lg-5 col-md-12 col-sm-12 col-12"}>
                                                    <div className="flight_Search_boxed py-2">
                                                        <div className='pb-1 h5 mb-0' id='dvFrom'>
                                                            <div ref={refSource} className="position-relative" >
                                                                <p onClick={handleOnClickSource}>{t('Pick-up Location')} </p>
                                                                <div className=''>
                                                                    <input id={'txtSource'} type="text" onClick={handleOnClickSource} />
                                                                    <div id='spnSourceAirportDetails' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                                                </div>
                                                                <div id={'dvSourceSpinner'} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                                                <div id={'dvSource'} >
                                                                    <ReactSearchAutocomplete
                                                                        onSearch={handleOnSourceSearch}
                                                                        items={items}
                                                                        formatResult={formatResult}
                                                                        autoFocus
                                                                        onSelect={handleOnSelectSource}
                                                                        showNoResults={false}
                                                                        maxResults={15}
                                                                        fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                                                        resultStringKeyName="cityName"

                                                                    />
                                                                </div>
                                                            </div>
                                                            <span className='d-none' id={'spnSourceCityCode'}></span>
                                                            <div className="plan_icon_posation">
                                                                <i className="fas fa-car"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={!isCorporate ? "col-lg-4 col-md-12 col-sm-12 col-12" : "col-lg-5 col-md-12 col-sm-12 col-12"}>
                                                    <div className="flight_Search_boxed py-2">
                                                        <div className='pb-1 h5 mb-0' id='dvTo'>
                                                            <div ref={refDestination} className="position-relative" >
                                                                <p onClick={handleOnClickDestination}>{t('Drop-off Location')} </p>
                                                                <div className=''>
                                                                    <input id={'txtDestination'} type="text" onClick={handleOnClickDestination} />
                                                                    <div id='spnDestinationAirportDetails' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                                                </div>
                                                                <div id={'dvDestinationSpinner'} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                                                <div id={'dvDestination'} >
                                                                    <ReactSearchAutocomplete
                                                                        onSearch={handleOnDestinationSearch}
                                                                        items={items}
                                                                        formatResult={formatResult}
                                                                        autoFocus
                                                                        onSelect={handleOnSelectDestination}
                                                                        showNoResults={false}
                                                                        maxResults={15}
                                                                        fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                                                        resultStringKeyName="cityName"

                                                                    />
                                                                </div>
                                                            </div>
                                                            <span className='d-none' id={'spnDestinationCityCode'}></span>
                                                            <div className="plan_icon_posation">
                                                                <i className="fas fa-car"></i>
                                                            </div>
                                                            <div className="range_plan d-none">
                                                                <span>
                                                                    <i style={{ cursor: 'pointer' }} id={'routeSwitch-1'} onClick={switchRoute} className="fas fa-exchange-alt"></i>
                                                                    <ReactTooltip
                                                                        anchorId={'routeSwitch-1'}
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={"Swap"}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                                                    <div className='row'>
                                                        <div id='dvDateRange' className="col-lg-12 col-md-6 col-sm-12 col-12">
                                                            <div className="form_search_date">
                                                                <div className="flight_Search_boxed date_flex_area padding-2y">
                                                                    <div className="Journey_date">
                                                                        <p>Pick-up Date</p>
                                                                        <DateRangePicker
                                                                            onHide={calGetDateCheckIn}
                                                                            initialSettings={{
                                                                                startDate: Object.keys(carSessionJson.calCheckIn).length > 0 ? new Date(carSessionJson.calCheckIn.replace("'", "")) : valueParam.addhotel === "1" ? sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : fromTodayDate : fromTodayDate, endDate: '', singleDatePicker: true,
                                                                                showDropdowns: true, autoApply: true, minDate: new Date()
                                                                            }} >
                                                                            <input id={'calCheckIn'} type="text" autoComplete='off' />
                                                                        </DateRangePicker>
                                                                        <input type="time" id='departureTime' className="form-control form-control-sm" />
                                                                    </div>
                                                                    <div className="Journey_date">
                                                                        <p>Drop-off Date</p>
                                                                        <DateRangePicker key={minToDateChange}
                                                                            onHide={calGetDateCheckOut}
                                                                            initialSettings={{
                                                                                startDate: Object.keys(carSessionJson.calCheckOut).length > 0 ? new Date(carSessionJson.calCheckOut.replace("'", "")) : valueParam.addhotel === "1" ? sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : toTodayDate : toTodayDate,
                                                                                endDate: '',
                                                                                singleDatePicker: true,
                                                                                showDropdowns: true, autoApply: true,
                                                                                minDate: getCheckOutMinDate()
                                                                            }} >
                                                                            <input id={'calCheckOut'} type="text" autoComplete='off' />
                                                                        </DateRangePicker>
                                                                        <input type="time" id='arrivalTime' className="form-control form-control-sm" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="top_form_search_button my-3">
                                                    <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* /* <!-- Car Offer Area -->     /* <!-- imagination Area --> */}
        {getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' && !isSubmitClicked &&
            <HomePageCar />
        }

        {searchCarList}
        {/*    <!-- End Main --> */}

    </Fragment>

};

export default SearchCar;