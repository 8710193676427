import { sortHotelResult } from '../utils/HotelSort';
import { getConfigurationByBoolen, getConfigurationByValue } from '../utils/ConfigurationsFunction';

export const filterHotelResults = (filterOptions, roomPrice, roomName, brandName, ratePlanName, contentCode, sortValue, sortBy, manualFilter = '') => {
    let hotelResultTemp = '';
    if (manualFilter === '') {
        let hotelSearchResponseData = JSON.parse(sessionStorage.getItem('hotelSearchResponse'));
        hotelResultTemp = hotelSearchResponseData.data.hotelResults;
    }
    else {
        hotelResultTemp = JSON.parse(sessionStorage.getItem('initialHotelResults'));
    }

    hotelResultTemp = filterRoomPrice(hotelResultTemp, roomPrice);
    hotelResultTemp = filterHotelName(hotelResultTemp, roomName);
    hotelResultTemp = filterHotelBrands(hotelResultTemp, brandName);
    hotelResultTemp = filterPropertyType(hotelResultTemp);
    // User Story 9645: Remove Exclusive Deals option in Hotel Search and Filters
    if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
        hotelResultTemp = filterHotelExclusiveDeals(hotelResultTemp, ratePlanName);
    }
    if (getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE")) {
        hotelResultTemp = filterHotelContent(hotelResultTemp, contentCode);
    }
    hotelResultTemp = filterRefundableTypes(hotelResultTemp);
    hotelResultTemp = filterCommission(hotelResultTemp);

    hotelResultTemp = sortHotelResult(sortValue, hotelResultTemp, sortBy);

    return hotelResultTemp;
}

export const filterRoomPrice = (hotelResult, roomPrice) => {
    let minRoomPrice = parseFloat(roomPrice[0]);
    let maxRoomPrice = Math.ceil(roomPrice[1]);

    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        if (element1.price[0].offeredPrice.perNight >= minRoomPrice && element1.price[0].offeredPrice.perNight <= maxRoomPrice) {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                hotelResultTemp.push(element1);
                arrayTemp.push({
                    sequenceId: element1.sequenceId
                });
            }
        }
    });

    if (roomPrice.length > 0) {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterHotelName = (hotelResult, roomName) => {
    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        if (element1.hotelName === roomName) {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                hotelResultTemp.push(element1);
                arrayTemp.push({
                    sequenceId: element1.sequenceId
                });
            }
        }
    });

    if (roomName !== '') {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterHotelBrands = (hotelResult, brandName) => {
    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        if (element1.chainName + " (" + element1.chainCode + ")" === brandName) {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                hotelResultTemp.push(element1);
                arrayTemp.push({
                    sequenceId: element1.sequenceId
                });
            }
        }
    });

    if (brandName !== '') {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterPropertyType = (hotelResult) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.propertytypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        if (element1.propertyTypeInfo !== undefined && element1.propertyTypeInfo !== null) {
            element1.propertyTypeInfo.forEach(element2 => {
                if (filterOptions.includes(element2.description)) {
                    const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                    if (!found) {
                        hotelResultTemp.push(element1);
                        arrayTemp.push({
                            sequenceId: element1.sequenceId
                        });
                    }
                }
            });
        }

    });

    if (filterOptions.length !== 0) {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterHotelExclusiveDeals = (hotelResult, ratePlanName) => {
    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        element1.roomRateDetails.forEach(element2 => {
            if (element2.ratePlanName === ratePlanName) {
                const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                if (!found) {
                    hotelResultTemp.push(element1);
                    arrayTemp.push({
                        sequenceId: element1.sequenceId
                    });
                }
            }
        });
    });

    if (ratePlanName !== '') {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterHotelContent = (hotelResult, contentCode) => {
    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        if (element1.providerSourceCode === contentCode) {
            const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
            if (!found) {
                hotelResultTemp.push(element1);
                arrayTemp.push({
                    sequenceId: element1.sequenceId
                });
            }
        }
    });

    if (contentCode !== '') {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}

export const filterRefundableTypes = (hotelResult) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.refundabletypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var hotelResultTemp = [];  
    hotelResult.forEach(element1 => {
        console.log(element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable)
        if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true') {
            if (filterOptions.includes('Non Refundable')) {
                const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                if (!found) {
                    hotelResultTemp.push(element1);
                    arrayTemp.push({
                        sequenceId: element1.sequenceId
                    });
                }
            }
        }
        else if (element1.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'false') {
            if (filterOptions.includes('Refundable')) {
                const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                if (!found) {
                    hotelResultTemp.push(element1);
                    arrayTemp.push({
                        sequenceId: element1.sequenceId
                    });
                }
            }
        }
    });

    if (filterOptions.length !== 0) {
        hotelResult = hotelResultTemp;
    }
    console.log(hotelResultTemp)
    return hotelResult;
}

export const filterCommission = (hotelResult) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.commissionoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var hotelResultTemp = [];

    hotelResult.forEach(element1 => {
        element1.price.forEach(element2 => {
            if ((element2.agentCommissionType !== undefined || element2.agentCommission !== undefined) && element2.agentCommission !== '' && element2.agentCommission !== '0') {
                if (filterOptions.includes('agentCommission')) {
                    const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                    if (!found) {
                        hotelResultTemp.push(element1);
                        arrayTemp.push({
                            sequenceId: element1.sequenceId
                        });
                    }
                }
            }
        });
    });

    if (filterOptions.length !== 0) {
        hotelResult = hotelResultTemp;
    }

    return hotelResult;
}