import sorter from "sort-nested-json";

export const sortCarResult = (sortOption, carResults, sortBy) => {
    if (sortOption === '1') {
        var arrayTemp = [];
        carResults.forEach(element1 => {
            const found = arrayTemp.find(el => el.sequencenumber === element1.sequencenumber);
            if (!found) {
                arrayTemp.push({
                    carPrice: parseFloat(element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount),
                    sequencenumber: element1.sequencenumber
                });
            }
        });

        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("carPrice") : sorter.sort(arrayTemp).desc("carPrice");

        let carResultsTemp = [];
        list.forEach(element => {
            carResultsTemp.push(
                carResults.filter(x => x.sequencenumber === element.sequencenumber)[0]
            );
        });

        carResults = carResultsTemp;
    }

    return carResults;
}