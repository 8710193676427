import { Fragment } from "react";
import ExportExcel from "./ExportExcel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getFormatDate } from "../../../../utils/CommonFunction";
import PaginatedItems from "./PaginatedItems";

const SearchCarBookingResult = (props) => {

    function handleResetClick(event) {
        event.preventDefault();
        props.onResetForm();
    }

    return <Fragment>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Car Booking Report </label>
                                                </div>
                                                <div className='col-md-6'>

                                                    <div className='float-lg-end'>
                                                        <span id="excel" className='float-end'>
                                                            <ExportExcel reportJSON={props.reportData.data} ></ExportExcel>
                                                        </span>
                                                    </div>
                                                    <div className='float-lg-end d-none'>
                                                        <span id="pdf" className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end' ><i className="fas fa-file-pdf"></i>
                                                        </span>
                                                    </div>
                                                    <div className='float-end'>
                                                        <a id='back'
                                                            href="/#"
                                                            onClick={handleResetClick}
                                                            className='btn text-white gt-main-btn btn-sm ms-1 mt-1 float-lg-end'>
                                                            <span><i className="fa fa-arrow-left"></i></span>
                                                        </a>
                                                    </div>
                                                    <ReactTooltip
                                                        anchorId={"print"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Take a print"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"pdf"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export PDF"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"excel"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Export Excel"}
                                                    />
                                                    <ReactTooltip
                                                        anchorId={"back"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={"Back to Search"}
                                                    />

                                                </div>
                                                <div className="col-lg-12">
                                                    <div
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="">
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                </div>
                                                                <div className="table-responsive my-2">
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='fw-bold'>Date From</td>
                                                                                <td>{getFormatDate(props.reportData.data[0].dateFrom.replaceAll('-', ''))}</td>
                                                                                <td className='fw-bold'>Date To</td>
                                                                                <td>{getFormatDate(props.reportData.data[0].dateTo.replaceAll('-', ''))}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='w-25 fw-bold'>Company Name</td>
                                                                                <td className='w-25'>{props.reportData.data[0].companyNameInput}</td>
                                                                                <td className='w-25 fw-bold'>Status</td>
                                                                                <td className='w-25'>{props.reportData.data[0].statusInput}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 cstm-mob-pagination" >
                                                        <PaginatedItems items={props.reportData} itemsPerPage={10} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>;
};

export default SearchCarBookingResult;
