
import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';
import CarItem from './CarItem';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useHistory, useLocation } from 'react-router-dom';
import { sortCarResult } from '../../../utils/CarSort';
import { filterCarResults } from '../../../utils/CarFilter';
import { useTranslation } from 'react-i18next';
import RangeSlider from 'react-range-slider-input';
import sorter from "sort-nested-json";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';


const CarList = (props) => {
    const { t } = useTranslation(['translation.SearchCar']);
    const [sortValue, setSortValue] = useState('');
    const history = useHistory();
    const location = useLocation();
    const [sortBy, setSortBy] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [carPrice, setCarPrice] = useState([]);
    const [minCarPrice, setMinCarPrice] = useState();
    const [maxCarPrice, setMaxCarPrice] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [transmissionTypeList, setTransmissionTypeList] = useState([]);
    const [fuelTypeList, setFuelTypeList] = useState([]);

    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Price' }
    ];

    let carSessionJson = JSON.parse(sessionStorage.getItem("carSessionDataJson"));

    const sortAscChangeHandler = () => {
        //setSortValue(event.value);
        setSortBy('asc');
        let carResultsTemp = [];
        if (sortValue === '1') {
            carResultsTemp = sortCarResult(sortValue, props.searchCar.data.carResults, 'asc');
        }

        props.searchCar.data.carResults = carResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    const sortChangeHandler = (event) => {
        setSortValue(event.value);
        setSortBy('asc');
        let carResultsTemp = [];
        if (event.value === '1') {
            carResultsTemp = sortCarResult(event.value, props.searchCar.data.carResults, 'asc');
        }

        props.searchCar.data.carResults = carResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    const sortDescChangeHandler = (event) => {
        //setSortValue(event.value);
        setSortBy('desc');
        let carResultsTemp = [];
        if (sortValue === '1') {
            carResultsTemp = sortCarResult(sortValue, props.searchCar.data.carResults, 'desc');
        }

        props.searchCar.data.carResults = carResultsTemp;
        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    useEffect(() => {
        // setTimeout(function () {
        // }, 1000);

        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });

        sessionStorage.setItem("initialCarResults", JSON.stringify(props.searchCar.data.carResults));
        setSortBy('asc');
        setSortValue("1");
        let carResultsTemp = sortCarResult("1", props.searchCar.data.carResults, 'asc');
        props.searchCar.data.carResults = carResultsTemp;

        if (location.search !== undefined && location.search !== '') {
            history.push({
                pathname: location.pathname,
                search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }

    }, [])

    useEffect(() => {
        if (document.querySelector('#selectedCarPrice') !== null) {
            document.querySelector('#selectedCarPrice').classList.add('d-none');
        }

        if (document.querySelector('.clear-icon') !== null) {
            var controlCount = document.querySelectorAll('.clear-icon');
            if (controlCount[1] !== undefined) {
                controlCount[1].click();
            }
        }

        let carResultsTemp = filterCarResults('', '', sortValue, sortBy, '');
        props.searchCar.data.carResults = carResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })

        var carPriceList = [];
        props.searchCar.data.carResults.forEach(element1 => {
            const found = carPriceList.find(el => el.carPrice === element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount);
            if (!found) {
                carPriceList.push({
                    carPrice: parseFloat(element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount)
                });
                setCurrencyCode(element1.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode);
            }
        });

        let carPriceListSorted = sorter.sort(carPriceList).asc("carPrice");
        setMinCarPrice(parseFloat(carPriceListSorted[0].carPrice));
        setMaxCarPrice(Math.ceil(carPriceListSorted[carPriceListSorted.length - 1].carPrice));
        setCarPrice([parseFloat(carPriceListSorted[0].carPrice), Math.ceil(carPriceListSorted[carPriceListSorted.length - 1].carPrice)]);

        var transmissionTypeListTemp = [];
        props.searchCar.data.carResults.forEach(element1 => {
            const found = transmissionTypeListTemp.find(el => el.transmissionType === element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType);
            if (!found) {
                transmissionTypeListTemp.push({
                    id: `chk${element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType.replace(' ', '')}`,
                    transmissionType: element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.transmissionType
                });
            }
        });
        transmissionTypeListTemp = sorter.sort(transmissionTypeListTemp).asc("transmissionType");
        setTransmissionTypeList(transmissionTypeListTemp);

        var fuelTypeListTemp = [];
        props.searchCar.data.carResults.forEach(element1 => {
            const found = fuelTypeListTemp.find(el => el.fuelType === element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType);
            if (!found) {
                fuelTypeListTemp.push({
                    id: `chk${element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType.replace(' ', '')}`,
                    fuelType: element1.vehRentalCore.vehVendorAvail.vehAvail.vehicle.fuelType
                });
            }
        });
        fuelTypeListTemp = sorter.sort(fuelTypeListTemp).asc("fuelType");
        setFuelTypeList(fuelTypeListTemp);

    }, [])

    const toggleFilter = () => {
        if (showFilter === false) {
            setShowFilter(true);
            document.querySelector('#filterBoxDivCar').style.display = "";
            document.querySelector('#itemCardDivCar').classList.add('col-md-9');
            document.querySelector('#itemCardDivCar').classList.remove('col-md-12');
        }
        else {
            setShowFilter(false);
            document.querySelector('#filterBoxDivCar').style.display = "none";
            document.querySelector('#itemCardDivCar').classList.add('col-md-12');
            document.querySelector('#itemCardDivCar').classList.remove('col-md-9');
        }
    }

    const onApplyPriceChangeHandler = (event) => {
        let carResultsTemp = filterCarResults('', carPrice, sortValue, sortBy, '');
        props.searchCar.data.carResults = carResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    const onPriceChangeHandler = (event) => {
        document.querySelector('#selectedCarPrice').classList.remove('d-none');
        setCarPrice(event);
    }

    const onTransmissionTypeChangeHandler = (event) => {
        let carResultsTemp = filterCarResults('', carPrice, sortValue, sortBy, '');
        props.searchCar.data.carResults = carResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    const onFuelTypeChangeHandler = (event) => {
        let carResultsTemp = filterCarResults('', carPrice, sortValue, sortBy, '');
        props.searchCar.data.carResults = carResultsTemp;

        history.push({
            pathname: location.pathname,
            search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
        })
    }

    return <Fragment>
        <div className="main mt-5">
            <div className="container">
                {/* User Story 1070: Change in Search for Single PNR */}
                <div className='row'>
                    <div className='col-lg-2'>
                        <div><label className="h6 text-primary" for="city">Filter On</label></div>
                        <button id='btnShowFilterCar' onClick={toggleFilter} className="btn gt-filter-btn-padding text-white gt-main-btn" ><i className="fa-solid fa-filter"></i></button>
                        <ReactTooltip
                            anchorId="btnShowFilterCar"
                            place="bottom"
                            variant="info"
                            className="tooltip"
                            html={t('Show Filter')}
                        />
                    </div>

                    <div className='col-lg-7 my-auto text-center'>
                        {/* <div className='h5 text-muted'>We found <span className='text-primary'>640 properties</span> availability.</div> */}
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-3">

                        <div className='row'>
                            <div className='col-md-9 text-end'>
                                <label className="h6 text-primary mb-2" for="city">Sort By</label>
                            </div>
                            <div className='col-md-3'>
                                <div className='text-primary float-lg-end text-end'>
                                    <span style={{ cursor: 'pointer' }} id='spnSortAsc' onClick={sortAscChangeHandler}><i className="fas fa-arrow-circle-down h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortAsc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('Low to High')}
                                    // content={t('Low to High')}
                                    />
                                    <span style={{ cursor: 'pointer' }} id='spnSortDesc' onClick={sortDescChangeHandler}><i className="fas fa-arrow-circle-up ms-1 h5"></i></span>
                                    <ReactTooltip
                                        anchorId="spnSortDesc"
                                        place="bottom"
                                        variant="info"
                                        className="tooltip"
                                        html={t('High to Low')}
                                    // content={t('High to Low')}
                                    />
                                </div>
                            </div>
                        </div>
                        <Dropdown onChange={sortChangeHandler}
                            value={sortOptions.filter(option => option.value === sortValue)}
                            options={sortOptions}
                            textField="value"
                            dataItemKey="label"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                            placeholder=""
                        //autoFocus={props.pageFocus}
                        />
                    </div>
                    {/* User Story 1070: Change in Search for Single PNR */}
                </div>
                <div className='row' style={{ overflow: 'hidden' }}>
                    <div className="col-md-3" id='filterBoxDivCar' style={{ display: 'none' }}>
                        <div className="left_side_search_area">
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5 className='my-3'>Price</h5>
                                </div>
                                <br></br>
                                {
                                    (carPrice[0] !== undefined) &&
                                    <span id='selectedCarPrice' className='d-none'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(carPrice[0]).split('.')[0]}-{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(carPrice[1]).split('.')[0]}</span>
                                }
                                <div className="filter-price">
                                    <div id="price-slider">
                                        {
                                            (minCarPrice !== undefined && maxCarPrice !== undefined) &&
                                            <RangeSlider id={"sliderCarPrice"}
                                                rangeSlideDisabled={false}
                                                thumbsDisabled={[false, false]}
                                                min={minCarPrice} max={maxCarPrice}
                                                step={1}
                                                defaultValue={[minCarPrice, maxCarPrice]}
                                                disabled={false}
                                                onInput={onPriceChangeHandler}
                                            />
                                        }
                                        <br></br>
                                        {
                                            (minCarPrice !== undefined) &&
                                            <span id='minCarPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(minCarPrice).split('.')[0]}</span>
                                        }

                                        {
                                            (maxCarPrice !== undefined) &&
                                            <span id='maxCarPrice'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(maxCarPrice).split('.')[0]}</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-12 text-end'>
                                    <button className="apply text-end" type="button" id='applyPerPersonPrice'
                                        onClick={onApplyPriceChangeHandler}>Apply</button>
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Transmission</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        transmissionTypeList.map(element => (
                                            <div key={element.id} className="form-check">
                                                <input className="form-check-input transmissiontypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onTransmissionTypeChangeHandler(e)} data={element.transmissionType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.transmissionType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Fuel Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    {
                                        fuelTypeList.map(element => (
                                            <div key={element.id} className="form-check">
                                                <input className="form-check-input fueltypeoption" type="checkbox" id={element.id}
                                                    onChange={(e) => onFuelTypeChangeHandler(e)} data={element.fuelType} />
                                                <label className="form-check-label" htmlFor={element.id}>
                                                    <span className="area_flex_one">
                                                        <span>{element.fuelType} </span>
                                                    </span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='itemCardDivCar' className='col-md-12 full-width'>
                        <div className="main-cn hotel-page clearfix">
                            <div className="row">
                                <div className="col-md-12 col-md-push-3">
                                    <section className="hotel-list">
                                        <div className="sort-view clearfix">
                                        </div>
                                        {props.searchCar.data.carResults.map((searchCar) => (
                                            <CarItem
                                                key={searchCar.sequencenumber}
                                                id={searchCar.sequencenumber}
                                                carResults={searchCar}
                                                showData="1"
                                                sourceErrors={props.searchCar.data.sourceErrors}
                                            />
                                        ))}
                                    </section>
                                    {/*   <!-- End Car List --> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default CarList;
