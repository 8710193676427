// import { Fragment } from 'react';
import { Fragment, useState } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import BookingDetails from './Components/BookingDetails';
import ContactPerson from './Components/ContactPerson';
import GuestInformation from './Components/DriverDetails';
import BillingDetails from './Components/BillingDetails';
import BookingRemarks from './Components/BookingRemarks';
import Booking from './Components/Booking';
import CarConfirmation from '../Cars/CarConfirmation';
import CarItem from '../Cars/Components/CarItem'

const CarConfirmations = (props) => {
    useDocumentTitle('Car Confirmation')
    let CarBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));

    const [showCarConfirmation, setShowCarConfirmation] = useState(true);
    let pnr = CarBookingJSON.data.bookingReferenceID !== undefined ?
        CarBookingJSON.data.bookingReferenceID.id : null;
    if (CarBookingJSON.data.requestType === "ON" && pnr === null) {
        setShowCarConfirmation(false);
    }
    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper carListItemDetails">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="col-lg-12">
                                <div className='row'>
                                    <BookingDetails CarBookingJSON={CarBookingJSON} hide="2"></BookingDetails>
                                    <CarItem
                                        key={CarBookingJSON.sequencenumber}
                                        id={CarBookingJSON.sequencenumber}
                                        carResults={CarBookingJSON.data.carResults[0]}
                                        isFromDetailPage={true}
                                        showData="1"
                                    />

                                    <Booking carResults={CarBookingJSON.data.carResults[0]}></Booking>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <ContactPerson carResults={CarBookingJSON.data.travelerInfo}></ContactPerson>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <GuestInformation carResults={CarBookingJSON.data.travelerInfo}></GuestInformation>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <BillingDetails CarBookingJSON={CarBookingJSON} print="1"></BillingDetails>
                                    {CarBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.find(remarks => remarks.remarkType === 'General').text !== '' ? <Fragment><div className='col-md-12'> <hr></hr></div>
                                        <div className="">
                                            <BookingRemarks CarBookingJSON={CarBookingJSON}></BookingRemarks>
                                        </div></Fragment> : ''}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {showCarConfirmation && <CarConfirmation />}
    </Fragment>

};

export default CarConfirmations;