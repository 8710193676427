import { Fragment } from "react";
import { toCamelCase, toTitleCase } from "../../../utils/CommonFunction";

const CarDetailPolicyList = (props) => {
  function getAddress(address) {
    let addressContents = [];
    if (
      props.data.locationDetail.addressInformation.streetNumber &&
      props.data.locationDetail.addressInformation.streetNumber.length
    ) {
      addressContents.push(
        props.data.locationDetail.addressInformation.streetNumber
      );
    }

    if (
      props.data.locationDetail.addressInformation.address &&
      props.data.locationDetail.addressInformation.address.length
    ) {
      addressContents.push(
        props.data.locationDetail.addressInformation.address
      );
    }

    if (
      props.data.locationDetail.addressInformation.city?.name &&
      props.data.locationDetail.addressInformation.city?.name.length
    ) {
      addressContents.push(
        props.data.locationDetail.addressInformation.city?.name
      );
    }

    if (
      props.data.locationDetail.addressInformation.stateProv?.stateCode &&
      props.data.locationDetail.addressInformation.stateProv?.stateCode.length
    ) {
      addressContents.push(
        props.data.locationDetail.addressInformation?.postalCode &&
          props.data.locationDetail.addressInformation?.postalCode.length ?
          props.data.locationDetail.addressInformation.stateProv?.stateCode + " " + props.data.locationDetail.addressInformation?.postalCode :
          props.data.locationDetail.addressInformation.stateProv?.stateCode
      );
    }

    if (
      props.data.locationDetail.addressInformation.country?.code &&
      props.data.locationDetail.addressInformation.country?.code.length
    ) {
      addressContents.push(
        props.data.locationDetail.addressInformation.country?.code
      );
    }

    //return toTitleCase(addressContents.join(', '));
    return addressContents.join(", ");
  }

  function getContactInfo(contactInfo) {
    let contactInfoOutput = [];
    for (let contact in contactInfo) {
      contactInfoOutput.push(
        <div class="col-md-12" key={contact}>
          <p class="mb-2">
            {toTitleCase(contact) + ": " + contactInfo[contact]}
          </p>
        </div>
      );
    }

    return contactInfoOutput;
  }

  function getHoursOfOperation(operationTimes) {
    let opTimesOutput = [];
    operationTimes.forEach((timeInfo) => {
      if (timeInfo.timeDetail) {
        timeInfo.timeDetail.forEach((timeDtl) => {
          opTimesOutput.push(
            <tr key={timeInfo.dayOfWeek + timeDtl.rph}>
              <td>{timeInfo.dayOfWeek}</td>
              <td>{timeDtl.start}</td>
              <td>{timeDtl.end}</td>
            </tr>
          );
        });
      } else {
        opTimesOutput.push(
          <tr key={timeInfo.dayOfWeek}>
            <td>{timeInfo.dayOfWeek}</td>
            <td>-</td>
            <td>-</td>
          </tr>
        );
      }
    });

    return opTimesOutput;
  }

  function getPaymentCCInformation(ccInfo) {
    return (
      <div class="row mb-3" key={"R"}>
        <div class="h6 my-2 text-primary">Payment Cards</div>
        <div className="col-md-12">
          <span>
            {ccInfo.paymentCard
              .map((card) => {
                return card.cardName + " (" + card.cardCode + ")";
              })
              .join(", ")}
          </span>
        </div>
      </div>
    );
  }

  function getPaymentInformation(paymentOptions) {
    let paymentOptionsOutput = [];
    let cardPaymentOption = null;
    paymentOptions.forEach((option) => {
      if (option.code === "R") {
        //CC info
        cardPaymentOption = option;
      } else {
        if (option.info) {
          if (option.info.length === 1 &&
            (option.info[0].text === '.' || option.info[0].text === '*')) {
            //return works like continue in a forEach loop
            return;
          }

          paymentOptionsOutput.push(
            <div class="row mb-3" key={option.code}>
              <div class="h6 mb-2 text-primary">{option.type}</div>
              <div className="col-md-12">
                {option.info
                  .map((info) => {
                    if (info.text === ".") {
                      return " ";
                    }

                    return info.text + " ";
                  })
                  .join("")}
              </div>
            </div>
          );
        }
      }
    });

    if (cardPaymentOption) {
      paymentOptionsOutput.push(getPaymentCCInformation(cardPaymentOption));
    }

    return paymentOptionsOutput;
  }

  function getCarExtras(services) {
    let servicesOutput = [];
    let servicesWithType = [];
    services.forEach((service) => {
      if (service.type) {
        servicesWithType.push(service);
      } else {
        servicesOutput.push(
          <p key={"nocode"}>
            {service.info
              .map((info) => {
                if (info.text === ".") {
                  return " ";
                }

                return info.text + " ";
              })
              .join("")}
          </p>
        );
      }
    });

    servicesWithType.forEach((service) => {
      servicesOutput.push(
        <div class="row mb-3" key={service.code}>
          <div class="h6 mb-2 text-primary"><i class="fas fa-check py-1"></i> {service.type}</div>
          <p>
            {service.info
              .map((info) => {
                if (info.text === ".") {
                  return " ";
                }

                return info.text + " ";
              })
              .join("")}
          </p>
        </div>
      );
    });

    return servicesOutput;
  }

  function getCarMakes(makes) {
    let carMakesOutput = [];
    makes.forEach((make) => {
      carMakesOutput.push(
        <tr key={make.type}>
          <td>{make.type}</td>
          <td>{make.group}</td>
          <td>{make.example}</td>
          <td>{make.capacity}</td>
          <td>{make.numberOfDoors}</td>
          <td>{make.numberOfBags}</td>
        </tr>
      );
    });

    return carMakesOutput;
  }

  return (
    <Fragment>
      <h3 className="heading_theme mt-5">Car Policies</h3>

      <div class="">
        <div class="tour_details_boxed_inner mobile-toggle-btn carPolicy">
          {/* Location */}
          {props.data.locationDetail?.addressInformation && (
            <div className="accordion" id="accordionLocation">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingLocation">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#locationContent"
                      aria-expanded="true"
                      aria-controls="locationContent"
                    >
                      <span>Location</span>
                    </button>
                  </h2>
                  <div
                    id="locationContent"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingLocation"
                    data-bs-parent="#accordionLocation"
                  >
                    <div className="col-md-12">
                      <div class="row mb-3">
                        <div class="h6 mb-2 text-primary"><i class="fas fa-map-marker-alt rrr"></i> Address</div>
                        <div class="col-md-12">
                          <p class="mb-2">{getAddress()}</p>
                        </div>
                      </div>

                      {props.data.locationDetail.contactDetails
                        ?.contactDetail &&
                        props.data.locationDetail.contactDetails?.contactDetail
                          .length > 0 && (
                          <>
                            <div class="row mb-3">
                              <div class="h6 mb-2 text-primary"><i class="fas fa-phone"></i> Contact Details</div>
                              {getContactInfo(
                                props.data.locationDetail.contactDetails
                                  ?.contactDetail[0]
                              )}
                            </div>
                          </>
                        )}
                      {props.data.locationDetail.additionalInfo
                        ?.operationSchedules?.operationSchedule &&
                        props.data.locationDetail.additionalInfo
                          ?.operationSchedules?.operationSchedule[0]
                          .operationTimes?.operationTime &&
                        props.data.locationDetail.additionalInfo
                          ?.operationSchedules?.operationSchedule[0]
                          .operationTimes?.operationTime.length > 0 && (
                          <>
                            <div class="row mb-3">
                              <div class="h6 mb-2 text-primary">
                                <i class="far fa-clock"></i> Hours of Operation
                              </div>
                              <div className="col-md-12">
                                <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col">Day</th>
                                      <th scope="col">Start Time</th>
                                      <th scope="col">End Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getHoursOfOperation(
                                      props.data.locationDetail.additionalInfo
                                        ?.operationSchedules
                                        ?.operationSchedule[0].operationTimes
                                        ?.operationTime
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Shuttle Service */}
          {props.data.locationDetail?.additionalInfo?.shuttleInformation
            ?.shuttle && (
              <div className="accordion" id="accordionShuttleService">
                <div className="accordion_flex_area  mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingShuttleService">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#shuttleServiceContent"
                        aria-expanded="true"
                        aria-controls="shuttleServiceContent"
                      >
                        <span>Shuttle Service</span>
                      </button>
                    </h2>
                    <div
                      id="shuttleServiceContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingShuttleService"
                      data-bs-parent="#accordionShuttleService"
                    >
                      <div className="col-md-12">
                        {props.data.locationDetail?.additionalInfo?.shuttleInformation?.shuttle.map(
                          (shuttleItem) => {
                            if (!shuttleItem.info ||
                              (shuttleItem.info.length === 1 &&
                                (shuttleItem.info[0].text === '.' || shuttleItem.info[0].text === '*'))) {
                              return '';
                            }
                            return <div class="row mb-3" key={shuttleItem.type}>
                              <div class="h6 mb-2 text-primary">{shuttleItem.typeDescription}</div>
                              <div className="col-md-12">
                                {shuttleItem.info
                                  .map((info) => {
                                    if (info.text === ".") {
                                      return " ";
                                    }
                                    return info.text + " ";
                                  })
                                  .join("")}
                              </div>
                            </div>
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Makes */}
          {props.data.vehicles?.makes?.makeModelInfo && (
            <div className="accordion" id="accordionMake">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingMake">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#makeContent"
                      aria-expanded="true"
                      aria-controls="makeContent"
                    >
                      <span>Makes</span>
                    </button>
                  </h2>
                  <div
                    id="makeContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="makeLocation"
                    data-bs-parent="#accordionMake"
                  >
                    <div className="col-md-12 mobileTableScroll">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Car Type</th>
                            <th scope="col">Class</th>
                            <th scope="col">Example of Make/Model</th>
                            <th scope="col">Passengers</th>
                            <th scope="col">Doors</th>
                            <th scope="col">No. of Bags</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getCarMakes(
                            props.data.vehicles?.makes?.makeModelInfo
                          )}
                        </tbody>
                      </table>
                      {props.data.vehicles?.makes?.info && (
                        <p>
                          {props.data.vehicles?.makes?.info
                            .map((info) => {
                              if (info.text === ".") {
                                return " ";
                              }
                              return info.text + " ";
                            })
                            .join("")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Drop Off */}
          {props.data.locationDetail?.additionalInfo?.dropOff?.info && (
            <div className="accordion" id="accordionDropOff">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingDropOff">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#dropOffContent"
                      aria-expanded="true"
                      aria-controls="dropOffContent"
                    >
                      <span>Drop Off</span>
                    </button>
                  </h2>
                  <div
                    id="dropOffContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingDropOff"
                    data-bs-parent="#accordionDropOff"
                  >
                    <div className="col-md-12">
                      <p>
                        {props.data.locationDetail?.additionalInfo?.dropOff.info
                          .map((info) => {
                            if (info.text === ".") {
                              return " ";
                            }
                            return info.text + " ";
                          })
                          .join("")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Requirements & Restrictions */}
          {(props.data.requirements?.license?.info ||
            props.data.requirements?.driver?.additionalDriverFee?.info ||
            props.data.requirements?.driver?.info) && (
              <div className="accordion" id="accordionReqRes">
                <div className="accordion_flex_area  mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingReqRes">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#reqResContent"
                        aria-expanded="true"
                        aria-controls="reqResContent"
                      >
                        <span>Requirements & Restrictions</span>
                      </button>
                    </h2>
                    <div
                      id="reqResContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingReqRes"
                      data-bs-parent="#accordionReqRes"
                    >
                      <div className="col-md-12">
                        {props.data.requirements?.license?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">License</div>
                            <div className="col-md-12">
                              {props.data.requirements?.license?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                        {props.data.requirements?.driver?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Additional Drivers</div>
                            <div className="col-md-12">
                              {props.data.requirements?.driver?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                        {props.data.requirements?.driver?.additionalDriverFee?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Additional Driver Fee</div>
                            <div className="col-md-12">
                              {props.data.requirements?.driver?.additionalDriverFee?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Age */}
          {(props.data.requirements?.age?.info ||
            props.data.requirements?.age?.surcharges?.info) && (
              <div className="accordion" id="accordionAge">
                <div className="accordion_flex_area  mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingAge">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ageContent"
                        aria-expanded="true"
                        aria-controls="ageContent"
                      >
                        <span>Age</span>
                      </button>
                    </h2>
                    <div
                      id="ageContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingAge"
                      data-bs-parent="#accordionAge"
                    >
                      <div className="col-md-12">
                        {props.data.requirements?.age?.surcharges?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Age Surcharges</div>
                            <div className="col-md-12">
                              {props.data.requirements?.age?.surcharges?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                        {props.data.requirements?.age.info &&
                          <p>
                            {props.data.requirements?.age.info
                              .map((info) => {
                                if (info.text === ".") {
                                  return " ";
                                }

                                return info.text + " ";
                              })
                              .join("")}
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Payment information */}
          {props.data.requirements?.paymentOptions?.payment && (
            <div className="accordion" id="accordionPayment">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingPayment">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#paymentContent"
                      aria-expanded="true"
                      aria-controls="paymentContent"
                    >
                      <span>Payment Information</span>
                    </button>
                  </h2>
                  <div
                    id="paymentContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingPayment"
                    data-bs-parent="#accordionPayment"
                  >
                    <div className="col-md-12">
                      {getPaymentInformation(
                        props.data.requirements?.paymentOptions?.payment
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Geographic Information */}
          {props.data.locationDetail?.additionalInfo?.geographicRestrictions
            ?.info && (
              <div className="accordion" id="accordionGeoRes">
                <div className="accordion_flex_area mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingGeoRes">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#geoResContent"
                        aria-expanded="true"
                        aria-controls="geoResContent"
                      >
                        <span>Geographic Information</span>
                      </button>
                    </h2>
                    <div
                      id="geoResContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingGeoRes"
                      data-bs-parent="#accordionGeoRes"
                    >
                      <div className="col-md-12">
                        <p>
                          {props.data.locationDetail?.additionalInfo?.geographicRestrictions?.info
                            .map((info) => {
                              if (info.text === ".") {
                                return " ";
                              }

                              return info.text + " ";
                            })
                            .join("")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Additional Fees */}
          {(props.data.additionalFees?.fees?.info ||
            props.data.additionalFees?.taxes?.info) && (
              <div className="accordion" id="accordionAdlFee">
                <div className="accordion_flex_area mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingAdlFee">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#adlFeeContent"
                        aria-expanded="true"
                        aria-controls="adlFeeContent"
                      >
                        <span>Additional Fees</span>
                      </button>
                    </h2>
                    <div
                      id="adlFeeContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingAdlFee"
                      data-bs-parent="#accordionAdlFee"
                    >
                      <div className="col-md-12">
                        {props.data.additionalFees?.fees?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Fees</div>
                            <div className="col-md-12">
                              {props.data.additionalFees?.fees?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}

                        {props.data.additionalFees?.taxes?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Taxes</div>
                            <div className="col-md-12">
                              {props.data.additionalFees?.taxes?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Surcharges */}
          {props.data.additionalFees?.surcharges?.info && (
            <div className="accordion" id="accordionSurcharge">
              <div className="accordion_flex_area mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSurcharge">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#surchargeContent"
                      aria-expanded="true"
                      aria-controls="surchargeContent"
                    >
                      <span>Surcharges</span>
                    </button>
                  </h2>
                  <div
                    id="surchargeContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSurcharge"
                    data-bs-parent="#accordionSurcharge"
                  >
                    <div className="col-md-12">
                      <p>
                        {props.data.additionalFees?.surcharges?.info
                          .map((info) => {
                            if (info.text === ".") {
                              return " ";
                            }

                            return info.text + " ";
                          })
                          .join("")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Fuel */}
          {props.data.vehicles?.fuel?.info && (
            <div className="accordion" id="accordionFuel">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFuel">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#fuelContent"
                      aria-expanded="true"
                      aria-controls="fuelContent"
                    >
                      <span>Fuel</span>
                    </button>
                  </h2>
                  <div
                    id="fuelContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFuel"
                    data-bs-parent="#accordionFuel"
                  >
                    <div className="col-md-12">
                      <p>
                        {props.data.vehicles?.fuel.info
                          .map((info) => {
                            if (info.text === ".") {
                              return " ";
                            }

                            return info.text + " ";
                          })
                          .join("")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Coverages */}
          {props.data.liabilities?.coverages?.info?.insurance && (
            <div className="accordion" id="accordionCoverage">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingCoverage">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#coverageContent"
                      aria-expanded="true"
                      aria-controls="coverageContent"
                    >
                      <span>Coverages</span>
                    </button>
                  </h2>
                  <div
                    id="coverageContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingCoverage"
                    data-bs-parent="#accordionCoverage"
                  >
                    <div className="col-md-12">
                      {props.data.liabilities?.coverages?.info?.insurance.info && (
                        <div class="row mb-3">
                          <div class="h6 mb-2 text-primary">
                            Insurance
                          </div>
                          <div class="col-md-12">
                            <p class="mb-2">
                              {props.data.liabilities?.coverages?.info?.insurance.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </p>
                          </div>
                        </div>
                      )}
                      {props.data.liabilities?.coverages?.info?.insurance.personalAccident &&
                        props.data.liabilities?.coverages?.info?.insurance.personalAccident.length > 0 &&
                        props.data.liabilities?.coverages?.info?.insurance.personalAccident[0].info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">
                              Personal Accident Insurance
                            </div>
                            <div class="col-md-12">
                              <p class="mb-2">
                                {props.data.liabilities?.coverages?.info?.insurance.personalAccident[0].info
                                  .map((info) => {
                                    if (info.text === ".") {
                                      return " ";
                                    }

                                    return info.text + " ";
                                  })
                                  .join("")}
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Waivers */}
          {props.data.liabilities?.coverages?.info?.waivers?.lossDamages
            ?.info && (
              <div className="accordion" id="accordionWaiver">
                <div className="accordion_flex_area  mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingCoverage">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#waiverContent"
                        aria-expanded="true"
                        aria-controls="waiverContent"
                      >
                        <span>Waivers</span>
                      </button>
                    </h2>
                    <div
                      id="waiverContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingWaiver"
                      data-bs-parent="#accordionWaiver"
                    >
                      <div className="col-md-12">
                        <p>
                          {props.data.liabilities?.coverages?.info?.waivers?.lossDamages?.info
                            .map((info) => {
                              if (info.text === ".") {
                                return " ";
                              }

                              return info.text + " ";
                            })
                            .join("")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Services Offered */}
          {props.data.locationDetail?.additionalInfo?.serviceOffered &&
            props.data.locationDetail?.additionalInfo?.serviceOffered.length > 0 &&
            (props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.info ||
              props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.custom) && (
              <div className="accordion" id="accordionSerOff">
                <div className="accordion_flex_area mt-2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSerOff">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#serOffContent"
                        aria-expanded="true"
                        aria-controls="serOffContent"
                      >
                        <span>Services Offered</span>
                      </button>
                    </h2>
                    <div
                      id="serOffContent"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSerOff"
                      data-bs-parent="#accordionSerOff"
                    >
                      <div className="col-md-12">

                        {props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.custom &&
                          props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.custom.length > 0 && (
                            <Fragment>
                              {props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices.custom.map((customService) =>
                                <div class="row mb-3" >
                                  <div class="h6 mb-2 text-primary"><i className="fas fa-check py-1"></i> {customService.name}</div>
                                  <div className="col-md-12">
                                    {customService.info
                                      .map((info) => {
                                        if (info.text === ".") {
                                          return " ";
                                        }

                                        return info.text + " ";
                                      })
                                      .join("")}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          )}

                        {props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.info && (
                          <div class="row mb-3">
                            <div class="h6 mb-2 text-primary">Additional Services</div>
                            <div className="col-md-12">
                              {props.data.locationDetail?.additionalInfo?.serviceOffered[0].additionalServices?.info
                                .map((info) => {
                                  if (info.text === ".") {
                                    return " ";
                                  }

                                  return info.text + " ";
                                })
                                .join("")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Car Extras */}
          {props.data.servicesOffered?.specialEquipments?.specialEquipment && (
            <div className="accordion" id="accordionExtra">
              <div className="accordion_flex_area  mt-2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingExtra">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#extraContent"
                      aria-expanded="true"
                      aria-controls="extraContent"
                    >
                      <span>Car Extras</span>
                    </button>
                  </h2>
                  <div
                    id="extraContent"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingExtra"
                    data-bs-parent="#accordionExtra"
                  >
                    <div className="col-md-12">
                      {getCarExtras(
                        props.data.servicesOffered?.specialEquipments
                          ?.specialEquipment
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment >
  );
};

export default CarDetailPolicyList;