import {
    SEARCH_CAR,
    DETAILS_CAR, CAR_PRICE_CHECK, CAR_BOOKING, CAR_RETRIEVE_TRIP,
    CANCEL_CAR,
    CAR_SEND_EMAIL

} from './CONSTANTS'
export async function searchCar(searchData) {
    const response = await fetch(SEARCH_CAR(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }

    return data;
}
export async function detailsCar(searchData) {
    const response = await fetch(DETAILS_CAR(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }

    return data;
}

export async function carPriceCheck(searchData) {
    const response = await fetch(CAR_PRICE_CHECK(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }

    return data;
}
export async function carBooking(searchData) {
    const response = await fetch(CAR_BOOKING(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }

    return data;
}

export async function retrieveCar(searchData) {
    const response = await fetch(CAR_RETRIEVE_TRIP(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }

    return data;
}

export async function cancelCar(searchData) {
    const response = await fetch(CANCEL_CAR(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch car.');
    }
    return data;
}
export async function SendMailCar(mailData) {    
    const response = await fetch(CAR_SEND_EMAIL(), {
        method: 'POST',
        body: JSON.stringify(mailData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not send email.');
    }    
    return data;
}