import { Fragment, useEffect, useState } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import HomePageBottomArea from '../Home/HomePageBottomArea';
import SearchFlightPartial from '../Home/SearchFlightPartial';
import SearchHotelPartial from '../Home/SearchHotelPartial';
import SearchSightseeingPartial from '../Home/SearchSightseeingPartial';
import SearchHolidaysPartial from '../Home/SearchHolidaysPartial';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';

const HomePage = () => {
    useDocumentTitle('Home');
    const [loadComponent, setLoadComponent] = useState('');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        sessionStorage.setItem("LoaderHeader", "0");
    }, [])

    useEffect(() => {
        if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) {
            setLoadComponent('Flight');
        }
        else if (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR') && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) {
            setLoadComponent('Flight');
        }
        else if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) {
            setLoadComponent('Hotel');
        }
        else if (checkSecurityDetails("Book", "Car") && checkSecurityDetails("Book", "Car", "Book_Car_Search") && getConfigurationByBoolen("ALLOW_CAR") === true) {
            setLoadComponent('Car');
        }
        else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
            setLoadComponent('Sightseeing');
        }
        else if (getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '') {
            setLoadComponent('Holidays');

        }
    }, [loadComponent])

    return <Fragment>
        {/*  <!-- Banner Area --> */}

        <header class="main_header_arae homepage">
            <div class="topbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-list">
                                <a class="navbar-brand" href="/homepage">
                                    <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo" style={{ height: "85px" }} />
                                </a>
                                {/* <li>
                                <a href="#!"><i class="fab fa-facebook"></i></a>
                                <a href="#!"><i class="fab fa-twitter-square"></i></a>
                                <a href="#!"><i class="fab fa-instagram"></i></a>
                                <a href="#!"><i class="fab fa-linkedin"></i></a>
                            </li>
                            <li><a href="#!"><span>+011 234 567 89</span></a></li>
                            <li><a href="#!"><span>contact@domain.com</span></a></li> */}
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-others-options">

                                <li>
                                    <div class="dropdown language-option">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <span class="lang-name"></span>
                                        </button>
                                        <div class="dropdown-menu language-dropdown-menu">
                                            <a class="dropdown-item" href="#">Help </a>
                                            <a class="dropdown-item" href="#">Help Center</a>
                                            <a class="dropdown-item" href="#">Contact Us</a>
                                        </div>
                                    </div>
                                </li>
                                <li><a href="login.html">My Trip</a></li>
                                <li><a href="register.html">Login</a></li>
                                <li>
                                    <div class="dropdown language-option">
                                        <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <span class="lang-name"></span>
                                        </button>
                                        <div class="dropdown-menu language-dropdown-menu">
                                            <a class="dropdown-item" href="#">USD</a>
                                            <a class="dropdown-item" href="#">BD</a>
                                            <a class="dropdown-item" href="#">URO</a>
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                <div class="dropdown language-option">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span class="lang-name"></span>
                                    </button>
                                    <div class="dropdown-menu language-dropdown-menu">
                                        <a class="dropdown-item" href="#">USD</a>
                                        <a class="dropdown-item" href="#">BD</a>
                                        <a class="dropdown-item" href="#">URO</a>
                                    </div>
                                </div>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar-area1">
                <div class="main-responsive-nav">
                    <div class="container">
                        <div class="main-responsive-menu">
                            <div class="logo">
                                <a href="index.html">
                                    <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-navbar">
                    <div class="container">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <a class="navbar-brand d-none" href="/homepage">
                                <img src="https://www.kanoo.com/wp-content/uploads/2020/01/YBA_Kanoo_Logo_240.png" alt="logo" />
                            </a>
                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                    {/* <li class="nav-item">
                                    <a href="#" class="nav-link active">
                                        Home
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="index.html" class="nav-link">Home One</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="index-2.html" class="nav-link active">Home Two</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Tours
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="tour-search.html" class="nav-link">Tour</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-details.html" class="nav-link">Tour Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-booking-submission.html" class="nav-link">Tour Booking</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="top-destinations.html" class="nav-link">Top Destination</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="top-destinations-details.html" class="nav-link">Destination
                                                Details</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Flights
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="flight-search-result.html" class="nav-link">Flight</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="flight-booking-submission.html" class="nav-link">Flight Booking</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Hotel
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="hotel-search.html" class="nav-link">Hotel</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="hotel-details.html" class="nav-link">Hotel Booking</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="room-details.html" class="nav-link">Room Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="room-booking.html" class="nav-link">Room Booking</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Visa
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="visa-details.html" class="nav-link">Visa Details</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-application.html" class="nav-link">Visa Application</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Business visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Education visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Working visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Tourist visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">Medical visa </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="visa-info.html" class="nav-link">On-arrival visa </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Pages
                                        <i class="fas fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a href="about.html" class="nav-link">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="tour-guides.html" class="nav-link">Team</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="testimonials.html" class="nav-link">Testimonials</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="faqs.html" class="nav-link">FAQ</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="booking-confirmation.html" class="nav-link">Booking
                                                Confirmation</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">News</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="news.html" class="nav-link">News</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="news-details.html" class="nav-link">News Details</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">User Pages</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="login.html" class="nav-link">Login</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="register.html" class="nav-link">Register</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="forgot-password.html" class="nav-link">Forget Password</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="verify-otp.html" class="nav-link">Verify OTP</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="reset-password.html" class="nav-link">Reset Password</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#" class="nav-link">Customer Dashboard</a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a href="dashboard.html" class="nav-link">Dashboard</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="hotel-booking.html" class="nav-link">Hotel booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="flight-booking.html" class="nav-link">Flight booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="tour-booking.html" class="nav-link">Tour booking</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="booking-history.html" class="nav-link">Booking history</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="my-profile.html" class="nav-link">My profile</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="wallet.html" class="nav-link">Wallet</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a href="notification.html" class="nav-link">Notifications</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="error.html" class="nav-link">404 Error</a>
                                        </li>
                                    </ul>
                                </li> */}
                                    {/* <li class="nav-item">
                                    <a href="/homepage" class="nav-link kt-nav-btn"><i class="fas fa-plane-departure"></i> Flights</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-hotel"></i> Hotels</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-hiking"></i> Sightseeing</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-umbrella-beach"></i> Holidays</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-kaaba"></i> Haj and Umrah</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link"><i class="fas fa-ship"></i> Cruises</a>
                                </li> */}
                                </ul>
                                <div class="others-options d-flex align-items-center">

                                    <li style={{ listStyle: "none" }}><a href="#!" className='text-white text-16px'><span><i class="fas fa-phone"></i> +971 4 4082323</span></a></li>
                                    {/* <div class="option-item">
                                    <a href="#" class="search-box">
                                        <i class="bi bi-search"></i></a>
                                </div> */}
                                    {/* <div class="option-item">
                                    <a href="become-vendor.html" class="btn  btn_navber">Become a partner</a>
                                </div> */}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="dot-menu">
                            <div class="inner">
                                <div class="circle circle-one"></div>
                                <div class="circle circle-two"></div>
                                <div class="circle circle-three"></div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="option-inner">
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                                    </div>
                                    <div class="option-item">
                                        <a href="contact.html" class="btn  btn_navber">Get free quote</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section id="home_two_banner">
            <div class="home_two_banner_slider_wrapper owl-carousel owl-theme">
                <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                    style={{
                        backgroundImage: "url(https://andit.co/projects/html/and-tour/demo/assets/img/home-fourteen/common/banner-fourteen.png)",

                        //  background: "#032942"

                    }}>
                    {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
                <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                    style={{ backgroundImage: "url(./assets/img/banner/homepage2.png)" }}>
                    {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
                <div class="banner_two_slider_item fadeInUp" data-wow-duration="2s"
                    style={{ backgroundImage: "url(./assets/img/banner/homepage1.png)" }}>
                    {/* <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner_two_text">
                                <h3 class="title">BEST TRAVEL AGENCY</h3>
                                <h1 class="slider-sttle">EXPLORE</h1>
                                <h2 class="slider-pararp">The world with us!</h2>
                                <h4 class="slider-pararp">Find awesome flights, hotel, tour, car and packages</h4>
                                <div class="home_two_button btn-animation">
                                    <a href="top-destinations.html" class="btn btn_theme_white btn_md">Discover</a>
                                    <a href="top-destinations.html" class="btn btn_theme_transparent btn_md">Know
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
        </section>
        <section className='home-original-search position-relative'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mx-auto">
                        <div className="theme_search_form_area my-2 home-slider-serach-section">
                            {
                                loadComponent === 'Flight' &&
                                <SearchFlightPartial></SearchFlightPartial>
                            }
                            {
                                loadComponent === 'Hotel' &&
                                <SearchHotelPartial></SearchHotelPartial>
                            }
                            {
                                loadComponent === 'Sightseeing' &&
                                <SearchSightseeingPartial></SearchSightseeingPartial>
                            }
                            {
                                loadComponent === 'Holidays' &&
                                <SearchHolidaysPartial></SearchHolidaysPartial>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <HomePageBottomArea></HomePageBottomArea>
    </Fragment>

};

export default HomePage;