import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { rebookingTrip ,getRebooking} from "../../../services/Booking-API";
import useHttp from "../../../services/use-http";


const Rebooking = (props) => {
    const inputEmailIdRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(rebookingTrip);
    const { sendRequest: sendRebookingRequest,status:rebookingStatus, data: loadedRequestRebooking } = useHttp(getRebooking)
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState(false);
    const [rebook, setRebook] = useState(false);
  

    function functionExit() {
        props.errorHandler();
      
    }
 
    const errorHandler1 = () => {
        setError1(null);
    };
    useEffect(() => {
        const onSearch = ({
                "shoppingID": props.bookingJSON.data.shoppingID
            });
            sendRebookingRequest(onSearch);
    }, [sendRebookingRequest]);

    useEffect(() => {
        if (rebookingStatus === 'completed') {
            if (loadedRequestRebooking.errors.status === 'FALSE') {
                setRebook(true);
                inputEmailIdRef.current.value=loadedRequestRebooking.data[0].description;              
            } 
            return;
        }

    }, [rebookingStatus])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote.errors.status === 'FALSE') {
                setMailConfirm('Trip reschedule request submitted successfully.');
                setRebook(true);
            } else {

                setMailConfirm("Some error has occurred");
                setRebook(false);

            }
            return;
        }

    }, [status])
    function onBlurCheck() {

        if (inputEmailIdRef.current.value.trim() === '') {
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

    }

    function submitFormHandler() {

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

        let rebooking = (
            {
                "shoppingID": parseInt(props.bookingJSON.data.shoppingID),
                "bookingReferenceNumber": props.bookingJSON.data.bookingReferenceNumber,
                "firstName": props.bookingJSON.data.travelerInfo.traveler===undefined?props.bookingJSON.data.travelerInfo.guestInfo[0].personName.givenName:props.bookingJSON.data.travelerInfo.traveler[0].personName.givenName,
                "middleName": props.bookingJSON.data.travelerInfo.traveler===undefined?props.bookingJSON.data.travelerInfo.guestInfo[0].personName.middleName:props.bookingJSON.data.travelerInfo.traveler[0].personName.middleName,
                "lastName": props.bookingJSON.data.travelerInfo.traveler===undefined?props.bookingJSON.data.travelerInfo.guestInfo[0].personName.surname:props.bookingJSON.data.travelerInfo.traveler[0].personName.surname,
                "emailID": props.bookingJSON.data.travelerInfo.traveler===undefined?props.bookingJSON.data.travelerInfo.guestInfo[0].email[0].emailId:props.bookingJSON.data.travelerInfo.traveler[0].email[0].emailId,
                "description": inputEmailIdRef.current.value,
                "userID": JSON.parse(localStorage.getItem('userID')),
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "traceID": JSON.parse(localStorage.getItem('traceID')),
                "ipAddress": localStorage.getItem("IPAddress"),
                "serviceCode": props.bookingJSON.data.travelerInfo.traveler===undefined?'HT':'FL',
               
            }
        );

        sendRequest(rebooking);
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}

        <div >
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="control">
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Reschedule Reason<span className="error-icon"> *</span>

                                </label>
                                <div>
                                    <textarea type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={inputEmailIdRef}
                                        autoFocus>
                                    </textarea>
                                    {balnkMailError && <p className="error-text">Reschedule Reason Details.</p>
                                    }
                                    {invalidEmailError && <p className="error-text">Reschedule Reason Details are not valid.</p>}
                                    {!balnkMailError && !invalidEmailError && rebook && <p className="success-text">{mailConfirm}</p>}
                                    {!balnkMailError && !invalidEmailError && !rebook && <p className="error-text">{mailConfirm}</p>}
                                    <span id="msgfName1" className="error-text"></span></div>
                            </div>
                        </div>
                        {/* </div> */}
                        <div className="col-md-7 my-auto">

                        </div>
                        <div className="col-md-5 my-auto align-right">
                            {!rebook && <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler}>
                                {status === 'pending' ?
                                    <i className="fa fa-spinner fa-spin" ></i>
                                    : 'Confirm'}
                            </button>}
                            <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
};
export default Rebooking;