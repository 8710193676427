import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { useHistory } from 'react-router-dom';

const SearchHolidaysPartial = () => {
    const { t } = useTranslation(['translation.Holidays']);
    const history = useHistory();

    useDocumentTitle('Holidays Search');
    const [error1, setError] = useState('');
    const [items, setItems] = useState([]);
    const { detect } = require('detect-browser');
    const browser = detect();

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (document.querySelector("#lblFlights_holidays") !== null) {
            document.querySelector("#lblFlights_holidays").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights_holidays").classList.add("text-primary");
        }

        if (document.querySelector("#lblFlightPlusHotel_holidays") !== null) {
            document.querySelector("#lblFlightPlusHotel_holidays").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel_holidays").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights_holidays") !== null) {
                document.querySelector("#lblFlights_holidays").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights_holidays").classList.add("text-primary");
            }

            document?.querySelector("#lblHotel_holidays")?.classList?.remove("text-primary");
            document?.querySelector("#lblHotel_holidays")?.classList?.add("flight-active-tab");
        }

        if (document.querySelector("#lblHotel_holidays") !== null) {
            document?.querySelector("#lblHotel_holidays")?.classList?.remove("flight-active-tab");
            document?.querySelector("#lblHotel_holidays")?.classList?.add("text-primary");
        }

        if (document.querySelector("#lblSightseeing_holidays") !== null) {
            document.querySelector("#lblSightseeing_holidays").classList.add("text-primary");
            document.querySelector("#lblSightseeing_holidays").classList.remove("flight-active-tab");
        }

        if (document.querySelector("#lblHolidays_holidays") !== null) {
            document.querySelector("#lblHolidays_holidays").classList.add("text-primary");
            document.querySelector("#lblHolidays_holidays").classList.remove("flight-active-tab");
        }

    }, [])

    const controlHandlerFightSearch = (event) => {
        console.log(4)
        console.log(event.target.id)
        console.log(sessionStorage.getItem('serviceType'))
        if (event.target.id === 'lblFlights_holidays') {
            sessionStorage.setItem("serviceType", "lblFlights");

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "none";
            }

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlights_flight").classList.remove("text-primary");
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblFlights_sightseeing").classList.remove("text-primary");
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
            }

            document.querySelector('#oneway-tab').classList.remove("disabled");
            document.querySelector('#multi_city-tab').classList.remove("disabled");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHotel_holidays') {
            sessionStorage.setItem("serviceType", "lblHotel");

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
                document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblHotel_sightseeing").classList.remove("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_hotel") !== null) {
                document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
                document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.remove('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel_holidays') {
            sessionStorage.setItem("serviceType", "lblFlightPlusHotel");

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "";
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("text-primary");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
            }

            if (document.querySelector("#lblHolidays_sightseeing") !== null) {
                document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
            }

            document.querySelector('#divTripType').classList.add("d-none");
            document.querySelector('#roundtrip-tab').click();

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblSightseeing_holidays') {
            sessionStorage.setItem("serviceType", "lblSightseeing");

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_sightseeing") !== null) {
                document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.remove('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHolidays_holidays') {
            // sessionStorage.setItem("serviceType", "lblHolidays");

            // if (document.querySelector("#lblFlights_sightseeing") !== null) {
            //     document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_sightseeing") !== null) {
            //     document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            //     document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            // }

            // if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
            //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
            //     document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            // }

            // if (document.querySelector('.show_flight') !== null) {
            //     document.querySelector('.show_flight').classList.add('d-none');
            // }
            // if (document.querySelector('.show_hotel') !== null) {
            //     document.querySelector('.show_hotel').classList.add('d-none');
            // }
            // if (document.querySelector('.show_sightseeing') !== null) {
            //     document.querySelector('.show_sightseeing').classList.add('d-none');
            // }
            // if (document.querySelector('.show_holidays') !== null) {
            //     document.querySelector('.show_holidays').classList.remove('d-none');
            // }

            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
    }

    if (sessionStorage.getItem('serviceType') === 'lblFlights') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblFlightPlusHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.remove('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblSightseeing') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.remove('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHolidays') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.remove('d-none');
        }
    }

    // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
    //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
    //     document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
    // }

    return <Fragment>
        <div className="row position-relative show_holidays">
            <div className="col-lg-12 tour_search_form">
                <form action="!#">
                    <div className="row">
                        <label for="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                        <div className='text-sm-center1 mb-2'>
                            <span>
                                {
                                    (checkSecurityDetails("Book", "Flight") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                    <label for="" className="h6 mb-2" role="button" id='lblFlights_holidays' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                }
                                {
                                    (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                        getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                        && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) ?
                                        <label for="" className="h6 mb-2 ms-3 text" role="button" id='lblFlightPlusHotel_holidays' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                        <label id='lblFlightPlusHotel_holidays' ></label>
                                }
                                {
                                    (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel_holidays' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                }
                                {
                                    (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing_holidays' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                }
                                {
                                    getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                    <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays_holidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                }
                            </span>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Fragment>
};

export default SearchHolidaysPartial;