import { Fragment, useEffect } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import BookingDetails from '../CarBookingConfirmation/Components/BookingDetails';
import DriverDetails from '../CarBookingConfirmation/Components/DriverDetails';
import ContactPerson from '../CarBookingConfirmation/Components/ContactPerson';
import BillingDetails from '../CarBookingConfirmation/Components/BillingDetails';
import BookingRemarks from '../CarBookingConfirmation/Components/BookingRemarks';
import Booking from '../CarBookingConfirmation/Components/Booking';
import CarItem from '../CarBooking/Components/CarItem';


const RetrieveCar = () => {
    useDocumentTitle('Trip Details')
    let CarBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));

    window.scrollTo({
        top: 50,
        behavior: "smooth",
    });

    return <Fragment>
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>

        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper carListItemDetails">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area mb-3">
                            <div className="col-lg-12">
                                <div className='row'>
                                    <BookingDetails CarBookingJSON={CarBookingJSON} hide="1"></BookingDetails>
                                    <CarItem
                                        key={CarBookingJSON.sequencenumber}
                                        id={CarBookingJSON.sequencenumber}
                                        carResults={CarBookingJSON.data.carResults[0]}
                                        isFromDetailPage={true}
                                        showData="1"
                                    />

                                    <Booking carResults={CarBookingJSON.data.carResults[0]}></Booking>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <ContactPerson carResults={CarBookingJSON.data.travelerInfo}></ContactPerson>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <DriverDetails carResults={CarBookingJSON.data.travelerInfo}></DriverDetails>
                                    <div className='col-md-12'> <hr></hr></div>
                                    <BillingDetails CarBookingJSON={CarBookingJSON} print="1"></BillingDetails>
                                    {CarBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.find(remarks => remarks.remarkType === 'General').text !== '' ? <Fragment><div className='col-md-12'> <hr></hr></div>
                                        <div className="">
                                            <BookingRemarks CarBookingJSON={CarBookingJSON}></BookingRemarks>
                                        </div></Fragment> : ''}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default RetrieveCar;