import { Fragment, useState, useEffect } from 'react';
import PrintComponent from './PrintComponent'
import PopupModal from '../../../components/Common/Flight/PrintPopupModal';
import CarSendMail from './CarSendMail';
import CarNotes from '../../MyTrips/Components/CarNotes';
import MdModal from '../../Flights/MdModal';
import { useLocation } from 'react-router-dom';
import CancelPNRCar from '../../MyTrips/Components/CancelPNRCar';
import Logs from '../../MyTrips/Components/Logs';
import Rebooking from '../../MyTrips/Components/Rebooking';
import HistoryDetails from '../../MyTrips/Components/HistoryDetails';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';

const BookingDetails = (props) => {
    const [errorMail, setErrorMail] = useState();
    const [error1, setError] = useState();
    const [isCanceled, setIsCanceled] = useState(false);
    const [isRebook, setIsRebook] = useState(false);
    const location = useLocation();

    const [carCancelStatus, setCarCancelStatus] = useState('');
    let pnr = props.CarBookingJSON.data.bookingReferenceID !== undefined ?
        props.CarBookingJSON.data.bookingReferenceID.id : null;
    let isFailed = (props.CarBookingJSON.data.requestType === "ON" && isCanceled === false && pnr === null) ? true : false

    useEffect(() => {
        if (props.report !== undefined && props.report === 'true') {
            var menuToHideList = document.querySelectorAll('.hidereportmenu');
            menuToHideList.forEach(element => {
                element.classList.add('d-none');
            });
        }
    })

    const onPNRCancel = () => {
        setErrorMail({
            title: "Cancel Booking",
            message: <div className='col-md-12'>
                <div className='dummy-flight'>
                    <CancelPNRCar mailData={props.CarBookingJSON} errorHandler={errorHandler} hideData="0"></CancelPNRCar>
                </div></div>,
        })

    }
    const onNotes = () => {
        setErrorMail({
            title: "Internal Notes",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <CarNotes bookingJSON={props.CarBookingJSON} errorHandler={errorHandler} hideData="0" />
                </div></div>,
        })
    };
    const onRebooking = () => {
        setErrorMail({
            title: "Reschedule Request",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <Rebooking bookingJSON={props.CarBookingJSON} errorHandler={errorHandler} ></Rebooking>
                </div></div>,
        })


    }
    const onHistory = () => {
        setErrorMail({
            title: "History Details",
            message: <div className='col-md-12'>
                <div>
                    <HistoryDetails bookingJSON={props.CarBookingJSON} errorHandler={errorHandler} hideData="0"></HistoryDetails>
                </div></div>,
        })

    }
    const onLogs = () => {
        setErrorMail({
            title: "Logs",
            message: <div className='col-md-12'>
                <div>
                    <Logs bookingJSON={props.CarBookingJSON} errorHandler={errorHandler} ></Logs>
                </div></div>,
        })
    }
    const onMail = () => {
        setErrorMail({
            title: "Send Booking Details",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <CarSendMail mailData={props.CarBookingJSON} errorHandler={errorHandler}></CarSendMail>
                </div></div>,
        })
    }
    useEffect(() => {
        if (location.pathname === "/RetrieveCar") {
            setIsRebook(true);
        }
        else {
            setIsRebook(false);
        }

        if (location.carTrip !== undefined) {
            setIsCanceled(location.carTrip.isCanceled);
            setCarCancelStatus(location.carTrip.carCancelStatus);
        }
        else {
            setIsCanceled(sessionStorage.getItem("myTripIsCarCancelled") === 'true' ? true : false)
            setCarCancelStatus(sessionStorage.getItem("carStatus"));
        }

        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, [])

    const errorHandler = () => {
        setError(null);
        setErrorMail(null);
    };

    const getBookingStatusClassName = () => {
        if (props.CarBookingJSON.data.requestType === "OF") {
            return "badge h6 rounded-pill mb-0 bg-primary";
        } else if (isCanceled) {
            return 'badge h6 rounded-pill mb-0 bg-Failed';
        } else if (pnr === null) {
            return 'badge h6 rounded-pill mb-0 bg-Failed';
        } else if (pnr !== null) {
            return "badge h6 rounded-pill mb-0 bg-success";
        }

    }
    const getBookingStatusText = () => {
        //Case 1: Booking Request

        if (props.CarBookingJSON.data.requestType === "OF") {
            return " Booking Request";
        } else if (isCanceled) {
            return ' Canceled';
        } else if (pnr === null) {
            return " Failed";
        } else if (pnr !== null) {
            return " Booked";
        }

    }
    const getBookingStatusIcon = () => {
        //Case 1: Booking Request

        if (props.CarBookingJSON.data.requestType === "OF") {
            return <i className='fa-solid fa-circle-check'></i>
        }
        //Case 1: Canceled
        else if (isCanceled) {

            return <i className='fa-solid fa-circle-xmark'></i>
        }
        //Case 1: Failed
        else if (pnr === null) {
            return <i className='fa-solid fa-circle-xmark'></i>
        } //Case 1: Booked 
        else if (pnr !== null) {
            return <i className='fa-solid fa-check-circle'></i>
        }
    }
    //const getBookingStatusIcon=()=>{}

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        {/* {props.hideData !== '1' && <div className='col-md-12'>
            <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Booking Details &nbsp;
                <span className={getBookingStatusClassName()}>
                    {getBookingStatusIcon()}
                    <span className='ms-1'>
                    </span>
                    {getBookingStatusText()}
                </span></label>
        </div>} */}
        <div className='col-md-12'>
            <div className='row'>
                {props.hideData !== '1' &&
                    <div className='col-md-6'>
                        <label for="" className="h5 text-primary"><i className="fas fa-globe-asia"></i> Booking Details <span class={props.CarBookingJSON.data.requestType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" : isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' : pnr !== null ? "badge h6 rounded-pill mb-0 bg-success" : "badge h6 rounded-pill mb-0 bg-Failed"}><i class={props.CarBookingJSON.data.requestType === "OF" ? "fa-solid fa-circle-check" : isCanceled !== false ? 'fa-solid fa-circle-xmark' : pnr !== null ? "fa-solid fa-check-circle" : "fa-solid fa-circle-xmark"}></i> <span className='ms-1'></span> {props.CarBookingJSON.data.requestType === "OF" ? " Booking Request" : isCanceled !== false ?
                            carCancelStatus === "Cancelation Requested" ? 'Cancelation Requested' :
                                'Canceled' : pnr !== null ? "Booked" : "Failed"}</span></label>
                    </div>}
                <div className='col-md-6'>

                    {
                        checkSecurityDetails("My Trips", "Email", "My Trips_Email_Allow Send Email") &&
                        isCanceled === false && isFailed === false && <div className='float-lg-end '>

                            <span id="sendMail" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end hidereportmenu ' onClick={() => onMail()}><i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    }

                    {
                        checkSecurityDetails("My Trips", "Print", "My Trips_Print_Allow Print")
                        &&
                        isCanceled === false && isFailed === false && <div className='float-lg-end hidereportmenu d-none'>
                            <PrintComponent print="1"></PrintComponent>
                        </div>
                    }
                    {
                        checkSecurityDetails("My Trips", "Cancelation", "My Trips_Cancelation_Allow Cancelation") &&
                        (isCanceled === false && props.CarBookingJSON.data.reasons !== undefined) &&
                        <div className='float-lg-end'>
                            <span id="pnrCancellation" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-danger float-end hidereportmenu' onClick={() => onPNRCancel()}><i className="fas fa-times px-1"></i>
                            </span>
                        </div>
                    }
                    {
                        checkSecurityDetails("My Trips", "Logs", "My Trips_Reschedule_Allow Reschedule") && (isCanceled === false && isRebook) &&
                        <div className='float-lg-end'>
                            <span id="pnrRebooking" style={{ display: pnr !== null ? "block" : "none" }} className='btn btn-sm text-white mt-1 ms-1 btn-success float-end hidereportmenu' onClick={() => onRebooking()}><i className="fas fa-retweet rotate-90"></i>
                            </span>
                            <ReactTooltip
                                anchorId={"pnrRebooking"}
                                place="bottom"
                                variant="info"
                                multiline={true}
                                className="tooltip"
                                html={"Reschedule Request"}
                            />
                        </div>
                    }
                    {checkSecurityDetails("My Trips", "Internal Note", "My Trips_InternalNote_Allow  Internal Note") && props.hide !== '1' && getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" && <div className='float-lg-end'>
                        <span id="notes" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end hidereportmenu' onClick={() => onNotes()}><i className="fas fa-file-alt"></i>
                        </span>
                    </div>}
                    {checkSecurityDetails("My Trips", "History", "My Trips_History_Allow History") && props.hide !== '1' && <div className='float-lg-end'>
                        <span id="history" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end hidereportmenu' onClick={() => onHistory()}><i className="fas fa-history"></i>
                        </span>
                    </div>}
                    {checkSecurityDetails("My Trips", "Reschedule", "My Trips_Logs_Allow Logs") && (pnr === null && props.CarBookingJSON.data.requestType === "ON") && <div className='float-lg-end'>
                        <span id="Logs" className='btn btn-sm text-white mt-1 ms-1 btn-dark float-end hidereportmenu' onClick={() => onLogs()}><i className="fas fa-hourglass-half"></i>
                        </span>
                    </div>}
                    <ReactTooltip
                        anchorId={"sendMail"}
                        place="bottom"
                        variant="info"
                        multiline={true}
                        className="tooltip"
                        html={"Email"}
                    />
                    <ReactTooltip
                        anchorId={"pnrCancellation"}
                        place="bottom"
                        variant="info"
                        multiline={true}
                        className="tooltip"
                        html={"Cancel Booking"}
                    />
                    <ReactTooltip
                        anchorId={"notes"}
                        place="bottom"
                        variant="info"
                        multiline={true}
                        className="tooltip"
                        html={"Internal Notes"}
                    />
                    <ReactTooltip
                        anchorId={"history"}
                        place="bottom"
                        variant="info"
                        multiline={true}
                        className="tooltip"
                        html={"History"}
                    />
                </div>
            </div>

        </div>

        <div className='row mt-2'>
            <div className='col-md-12'>
                <div className='col-md-12'>
                    <div className='text-muted h6 mb-2 mt-2'>
                        <b className='text-primary' >Confirmation No. </b> <span className='text-black'>{props.CarBookingJSON.data.bookingReferenceNumber}</span>

                        <span>{props.CarBookingJSON.data.bookingReferenceID !== undefined && <span> | <b className=' text-primary'>PNR </b> <span className='text-black'>{props.CarBookingJSON.data.bookingReferenceID.id}</span></span>}

                        </span>
                        <span>{props.CarBookingJSON.data.bookingReferenceID !== undefined && props.CarBookingJSON.data.carResults[0].vehRentalCore.confirmationNumber !== undefined && <span> | <b className='text-primary'>Car Confirmation No.  </b> <span className='text-black'>{props.CarBookingJSON.data.carResults[0].vehRentalCore.confirmationNumber}</span></span>} </span>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>

};

export default BookingDetails;