import { Fragment, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import TravellerDetails from './Components/TravellerDetails';
import { useTranslation } from 'react-i18next';
import useHttp from '../../../services/use-http';
import { addCustomer, viewCustomer } from '../../../services/Customer-API';
import secureLocalStorage from 'react-secure-storage';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { AUTHENTICATION } from '../../../navigation/CONSTANTS.js';

const Profile = () => {
    const { t } = useTranslation(['translation.Profile']);
    useDocumentTitle('My Profile');
    const { sendRequest: sendRequestView, status: statusView, data: loadedCustomerDetailsView } = useHttp(viewCustomer);
    const history = useHistory();
    const location = useLocation();
    const [customerId, setCustomerId] = useState('');
    const [error1, setError] = useState();

    let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));

    useEffect(() => {
        if (profileJSON === null) {
            window.location.pathname = `${AUTHENTICATION}`;
        }
    }, [])

    useEffect(() => {
        const requestData = (
            {
                "emailID": secureLocalStorage.getItem("CustomerLoginEmailID"),
                "officeID": JSON.parse(localStorage.getItem("officeID"))
            }
        );

        sendRequestView(requestData);
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (statusView === 'completed') {
            for (let i in loadedCustomerDetailsView) {
                if (loadedCustomerDetailsView['errors'].status === 'FALSE') {
                    viewCustomerDetails(loadedCustomerDetailsView.data[0]);
                }
                else if (loadedCustomerDetailsView['errors'].status === 'TRUE') {
                    setError({
                        title: "Message",
                        message: loadedCustomerDetailsView.errors.error.description,
                    });
                }
            }
        }

    }, [statusView, loadedCustomerDetailsView])

    function viewCustomerDetails(param) {
        let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));

        profileJSON.data.travelerInfo.traveler[0].PersonName.NamePrefix = param.title;
        profileJSON.data.travelerInfo.traveler[0].PersonName.GivenName = param.firstName;
        profileJSON.data.travelerInfo.traveler[0].PersonName.MiddleName = param.middleName;
        profileJSON.data.travelerInfo.traveler[0].PersonName.Surname = param.lastName;

        profileJSON.data.travelerInfo.traveler[0].email[0].emailId = param.emailID;
        profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile = param.travelerPhoneNumber !== '' ? param.travelerPhoneCode + " " + param.travelerPhoneNumber.replace(/[^0-9]/g, '') : param.phoneCode + " " + param.phoneNumber.replace(/[^0-9]/g, '');
        profileJSON.data.travelerInfo.traveler[0].dateOfBirth.date = param.dateOfBirth ? param.dateOfBirth : '00000000';
        profileJSON.data.travelerInfo.traveler[0].Gender = param.gender;

        if (profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[0].DocID !== undefined) {
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocHolderNationality = param.nationality;
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocID = param.passportNo;
            profileJSON.data.travelerInfo.traveler[0].Document[0].ExpireDate = param.passportExp !== undefined && param.passportExp !== '' ? param.passportExp : '00000000';
            profileJSON.data.travelerInfo.traveler[0].Document[0].DocIssueCountry = param.country;
        }

        if (profileJSON.data.travelerInfo.traveler[0].Document !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1] !== undefined && profileJSON.data.travelerInfo.traveler[0].Document[1].DocID !== undefined) {
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocType = param.additional;
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocID = param.additionalNumber;
            profileJSON.data.travelerInfo.traveler[0].Document[1].DocIssueCountry = param.additionalCountry;
        }

        setCustomerId(param.id);
        localStorage.setItem("ProfileData", JSON.stringify(profileJSON));
        history.push({ pathname: location.pathname });
    }

    const errorHandler = () => {
        setError(null);
    };

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        {(statusView === 'pending') &&
            <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mt-5">
                                <div className="row  position-relative gt-h-255 my-auto">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        {/*   <!-- Form Area --> */}
        {
            (statusView === 'completed') &&
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mb-4">

                                <div className="row">
                                    <div className="col-lg-12 position-relative">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label for="" className="h5 text-primary mb-0"><i className="fas fa-globe-asia"></i> My Profile</label>
                                                    </div>
                                                </div>

                                                {profileJSON?.data !== null &&
                                                    <div className='row'>
                                                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                            <div className="tour_details_boxed_inner">
                                                                <TravellerDetails traveler={profileJSON !== null ? profileJSON.data : null} customerId={customerId}></TravellerDetails>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

    </Fragment>
};

export default Profile;
