import React, { useEffect } from "react";
import { Fragment } from "react";
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../components/Common/Loading/LoadingSpinner';
import { FLIGHTSEARCH, SEARCHPNR, TRAVELREQUEST, SEARCHHOTEL, SEARCHSIGHTSEEING, SEARCHHOLIDAYS, CRYPTIC, MYTRIPS, UNAUTHORIZED, SEARCHSALES, SEARCHCAR } from './CONSTANTS';
import { checkSecurityDetails } from "../utils/RoleUtils";
import { getConfigurationByBoolen, getConfigurationByValue } from "../utils/ConfigurationsFunction";
import { getCustomizeDetails } from "../utils/CustomizeUtils";

const Loading = () => {
    const history = useHistory();

    useEffect(() => {

        setTimeout(() => {
            sessionStorage.setItem("LoaderHeader", "0");
            if (localStorage.getItem('isCorporate') === 'true')
                // history.push(TRAVELREQUEST);
            window.location.href = TRAVELREQUEST;

            else if (JSON.parse(localStorage.getItem("isTravelAgent")) === "1")
                history.push(MYTRIPS);
            else if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search")) {
                // history.push(FLIGHTSEARCH);
                window.location.href = FLIGHTSEARCH;
            } else if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) {
                // history.push(SEARCHHOTEL);
                window.location.href = SEARCHHOTEL;
            } else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                // history.push(SEARCHSIGHTSEEING)
                window.location.href = SEARCHSIGHTSEEING;
            }
            else if (getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '') {
                // history.push(SEARCHHOLIDAYS)
                window.location.href = SEARCHHOLIDAYS;
            } else if ((checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true)) {
                // history.push(SEARCHPNR)
                window.location.href = SEARCHPNR;
            } else if ((checkSecurityDetails("Book", "SabreHost") && getConfigurationByBoolen("ALLOW_SABRE_HOST") === true)) {
                // history.push(CRYPTIC)
                window.location.href = CRYPTIC;
            }
            else if (checkSecurityDetails("My Trips")) {
                // history.push(MYTRIPS);
                window.location.href = MYTRIPS;
            }
            else if (checkSecurityDetails("Reports", "DashBoard")) {
                // history.push(SEARCHSALES);
                window.location.href = SEARCHSALES;
            } else if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) {
                // history.push(SEARCHCAR);
                window.location.href = SEARCHCAR;
            }
            else {
                // history.push(UNAUTHORIZED)
                window.location.href = UNAUTHORIZED;
            }

            //history.go();
            //return <Redirect to={FLIGHTSEARCH}></Redirect>
        }, 1000);

    }, []);

    if (getCustomizeDetails("loaderColor")) {
        document.documentElement.style.setProperty('--custom-loader', getCustomizeDetails("loaderColor"));
    }

    return (
        <>
            {/* Page Not Found! */}
            <Fragment>
                {/*     <!-- Common Banner Area --> */}
                <section id="common_banner" className="MainSscreenLoader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 full-page-loader">
                                <LoadingSpinner></LoadingSpinner>
                            </div>
                        </div>
                    </div>
                </section>




                {/* <div className="preloader">
                    <div className="d-table">
                        <div >
                            <div >
                                
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                </div> */}


            </Fragment>
        </>
    );
};
export default Loading;
