import { Fragment } from 'react';
import { getFormatDate, getFormatTime } from './../../../utils/CommonFunction'
const Booking = (props) => {
    const getRentalDays = (vehicle) => {
        const rentalDays = vehicle?.vehRentalCore?.vehLocationInfo?.rentalDays;
        const rentalHours = vehicle?.vehRentalCore?.vehLocationInfo?.rentalHours;
        if (rentalDays && rentalDays !== '0') {
            return `  ${rentalDays} day${parseInt(rentalDays) > 1 ? 's' : ''}`
        } else if (rentalHours && rentalHours !== '0') {
            return `  ${rentalHours} hour${parseInt(rentalHours) > 1 ? 's' : ''}`
        }
    }
    return <Fragment>
        <div className="mt-2">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" style={{ color: " #38c1f5" }} id="headingsix">
                Booking
            </h5>
            <div id="collapsesix" className="">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4"><div className='h6 mb-2 print-primary-title' style={{ color: "#12688B" }}>Pick-up date & time</div> </div>
                            <div className="col-lg-9 col-md-9  col-sm-8 col-8 "><div className='h6 mb-2'>
                                {getFormatDate(props.carResults.vehRentalCore.vehLocationInfo.pickupDate)}
                                {props.carResults.vehRentalCore.vehLocationInfo.pickupTime !== undefined
                                    ? ", " +
                                    getFormatTime(
                                        props.carResults.vehRentalCore.vehLocationInfo.pickupTime) : ''}
                            </div> </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 print-primary-title' style={{ color: "#12688B", }}> Drop-off date & time</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>
                                    {getFormatDate(props.carResults.vehRentalCore.vehLocationInfo.returnDate)}
                                    {props.carResults.vehRentalCore.vehLocationInfo.pickupTime !== undefined
                                        ? ", " +
                                        getFormatTime(
                                            props.carResults.vehRentalCore.vehLocationInfo.returnTime) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                            <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                <div className='h6 print-primary-title' style={{ color: "#12688B" }}>Total length of car rental</div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                                <div className='h6'>
                                    {getRentalDays(props.carResults)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Fragment>

};

export default Booking;