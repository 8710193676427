import { Fragment, useState, useRef, useEffect } from "react";
import MdModal from '../../Flights/MdModal';
import { cancelCar } from "../../../services/Car-API";
import useHttp from "../../../services/use-http";
import { emailValidation } from '../../../utils/Validation';
import { useHistory } from 'react-router-dom';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { calculateRoeAmountAdditional } from '../../../utils/CommonFunction';

const CancelPNRCar = (props) => {
    const inputEmailIdRef = useRef("");
    const [error1, setError1] = useState();
    const { sendRequest, status, data: loadedQuote } = useHttp(cancelCar);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [balnkMailError, setBlankMailError] = useState(false);
    const [mailConfirm, setMailConfirm] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

    //USER STORY 2449: New fields in Car Cancelation pop-up
    const [invalidServiceFeeError, setInvalidServiceFeeError] = useState(false);
    const [invalidCarCancelationChargesError, setInvalidCarCancelationChargesError] = useState(false);
    const [invalidRevisedCarCommissionError, setInvalidRevisedCarCommissionError] = useState(false);
    const [mandatoryCheckError, setMandatoryCheckError] = useState(false);
    const history = useHistory();

    let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

    const checkBoxChangeHandler = (event) => {
        setIsCheckBoxChecked(!isCheckBoxChecked)
    }

    function functionExit() {
        props.errorHandler();
        if (cancelled) {
            sessionStorage.setItem("myTripIsCarCancelled", true);
            history.push({
                pathname: '/RetrieveCar',
                carTrip: { isCanceled: true }
            });
            history.go();
        }
        else {
            sessionStorage.setItem("myTripIsCarCancelled", false);
            history.push({
                pathname: '/RetrieveCar',
                carTrip: { isCanceled: false }
            });
            history.go();
        }
    }
    useEffect(() => {
        if (props.mailData.data.reasons.reason !== undefined) {
            inputEmailIdRef.current.value = props.mailData.data.reasons.reason[0].text;
            setCancelled(true);
        }
    }, [props.CarBookingJSON])
    const errorHandler1 = () => {
        setError1(null);
    };
    useEffect(() => {
        if (status === 'completed') {
            if (loadedQuote) {
                if (loadedQuote.data.errors.status === 'FALSE') {
                    setMailConfirm(loadedQuote.data.errors.error.description);
                    setCancelled(true);

                } else {
                    if (loadedQuote.data.errors.error.code === 'SEGMENT_NOT_FOUND') {
                        // setMailConfirm("Trip already canceled.");
                        setMailConfirm(loadedQuote.data.errors.error.description);
                        setCancelled(true);
                    } else {
                        setMailConfirm(loadedQuote.data.errors.error.description);
                        setCancelled(false)
                    }

                }
            } else {
                setMailConfirm("Some error has occurred");
                setCancelled(false);
            }

            return;
        }

    }, [status])
    function onBlurCheck() {

        if (inputEmailIdRef.current.value.trim() === '') {
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }

    }
    function submitFormHandler() {

        if (inputEmailIdRef.current.value.trim() === '') {
            inputEmailIdRef.current.focus();
            setBlankMailError(true);
            setInvalidEmailError(false)
            return;
        }
        else {
            setBlankMailError(false);
        }
        //USER STORY 2449: New fields in Car Cancelation pop-up
        if (document.querySelector('#serviceFee') !== null && document.querySelector('#serviceFee').value !== '' && !document.querySelector('#serviceFee').value.match('^[0-9.]+$')) {
            document.querySelector('#serviceFee').focus();
            setInvalidServiceFeeError(true)
            return;
        }
        else {
            setInvalidServiceFeeError(false);
        }

        if (document.querySelector('#carCancelationCharge') !== null && document.querySelector('#carCancelationCharge').value !== '' && !document.querySelector('#carCancelationCharge').value.match('^[0-9.]+$')) {
            document.querySelector('#carCancelationCharge').focus();
            setInvalidCarCancelationChargesError(true);
            return;
        }
        else {
            setInvalidCarCancelationChargesError(false);
        }
        if (document.querySelector('#revisedCarCommission') !== null && document.querySelector('#revisedCarCommission').value !== '' && !document.querySelector('#revisedCarCommission').value.match('^[0-9.]+$')) {
            document.querySelector('#revisedCarCommission').focus();
            setInvalidRevisedCarCommissionError(true)
            return;
        }
        else {
            setInvalidRevisedCarCommissionError(false);
        }
        if (!isCheckBoxChecked) {
            document.querySelector('#checkBox').focus();
            setMandatoryCheckError(true)
            return;
        }
        else {
            setMandatoryCheckError(false);
        }

        let carBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));
        let cancellationRemarks = JSON.stringify({
            "Reason": [
                {
                    "Type": "cancel",
                    "Language": null,
                    "Text": inputEmailIdRef.current.value,
                    "CancellationCharge": document.querySelector('#carCancelationCharge') !== null ? document.querySelector('#carCancelationCharge').value === '' ? "0" : document.querySelector('#carCancelationCharge').value : "0",
                    "ServiceFee": document.querySelector('#serviceFee') !== null ? document.querySelector('#serviceFee').value === '' ? "0" : document.querySelector('#serviceFee').value : "0",
                    "Commission": document.querySelector('#revisedCarCommission') !== null ? document.querySelector('#revisedCarCommission').value === '' ? "0" : document.querySelector('#revisedCarCommission').value : "0"
                }
            ]
        });
        carBookingJSON.data.reasons = JSON.parse(cancellationRemarks);
        carBookingJSON.data.travelAgentName = JSON.parse(localStorage.getItem('displayName'));
        carBookingJSON.data.userID = JSON.parse(localStorage.getItem('userID'));

        // carBookingJSON.data.carResults[0].price[0].equivalentCurrencyCode = walletCurrency;

        // let fareCurrency = carBookingJSON.data.carResults[0].price[0].currencyCode;
        // let serviceFeeAmount = carBookingJSON.data.reasons.Reason[0].ServiceFee;
        // carBookingJSON.data.reasons.Reason[0].equivalentServiceFee = calculateRoeAmountAdditional(fareCurrency, serviceFeeAmount, false);

        // let cancellationChargeAmount = carBookingJSON.data.reasons.Reason[0].CancellationCharge;
        // carBookingJSON.data.reasons.Reason[0].equivalentCancellationCharge = calculateRoeAmountAdditional(fareCurrency, cancellationChargeAmount, false);

        sendRequest(
            carBookingJSON
        );
    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler1}
            ></MdModal>
        )}
        <div className="row">
            <div className="col-md-12 gt-h-340px">
                <div className="control">

                    <label className="h6 text-primary mb-2 col-lg-12" for="category"> Cancelation Reason <span className="error-icon"> *</span>
                    </label>
                    <div>
                        <textarea id="txtCancelationReason" type="text" rows="2" className="mb-2 form-control form-control-sm col-lg-12" onChange={onBlurCheck} ref={inputEmailIdRef}
                            autoFocus>
                        </textarea>
                        {balnkMailError && <p className="error-text">Cancelation Reason is required.</p>
                        }
                        {invalidEmailError && <p className="error-text">Cancelation Reason is not valid.</p>}

                        <span id="msgfName1" className="error-text"></span>
                    </div>

                    {/* User Story 2409: New fields in Flight Cancelation pop-up */}
                    <div className="row">
                        {
                            getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                            <div className="col-lg-12">
                                <label id="lblServiceFee" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Service Fee </label>
                                <input type="number" id="serviceFee" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidServiceFeeError && <p className="error-text">Service Fee is not valid.</p>}
                            </div>
                        }
                        {
                            getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" &&
                            <div className="col-lg-6">
                                <label id="lblCarancelationCharge" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Car Cancelation Charges </label>
                                <input type="number" id="carCancelationCharge" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidCarCancelationChargesError && <p className="error-text">Car Cancelation Charges is not valid.</p>}
                            </div>
                        }
                        {
                            getConfigurationByBoolen("ALLOW_COMISSION") &&
                            <div className="col-lg-6">
                                <label id="lblRevisedCarCommission" className="h6 text-primary mb-2 mt-3 col-lg-12" for="category"> Revised Car Commission </label>
                                <input type="number" id="revisedCarCommission" className="mb-2 form-control form-control-sm col-lg-12" min="0" onKeyDown={preventActions} onPaste={preventPasteNegative} maxLength="10">
                                </input>
                                {invalidRevisedCarCommissionError && <p className="error-text">Revised Car Commission is not valid.</p>}
                            </div>
                        }
                    </div>


                    <div className='text-black col-md-12 mt-2'>
                        <input className="form-check-input tandc-payment-option" type="checkbox" id="checkBox" checked={isCheckBoxChecked} onChange={checkBoxChangeHandler}></input>&nbsp;<span> I understand and agree that cancelation will be subject to applicable penalties from the provider.</span>
                        {mandatoryCheckError && < p className="error-text">Please agree to the cancelation conditions .</p>}
                    </div>
                    {!balnkMailError && !invalidEmailError && cancelled && <p className="success-text">{mailConfirm}</p>}
                    {!balnkMailError && !invalidEmailError && !cancelled && <p className="error-text">{mailConfirm}</p>}

                </div>
            </div>

            <div className="col-md-12">
                <div className="row">
                    <div className="my-auto col-md-6"><span className="error-icon">*</span><span id='requiredIndicator'>Indicates required fields</span></div>
                    <div className="col-md-6 text-end">
                        {!cancelled && <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler}>
                            {status === 'pending' ?
                                <i className="fa fa-spinner fa-spin" ></i>
                                : 'Confirm'}
                        </button>}
                        <button className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit}>
                            Exit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};
export default CancelPNRCar;