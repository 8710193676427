
import { useEffect, } from 'react';
import useHttp from '../../services/use-http';
import ReactDOMServer from 'react-dom/server';
import { sendCarEmailProposal } from '../../services/SendMail-API';
import { getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import CarPrintConfirmation from '../CarBookingConfirmation/CarPrintConfirmation';
const CarConfirmation = () => {
    const { sendRequest, status, data: loadedQuote } = useHttp(sendCarEmailProposal);


    useEffect(() => {
        const data = ReactDOMServer.renderToString(<html>
            <head >

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" integrity="sha512-0S+nbAYis87iX26mmj/+fWt1MmaKCv80H+Mbo+Ne7ES4I6rxswpfnC6PxmLiw33Ywj2ghbtTw0FkLbMWqh4F7Q==" crossorigin="anonymous" referrerpolicy="no-referrer" />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/fontawesome.min.css" integrity="sha512-RtgdY8FN0wFbR4ascGaGFDgjPJBzXP6WZZExVmmWJOaTCm4B0nF3e4G9iy9SfATuFQxyc+Mq7hXINynh3iwLDg==" crossorigin="anonymous" referrerpolicy="no-referrer" />

                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />

                <link href="http://localhost:3000/assets/css/Recommendation.css" rel="stylesheet" />


            </head>

            <body style={{ fontFamily: 'Poppins' }}>
                <CarPrintConfirmation />
            </body></html>);
        let CarBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));
        const title = "Hi " + CarBookingJSON.data.travelerInfo.traveler[0].personName.givenName + " " + CarBookingJSON.data.travelerInfo.traveler[0].personName.middleName + " " + CarBookingJSON.data.travelerInfo.traveler[0].personName.surname + ",";


        let inputData;
        if (CarBookingJSON.data.requestType === "OF") {
            inputData = {
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "data": {
                    "title": title,
                    "mailContent": "We have received your car booking request with Confirmation No. " + CarBookingJSON.data.bookingReferenceNumber + ".",
                    "receipent": CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId !== null && CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId !== '' ? CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId : "",
                    "BCC": getConfigurationByValue('CAR_BOOKING_SENT_REQUEST') !== null && getConfigurationByValue('CAR_BOOKING_SENT_REQUEST') !== '' ? getConfigurationByValue('CAR_BOOKING_SENT_REQUEST') : "",
                    "fileName": "Confirmation No. " + CarBookingJSON.data.bookingReferenceNumber,
                    "remarks": "Please find attached the details.",
                    "subject": "Car Booking Request for Confirmation No." + CarBookingJSON.data.bookingReferenceNumber,
                    "pdfHtml": data,
                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "userID": JSON.parse(localStorage.getItem('userID')),

                }
            }
        } else {

            inputData = {
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "data": {
                    "title": title,
                    "mailContent": "Your car has been successfully booked with Confirmation No. " + CarBookingJSON.data.bookingReferenceNumber + ".",
                    "receipent": CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId !== null && CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId !== '' ? CarBookingJSON.data.travelerInfo.traveler[0].email[0].emailId : "",
                    "BCC": getConfigurationByValue('CAR_SENT_REQUEST') !== null && getConfigurationByValue('CAR_SENT_REQUEST') !== '' ? getConfigurationByValue('CAR_SENT_REQUEST') : "",
                    "fileName": "Confirmation No. " + CarBookingJSON.data.bookingReferenceNumber,
                    "remarks": "Please find attached the details.",
                    "subject": "Car Booking Confirmation No. " + CarBookingJSON.data.bookingReferenceNumber,
                    "pdfHtml": data,
                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "userID": JSON.parse(localStorage.getItem('userID')),

                }
            }
        }


        if (inputData !== null && inputData !== undefined && inputData !== '' && (sessionStorage.getItem("carMailSend") === null || sessionStorage.getItem("carMailSend") === 'false')) {
            sendRequest(
                inputData
            );
            sessionStorage.setItem('carMailSend', 'true');
        }


    }, [])

};

export default CarConfirmation;