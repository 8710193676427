import { Fragment, useEffect, useState } from "react";
import { checkSecurityDetails } from "../../utils/RoleUtils";
import {
  getConfigurationByValue,
  getConfigurationByBoolen,
} from "../../utils/ConfigurationsFunction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { searchVisa } from "../../services/Visa-API";
import useHttp from "../../services/use-http";
import LoadingSpinner from "../../components/Common/Loading/LoadingSpinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'

const SearchVisa = () => {
  useDocumentTitle("e-Visa");
  const { t } = useTranslation(["translation.Sightseeing"]);
  const history = useHistory();
  const location = useLocation();
  const { sendRequest, status, data, error } = useHttp(searchVisa);
  const [visaUrl, setVisaUrl] = useState("");
  const [trackUrl, setTrackUrl] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [isCreateVisa, setIsCreateVisa] = useState(true);

  useEffect(() => {
    sendRequest({

      "external_user_id": JSON.parse(localStorage.getItem('officeID')),
      "token": "Visagjkskhwjoowhkhsksksnslfhskkskksghjskj",
      "host": "fareladder",
      "cor": "India",
      "nationality": "",
      "travelling_to": "",
      "travelling_to_identity": "",
      "start_date": "",
      "end_date": "",
      "agency_name": JSON.parse(localStorage.getItem('companyName')),

    })
  }, [sendRequest])


  useEffect(() => {
    sessionStorage.setItem("myTripIsCanceled", "");
    sessionStorage.setItem("myTripIsHotelCancelled", "");

    // window.scrollTo({
    //   top: 50,
    //   behavior: "smooth",
    // });

    if (document.querySelector("#lblFlights") !== null) {
      document
        .querySelector("#lblFlights")
        .classList.remove("flight-active-tab");
      document.querySelector("#lblFlights").classList.add("text-primary");
    }

    if (document.querySelector("#lblFlightPlusHotel") !== null) {
      document
        .querySelector("#lblFlightPlusHotel")
        .classList.remove("flight-active-tab");
      document
        .querySelector("#lblFlightPlusHotel")
        .classList.add("text-primary");
    } else {
      if (document.querySelector("#lblFlights") !== null) {
        document
          .querySelector("#lblFlights")
          .classList.remove("flight-active-tab");
        document.querySelector("#lblFlights").classList.add("text-primary");
      }
    }

    if (document.querySelector("#lblHotel") !== null) {
      document
        ?.querySelector("#lblHotel")
        ?.classList?.remove("flight-active-tab");
      document?.querySelector("#lblHotel")?.classList?.add("text-primary");
    }

    if (document.querySelector("#lblCar") !== null) {
      document
        ?.querySelector("#lblCar")
        ?.classList?.remove("flight-active-tab");
      document?.querySelector("#lblCar")?.classList?.add("text-primary");
    }

    if (document.querySelector("#lblVisa") !== null) {
      document.querySelector("#lblVisa").classList.remove("text-primary");
      document.querySelector("#lblVisa").classList.add("flight-active-tab");
    }
    if (document.querySelector("#lblHolidays") !== null) {
      document.querySelector("#lblHolidays").classList.add("text-primary");
      document
        .querySelector("#lblHolidays")
        .classList.remove("flight-active-tab");
    }

    if (document.querySelector("#lblSightseeing") !== null) {
      document.querySelector("#lblSightseeing").classList.add("text-primary");
      document
        .querySelector("#lblSightseeing")
        .classList.remove("flight-active-tab");
    }
  }, []);
  useEffect(() => {
    if (status === "completed") {
      setTrackUrl(data.data.evm_track_url);
      setVisaUrl(data.data.evm_url);
      if (document.getElementById("createVisaApplication") !== null) {
        document.getElementById("createVisaApplication").style.display = "none";
      }
      setTimeout(() => {
        setIsloading(false);
        if (isCreateVisa) {
          if (document.getElementById("createVisaApplication") !== null) {
            document.getElementById("createVisaApplication").style.display = "";
          }
        }


      }, 10000);
    }
  }, [status]);

  useEffect(() => {
    if (isCreateVisa) {
      if (document.getElementById("trackVisaApplication") !== null) {
        document.getElementById("trackVisaApplication").style.display = "none";
      }

      if (document.getElementById("createVisaApplication") !== null) {
        document.getElementById("createVisaApplication").style.display = "";
      }
    } else {
      if (document.getElementById("trackVisaApplication") !== null && !isLoading) {
        document.getElementById("trackVisaApplication").style.display = "";
      }

      if (document.getElementById("createVisaApplication") !== null) {
        document.getElementById("createVisaApplication").style.display = "none";
      }

    }
  }, [isCreateVisa]);


  const controlHandlerFightSearch = (event) => {
    if (event.target.id === "lblFlights") {
      history.push("/FlightSearch?Flights=1");
    } else if (event.target.id === "lblHotel") {
      history.push("/SearchHotel");
    } else if (event.target.id === "lblCar") {
      history.push("/SearchCar");
    } else if (event.target.id === "lblFlightPlusHotel") {
      history.push("/FlightSearch?FlightPlusHotel=1");
    } else if (event.target.id === "lblVisa") {
      //location.reload(true);
      history.push("/SearchVisa");
      // window.location.reload(true);
      if (document.querySelector("#lblFlights") !== null) {
        document.querySelector("#lblFlights").classList.add("text-primary");
        document
          .querySelector("#lblFlights")
          .classList.remove("flight-active-tab");
      }

      if (document.querySelector("#lblFlightPlusHotel") !== null) {
        document
          .querySelector("#lblFlightPlusHotel")
          .classList.add("text-primary");
        document
          .querySelector("#lblFlightPlusHotel")
          .classList.remove("flight-active-tab");
      }

      if (document.querySelector("#lblHotel") !== null) {
        document.querySelector("#lblHotel").classList.add("text-primary");
        document
          .querySelector("#lblHotel")
          .classList.remove("flight-active-tab");
      }

      if (document.querySelector("#lblCar") !== null) {
        document.querySelector("#lblCar").classList.add("text-primary");
        document
          .querySelector("#lblCar")
          .classList.remove("flight-active-tab");
      }

      if (document.querySelector("#lblSightseeing") !== null) {
        document
          .querySelector("#lblSightseeing")
          .classList.remove("flight-active-tab");
        document.querySelector("#lblSightseeing").classList.add("text-primary");
      }

      if (document.querySelector("#lblHolidays") !== null) {
        document
          .querySelector("#lblHolidays")
          .classList.remove("flight-active-tab");
        document.querySelector("#lblHolidays").classList.add("text-primary");
      }
      if (document.querySelector("#lblVisa") !== null) {
        document.querySelector("#lblVisa").classList.remove("text-primary");
        document.querySelector("#lblVisa").classList.add("flight-active-tab");
      }
      // history.push('/SearchVisa');
    } else if (event.target.id === "lblHolidays") {
      //history.push('/SearchHolidays');
      window.open(getConfigurationByValue("HOLIDAYS_URL"));
    } else if (event.target.id === "lblSightseeing") {
      history.push("/SearchSightseeing");
    }
  };


  const visaHandler = (event) => {
    event.preventDefault();
    if (event.target.id === "trackVisa") {
      setIsCreateVisa(false);
      setIsloading(true);
      if (document.getElementById("createVisaApplication") !== null) {
        document.getElementById("createVisaApplication").style.display = "none";
      }
      if (document.getElementById("trackVisaApplication") !== null) {
        document.getElementById("trackVisaApplication").style.display = "none";
      }
      setTimeout(() => {
        setIsloading(false);
        if (document.getElementById("trackVisaApplication") !== null && !isLoading) {
          document.getElementById("trackVisaApplication").style.display = "";
        }
      }, 10000);

    }
    else if (event.target.id === "createVisa") {
      sendRequest({

        "external_user_id": JSON.parse(localStorage.getItem('officeID')),
        "token": "Visagjkskhwjoowhkhsksksnslfhskkskksghjskj",
        "host": "fareladder",
        "cor": "India",
        "nationality": "",
        "travelling_to": "",
        "travelling_to_identity": "",
        "start_date": "",
        "end_date": "",
        "agency_name": JSON.parse(localStorage.getItem('companyName')),


      })
      setIsCreateVisa(true);
      setIsloading(true);
      if (document.getElementById("trackVisaApplication") !== null) {
        document.getElementById("trackVisaApplication").style.display = "none";
      }
      if (document.getElementById("createVisaApplication") !== null) {
        document.getElementById("createVisaApplication").style.display = "none";
      }
      setTimeout(() => {
        setIsloading(false);
        if (document.getElementById("createVisaApplication") !== null) {
          document.getElementById("createVisaApplication").style.display = "";
        }
      }, 10000);

    }

  }
  return (
    <Fragment>
      <section className="sub-banner">
        <div className="bg-parallax bg-1"></div>
        <div className="logo-banner text-center"></div>
      </section>

      <section
        id="theme_search_form_tour"
        className="fligth_top_search_main_form_wrapper"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="theme_search_form_area position-relative my-4">
                <div
                  className={
                    isLoading
                      ? "row position-relative visa-loader-h my-auto"
                      : "row position-relative"
                  }
                >
                  {isLoading && <LoadingSpinner />}
                  {/* {1 == 1 && <LoadingSpinner />} */}
                  <div className="col-lg-12">
                    <div className="tour_search_form">
                      <form action="!#">
                        <div className="row position-relative mb-4">
                          <label for="" className="h5 text-primary mb-3 me-3">
                            <i className="fas fa-globe-asia"></i> Search
                          </label>
                          <div className="text-sm-center1 mb-2">
                            <div>
                              {checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") &&
                                getConfigurationByBoolen("ALLOW_FLIGHT") ===
                                true && (
                                  <label
                                    for=""
                                    className="h6 mb-2"
                                    role="button"
                                    id="lblFlights"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i className="fas fa-plane-departure"></i>{" "}
                                    {t("flights")}
                                  </label>
                                )}
                              {getConfigurationByBoolen("ALLOW_SINGLE_PNR") !==
                                null &&
                                getConfigurationByBoolen("ALLOW_HOTEL") &&
                                getConfigurationByBoolen("ALLOW_SINGLE_PNR") &&
                                getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") ? (
                                <label
                                  for=""
                                  className="h6 mb-2 ms-3"
                                  role="button"
                                  id="lblFlightPlusHotel"
                                  onClick={controlHandlerFightSearch}
                                >
                                  <i className="fas fa-globe-asia"></i>{" "}
                                  {t("Flights and Hotels")}
                                </label>
                              ) : (
                                <label id="lblFlightPlusHotel"></label>
                              )}
                              {checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") &&
                                getConfigurationByBoolen("ALLOW_HOTEL") ===
                                true && (
                                  <label
                                    for=""
                                    className="h6 mb-2 ms-3"
                                    role="button"
                                    id="lblHotel"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i className="fas fa-hotel"></i> Hotels
                                  </label>
                                )}
                              {checkSecurityDetails("Book", "Car") && checkSecurityDetails("Book", "Car", "Book_Car_Search") &&
                                getConfigurationByBoolen("ALLOW_CAR") ===
                                true && (
                                  <label
                                    for=""
                                    className="h6 mb-2 ms-3"
                                    role="button"
                                    id="lblCar"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i className="fas fa-car"></i> Cars
                                  </label>
                                )}
                              {checkSecurityDetails("Book", "Sightseeing") &&
                                getConfigurationByBoolen(
                                  "ALLOW_SIGHTSEEING"
                                ) === true && (
                                  <label
                                    for=""
                                    className="h6 mb-2 ms-3"
                                    role="button"
                                    id="lblSightseeing"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i className="fas fa-hiking"></i>{" "}
                                    Sightseeing
                                  </label>
                                )}
                              {getConfigurationByBoolen("ALLOW_HOLIDAYS") ===
                                true &&
                                getConfigurationByValue("HOLIDAYS_URL") !==
                                null &&
                                getConfigurationByValue("HOLIDAYS_URL") !==
                                "" && (
                                  <label
                                    for=""
                                    className="h6 mb-2 ms-3"
                                    role="button"
                                    id="lblHolidays"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i className="fas fa-umbrella-beach"></i>{" "}
                                    Holidays
                                  </label>
                                )}
                              {getConfigurationByBoolen("ALLOW_VISA") ===
                                true && checkSecurityDetails("Book", "e-Visa") && checkSecurityDetails("Book", "e-Visa", "Book_e-Visa_Allow e-Visa") && (
                                  <label
                                    for=""
                                    className="h6 mb-2 ms-3"
                                    role="button"
                                    id="lblVisa"
                                    onClick={controlHandlerFightSearch}
                                  >
                                    <i class="fas fa-passport"></i> e-Visa
                                  </label>
                                )}
                            </div>
                          </div>

                          <div className="col-md-12 ">
                            <button className=" ms-1 btn btn_theme btn_sm mt-2" id='createVisa' onClick={visaHandler}><i className="fa fa-plus "></i > Create Application</button>
                            <button className=" ms-1 btn btn_theme btn_sm mt-2" id='trackVisa' onClick={visaHandler}><i className="fa fa-tasks "></i> Track Application&nbsp;</button>
                          </div>

                          {isCreateVisa === false &&
                            <div className="col-lg-12 my-4">
                              <iframe
                                className="w-100"
                                id="trackVisaApplication"
                                src={trackUrl}
                                style={{ height: "600px", display: "none", }}
                                target="_blank"
                              ></iframe>
                            </div>}
                          {isCreateVisa &&
                            <div className="col-lg-12 my-4">
                              <iframe
                                id="createVisaApplication"
                                className="w-100"
                                src={visaUrl}
                                style={{ height: "600px", display: "none", }}
                                target="_blank"
                              ></iframe>
                            </div>

                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </Fragment >
  );
};

export default SearchVisa;
