import { Fragment } from 'react';

const BookingRemarks = (props) => {
    return <Fragment>
        <div className="">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree" style={{ color: " #38c1f5" }}>
                Booking Remarks
            </h5>
            <div
                aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                <div className='row'>
                    <div className='col-md-12'>

                        <div className="row">

                            <div className="col-md-12" style={{ fontSize: "18px" }}>
                                <div className="h6 mb-2"> {props.CarBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.find(remarks => remarks.remarkType === 'General').text}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default BookingRemarks;