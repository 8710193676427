import { useState, useEffect } from "react";
import useHttp from "../../../services/use-http";
import { getRetrieveTravelRequest } from "../../../services/Booking-API";
import RetrieveTravelRequest from "./RetrieveTravelRequest";
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { useTranslation } from "react-i18next";
import { searchMyTrip } from '../../../services/Booking-API';
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { getCompanyLogo } from "../../../utils/CommonFunction";

const ViewTravelRequestThroughMail = () => {
    const { t } = useTranslation(['translation.ViewTravelRequest']);
    useDocumentTitle("Travel Request View");
    const [errorMsg, setErrorMsg] = useState('');
    const { sendRequest: sendTravelRequest, data: loadedTravelRequestDetails, status: travelRequestStatus } = useHttp(getRetrieveTravelRequest);
    const { sendRequest: sendMyTripRequest, data: myTripResponse, status: myTripSearchStatus } = useHttp(searchMyTrip);
    const [queryJSON] = useState(JSON.parse(localStorage.getItem('emailQueryJSON')));
    const [myTripResponseHasData, setMyTripResponseHasData] = useState(false);

    function getTripDetails(queryJSON) {

        const searchMyTripInput = {
            confirmationNo: queryJSON.ConfirmationNumber,
            pnrNo: '',
            lastName: '',
            service: '',
            bookingDateFrom: '',
            bookingDateTo: '',
            travelDateFrom: '',
            travelDateTo: '',
            officeID: queryJSON.OfficeID,
            officeName: '',
            userID: queryJSON.UserID,
            status: 'All',
            ModuleType: getConfigurationByValue("ACCESS_GLOBETROTTER") === "1" ? 'B2B' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "2" ? 'API' : getConfigurationByValue("ACCESS_GLOBETROTTER") === "3" ? 'B2C' : 'B2E',
            IsCorporate: true,
            CustomerLoginID: null,
            isMyQueue: queryJSON.IsApprover,
        };

        sendMyTripRequest(searchMyTripInput);
    }

    useEffect(() => {
        if (queryJSON) {
            sessionStorage.setItem("isMyQueue", queryJSON.IsApprover);
            getTripDetails(queryJSON);
            sendTravelRequest(queryJSON.ConfirmationNumber);
        }
        else {
            setErrorMsg(t('msgRecordCannotBeDisplayed'));
        }
    }, []);

    useEffect(() => {
        if (myTripResponse) {
            if (myTripResponse.errors.status === 'FALSE') {
                setMyTripResponseHasData(true);
                sessionStorage.setItem("myTripData", JSON.stringify(myTripResponse.data[0]));
            }
            else {
                setErrorMsg(t('msgRecordCannotBeDisplayed'));
            }
        }
    }, [myTripResponse]);

    return (
        <>
            <div className='position-relative main-responsive-menu'>
                <img src={getCompanyLogo()}
                    alt=""
                    className='log-in-head-logo-bg position-absolute' />
            </div>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {(travelRequestStatus === 'pending' ||
                myTripSearchStatus === 'pending') &&
                errorMsg === '' &&
                <div className="col-md-12">
                    <LoadingSpinnerWithCard showBanner={false} />
                </div>
            }

            {travelRequestStatus === 'completed' && myTripResponseHasData &&
                <RetrieveTravelRequest
                    isThroughMail={true}
                    travelRequestData={loadedTravelRequestDetails}>
                </RetrieveTravelRequest>
            }

            {/*Message for error*/}
            {errorMsg.length > 0 &&
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-30">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="form-group col-md-12">
                                                <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12">
                                                    <i className="fas fa-globe-asia"></i>{' '}
                                                    Travel Request View
                                                </label>

                                                {/*Message for error*/}
                                                <div className="h6 fw-bold">
                                                    {errorMsg}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            }

        </>);
}

export default ViewTravelRequestThroughMail;