import { Fragment, useEffect, useMemo } from "react";
import useHttp from "../../../services/use-http";
import { getCustomizationByPageName } from "../../../services/CustomizeSetting-API";
import { compress } from "@amoutonbrady/lz-string";
import { getLoginUrl, isDomainIgnoredForCustomization } from "../../../utils/CommonFunction";

const NonAuthCustomizeLoader = () => {

    const { sendRequest: sendRequestForCustomization,
        status: getCustomizationStatus,
        data: customizeResponse
    } = useHttp(getCustomizationByPageName);

    function clearCustomizationStorage() {
        localStorage.removeItem('customizationForNonAuthLoaded');
        localStorage.removeItem('customizeDetailsForNonAuth');
    }

    function setCustomizationDetailsInStorage(customizationDetails) {        
        localStorage.setItem('customizeDetailsForNonAuth', compress(JSON.stringify(customizationDetails)));
    }  

    const currentClientIdentifier = useMemo(() => {
        const urlParts = window.location.href.toLowerCase().split('/');
        const urlLastPart = urlParts.at(-1).trim();
        let clientKey = '';
        let clientKeyLocation = '';

        if (urlLastPart === '' || urlLastPart === 'login') {
            //case where no client identifiation is provided or it's part of the domain/sub-domain
            clientKey = window.location.host;
            clientKeyLocation = 'host';
        }
        else {
            //client identifier is part of url, e.g. '/login/<clientKey>'
            clientKey = urlLastPart;
            clientKeyLocation = 'param';
        }

        return {
            key: clientKey,
            keyLocation: clientKeyLocation
        };
    }, []);

    useEffect(() => {
        if (getCustomizationStatus === 'completed') {
            //console.log('customizeResponse', customizeResponse);
            if (customizeResponse && customizeResponse.errors.status === 'FALSE') {
                setCustomizationDetailsInStorage(customizeResponse.data);
            }
            //redirect here
            window.location.href = getLoginUrl();
        }
    }, [getCustomizationStatus, customizeResponse]);

    useEffect(() => {
        clearCustomizationStorage();
        localStorage.setItem('customizationForNonAuthLoaded', true);
        if(!isDomainIgnoredForCustomization()) {            
            sendRequestForCustomization({
                pageName: currentClientIdentifier?.keyLocation === 'param' ?
                    currentClientIdentifier?.key : window.location.href.toLowerCase()
            });
        }
        else {
            window.location.href = getLoginUrl();
        }

    }, []);

    return <Fragment>        
    </Fragment>;
};

export default NonAuthCustomizeLoader;