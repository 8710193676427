import { Fragment, useMemo } from 'react';
import { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { getConfigurationByBoolen, getConfigurationByValue } from './../../../utils/ConfigurationsFunction';
import useHttp from '../../../services/use-http';
import { authenticationOTP, authenticationOTPCheck, authenticationResendOTP } from '../../../services/Authentication-API';
import { isB2CUserLoggedIn, updateB2CProfileJSON } from '../../../utils/CommonFunction';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import secureLocalStorage from 'react-secure-storage';
import { regEmailID, regNumber } from '../../../config/RegularExpression';

const ContactPerson = (props) => {
    const { t } = useTranslation(['translation.Booking']);
    const emailInputRef = useRef('');
    const phoneInputRef = useRef('');
    const phoneDestinationInputRef = useRef('');

    const [emailIdError, setEmailIdError] = useState('');
    const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);
    const [phoneNumberDestinationInputHasError, setPhoneNumberDestinationInputHasError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    //const [activeTabSet, setActiveTab] = useState();
    const [phoneNumberDestination, setPhoneNumberDestination] = useState('');
    const [countryDialCodeDestination, setCountryDialCodeDestination] = useState('');

    const { sendRequest: sendOTPRequest,
        status: otpRequestStatus,
        data: otpResponse } = useHttp(authenticationOTP);

    const { sendRequest: sendOTPResendRequest,
        status: otpRequestResendStatus,
        data: otpResendResponse } = useHttp(authenticationResendOTP);

    const { sendRequest: sendOTPCheckRequest,
        status: otpCheckRequestStatus,
        data: otpCheckResponse } = useHttp(authenticationOTPCheck);

    const validEmailDomains = useMemo(() => {
        const emailDomainsFromConfig = (getConfigurationByValue('ACCESS_GLOBETROTTER') === '3' &&
            getConfigurationByBoolen('ALLOW_BOOKING_WITH_DOMAIN')) ?
            getConfigurationByValue('ALLOW_BOOKING_FROM_DOMAIN') : '';
        //const emailDomainsFromConfig = 'fareladder.us, gmail.com';
        let emailDomains = [];
        if (emailDomainsFromConfig && emailDomainsFromConfig.length) {
            emailDomains = emailDomainsFromConfig.split(',').map(email => {
                return email.trim().toLowerCase();
            });
        }

        return emailDomains;
    }, []);

    const [shouldValidateEmailDomain] = useState(validEmailDomains && validEmailDomains.length > 0);

    const [shouldValidateOTP] = useState(isB2CUserLoggedIn() ?
        false : getConfigurationByBoolen('VALIDATE_WITH_OTP_DURING_BOOKING'));
    //const [shouldValidateOTP, setShouldValidateOTP] = useState(true);
    const [otpValue, setOTPValue] = useState('');
    const [isOTPGenerated, setIsOTPGenerated] = useState(false);
    const [isOTPValidated, setIsOTPValidated] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [isResendOTPInProgress, setIsResendOTPInProgress] = useState(false);

    const disableEmail = useMemo(() => {
        return isB2CUserLoggedIn() || isOTPValidated;
    }, [isOTPValidated]);

    const [timeLeft, setTimeLeft] = useState(60);
    const [countInterval, setCountInterval] = useState(null);
    const [error, setError] = useState(null);
    const otpInputRef = useRef(null);

    useEffect(() => {
        //setActiveTab(JSON.parse(localStorage.getItem("tabActiveStatusSet")).Traveller);
        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId === '' && localStorage.getItem('email') !== null) {
            emailInputRef.current.value = getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3" ? JSON.parse(localStorage.getItem('email')) : ''
        }
        else if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId !== '') {
            emailInputRef.current.value = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Email[0].EmailId;
        }

        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === "3") {
            if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
                let profileJSON = JSON.parse(localStorage.getItem("ProfileData"));

                setPhoneNumber(profileJSON !== null ? profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[1] : '')
                setCountryDialCode(profileJSON !== null ? profileJSON.data.travelerInfo.traveler[0].telephone[0].mobile.split(' ')[0] : '')
            }
            else if (phoneNumber === '' && countryDialCode === '') {
                let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0].length;
                let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').length;

                setCountryDialCode(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0]);
                setPhoneNumber(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            }
        }
        else {
            if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
                setPhoneNumber(JSON.parse(localStorage.getItem('phoneNumber')))
                setCountryDialCode(JSON.parse(localStorage.getItem('phoneCode')))
            }
            else if (phoneNumber === '' && countryDialCode === '') {
                let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ')[0].length;
                let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.replace(/[^0-9]/g, '').length;

                setCountryDialCode(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.split(' ')[0]);
                setPhoneNumber(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].PhoneNumber.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            }
        }

        // if (hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.trim() === '' && localStorage.getItem('phoneNumber') !== null && localStorage.getItem('phoneCode') !== null) {
        //     setPhoneNumberDestination(JSON.parse(localStorage.getItem('phoneNumber')))
        //     setCountryDialCodeDestination(JSON.parse(localStorage.getItem('phoneCode')))
        // }
        if (phoneNumberDestination === '' && countryDialCodeDestination === '') {
            let countryDialCodeLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0].length;
            let phoneNumberLength = hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').length;

            setCountryDialCodeDestination(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile !== undefined ? hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ').length <= 1 ? getConfigurationByValue("BASE_MOBILE_COUNTRY_CODE") : hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.split(' ')[0] : '');
            setPhoneNumberDestination(hotelBookingJSON.data.TravelerInfo.GuestInfo[0].Telephone[0].Mobile.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
        }

        return () => {
            if (countInterval) {
                clearInterval(countInterval);
            }
        };
    }, [])

    const setPhone = (value) => {
        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            let countryDialCodeLength = phoneInputRef.current.numberInputRef.value.split(' ')[0].length;
            let phoneNumberLength = phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

            setCountryDialCode(phoneInputRef.current.numberInputRef.value.split(' ')[0]);
            setPhoneNumber(phoneInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
            setPhoneNumberInputHasError(false);
        }
    };

    const setPhoneDestination = (value) => {
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
            if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 &&
                phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
                //setPhoneNumberDestinationInputHasError(true);
                //return;
            }
            else {
                let countryDialCodeLength = phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0].length;
                let phoneNumberLength = phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').length;

                setCountryDialCodeDestination(phoneDestinationInputRef.current.numberInputRef.value.split(' ')[0]);

                setPhoneNumberDestination(phoneDestinationInputRef.current.numberInputRef.value.replace(/[^0-9]/g, '').substring(countryDialCodeLength - 1, phoneNumberLength));
                setPhoneNumberDestinationInputHasError(false);
            }
        }
    };

    const onBlurPhoneDestination = (value) => {
        if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 && phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberDestinationInputHasError(true);
            return;
        }
    };

    function assignContactPerson(event) {
        event.preventDefault();
        if (emailInputRef.current.value === '') {
            setEmailIdError(t('validationEmailId'));
            return;
        }
        else {
            setEmailIdError('');
        }

        if (!emailInputRef.current.value.match(regEmailID)) {
            setEmailIdError(t('validationEmailId'));
            return;
        }
        else {
            setEmailIdError('');
        }

        if (shouldValidateEmailDomain) {
            if (!isValidEmailDomain(emailInputRef.current.value)) {
                setEmailIdError(t('errorInvalidEmailDomain').replace('DOMAIN_NAMES', validEmailDomains.join(', ')));
                return;
            }
            else {
                setEmailIdError('');
            }
        }

        if (phoneInputRef.current.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
            if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 &&
                phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
                setPhoneNumberDestinationInputHasError(true);
                return;
            }
            else {
                setPhoneNumberDestinationInputHasError(false);
            }
        }

        if (shouldValidateOTP && isOTPGenerated && !isOTPValidated) {
            if (otpValue === '') {
                setOtpError(t('errorOTPRequired'));
                return;
            }
            else {
                setOtpError('');
            }
        }

        if (shouldValidateOTP && !isOTPGenerated) {
            getOTP(false);
        }
        else if (shouldValidateOTP && isOTPGenerated && !isOTPValidated) {
            checkOTPValidation();
        }
        else {
            processContinueFlow();
        }
    }

    function getOTP(isResend) {
        const bookingData = JSON.parse(sessionStorage.getItem('HotelBookingData'));
        const traveler = bookingData?.data?.TravelerInfo?.GuestInfo[0];

        let otpRequestInput = {
            id: null,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            ipAddress: null,
            title: traveler?.PersonName?.NamePrefix,
            firstName: traveler?.PersonName?.GivenName,
            middleName: traveler?.PersonName?.MiddleName,
            lastName: traveler?.PersonName?.Surname,
            emailID: emailInputRef.current.value,
            emailLoginID: emailInputRef.current.value,
            phoneCode: '',
            phoneNumber: '',
            travelerPhoneCode: countryDialCode,
            travelerPhoneNumber: phoneNumber,
            officeID: JSON.parse(localStorage.getItem('officeID')),
            address: '',
            dateOfBirth: traveler?.dateOfBirth?.date,
            gender: traveler?.Gender,
            nationality: '',
            passportNo: '',
            passportExp: '',
            city: '',
            country: '',
            additional: '',
            additionalNumber: '',
            additionalCountry: '',
            isBookingOTP: true
        };

        if (isResend) {
            sendOTPResendRequest(otpRequestInput);
        }
        else {
            sendOTPRequest(otpRequestInput);
        }
    }

    function checkOTPValidation() {
        sendOTPCheckRequest({
            id: "",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: emailInputRef.current.value,
            oTP: otpValue,
            officeID: JSON.parse(localStorage.getItem('officeID')),
            isBookingOTP: true
        });
    }

    function processContinueFlow() {
        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        hotelBookingJSON.data.TravelerInfo.GuestInfo.forEach(element => {
            element.Email[0].EmailId = emailInputRef.current.value;
            element.Telephone[0].PhoneNumber = countryDialCode + " " + phoneNumber;
            element.Telephone[0].Mobile = countryDialCodeDestination + " " + phoneNumberDestination;
        });
        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

        props.onContinueButton("2");
        // window.scrollTo({
        //     top: 530,
        //     behavior: "smooth",
        // });
        if (document.querySelector("#headingThree") !== null) {
            const yOffset = -250;
            const element2 = document.getElementById("headingThree");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    function handleEmailChange() {
        setIsOTPGenerated(false);
        setOtpError('');
    }

    function onBlurCheck() {

        if (emailInputRef.current.value === '') {
            setEmailIdError(t('validationEmailId'));
            return;
        }
        else {
            setEmailIdError('');
        }

        if (!emailInputRef.current.value.match(regEmailID)) {
            setEmailIdError(t('validationEmailId'));
            return;
        }
        else {
            setEmailIdError('');
        }

        if (shouldValidateEmailDomain) {
            if (!isValidEmailDomain(emailInputRef.current.value)) {
                setEmailIdError(t('errorInvalidEmailDomain').replace('DOMAIN_NAMES', validEmailDomains.join(', ')));
                return;
            }
            else {
                setEmailIdError('');
            }
        }

        if (phoneInputRef?.current?.numberInputRef.value.length < 12) {
            setPhoneNumberInputHasError(true);
            return;
        }
        else {
            setPhoneNumberInputHasError(false);
        }

        if (getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3") {
            if (phoneDestinationInputRef.current.numberInputRef.value.length > 4 &&
                phoneDestinationInputRef.current.numberInputRef.value.length < 12) {
                setPhoneNumberDestinationInputHasError(true);
                return;
            }
            else {
                setPhoneNumberDestinationInputHasError(false);
            }
        }
    }

    function handleOTPChange(event) {
        if (!event.target.value.match(regNumber)) {
            setOTPValue('');
            return;
        }
        setOtpError('');
        setOTPValue(event.target.value);
    }

    function handleResendOTPClick(event) {
        event.preventDefault();
        setOtpError('');
        getOTP(true);
        setIsResendOTPInProgress(true);
    }

    function isValidEmailDomain(email) {
        const emailDomain = email.split('@')[1].toLowerCase();
        if (validEmailDomains.indexOf(emailDomain) >= 0) {
            return true;
        }
        return false;
    }

    function triggerOTPCountdown() {
        if (countInterval) {
            clearInterval(countInterval);
        }
        setCountInterval(() => setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000));
    }

    function handleErrorOKClick() {
        setError(null);
    }

    function processOtpResponse(response) {
        if (response.errors.status === 'FALSE') {
            setIsOTPGenerated(true);
            // setTimeLeft(60);
            // triggerOTPCountdown();
        }
        else {
            //issue with OTP generation
            setError({
                title: 'Message',
                message: t('errorOTPGeneration')
            });
        }
        setOTPValue('');
        setIsResendOTPInProgress(false);
    }

    useEffect(() => {
        if (otpResponse) {
            processOtpResponse(otpResponse);
            setTimeLeft(60);
            triggerOTPCountdown();
        }
    }, [otpResponse]);

    useEffect(() => {
        if (otpResendResponse) {
            processOtpResponse(otpResendResponse);
            if (otpInputRef?.current) {
                otpInputRef.current.focus();
            }
        }
    }, [otpResendResponse]);

    useEffect(() => {
        if (otpCheckResponse) {
            if (otpCheckResponse.errors.status === 'FALSE') {
                setIsOTPValidated(true);
                secureLocalStorage.setItem("isCustomerAuthentication", JSON.stringify(true));
                secureLocalStorage.setItem("CustomerLoginEmailID", otpResponse.data.emailLoginID);
                localStorage.setItem("displayNameB2c", JSON.stringify(otpResponse.data.customerDisplayName));
                updateB2CProfileJSON(otpCheckResponse.data[0]);
                processContinueFlow();
            }
            else {
                setError({
                    title: 'Message',
                    message: t('errorOTPInvalid')
                });
                setOTPValue('');
            }
        }
    }, [otpCheckResponse]);

    return (<Fragment>

        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={handleErrorOKClick}
            ></ErrorModal>
        )}

        <div className="accordion_flex_area">
            <div className="accordion_left_side position-relative text-muted">
                <h5>2</h5>
                <span id="completeTwo" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button id="headingTwo" className="text-muted accordion-button collapsed" type="button"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">Booking Contact
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse"
                    aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='control'>
                                    <label className="h6 text-primary mb-2 col-lg-12"
                                        htmlFor='category'>Email<span className="error-icon"> *</span>
                                    </label>
                                    <input type='text' className="mb-0 form-control"
                                        ref={emailInputRef}
                                        maxLength={100}
                                        onBlur={onBlurCheck}
                                        onChange={handleEmailChange}
                                        disabled={disableEmail}
                                        id='email'></input>
                                    {emailIdError && emailIdError.length > 0 &&
                                        <p className="error-text">{emailIdError}</p>
                                    }
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='control'>
                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone<span className="error-icon"> *</span></label>
                                    <PhoneInput type='text' required
                                        country={'us'}
                                        id='phone'
                                        value={countryDialCode + phoneNumber}
                                        placeholder=''
                                        enableSearch={true}
                                        ref={phoneInputRef}
                                        onChange={setPhone}
                                    />
                                    {phoneNumberInputHasError &&
                                        <p className="error-text">{t('validationPhoneNumber')}</p>
                                    }
                                </div>
                            </div>
                            {getConfigurationByValue("ACCESS_GLOBETROTTER") !== "3" &&
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <div className='control'>
                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='category'>Phone at Destination</label>
                                        <PhoneInput type='text' required
                                            country={'us'}
                                            id='phoneDestination'
                                            value={countryDialCodeDestination + phoneNumberDestination}
                                            onBlur={onBlurPhoneDestination}
                                            placeholder=''
                                            enableSearch={true}
                                            ref={phoneDestinationInputRef}
                                            onChange={setPhoneDestination}
                                        />
                                        {phoneNumberDestinationInputHasError &&
                                            <p className="error-text">{t('validationPhoneDestinationNumber')}</p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {isOTPGenerated && !isOTPValidated &&
                            <Fragment>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                        <label className="h6 text-primary mb-2 col-lg-12">
                                                To continue, please enter the OTP sent to <strong className='text-black text-break'>{emailInputRef.current.value}</strong><span className="error-icon"> *</span>
                                        </label>                                        
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-2">
                                            <input type="text"
                                                className="mb-0 form-control"
                                                maxLength={6}
                                                value={otpValue}
                                                onChange={handleOTPChange}
                                                placeholder="6-digit code"
                                                ref={otpInputRef}
                                                autoFocus
                                                id="otp"></input>
                                            {otpError && otpError.length > 0 &&
                                                <p className="error-text pb-0">
                                                    {otpError}
                                                </p>
                                            }                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 my-2">
                                        <div className="mb-2">
                                            <button type="button"
                                                onClick={handleResendOTPClick}
                                                disabled={timeLeft > 0}
                                                className="btn text-white gt-main-btn btn-sm">
                                                {(otpRequestStatus === 'pending' || otpRequestResendStatus === 'pending') ?
                                                    <i className="fa fa-spinner fa-spin" ></i> :
                                                    (timeLeft > 0 ? 'Resend OTP (' + timeLeft + ')' : 'Resend OTP')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 col-sm-12">
                                        <label className="mb-2 h6 col-lg-12">
                                            <i className="fa fa-info-circle text-primary"></i> {t('noteCheckJunkMail')}
                                        </label>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        <div className='row'>
                            <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                                <p><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="float-end">
                                    {/* <button type="button" className="btn  text-white btn-secondary ms-1 btn-sm">Skip to add-ons</button> */}
                                    <button type="button" onClick={assignContactPerson}
                                        className="btn  text-white gt-main-btn ms-1 btn-sm">
                                        {(!isResendOTPInProgress &&
                                            (otpRequestStatus === 'pending' || otpCheckRequestStatus === 'pending')) ?
                                            <i className="fa fa-spinner fa-spin" ></i> :
                                            'Continue'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>)
}

export default ContactPerson;