import { useRef, useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { ForgotPassword } from '../../../services/Authentication-API'
import { useTranslation } from 'react-i18next';
import { regEmailID } from '../../../config/RegularExpression'
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import useHttp from '../../../services/use-http';
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
import { getLoginPageBackgroundImage, getLoginPageCompanyLogo, getLoginUrl } from '../../../utils/CommonFunction';

const ChangePasswordForm = () => {

  useDocumentTitle('Forgot Password')

  const { t } = useTranslation(['translation.ForgotPassword']);

  const history = useHistory();

  const { sendRequest, status, data: forgotPasswordDetails, error } = useHttp(ForgotPassword);

  const loginIDInputRef = useRef();
  const [errors, setErrors] = useState();
  const [loginIDInputHasError, setLoginIDInputHasError] = useState(false);
  const [loginIDValidInputHasError, setLoginIDValidInputHasError] = useState(false);
  
  function customizePage() {
    if (getCustomizeDetails('forgotPwdLabelColor')) {
      document.documentElement.style.setProperty('--custom-heading', getCustomizeDetails('forgotPwdLabelColor'));
    }

    if (getCustomizeDetails('loginBtnGradientColor1')) {
      document.documentElement.style.setProperty('--login-custom-btn', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails('loginBtnGradientColor1')} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails('loginBtnGradientColor2')}`);
    }
  }
    
  useEffect(() => {

    customizePage();

    loginIDInputRef.current.focus();
  }, []);

  const errorHandler = () => {
    setErrors(null);
  };

  useEffect(() => {
    let loadedData = [];
    if (status === 'completed') {
      for (let i in forgotPasswordDetails) {
        if (forgotPasswordDetails['errors'].status === 'FALSE') {
          if (i === 'data') {
            loadedData = forgotPasswordDetails[i];

            setErrors({
              title: "Message",
              // message: `We just sent you instructions to reset your password on your registered- ${loginIDInputRef.current.value}.`,
              message: "We have sent you an email that will direct you to create a new password for your account.",
            });

          }
        }
        else if (forgotPasswordDetails['errors'].status === 'TRUE') {

          if (forgotPasswordDetails.errors.error.code === "1011") {
            setErrors({
              title: "Message",
              message: 'Email ID does not exist.',
            });
          } else {
            setErrors({
              title: "Message",
              message: 'Please check the Email ID.',
            });
          }


        }
      }
    }
    if (error) {
      setErrors({
        title: "Message",
        message: "Sorry, an unexpected error has occurred. Please try again.",
      });
      //return <p className='centered focused'>{error}</p>
    }
  }, [status, forgotPasswordDetails, history])
  const submitHandler = useCallback((event) => {
    event.preventDefault();
    const enteredLoginID = loginIDInputRef.current.value;
    // optional: Could validate here
    if (enteredLoginID === '') {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(true);
      setLoginIDValidInputHasError(false);
      return;
    } else {
      setLoginIDInputHasError(false);
    }
    if (!enteredLoginID.match(regEmailID)) {
      loginIDInputRef.current.focus();
      setLoginIDInputHasError(false);
      setLoginIDValidInputHasError(true);
      return;
    } else {
      setLoginIDValidInputHasError(false);
    }

    sendRequest({ loginEmailID: enteredLoginID });
    // optional: Add validation 
  }, [sendRequest]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ call submit function here
        submitHandler(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [submitHandler]);

  return (
    <Fragment>
      {errors && (
        <ErrorModal
          title={errors.title}
          message={errors.message}
          onConfirm={errorHandler}
        ></ErrorModal>
      )}
      <section className='gt_login_banner' style={getLoginPageBackgroundImage()}>
        <div className='position-relative'>
          <a href={getLoginUrl()} className="">
            <img src={getLoginPageCompanyLogo()} alt="" className='log-in-head-logo-bg' />
          </a>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 mb-1 position-relative">
            <div className="gt-common_author_boxed position-absolute width-100 gt-login-banner-div">
              <div className="card-body">
                <div className="col-md-10 col-sm-12 mx-auto signin-log">
                  <div className='text-center'>
                    <label className="mb-2 gt-heading_theme h4 custom-heading-text mb-3 fw-bolder text-center col-lg-12"><i className="fas fa-globe-asia"></i> Forgot Password</label>
                  </div>
                  <div className="form-group col-md-12">
                    <form action="#" id="main_author_form">
                      <div className='input-group'>
                        {/* <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>{t('loginid')}</label> */}
                        <span className="input-group-text login-input-icon" id="basic-addon1"><i className="fas fa-envelope text-primary"></i></span>
                        {/* <label className="h6 text-primary mb-2 col-lg-12">{t('loginid')}</label> */}
                        <input type='text'
                          className="form-control" tabIndex={1} id='email'
                          placeholder={t('loginid')}
                          /*  onChange={onloginIDChangeCheck} */
                          maxLength={100}
                          required ref={loginIDInputRef} />

                      </div>
                      <div className="form-group control">
                        {loginIDInputHasError && <p className="error-text">{t('validationLoginid')}</p>}
                        {loginIDValidInputHasError && <p className="error-text">{t('validationValidLoginid')}</p>}
                      </div>
                      <div className="common_form_submit float-end text-end">
                        {/* <button className="btn btn_theme btn_md" onClick={submitHandler} tabIndex={4}>Log in</button> */}
                        <a href={getLoginUrl()} className="btn gt-btn_md text-white gt-main-btn-WithoutAuth ms-1   mt-1">Login</a>
                        <button className="btn main-btn-width-auto text-white gt-main-btn-WithoutAuth  ms-1 mt-1" onClick={submitHandler} tabIndex={2}>   {status === 'pending' ?
                          <i className="fa fa-spinner fa-spin" ></i>
                          : 'Send Verification Email'}</button>
                       
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePasswordForm;