import { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHttp from '../../services/use-http';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import LoadingSpinner from "../../components/Common/Loading/LoadingSpinner";
//import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import { getAuthToken } from '../../services/Authentication-API';
import { decryptWithErrorHandling } from '../../utils';
import { compress } from '@amoutonbrady/lz-string';
import { getLoginUrl } from '../../utils/CommonFunction';
import { getCustomizeDetails } from '../../utils/CustomizeUtils';
import LoadingNeutral from '../../components/Common/Loading/LoadingNeutral';

const LandingPage = () => {
    const { t } = useTranslation(['translation.Approver']);
    const location = useLocation();
    const valueParam = queryString.parse(location.search);
    const history = useHistory();

    const { sendRequest: sendGetAuthToken,
        data: authTokenResponse,
        status: getAuthTokenStatus } = useHttp(getAuthToken);

    const { sendRequest: sendDecryptionRequest,
        data: decryptionResponse,
        status: decryptionRequestStatus } = useHttp(decryptWithErrorHandling);

    const [error, setError] = useState(null);

    const [destinationPages] = useState({
        1: '/ApprovalThroughMail',
        2: '/ViewTravelRequestThroughMail'
    });

    function handleErrorConfirm() {
        history.push(getLoginUrl());
    }

    function customizePage() {
        if (getCustomizeDetails("buttonColor")) {
            document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, ${getCustomizeDetails("buttonColor")} 0%, rgba(87, 29, 127, 0) 97%), ${getCustomizeDetails("buttonGradientColor2")}`);
        }
    }

    useEffect(() => {
        //localStorage.removeItem('tokenForNonAuth');
        localStorage.removeItem('customizeDetailsForNonAuth');
        localStorage.removeItem('roleDetailsForNonAuth');
        localStorage.removeItem('emailQueryJSON');
        document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd;`);
    }, []);

    useEffect(() => {
        if (valueParam.q) {
            sendGetAuthToken({
                userName: '',
                password: '',
                loginDetails: valueParam.q.replaceAll(' ', '+')
            });
        }
    }, [valueParam.q, sendGetAuthToken]);

    useEffect(() => {
        if (authTokenResponse) {
            //console.log(authTokenResponse);
            //In case of chkAuthentication response, even if there's error response, we can still get data for customization.
            if (authTokenResponse.data) {
                localStorage.setItem('customizeDetailsForNonAuth', compress(JSON.stringify(authTokenResponse.data.customizeDetails)));
                customizePage();
            }

            if (authTokenResponse.errors?.status === 'TRUE') {
                if (authTokenResponse.errors?.error?.code === '9001') {
                    setError({
                        title: 'Message',
                        message: t('statusAlreadyUpdated')
                    });
                }
                else {
                    setError({
                        title: 'Message',
                        message: t('errorGeneric')
                    });
                }
            }
            else {
                localStorage.setItem('tokenForNonAuth', authTokenResponse.data.idToken);
                localStorage.setItem('roleDetailsForNonAuth', JSON.stringify(authTokenResponse.data.roleDetails));
                sendDecryptionRequest(valueParam.q.replaceAll(' ', '+'));
            }
        }
    }, [authTokenResponse, t, sendDecryptionRequest, valueParam.q]);

    useEffect(() => {
        if (decryptionResponse) {
            if (decryptionResponse.data) {
                localStorage.setItem('emailQueryJSON', decryptionResponse.data);
                let queryJSON = JSON.parse(decryptionResponse.data);
                history.push(destinationPages[queryJSON.Type]);
            }
            else {
                setError({
                    title: 'Message',
                    message: t('errorGeneric')
                });
            }
        }
    }, [decryptionResponse, destinationPages, t]);

    return <Fragment>
        {error &&
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={handleErrorConfirm}
            ></ErrorModal>
        }
        {error === null &&
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 full-page-loader">
                            <LoadingNeutral></LoadingNeutral>
                            {/* {error === null &&
                            < LoadingSpinner ></LoadingSpinner>
                         } */}
                        </div>
                    </div>
                </div>
            </section>
        }
        {/*<LoadingSpinner></LoadingSpinner>*/}
    </Fragment >
};

export default LandingPage;