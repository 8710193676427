import { Fragment } from 'react';
import { getFormatDate, timeConvert, getDateDiffDays, getFormatTime } from './../../../utils/CommonFunction'

const BookingDetails = (props) => {
    //User Story 1073: Booking Summary for Single PNR
    let hotelBookingJSON = localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" ? JSON.parse(sessionStorage.getItem("BookingDataShoppingCart")) : JSON.parse(sessionStorage.getItem("HotelBookingData"));

    const getRentalDays = (vehicle) => {
        const rentalDays = vehicle?.vehRentalCore?.vehLocationInfo?.rentalDays;
        const rentalHours = vehicle?.vehRentalCore?.vehLocationInfo?.rentalHours;
        if (rentalDays && rentalDays !== '0') {
            return `${rentalDays} day${parseInt(rentalDays) > 1 ? 's' : ''}`
        } else if (rentalHours && rentalHours !== '0') {
            return `${rentalHours} hour${parseInt(rentalHours) > 1 ? 's' : ''}`
        }
    }

    return (<Fragment>
        <div className="left_side_search_area">
            <div className="left_side_search_boxed fff mb-0">
                <div className="left_side_search_heading">
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    {
                        localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" &&
                        <h5 className='mb-1'>Car Booking Details</h5>
                    }
                    {
                        localStorage.getItem("AddHotel") === null &&
                        <h5 className='mb-1'>Booking Details</h5>
                    }
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='text-14px'>Pick-up date & time</p>
                        <p className='fw-bold text-14px text-primary'>{getFormatDate(props.guestInfo.carResults[0].vehRentalCore.vehLocationInfo.pickupDate)}{", " + getFormatTime(props.guestInfo.carResults[0].vehRentalCore.vehLocationInfo.pickupTime)}</p>
                        {/* <p className='text-muted'>12:00 PM</p> */}
                    </div>
                    <div className='col-md-12'>
                        <p className='text-14px'>Drop-off date & time</p>
                        <p className='fw-bold text-14px text-primary'>{getFormatDate(props.guestInfo.carResults[0].vehRentalCore.vehLocationInfo.returnDate)}{", " + getFormatTime(props.guestInfo.carResults[0].vehRentalCore.vehLocationInfo.returnTime)}</p>
                        {/* <div className='text-muted'>10:00 AM</div> */}
                    </div>
                    <div className='col-md-12 mt-1'>
                        <p className='text-14px'> Total length of car rental</p>
                        <p className='fw-bold text-14px text-primary'>{getRentalDays(props.guestInfo.carResults[0])}</p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>)
}

export default BookingDetails;