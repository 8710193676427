import { Fragment } from 'react';

const PrintBookingDetails = (props) => {
    let pnr = props.CarBookingJSON.data.bookingReferenceID !== undefined ?
        props.CarBookingJSON.data.bookingReferenceID.id : null;

    let isCanceled = false;
    const getBookingStatusClassName = () => {
        if (props.CarBookingJSON.data.requestType === "OF") {
            return "badge h6 rounded-pill mb-0 bg-primary";
        } else if (isCanceled) {
            return 'badge h6 rounded-pill mb-0 bg-Failed';
        } else if (pnr === null) {
            return 'badge h6 rounded-pill mb-0';
        } else if (pnr !== null) {
            return "badge h6 rounded-pill mb-0 bg-success";
        }

    }
    const getBookingStatusText = () => {
        //Case 1: Booking Request

        if (props.CarBookingJSON.data.requestType === "OF") {
            return " Booking Request";
        } else if (isCanceled) {
            return ' Canceled';
        } else if (pnr === null) {
            return " Failed";
        } else if (pnr !== null) {
            return " Booked";
        }

    }
    const getBookingStatusIcon = () => {
        //Case 1: Booking Request

        if (props.CarBookingJSON.data.requestType === "OF") {
            return <i className='fa fa-check-circle'></i>
        }
        //Case 1: Canceled
        else if (isCanceled) {

            return <i className='fa fa-circle-xmark'></i>
        }
        //Case 1: Failed
        else if (pnr === null) {
            return <i className='fa fa-circle-xmark'></i>
        } //Case 1: Booked 
        else if (pnr !== null) {
            return <i className='fa fa-check-circle'></i>
        }
    }
    return <Fragment>
        <div>
            <br></br>
            <br></br>
        </div>
        <div className='col-md-12'>
            <label for="" className="h5 print-primary-title" style={{ color: "#12688B", fontSize: '1.6rem', }}><i className="fas fa-globe-asia"></i> Booking Details &nbsp;
                <span className={getBookingStatusClassName()}>
                    {getBookingStatusIcon()}
                    <span className='ms-1'>
                    </span>
                    {getBookingStatusText()}
                </span></label>
        </div>
        <div className='row mt-2'>
            <div className='col-md-12'>
                <div className='text-muted h6  mt-2' style={{ fontSize: "20px", fontWeight: '600', height: 'auto' }}>
                    <span>
                        <b className='print-primary-title' style={{ color: "#12688B", fontSize: "20px", fontWeight: '600' }}>Confirmation No. </b> <span className='text-black'>{props.CarBookingJSON.data.bookingReferenceNumber}</span>
                        <span>{props.CarBookingJSON.data.bookingReferenceID !== undefined && <span> | <b className='print-primary-title' style={{ color: "#12688B" }}>PNR </b> <span className='text-black'>{props.CarBookingJSON.data.bookingReferenceID.id}</span></span>}
                        </span>
                        <span>{props.CarBookingJSON.data.bookingReferenceID !== undefined && props.CarBookingJSON.data.carResults[0].vehRentalCore.confirmationNumber !== undefined && <span> | <b className='print-primary-title' style={{ color: "#12688B" }}>Car Confirmation No.  </b> <span className='text-black'>{props.CarBookingJSON.data.carResults[0].vehRentalCore.confirmationNumber}</span></span>} </span>
                    </span>
                </div>
            </div>
        </div>

    </Fragment>

};

export default PrintBookingDetails;