import React, { useState, useEffect, useRef, Fragment } from "react";
import { SketchPicker } from 'react-color';
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import { useTranslation } from "react-i18next";
import {
    getBase64, generateUUID,
    maxSelectFile, checkMimeType,
    getImageProperties,
    scrollToLoader,
    isValidFileSize
} from '../../../../utils';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { regEmailID } from '../../../../config/RegularExpression'
import { getConfigurationByBoolen } from "../../../../utils/ConfigurationsFunction";
import { isValidHttpUrl } from "../../../../utils/CommonFunction";
import { APP_URL } from "../../../../config/CONSTANTS";

const CustomizationForm = (props) => {
    localStorage.setItem("defaultSet", "0");
    const { t } = useTranslation(['translation.Customize']);

    const bottomTextInputRef = useRef('');
    const bottomRightTextInputRef = useRef('');
    const contactUsPhoneInputRef = useRef('');
    const contactUsEmailInputRef = useRef('');
    const linkName1InputRef = useRef('');
    const linkNameURL1InputRef = useRef('');
    const linkName2InputRef = useRef('');
    const linkNameURL2InputRef = useRef('');
    const linkName3InputRef = useRef('');
    const linkNameURL3InputRef = useRef('');
    const linkName4InputRef = useRef('');
    const linkNameURL4InputRef = useRef('');
    const linkName5InputRef = useRef('');
    const linkNameURL5InputRef = useRef('');
    const linkName6InputRef = useRef('');
    const linkNameURL6InputRef = useRef('');
    const linkName7InputRef = useRef('');
    const linkNameURL7InputRef = useRef('');
    const linkName8InputRef = useRef('');
    const linkNameURL8InputRef = useRef('');
    const linkName9InputRef = useRef('');
    const linkNameURL9InputRef = useRef('');
    const linkName10InputRef = useRef('');
    const linkNameURL10InputRef = useRef('');

    const rightLinkName1InputRef = useRef('');
    const rightLinkNameURL1InputRef = useRef('');
    const rightLinkName2InputRef = useRef('');
    const rightLinkNameURL2InputRef = useRef('');
    const rightLinkName3InputRef = useRef('');
    const rightLinkNameURL3InputRef = useRef('');
    const rightLinkName4InputRef = useRef('');
    const rightLinkNameURL4InputRef = useRef('');
    const rightLinkName5InputRef = useRef('');
    const rightLinkNameURL5InputRef = useRef('');
    const activePartnerInputRef = useRef('');
    const activeGDPRInputRef = useRef('');

    const companyLogoInputRef = useRef('');
    const bannerColorInputRef = useRef('');
    const buttonColorInputRef = useRef('');
    const textBorderColorInputRef = useRef('');
    const buttonGradientColorInputRef = useRef('');
    const textBackGroundColorInputRef = useRef('');
    const backGroundColorInputRef = useRef('');
    const footerBackGroundColorInputRef = useRef('');

    const hdrSupportBgColorInputRef = useRef();
    const hdrSupportLblColorInputRef = useRef();
    const hdrSupportEmailIDInputRef = useRef();
    const hdrSupportEmailIDColorInputRef = useRef();
    const showSupportSectionInputRef = useRef();
    const loginPageCompanyLogoInputRef = useRef();
    const loginPageBgImageInputRef = useRef();
    const loginPageNameInputRef = useRef();
    const loginPageForgotPwdBttnBgColorInputRef = useRef();
    const loginPageBttnGradientColor1InputRef = useRef();
    const loginPageBttnGradientColor2InputRef = useRef();
    const sessionOutImageInputRef = useRef();
    const forgotPwdPageLblColorInputRef = useRef();
    const favIconInputRef = useRef();
    const menuLabelColorInputRef = useRef();
    const loaderColorInputRef = useRef();

    const collapseHeaderRef = useRef();
    const collapseFooterRef = useRef();
    const collapseGeneralRef = useRef();
    const collapseLinksRef = useRef();
    const collapseSocialLinksRef = useRef();
    const collapseLoginPageRef = useRef();
    const collapseSessionOutPageRef = useRef();
    const collapseForgotPasswordPageRef = useRef();

    const [activePartner, setActivePartner] = useState(false);
    const [activeGDPR, setActiveGDPR] = useState(false);

    const [isFirtTime, setFirstTime] = useState(true);

    const [companyLogoImage, setCompanyLogoImage] = useState('');
    const [companyLogoError, setCompanyLogoError] = useState('');
    const [buttonColor, setButtonColor] = useState("");
    const [buttonGradientColor, setButtonGradientColor] = useState('');
    const [textBackGroundColor, setTextBackGroundColor] = useState('');
    const [textBorderColor, setTextBorderColor] = useState('');
    const [bannerColor, setBannerColor] = useState("");
    const [backGroundColor, setBackGroundColor] = useState("");
    const [footerBackGroundColor, setFooterBackGroundColor] = useState("");
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayButtonGradientColorPicker, setDisplayButtonGradientColorPicker] = useState(false);
    const [displayTextBackgroundColorPicker, setDisplayTextBackGroundColorPicker] = useState(false);
    const [displayTextBorderColorPicker, setDisplayTextBorderColorPicker] = useState(false);
    const [pageTitle, setPageTitle] = useState(t('pageTitle'));

    const [contactUsPhoneInputError, setContactUsPhoneInputError] = useState(false);
    const [blankBannerColorError, setBlankBannerColorError] = useState(false);
    const [blankTextBorderColorError, setBlankTextBorderColorError] = useState(false);
    const [invalidContactUsEmailError, setInvalidContactUEmailError] = useState(false);
    const [blankButtonColorError, setBlankButtonColorError] = useState(false);
    const [blankBackGroundColorError, setBlankBackGroundColorError] = useState(false);
    const [blankContactUsPhoneError, setBlankContactUsPhoneError] = useState(false);
    const [blankBContactUsEmailError, setBlankBContactUsEmailError] = useState(false);
    const [blankLinkName1Error, setBlankLinkName1Error] = useState(false);
    const [blankLinkNameURL1Error, setBlankLinkNameURL1Error] = useState(false);
    const [phoneLengthError, setPhoneLengthError] = useState(false);
    const [blankButtonGradientColorError, setBlankButtonGradientColorError] = useState(false);
    const [blankTextBackGroundColorError, setBlankTextBackGroundColorError] = useState(false);
    const [blankFooterBackgroundColorError, setBlankFooterBackgroundColorError] = useState(false);
    const [blankSocialMediaLinkNameError1, setBlankSocialMediaLinkNameError1] = useState(false);
    const [blankSocialMediaLinkUrlError1, setBlankSocialMediaLinkUrlError1] = useState(false);
    const [blankSocialMediaLinkNameError2, setBlankSocialMediaLinkNameError2] = useState(false);
    const [blankSocialMediaLinkUrlError2, setBlankSocialMediaLinkUrlError2] = useState(false);
    const [blankSocialMediaLinkNameError3, setBlankSocialMediaLinkNameError3] = useState(false);
    const [blankSocialMediaLinkUrlError3, setBlankSocialMediaLinkUrlError3] = useState(false);
    const [blankSocialMediaLinkNameError4, setBlankSocialMediaLinkNameError4] = useState(false);
    const [blankSocialMediaLinkUrlError4, setBlankSocialMediaLinkUrlError4] = useState(false);
    const [blankSocialMediaLinkNameError5, setBlankSocialMediaLinkNameError5] = useState(false);
    const [blankSocialMediaLinkUrlError5, setBlankSocialMediaLinkUrlError5] = useState(false);
    const [blankLinkName2Error, setBlankLinkName2Error] = useState(false);
    const [blankLinkNameURL2Error, setBlankLinkNameURL2Error] = useState(false);
    const [blankLinkName3Error, setBlankLinkName3Error] = useState(false);
    const [blankLinkNameURL3Error, setBlankLinkNameURL3Error] = useState(false);
    const [blankLinkName4Error, setBlankLinkName4Error] = useState(false);
    const [blankLinkNameURL4Error, setBlankLinkNameURL4Error] = useState(false);
    const [blankLinkName5Error, setBlankLinkName5Error] = useState(false);
    const [blankLinkNameURL5Error, setBlankLinkNameURL5Error] = useState(false);
    const [blankLinkName6Error, setBlankLinkName6Error] = useState(false);
    const [blankLinkNameURL6Error, setBlankLinkNameURL6Error] = useState(false);
    const [blankLinkName7Error, setBlankLinkName7Error] = useState(false);
    const [blankLinkNameURL7Error, setBlankLinkNameURL7Error] = useState(false);
    const [blankLinkName8Error, setBlankLinkName8Error] = useState(false);
    const [blankLinkNameURL8Error, setBlankLinkNameURL8Error] = useState(false);
    const [blankLinkName9Error, setBlankLinkName9Error] = useState(false);
    const [blankLinkNameURL9Error, setBlankLinkNameURL9Error] = useState(false);
    const [blankLinkName10Error, setBlankLinkName10Error] = useState(false);
    const [blankLinkNameURL10Error, setBlankLinkNameURL10Error] = useState(false);

    const [favIcon, setFavIcon] = useState('');
    const [favIconError, setFavIconError] = useState('');

    const [menuLabelColor, setMenuLabelColor] = useState('');
    const [menuLabelColorError, setMenuLabelColorError] = useState('');
    const [displayMenuLabelColorPicker, setDisplayMenuLabelColorPicker] = useState(false);

    const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
    const [displayFooterBackgroundColorPicker, setDisplayFooterBackgroundColorPicker] = useState(false);
    const [displayBannerColorPicker, setDisplayBannerColorPicker] = useState(false);

    const [showZoomSection, setShowZoomSection] = useState(false);
    const [showSupportSection, setShowSupportSection] = useState(false);

    const [hdrSupportBgColor, setHdrSupportBgColor] = useState('');
    const [hdrSupportBgColorError, setHdrSupportBgColorError] = useState('');
    const [displayHdrSupportBgColorPicker, setDisplayHdrSupportBgColorPicker] = useState(false);

    const [hdrSupportLblColor, setHdrSupportLblColor] = useState('');
    const [hdrSupportLblColorError, setHdrSupportLblColorError] = useState('');
    const [displayHdrSupportLblColorPicker, setDisplayHdrSupportLblColorPicker] = useState(false);

    const [hdrSupportEmailID, setHdrSupportEmailID] = useState('');
    const [hdrSupportEmailIDError, setHdrSupportEmailIDError] = useState('');

    const [hdrSupportEmailIDColor, setHdrSupportEmailIDColor] = useState('');
    const [hdrSupportEmailIDColorError, setHdrSupportEmailIDColorError] = useState('');
    const [displayHdrSupportEmailIDColorPicker, setDisplayHdrSupportEmailIDColorPicker] = useState(false);

    const [loginPageCompanyLogo, setLoginPageCompanyLogo] = useState('');
    const [loginPageCompanyLogoError, setLoginPageCompanyLogoError] = useState('');

    const [loginPageBgImage, setLoginPageBgImage] = useState('');
    const [loginPageBgImageError, setLoginPageBgImageError] = useState('');

    const [loginPageName, setLoginPageName] = useState('');
    const [loginPageNameError, setLoginPageNameError] = useState('');
    const [generatedLoginUrl, setGeneratedLoginUrl] = useState('');

    const [loginPageForgotPwdBttnBgColor, setLoginPageForgotPwdBttnBgColor] = useState('');
    const [displayLoginPageForgotPwdBttnBgColorPicker, setDisplayLoginPageForgotPwdBttnBgColorPicker] = useState(false);
    const [loginPageForgotPwdBttnBgColorError, setLoginPageForgotPwdBttnBgColorError] = useState('');

    const [loginPageBttnGradientColor1, setLoginPageBttnGradientColor1] = useState('');
    const [displayLoginPageBttnGradientColor1Picker, setDisplayLoginPageBttnGradientColor1Picker] = useState(false);
    const [loginPageBttnGradientColor1Error, setLoginPageBttnGradientColor1Error] = useState('');

    const [loginPageBttnGradientColor2, setLoginPageBttnGradientColor2] = useState('');
    const [displayLoginPageBttnGradientColor2Picker, setDisplayLoginPageBttnGradientColor2Picker] = useState(false);
    const [loginPageBttnGradientColor2Error, setLoginPageBttnGradientColor2Error] = useState('');

    const [sessionOutImage, setSessionOutImage] = useState('');
    const [sessionOutImageError, setSessionOutImageError] = useState('');

    const [forgotPwdPageLblColor, setForgotPwdPageLblColor] = useState('');
    const [displayForgotPwdPageLblColorPicker, setDisplayForgotPwdPageLblColorPicker] = useState(false);
    const [forgotPwdPageLblColorError, setForgotPwdPageLblColorError] = useState('');

    const [allowLoginCustomization] = useState(getConfigurationByBoolen('ALLOW_CUSTOMIZE_LOGIN'));
    //const [allowLoginCustomization] = useState(true);

    const [loaderColor, setLoaderColor] = useState('');
    const [loaderColorError, setLoaderColorError] = useState('');
    const [displayLoaderColorPicker, setDisplayLoaderColorPicker] = useState(false);

    const popover = {
        position: 'absolute',
        zIndex: '2',
    };

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    function handleClick(event) {
        event.preventDefault();
        setDisplayColorPicker(currentVal => !currentVal);
    }

    function handleButtonGradientClick(event) {
        event.preventDefault();
        setDisplayButtonGradientColorPicker(currentVal => !currentVal);
    }

    function handleTextBackgroundColorClick(event) {
        event.preventDefault();
        setDisplayTextBackGroundColorPicker(currentVal => !currentVal);
    }

    function handleTextBorderColorClick(event) {
        event.preventDefault();
        setDisplayTextBorderColorPicker(currentVal => !currentVal);
    }

    function handleClose(event) {
        event.preventDefault();
        setDisplayColorPicker(false);
    }

    function handleButtonGradientClose(event) {
        event.preventDefault();
        setDisplayButtonGradientColorPicker(false);
    }

    function handleTextBackgroundColorClose(event) {
        event.preventDefault();
        setDisplayTextBackGroundColorPicker(false);
    }

    function handleTextBorderColorClose(event) {
        event.preventDefault();
        setDisplayTextBorderColorPicker(false);
    }

    function handleBackgroundClick(event) {
        event.preventDefault();
        setDisplayBackgroundColorPicker(currentVal => !currentVal);
    }

    function handleFooterBackgroundClick(event) {
        event.preventDefault();
        setDisplayFooterBackgroundColorPicker(currentVal => !currentVal);
    }

    function handleBackgroundClose(event) {
        event.preventDefault();
        setDisplayBackgroundColorPicker(false);
    }

    function handleFooterBackgroundClose(event) {
        event.preventDefault();
        setDisplayFooterBackgroundColorPicker(false);
    }

    function handleBannerClick(event) {
        event.preventDefault();
        setDisplayBannerColorPicker(currentVal => !currentVal);
    }

    function handleBannerClose(event) {
        event.preventDefault();
        setDisplayBannerColorPicker(false);
    }

    function activePartnerChangeHandler() {
        setActivePartner(!activePartner);
    }

    function activeGDPRChangeHandler() {
        setActiveGDPR(!activeGDPR);
    }

    function emailValidation(email) {
        if (email.match(regEmailID)) {
            return true;
        }
        return false;
    }

    function companyLogoHandler(e) {
        let validImage = checkMimeType(e, ['jpg', 'jpeg', 'png']);

        if (validImage === false) {
            setCompanyLogoImage('');
            setCompanyLogoError(t('invalidCompanyLogo'));
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {

            getImageProperties(e.target.files[0])
                .then(response => {
                    if (response.width < 250 || response.height < 55) {
                        setCompanyLogoImage('');
                        setCompanyLogoError(t('invalidCompanyLogo'));
                        return;
                    }
                    else {
                        getBase64(e.target.files[0])
                            .then(result => {
                                if (isValidFileSize(200, result)) {
                                    setCompanyLogoImage(result);
                                    setCompanyLogoError('');
                                }
                                else {
                                    setCompanyLogoImage('');
                                    setCompanyLogoError(t('invalidCompanyLogoFileSize'));
                                    return false;
                                }
                            })
                            .catch(err => {
                                //setFavIconError(err?.message || '');
                            });
                    }
                });
        }

    }

    function bannerColorChangeHandler(color) {
        setBannerColor(color.hex);
        setBlankBannerColorError(false);
    }

    function buttonColorChangeHandler(color) {
        setButtonColor(color.hex);
        setBlankButtonColorError(false);
    }

    function buttonColorGradientChangeHandler(color) {
        setButtonGradientColor(color.hex);
    }

    function textBorderColorChangeHandler(color) {
        setTextBorderColor(color.hex);
    }

    function textBackGroundColorChangeHandler(color) {
        setTextBackGroundColor(color.hex);
    }

    function backGroundColorChangeHandler(color) {
        setBackGroundColor(color.hex);
        setBlankBackGroundColorError(false);
    }

    function footerBackGroundColorChangeHandler(color) {
        setFooterBackGroundColor(color.hex);
    }

    function setToDefault(event) {
        localStorage.setItem("defaultSet", "1");
        event.preventDefault();
        const officeID = JSON.parse(localStorage.getItem('officeID')) + "@@DEFAULT@@" + JSON.parse(localStorage.getItem('parentOfficeID'));

        props.updateForm(officeID);
    }

    function expandSection(section) {
        if (!section.current.classList.contains('show')) {
            section.current.classList.remove('collapse');
            section.current.classList.add('show');
        }
    }

    function validateHeaderSection(shouldFocus) {
        if (companyLogoImage === '') {
            setCompanyLogoError(t('blankCompanyLogoValidation'))
            if (shouldFocus) {
                expandSection(collapseHeaderRef);
                companyLogoInputRef.current.focus();
            }
            return false;
        } else {
            setCompanyLogoError('');
        }

        if (favIcon === '') {
            setFavIconError(t('errorFavIconBlank'));
            if (shouldFocus) {
                expandSection(collapseHeaderRef);
                favIconInputRef.current.focus();
                //scrollToElement('companyLogo');
            }
            return false;
        } else {
            setFavIconError('');
        }

        if (menuLabelColor === '') {
            setMenuLabelColorError(t('errorMenuLabelColorBlank'));
            if (shouldFocus) {
                expandSection(collapseHeaderRef);
                menuLabelColorInputRef.current.focus();
                //scrollToElement('companyLogo');
            }
            return false;
        } else {
            setMenuLabelColorError('');
        }

        if (bannerColor === '') {
            setBlankBannerColorError(true);
            if (shouldFocus) {
                expandSection(collapseHeaderRef);
                bannerColorInputRef.current.focus();
            }
            return false;
        }
        else {
            setBlankBannerColorError(false);
        }

        if (showSupportSection) {
            if (hdrSupportBgColor === '') {
                setHdrSupportBgColorError(t('errorHdrSupportBgColorBlank'));
                if (shouldFocus) {
                    expandSection(collapseHeaderRef);
                    hdrSupportBgColorInputRef.current.focus();
                }
                return false;
            }
            else {
                setHdrSupportBgColorError('');
            }

            if (hdrSupportLblColor === '') {
                setHdrSupportLblColorError(t('errorHdrSupportLblColorBlank'));
                if (shouldFocus) {
                    expandSection(collapseHeaderRef);
                    hdrSupportLblColorInputRef.current.focus();
                }
                return false;
            }
            else {
                setHdrSupportLblColorError('');
            }

            if (hdrSupportEmailID.trim() === '') {
                setHdrSupportEmailIDError(t('errorHdrSupportEmailIDBlank'));
                if (shouldFocus) {
                    expandSection(collapseHeaderRef);
                    hdrSupportEmailIDInputRef.current.focus();
                }
                return false;
            }
            else {
                setHdrSupportEmailIDError('');
            }

            if (!emailValidation(hdrSupportEmailID.trim())) {
                setHdrSupportEmailIDError(t('invalidEmailId'));
                if (shouldFocus) {
                    expandSection(collapseHeaderRef);
                    hdrSupportEmailIDInputRef.current.focus();
                }
                return false;
            } else {
                setHdrSupportEmailIDError('');
            }

            if (hdrSupportEmailIDColor.trim() === '') {
                setHdrSupportEmailIDColorError(t('errorHdrSupportEmailIDColorBlank'));
                if (shouldFocus) {
                    expandSection(collapseHeaderRef);
                    hdrSupportEmailIDColorInputRef.current.focus();
                }
                return false;
            }
            else {
                setHdrSupportEmailIDColorError('');
            }
        }

        return true;
    }

    function validateGeneralSection(shouldFocus) {
        if (buttonColor === '') {
            setBlankButtonColorError(true);
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                buttonColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankButtonColorError(false);
        }

        if (backGroundColor === '') {
            setBlankBackGroundColorError(true);
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                backGroundColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankBackGroundColorError(false);
        }

        if (textBorderColor === '') {
            setBlankTextBorderColorError(true);
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                textBorderColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankTextBorderColorError(false);
        }

        if (buttonGradientColor === '') {
            setBlankButtonGradientColorError(true);
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                buttonGradientColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankButtonGradientColorError(false);
        }

        if (textBackGroundColor === '') {
            setBlankTextBackGroundColorError(true);
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                textBackGroundColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankTextBackGroundColorError(false);
        }

        if (loaderColor === '') {
            setLoaderColorError(t('errorLoaderColorRequired'));
            if (shouldFocus) {
                expandSection(collapseGeneralRef);
                loaderColorInputRef.current.focus();
            }
            return false;
        } else {
            setLoaderColorError('');
        }

        return true;
    }

    function validateFooterSection(shouldFocus) {

        const contactUsPhoneInput = contactUsPhoneInputRef.current.value;
        const contactUsEmailInput = contactUsEmailInputRef.current.value;

        if (contactUsPhoneInput.trim() === '') {
            setBlankContactUsPhoneError(true);
            setContactUsPhoneInputError(false);
            setPhoneLengthError(false);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                contactUsPhoneInputRef.current.focus();
            }
            return false;
        }
        else {
            setBlankContactUsPhoneError(false);
        }

        if (!contactUsPhoneInput.match('^[0-9+ ]*$')) {
            setContactUsPhoneInputError(true);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                contactUsPhoneInputRef.current.focus();
            }
            return false;
        } else {
            setContactUsPhoneInputError(false);
        }

        if (contactUsPhoneInput.length < 8) {
            setPhoneLengthError(true);
            setBlankContactUsPhoneError(false);
            setContactUsPhoneInputError(false);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                contactUsPhoneInputRef.current.focus();
            }
            return false;
        } else {
            setPhoneLengthError(false);
        }

        if (contactUsEmailInput.trim() === '') {
            setBlankBContactUsEmailError(true);
            setInvalidContactUEmailError(false);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                contactUsEmailInputRef.current.focus();
            }
            return false;
        }
        else {
            setBlankBContactUsEmailError(false);
        }

        if (!emailValidation(contactUsEmailInput)) {
            setInvalidContactUEmailError(true);
            setBlankBContactUsEmailError(false);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                contactUsEmailInputRef.current.focus();
            }
            return false;
        } else {
            setInvalidContactUEmailError(false);
        }

        if (footerBackGroundColor === '') {
            setBlankFooterBackgroundColorError(true);
            if (shouldFocus) {
                expandSection(collapseFooterRef);
                footerBackGroundColorInputRef.current.focus();
            }
            return false;
        } else {
            setBlankFooterBackgroundColorError(false);
        }

        return true;
    }

    function validateLinksSection(shouldFocus) {

        const linkName2Input = linkName2InputRef.current.value;
        const linkNameURL2Input = linkNameURL2InputRef.current.value;
        const linkName3Input = linkName3InputRef.current.value;
        const linkNameURL3Input = linkNameURL3InputRef.current.value;
        const linkName4Input = linkName4InputRef.current.value;
        const linkNameURL4Input = linkNameURL4InputRef.current.value;
        const linkName5Input = linkName5InputRef.current.value;
        const linkNameURL5Input = linkNameURL5InputRef.current.value;
        const linkName6Input = linkName6InputRef.current.value;
        const linkNameURL6Input = linkNameURL6InputRef.current.value;
        const linkName7Input = linkName7InputRef.current.value;
        const linkNameURL7Input = linkNameURL7InputRef.current.value;
        const linkName8Input = linkName8InputRef.current.value;
        const linkNameURL8Input = linkNameURL8InputRef.current.value;
        const linkName9Input = linkName9InputRef.current.value;
        const linkNameURL9Input = linkNameURL9InputRef.current.value;
        const linkName10Input = linkName10InputRef.current.value;
        const linkNameURL10Input = linkNameURL10InputRef.current.value;
        const linkName1Input = linkName1InputRef.current.value;
        const linkNameURL1Input = linkNameURL1InputRef.current.value;

        //Link1
        if (linkName1Input.trim().length !== 0) {
            if (linkNameURL1Input.trim().length === 0) {
                //linkNameURL1InputRef.current.focus();
                setBlankLinkNameURL1Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL1InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL1Error(false);
            }

        }
        else {
            setBlankLinkNameURL1Error(false);
        }
        if (linkNameURL1Input.trim().length !== 0) {
            if (linkName1Input.trim().length === 0) {
                // linkName1InputRef.current.focus();
                setBlankLinkName1Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName1InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName1Error(false);
            }

        } else {
            setBlankLinkName1Error(false);
        }

        //Link2
        if (linkName2Input.trim().length !== 0) {
            if (linkNameURL2Input.trim().length === 0) {
                setBlankLinkNameURL2Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL2InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL2Error(false);
            }

        } else {
            setBlankLinkNameURL2Error(false);
        }
        if (linkNameURL2Input.trim().length !== 0) {
            if (linkName2Input.trim().length === 0) {
                setBlankLinkName2Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName2InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName2Error(false);
            }

        } else {
            setBlankLinkName2Error(false);
        }
        //link3
        if (linkName3Input.trim().length !== 0) {
            if (linkNameURL3Input.trim().length === 0) {
                setBlankLinkNameURL3Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL3InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL3Error(false);
            }

        } else {
            setBlankLinkNameURL3Error(false);
        }
        if (linkNameURL3Input.trim().length !== 0) {
            if (linkName3Input.trim().length === 0) {
                setBlankLinkName3Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName3InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName3Error(false);
            }

        } else {
            setBlankLinkName3Error(false);
        }
        //link4
        if (linkName4Input.trim().length !== 0) {
            if (linkNameURL4Input.trim().length === 0) {
                setBlankLinkNameURL4Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL4InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL4Error(false);
            }

        } else {
            setBlankLinkNameURL4Error(false);
        }
        if (linkNameURL4Input.trim().length !== 0) {
            if (linkName4Input.trim().length === 0) {
                setBlankLinkName4Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName4InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName4Error(false);
            }

        } else {
            setBlankLinkName4Error(false);
        }
        //link5
        if (linkName5Input.trim().length !== 0) {
            if (linkNameURL5Input.trim().length === 0) {
                setBlankLinkNameURL5Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL5InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL5Error(false);
            }

        } else {
            setBlankLinkNameURL5Error(false);
        }
        if (linkNameURL5Input.trim().length !== 0) {
            if (linkName5Input.trim().length === 0) {
                setBlankLinkName5Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName5InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName5Error(false);
            }

        } else {
            setBlankLinkName5Error(false);
        }
        //link6
        if (linkName6Input.trim().length !== 0) {
            if (linkNameURL6Input.trim().length === 0) {
                setBlankLinkNameURL6Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL6InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL6Error(false);
            }

        } else {
            setBlankLinkNameURL6Error(false);
        }
        if (linkNameURL6Input.trim().length !== 0) {
            if (linkName6Input.trim().length === 0) {
                setBlankLinkName6Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName6InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName6Error(false);
            }

        } else {
            setBlankLinkName6Error(false);
        }
        //link7
        if (linkName7Input.trim().length !== 0) {
            if (linkNameURL7Input.trim().length === 0) {
                setBlankLinkNameURL7Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL7InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL7Error(false);
            }

        } else {
            setBlankLinkNameURL7Error(false);
        }
        if (linkNameURL7Input.trim().length !== 0) {
            if (linkName7Input.trim().length === 0) {
                setBlankLinkName7Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName7InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName7Error(false);
            }

        } else {
            setBlankLinkName7Error(false);
        }
        //link8
        if (linkName8Input.trim().length !== 0) {
            if (linkNameURL8Input.trim().length === 0) {
                setBlankLinkNameURL8Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL8InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL8Error(false);
            }

        } else {
            setBlankLinkNameURL8Error(false);
        }
        if (linkNameURL8Input.trim().length !== 0) {
            if (linkName8Input.trim().length === 0) {
                setBlankLinkName8Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName8InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName8Error(false);
            }

        } else {
            setBlankLinkName8Error(false);
        }
        //link9
        if (linkName9Input.trim().length !== 0) {
            if (linkNameURL9Input.trim().length === 0) {
                setBlankLinkNameURL9Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL9InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL9Error(false);
            }

        } else {
            setBlankLinkNameURL9Error(false);
        }
        if (linkNameURL9Input.trim().length !== 0) {
            if (linkName9Input.trim().length === 0) {
                setBlankLinkName9Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName9InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName9Error(false);
            }

        } else {
            setBlankLinkName9Error(false);
        }
        //link10
        if (linkName10Input.trim().length !== 0) {
            if (linkNameURL10Input.trim().length === 0) {
                setBlankLinkNameURL10Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkNameURL10InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkNameURL10Error(false);
            }

        } else {
            setBlankLinkNameURL10Error(false);
        }
        if (linkNameURL10Input.trim().length !== 0) {
            if (linkName10Input.trim().length === 0) {
                setBlankLinkName10Error(true);
                if (shouldFocus) {
                    expandSection(collapseLinksRef);
                    linkName10InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankLinkName10Error(false);
            }

        } else {
            setBlankLinkName10Error(false);
        }

        return true;
    }

    function validateSocialMediaLinksSection(shouldFocus) {

        const rightLinkName1Input = rightLinkName1InputRef.current.value;
        const rightLinkNameURL1Input = rightLinkNameURL1InputRef.current.value;
        const rightLinkName2Input = rightLinkName2InputRef.current.value;
        const rightLinkNameURL2Input = rightLinkNameURL2InputRef.current.value;
        const rightLinkName3Input = rightLinkName3InputRef.current.value;
        const rightLinkNameURL3Input = rightLinkNameURL3InputRef.current.value;
        const rightLinkName4Input = rightLinkName4InputRef.current.value;
        const rightLinkNameURL4Input = rightLinkNameURL4InputRef.current.value;
        const rightLinkName5Input = rightLinkName5InputRef.current.value;
        const rightLinkNameURL5Input = rightLinkNameURL5InputRef.current.value;

        //socialMediaLink1
        if (rightLinkName1Input.trim().length !== 0) {
            if (rightLinkNameURL1Input.trim() === '') {
                setBlankSocialMediaLinkUrlError1(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkNameURL1InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankSocialMediaLinkUrlError1(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError1(false);
        }
        if (rightLinkNameURL1Input.trim().length !== 0) {
            if (rightLinkName1Input.trim() === '') {
                setBlankSocialMediaLinkNameError1(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkName1InputRef.current.focus();
                }
                return false;
            } else {
                setBlankSocialMediaLinkNameError1(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError1(false);
        }

        //socialMediaLink2
        if (rightLinkName2Input.trim().length !== 0) {
            if (rightLinkNameURL2Input.trim() === '') {
                setBlankSocialMediaLinkUrlError2(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkNameURL2InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankSocialMediaLinkUrlError2(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError2(false);
        }
        if (rightLinkNameURL2Input.trim().length !== 0) {
            if (rightLinkName2Input.trim() === '') {
                setBlankSocialMediaLinkNameError2(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkName2InputRef.current.focus();
                }
                return false;
            } else {
                setBlankSocialMediaLinkNameError2(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError2(false);
        }
        //SocialMediaLink3
        if (rightLinkName3Input.trim().length !== 0) {
            if (rightLinkNameURL3Input.trim() === '') {
                setBlankSocialMediaLinkUrlError3(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkNameURL3InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankSocialMediaLinkUrlError3(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError3(false);
        }
        if (rightLinkNameURL3Input.trim().length !== 0) {
            if (rightLinkName3Input.trim() === '') {
                setBlankSocialMediaLinkNameError3(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkName3InputRef.current.focus();
                }
                return false;
            } else {
                setBlankSocialMediaLinkNameError3(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError3(false);
        }
        //SocialMediaLink4
        if (rightLinkName4Input.trim().length !== 0) {
            if (rightLinkNameURL4Input.trim() === '') {
                setBlankSocialMediaLinkUrlError4(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkNameURL4InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankSocialMediaLinkUrlError4(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError4(false);
        }
        if (rightLinkNameURL4Input.trim().length !== 0) {
            if (rightLinkName4Input.trim() === '') {
                setBlankSocialMediaLinkNameError4(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkName4InputRef.current.focus();
                }
                return false;
            } else {
                setBlankSocialMediaLinkNameError4(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError4(false);
        }
        //SocialMediaLink5
        if (rightLinkName5Input.trim().length !== 0) {
            if (rightLinkNameURL5Input.trim() === '') {
                setBlankSocialMediaLinkUrlError5(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkNameURL5InputRef.current.focus();
                }
                return false;
            }
            else {
                setBlankSocialMediaLinkUrlError5(false);
            }
        } else {
            setBlankSocialMediaLinkUrlError5(false);
        }
        if (rightLinkNameURL5Input.trim().length !== 0) {
            if (rightLinkName5Input.trim() === '') {
                setBlankSocialMediaLinkNameError5(true);
                if (shouldFocus) {
                    expandSection(collapseSocialLinksRef);
                    rightLinkName5InputRef.current.focus();
                }
                return false;
            } else {
                setBlankSocialMediaLinkNameError5(false);
            }
        }
        else {
            setBlankSocialMediaLinkNameError5(false);
        }

        return true;
    }

    function validateLoginPageSection(shouldFocus) {

        if (loginPageCompanyLogo === '') {
            setLoginPageCompanyLogoError(t('blankCompanyLogoValidation'));
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageCompanyLogoInputRef.current.focus();
            }
            return false;
        } else {
            setLoginPageCompanyLogoError('');
        }

        if (loginPageBgImage === '') {
            setLoginPageBgImageError(t('errorLoginPageBgImageBlank'));
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageBgImageInputRef.current.focus();
            }
            return false;
        } else {
            setLoginPageBgImageError('');
        }

        if (loginPageName.trim() === '') {
            setLoginPageNameError(t('errorLoginPageNameRequired'));
            setGeneratedLoginUrl('');
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageNameInputRef.current.focus();
            }
            return false;
        }
        else {
            if (!isValidHttpUrl(loginPageName.trim()) && !loginPageName.trim().match('^[a-zA-Z0-9]+$')) {
                setLoginPageNameError(t('invalidInput'));
                setGeneratedLoginUrl('');
                if (shouldFocus) {
                    expandSection(collapseLoginPageRef);
                    loginPageNameInputRef.current.focus();
                }
                return false;
            }
            else {
                setLoginPageNameError('');
                setGeneratedLoginUrl(getGeneratedLoginUrl());
            }
        }

        if (loginPageForgotPwdBttnBgColor === '') {
            setLoginPageForgotPwdBttnBgColorError(t('errorLoginPageForgotPwdBttnBgColorRequired'));
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageForgotPwdBttnBgColorInputRef.current.focus();
            }
            return false;
        } else {
            setLoginPageForgotPwdBttnBgColorError('');
        }

        if (loginPageBttnGradientColor1 === '') {
            setLoginPageBttnGradientColor1Error(t('errorLoginPageBttnGradientColor1Required'));
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageBttnGradientColor1InputRef.current.focus();
            }
            return false;
        } else {
            setLoginPageBttnGradientColor1Error('');
        }

        if (loginPageBttnGradientColor2 === '') {
            setLoginPageBttnGradientColor2Error(t('errorLoginPageBttnGradientColor2Required'));
            if (shouldFocus) {
                expandSection(collapseLoginPageRef);
                loginPageBttnGradientColor2InputRef.current.focus();
            }
            return false;
        } else {
            setLoginPageBttnGradientColor2Error('');
        }

        return true;
    }

    function validateForm(shouldFocus) {

        if (!validateHeaderSection(shouldFocus)) {
            return false;
        }

        if (!validateGeneralSection(shouldFocus)) {
            return false;
        }

        if (!validateFooterSection(shouldFocus)) {
            return false;
        }

        if (!validateLinksSection(shouldFocus)) {
            return false;
        }

        if (!validateSocialMediaLinksSection(shouldFocus)) {
            return false;
        }

        if (allowLoginCustomization) {

            if (!validateLoginPageSection(shouldFocus)) {
                return false;
            }

            if (sessionOutImage === '') {
                setSessionOutImageError(t('errorSessionOutImageBlank'));
                if (shouldFocus) {
                    expandSection(collapseSessionOutPageRef);
                    sessionOutImageInputRef.current.focus();
                }
                return false;
            }
            else {
                setSessionOutImageError('');
            }

            if (forgotPwdPageLblColor === '') {
                setForgotPwdPageLblColorError(t('errorForgotPwdLblColorRequired'));
                if (shouldFocus) {
                    expandSection(collapseForgotPasswordPageRef);
                    forgotPwdPageLblColorInputRef.current.focus();
                }
                return false;
            }
            else {
                setForgotPwdPageLblColorError('');
            }
        }

        return true;
    }

    function onBlurCheck(event) {
        //alert("A");
        event.preventDefault();
        validateForm(false);
    }

    function submitFormHandler(event) {
        event?.preventDefault();
        const bottomTextInput = bottomTextInputRef.current.value;
        const bottomRightTextInput = bottomRightTextInputRef.current.value;
        const contactUsPhoneInput = contactUsPhoneInputRef.current.value;
        const contactUsEmailInput = contactUsEmailInputRef.current.value;
        const linkName1Input = linkName1InputRef.current.value;
        const linkNameURL1Input = linkNameURL1InputRef.current.value;
        const linkName2Input = linkName2InputRef.current.value;
        const linkNameURL2Input = linkNameURL2InputRef.current.value;
        const linkName3Input = linkName3InputRef.current.value;
        const linkNameURL3Input = linkNameURL3InputRef.current.value;
        const linkName4Input = linkName4InputRef.current.value;
        const linkNameURL4Input = linkNameURL4InputRef.current.value;
        const linkName5Input = linkName5InputRef.current.value;
        const linkNameURL5Input = linkNameURL5InputRef.current.value;
        const linkName6Input = linkName6InputRef.current.value;
        const linkNameURL6Input = linkNameURL6InputRef.current.value;
        const linkName7Input = linkName7InputRef.current.value;
        const linkNameURL7Input = linkNameURL7InputRef.current.value;
        const linkName8Input = linkName8InputRef.current.value;
        const linkNameURL8Input = linkNameURL8InputRef.current.value;
        const linkName9Input = linkName9InputRef.current.value;
        const linkNameURL9Input = linkNameURL9InputRef.current.value;
        const linkName10Input = linkName10InputRef.current.value;
        const linkNameURL10Input = linkNameURL10InputRef.current.value;

        const rightLinkName1Input = rightLinkName1InputRef.current.value;
        const rightLinkNameURL1Input = rightLinkNameURL1InputRef.current.value;
        const rightLinkName2Input = rightLinkName2InputRef.current.value;
        const rightLinkNameURL2Input = rightLinkNameURL2InputRef.current.value;
        const rightLinkName3Input = rightLinkName3InputRef.current.value;
        const rightLinkNameURL3Input = rightLinkNameURL3InputRef.current.value;
        const rightLinkName4Input = rightLinkName4InputRef.current.value;
        const rightLinkNameURL4Input = rightLinkNameURL4InputRef.current.value;
        const rightLinkName5Input = rightLinkName5InputRef.current.value;
        const rightLinkNameURL5Input = rightLinkNameURL5InputRef.current.value;

        if (!validateForm(true)) {
            return false;
        }

        let ID;
        if (localStorage.getItem('CUSTOMIZE_ID') !== undefined &&
            localStorage.getItem('CUSTOMIZE_ID') !== null &&
            localStorage.getItem('CUSTOMIZE_ID') !== '') {
            ID = localStorage.getItem('CUSTOMIZE_ID');
        }
        else {
            ID = '';
        }

        const addData = ({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            officeID: JSON.parse(localStorage.getItem('officeID')),
            companyLogo: companyLogoImage,
            headerBannerColor: bannerColor,
            buttonColor: buttonColor,
            borderColor: textBorderColor,
            buttonGradientColor2: buttonGradientColor,
            textBackgroundColor: textBackGroundColor,
            backgroundColor: backGroundColor,
            footerBackgroundColor: footerBackGroundColor,
            bottomLeftText: bottomTextInput,
            bottomRightText: bottomRightTextInput,

            //links value
            contactUsPhone: contactUsPhoneInput,
            contactUsEmail: contactUsEmailInput,
            linkName1: linkName1Input,
            linkNameURL1: linkNameURL1Input,
            linkName2: linkName2Input,
            linkNameURL2: linkNameURL2Input,
            linkName3: linkName3Input,
            linkNameURL3: linkNameURL3Input,
            linkName4: linkName4Input,
            linkNameURL4: linkNameURL4Input,
            linkName5: linkName5Input,
            linkNameURL5: linkNameURL5Input,
            linkName6: linkName6Input,
            linkNameURL6: linkNameURL6Input,
            linkName7: linkName7Input,
            linkNameURL7: linkNameURL7Input,
            linkName8: linkName8Input,
            linkNameURL8: linkNameURL8Input,
            linkName9: linkName9Input,
            linkNameURL9: linkNameURL9Input,
            linkName10: linkName10Input,
            linkNameURL10: linkNameURL10Input,

            rightlinkName1: rightLinkName1Input,
            rightLinkNameURL1: rightLinkNameURL1Input,
            rightlinkName2: rightLinkName2Input,
            rightLinkNameURL2: rightLinkNameURL2Input,
            rightlinkName3: rightLinkName3Input,
            rightLinkNameURL3: rightLinkNameURL3Input,
            rightlinkName4: rightLinkName4Input,
            rightLinkNameURL4: rightLinkNameURL4Input,
            rightlinkName5: rightLinkName5Input,
            rightLinkNameURL5: rightLinkNameURL5Input,

            partnerSlider: activePartner,
            certificate: activeGDPR,
            isDefault: true,

            loaderImage: '',
            faviconImage: favIcon,
            menuLabelColor: menuLabelColor,

            showSupportSection: showSupportSection,
            headerSupportBgColor: hdrSupportBgColor,
            headerSupportLblColor: hdrSupportLblColor,
            headerSupportEmailID: hdrSupportEmailID.trim(),
            headerSupportEmailIDColor: hdrSupportEmailIDColor,

            showZoomSection: showZoomSection,

            loginPageCompanyLogo: loginPageCompanyLogo,
            backgroungImageVideo: loginPageBgImage,
            pageName: loginPageName ? loginPageName.trim() : loginPageName,
            forgotPwdBackColor: loginPageForgotPwdBttnBgColor,
            loginBtnGradientColor1: loginPageBttnGradientColor1,
            loginBtnGradientColor2: loginPageBttnGradientColor2,
            sessionOutSideImage: sessionOutImage,
            forgotPwdLabelColor: forgotPwdPageLblColor,
            loaderColor: loaderColor
        });
        //console.log(addData);
        props.onAddCustomize(
            addData
        );

    }

    function handleFavIconChange(e) {
        let isValidImage = checkMimeType(e, ['png', 'ico']);

        if (!isValidImage) {
            setFavIcon('');
            setFavIconError(t('errorFavIconInvalid'));
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {

            getImageProperties(e.target.files[0])
                .then(response => {
                    if (response.width > 75 || response.height > 75) {
                        setFavIcon('');
                        setFavIconError(t('errorFavIconInvalid'));
                        return;
                    }
                    else {
                        getBase64(e.target.files[0])
                            .then(result => {
                                //console.log('favicon', result);
                                setFavIcon(result);
                                setFavIconError('');
                            })
                            .catch(err => {
                                setFavIconError(err?.message || '');
                            });
                    }
                });
        }
    }

    function handleMenuLabelColorChange(color) {
        setMenuLabelColor(color.hex);
        setMenuLabelColorError('');
    }

    function handleMenuLabelColorClick(event) {
        event.preventDefault();
        setDisplayMenuLabelColorPicker(!displayMenuLabelColorPicker);
    }

    function showSupportSectionChangeHandler() {
        setShowSupportSection(!showSupportSection);
    }

    function handleHdrSupportBgColorChange(color) {
        setHdrSupportBgColor(color.hex);
        setHdrSupportBgColorError('');
    }

    function handleHdrSupportBgColorClick(event) {
        event.preventDefault();
        setDisplayHdrSupportBgColorPicker(!displayHdrSupportBgColorPicker);
    }

    function handleHdrSupportLblColorChange(color) {
        setHdrSupportLblColor(color.hex);
        setHdrSupportLblColorError('');
    }

    function handleHdrSupportLblColorClick(event) {
        event.preventDefault();
        setDisplayHdrSupportLblColorPicker(!displayHdrSupportLblColorPicker);
    }

    function handleHdrSupportEmailIDChange(event) {
        setHdrSupportEmailID(event.target.value);
    }

    function handleHdrSupportEmailIDColorChange(color) {
        setHdrSupportEmailIDColor(color.hex);
        setHdrSupportEmailIDColorError('');
    }

    function handleHdrSupportEmailIDColorClick(event) {
        event.preventDefault();
        setDisplayHdrSupportEmailIDColorPicker(!displayHdrSupportEmailIDColorPicker);
    }

    function showZoomSectionChangeHandler() {
        setShowZoomSection(!showZoomSection);
    }

    function handleLoginPageCompanyLogoChange(e) {
        let isValidImage = checkMimeType(e, ['jpg', 'jpeg', 'png']);

        if (!isValidImage) {
            setLoginPageCompanyLogo('');
            setLoginPageCompanyLogoError(t('invalidCompanyLogo'));
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {

            getImageProperties(e.target.files[0])
                .then(response => {
                    if (response.width < 250 || response.height < 55) {
                        setLoginPageCompanyLogo('');
                        setLoginPageCompanyLogoError(t('invalidCompanyLogo'));
                        return;
                    }
                    else {
                        getBase64(e.target.files[0])
                            .then(result => {
                                if (isValidFileSize(200, result)) {
                                    setLoginPageCompanyLogo(result);
                                    setLoginPageCompanyLogoError('');
                                }
                                else {
                                    setLoginPageCompanyLogo('');
                                    setLoginPageCompanyLogoError(t('invalidCompanyLogoFileSize'));
                                    return;
                                }
                            })
                            .catch(err => {
                                setLoginPageCompanyLogoError(err?.message || '');
                            });
                    }
                });
        }
    }

    function handleLoginPageBgImageChange(e) {
        let isValidImage = checkMimeType(e, ['jpg', 'jpeg', 'png']);

        if (!isValidImage) {
            setLoginPageBgImage('');
            setLoginPageBgImageError(t('errorLoginPageBgImageInvalid'));
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {

            getImageProperties(e.target.files[0])
                .then(response => {
                    if (response.width < 1345 || response.height < 649) {
                        setLoginPageBgImage('');
                        setLoginPageBgImageError(t('errorLoginPageBgImageInvalid'));
                        return;
                    }
                    else {
                        getBase64(e.target.files[0])
                            .then(result => {
                                if (isValidFileSize(1024, result)) {
                                    setLoginPageBgImage(result);
                                    setLoginPageBgImageError('');
                                }
                                else {
                                    setLoginPageBgImage('');
                                    setLoginPageBgImageError(t('errorLoginPageBgImageInvalidFileSize'));
                                    return;
                                }
                            })
                            .catch(err => {
                                setLoginPageBgImageError(err?.message || '');
                            });
                    }
                });
        }
    }

    function handlePageNameChange(event) {
        setLoginPageName(event.target.value);
    }

    function handleLoginPageForgotPwdBttnBgColorChange(color) {
        setLoginPageForgotPwdBttnBgColor(color.hex);
        setLoginPageForgotPwdBttnBgColorError('');
    }

    function handleLoginPageForgotPwdBttnBgColorClick(event) {
        event.preventDefault();
        setDisplayLoginPageForgotPwdBttnBgColorPicker(!displayLoginPageForgotPwdBttnBgColorPicker);
    }

    function handleLoginPageBttnGradientColor1Change(color) {
        setLoginPageBttnGradientColor1(color.hex);
        setLoginPageBttnGradientColor1Error('');
    }

    function handleLoginPageBttnGradientColor1Click(event) {
        event.preventDefault();
        setDisplayLoginPageBttnGradientColor1Picker(!displayLoginPageBttnGradientColor1Picker);
    }

    function handleLoginPageBttnGradientColor2Change(color) {
        setLoginPageBttnGradientColor2(color.hex);
        setLoginPageBttnGradientColor2Error('');
    }

    function handleLoginPageBttnGradientColor2Click(event) {
        event.preventDefault();
        setDisplayLoginPageBttnGradientColor2Picker(!displayLoginPageBttnGradientColor2Picker);
    }

    function handleSessionOutImageChange(e) {
        let isValidImage = checkMimeType(e, ['jpg', 'jpeg', 'png']);

        if (!isValidImage) {
            setSessionOutImage('');
            setSessionOutImageError(t('errorSessionOutImageInvalid'));
            return;
        }

        if (maxSelectFile(e) && e.target.files.length !== 0) {

            getImageProperties(e.target.files[0])
                .then(response => {
                    if (response.width < 745 || response.height < 430) {
                        setSessionOutImage('');
                        setSessionOutImageError(t('errorSessionOutImageInvalid'));
                        return;
                    }
                    else {
                        getBase64(e.target.files[0])
                            .then(result => {
                                if (isValidFileSize(500, result)) {
                                    setSessionOutImage(result);
                                    setSessionOutImageError('');
                                }
                                else {
                                    setSessionOutImage('');
                                    setSessionOutImageError(t('errorSessionOutImageInvalidFileSize'));
                                    return;
                                }
                            })
                            .catch(err => {
                                setSessionOutImageError(err?.message || '');
                            });
                    }
                });
        }
    }

    function handledForgotPwdPageLblColorChange(color) {
        setForgotPwdPageLblColor(color.hex);
        setForgotPwdPageLblColorError('');
    }

    function handleForgotPwdPageLblColorClick(event) {
        event.preventDefault();
        setDisplayForgotPwdPageLblColorPicker(!displayForgotPwdPageLblColorPicker);
    }

    function getGeneratedLoginUrl(pageName) {
        let loginUrl = APP_URL;
        pageName = pageName || loginPageName;
        if (isValidHttpUrl(pageName)) {
            loginUrl = pageName;
        }
        else {
            loginUrl = loginUrl + 'login/' + pageName;
        }

        return loginUrl;
    }

    function handleLoaderColorChange(color) {
        setLoaderColor(color.hex);
        setLoaderColorError('');
    }

    function handleLoaderColorClick(event) {
        event.preventDefault();
        setDisplayLoaderColorPicker(!displayLoaderColorPicker);
    }

    //Login page name duplicate error comes from server side. This refresh token prop
    //will signal the component to show the error and focus on the input.
    useEffect(() => {
        if (props.duplicatePageNameErrorRefreshToken > 0) {
            setLoginPageNameError(t('errorLoginPageNameDuplicate').replace('PAGE_NAME', loginPageName));
            expandSection(collapseLoginPageRef);
            loginPageNameInputRef.current.focus();
        }
    }, [props.duplicatePageNameErrorRefreshToken]);

    useEffect(() => {
        if (props.isLoading) {
            scrollToLoader();
        }
    }, [props.isLoading]);

    useEffect(() => {
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });

        if (isFirtTime && props.loadedCustomizeDetails.length !== 0) {
            if (props.loadedCustomizeDetails.bottomLeftText !== '') {
                bottomTextInputRef.current.value = props.loadedCustomizeDetails.bottomLeftText;
            }
            if (props.loadedCustomizeDetails.bottomRightText !== '') {
                bottomRightTextInputRef.current.value = props.loadedCustomizeDetails.bottomRightText;
            }
            if (props.loadedCustomizeDetails.headerBannerColor !== null) {
                setBannerColor(props.loadedCustomizeDetails.headerBannerColor);
            }

            setCompanyLogoImage(props.loadedCustomizeDetails.companyLogo);

            if (props.loadedCustomizeDetails.buttonColor !== null) {
                setButtonColor(props.loadedCustomizeDetails.buttonColor);
            }

            if (props.loadedCustomizeDetails.borderColor !== null) {
                setTextBorderColor(props.loadedCustomizeDetails.borderColor);
            }

            if (props.loadedCustomizeDetails.loaderColor) {
                setLoaderColor(props.loadedCustomizeDetails.loaderColor);
            }

            if (backGroundColor === '' && props.loadedCustomizeDetails.backgroundColor !== null) {
                setBackGroundColor(props.loadedCustomizeDetails.backgroundColor);
            }

            if (props.loadedCustomizeDetails.buttonGradientColor2 !== null) {
                setButtonGradientColor(props.loadedCustomizeDetails.buttonGradientColor2);
            }

            if (backGroundColor === '' && props.loadedCustomizeDetails.footerBackgroundColor !== null) {
                setFooterBackGroundColor(props.loadedCustomizeDetails.footerBackgroundColor);
            }

            if (textBackGroundColor === '' && props.loadedCustomizeDetails.textBackgroundColor !== null) {
                setTextBackGroundColor(props.loadedCustomizeDetails.textBackgroundColor);
            }

            //links value
            contactUsPhoneInputRef.current.value = props.loadedCustomizeDetails.contactUsPhone;
            contactUsEmailInputRef.current.value = props.loadedCustomizeDetails.contactUsEmail;
            linkName1InputRef.current.value = props.loadedCustomizeDetails.linkName1;
            linkNameURL1InputRef.current.value = props.loadedCustomizeDetails.linkNameURL1;
            linkName2InputRef.current.value = props.loadedCustomizeDetails.linkName2;
            linkNameURL2InputRef.current.value = props.loadedCustomizeDetails.linkNameURL2;
            linkName3InputRef.current.value = props.loadedCustomizeDetails.linkName3;
            linkNameURL3InputRef.current.value = props.loadedCustomizeDetails.linkNameURL3;
            linkName4InputRef.current.value = props.loadedCustomizeDetails.linkName4;
            linkNameURL4InputRef.current.value = props.loadedCustomizeDetails.linkNameURL4;
            linkName5InputRef.current.value = props.loadedCustomizeDetails.linkName5;
            linkNameURL5InputRef.current.value = props.loadedCustomizeDetails.linkNameURL5;
            linkName6InputRef.current.value = props.loadedCustomizeDetails.linkName6;
            linkNameURL6InputRef.current.value = props.loadedCustomizeDetails.linkNameURL6;
            linkName7InputRef.current.value = props.loadedCustomizeDetails.linkName7;
            linkNameURL7InputRef.current.value = props.loadedCustomizeDetails.linkNameURL7;
            linkName8InputRef.current.value = props.loadedCustomizeDetails.linkName8;
            linkNameURL8InputRef.current.value = props.loadedCustomizeDetails.linkNameURL8;
            linkName9InputRef.current.value = props.loadedCustomizeDetails.linkName9;
            linkNameURL9InputRef.current.value = props.loadedCustomizeDetails.linkNameURL9;
            linkName10InputRef.current.value = props.loadedCustomizeDetails.linkName10;
            linkNameURL10InputRef.current.value = props.loadedCustomizeDetails.linkNameURL10;
            rightLinkName1InputRef.current.value = props.loadedCustomizeDetails.rightlinkName1;
            rightLinkNameURL1InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL1;
            rightLinkName2InputRef.current.value = props.loadedCustomizeDetails.rightlinkName2;
            rightLinkNameURL2InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL2;
            rightLinkName3InputRef.current.value = props.loadedCustomizeDetails.rightlinkName3;
            rightLinkNameURL3InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL3;
            rightLinkName4InputRef.current.value = props.loadedCustomizeDetails.rightlinkName4;
            rightLinkNameURL4InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL4;
            rightLinkName5InputRef.current.value = props.loadedCustomizeDetails.rightlinkName5;
            rightLinkNameURL5InputRef.current.value = props.loadedCustomizeDetails.rightLinkNameURL5;

            setActivePartner(props.loadedCustomizeDetails.partnerSlider);
            setActiveGDPR(props.loadedCustomizeDetails.certificate);

            if (props.loadedCustomizeDetails.faviconImage) {
                setFavIcon(props.loadedCustomizeDetails.faviconImage);
            }

            if (props.loadedCustomizeDetails.menuLabelColor) {
                setMenuLabelColor(props.loadedCustomizeDetails.menuLabelColor);
            }

            if (props.loadedCustomizeDetails.showSupportSection) {
                setShowSupportSection(true);
            }

            if (props.loadedCustomizeDetails.headerSupportBgColor) {
                setHdrSupportBgColor(props.loadedCustomizeDetails.headerSupportBgColor);
            }

            if (props.loadedCustomizeDetails.headerSupportLblColor) {
                setHdrSupportLblColor(props.loadedCustomizeDetails.headerSupportLblColor);
            }

            if (props.loadedCustomizeDetails.headerSupportEmailID) {
                setHdrSupportEmailID(props.loadedCustomizeDetails.headerSupportEmailID);
            }

            if (props.loadedCustomizeDetails.headerSupportEmailIDColor) {
                setHdrSupportEmailIDColor(props.loadedCustomizeDetails.headerSupportEmailIDColor);
            }

            if (props.loadedCustomizeDetails.showZoomSection) {
                setShowZoomSection(true);
            }

            if (props.loadedCustomizeDetails.loginPageCompanyLogo) {
                setLoginPageCompanyLogo(props.loadedCustomizeDetails.loginPageCompanyLogo);
            }

            if (props.loadedCustomizeDetails.backgroungImageVideo) {
                setLoginPageBgImage(props.loadedCustomizeDetails.backgroungImageVideo);
            }

            if (props.loadedCustomizeDetails.pageName) {
                setLoginPageName(props.loadedCustomizeDetails.pageName);
                setGeneratedLoginUrl(getGeneratedLoginUrl(props.loadedCustomizeDetails.pageName));
            }

            if (props.loadedCustomizeDetails.forgotPwdBackColor) {
                setLoginPageForgotPwdBttnBgColor(props.loadedCustomizeDetails.forgotPwdBackColor);
            }

            if (props.loadedCustomizeDetails.loginBtnGradientColor1) {
                setLoginPageBttnGradientColor1(props.loadedCustomizeDetails.loginBtnGradientColor1);
            }

            if (props.loadedCustomizeDetails.loginBtnGradientColor2) {
                setLoginPageBttnGradientColor2(props.loadedCustomizeDetails.loginBtnGradientColor2);
            }

            if (props.loadedCustomizeDetails.sessionOutSideImage) {
                setSessionOutImage(props.loadedCustomizeDetails.sessionOutSideImage);
            }

            if (props.loadedCustomizeDetails.forgotPwdLabelColor) {
                setForgotPwdPageLblColor(props.loadedCustomizeDetails.forgotPwdLabelColor);
            }

            setPageTitle(t('pageTitle'));
        }

        if (props.loadedCustomizeDetails.length === 0) {
            setPageTitle(t('pageTitle'));
        }
        setFirstTime(false)

        //If enter key is presed on color picker popup, hide the popup
        //If enter key is pressed on any other form control, submit it
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (document.getElementsByClassName('sketch-picker').length > 0) {
                    return false;
                }

                document.getElementById('btnSave')?.click();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, []);
    
    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        {props.isLoading &&
                                            <div className="loading">
                                                <LoadingSpinner className={'top'} />
                                            </div>
                                        }
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12">
                                                        <i className="fas fa-globe-asia"></i>{" " + pageTitle}
                                                    </label>

                                                    <form className='form'>

                                                        {/*Header Section*/}
                                                        <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                            <div className="accordion" id="accordionHeader">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                    <div className="accordion-item col-md-12">

                                                                        <h2 className="accordion-header" id="headingHeader">
                                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseHeader" aria-expanded="true"
                                                                                aria-controls="collapseHeader"> <span><i class="fas fa-radiation"></i>   {t('labelHeader')}</span>
                                                                            </button>
                                                                        </h2>

                                                                        <div id="collapseHeader"
                                                                            ref={collapseHeaderRef}
                                                                            className="accordion-collapse show"
                                                                            aria-labelledby="headingHeader" data-bs-parent="#accordionHeader">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className='control col-md-6'>
                                                                                        <label htmlFor="companyLogo"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('companyLogo')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <br></br>
                                                                                        <span className="note">{t('logoImageFormat')}</span>
                                                                                        <input type="file"
                                                                                            id="companyLogo"
                                                                                            className="form-control form-control-sm"
                                                                                            onChange={companyLogoHandler}
                                                                                            accept=".jpg,.jpeg,.png"
                                                                                            ref={companyLogoInputRef}>
                                                                                        </input>
                                                                                        {companyLogoImage.length > 0 &&
                                                                                            <span key={generateUUID(10)}>
                                                                                                <div className="mt-2">
                                                                                                    <img className="border-blue-img gt-customize-h-55"
                                                                                                        src={companyLogoImage}
                                                                                                        alt="" />
                                                                                                </div>
                                                                                            </span>
                                                                                        }
                                                                                        {companyLogoError &&
                                                                                            companyLogoError.length > 0 &&
                                                                                            <p className="error-text">
                                                                                                {companyLogoError}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='control col-md-6'>
                                                                                        <label htmlFor="favIcon"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('labelFavIcon')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <br></br>
                                                                                        <span className="note">{t('noteFavIcon')}</span>
                                                                                        <input type="file"
                                                                                            id="favIcon"
                                                                                            className="form-control form-control-sm"
                                                                                            onChange={handleFavIconChange}
                                                                                            accept=".png,.ico"
                                                                                            ref={favIconInputRef}>
                                                                                        </input>
                                                                                        {favIcon.length > 0 &&
                                                                                            <span key={generateUUID(10)}>
                                                                                                <div className="mt-2">
                                                                                                    <img className="border-blue-img gt-customize-h-55"
                                                                                                        src={favIcon}
                                                                                                        alt="" />
                                                                                                </div>
                                                                                            </span>
                                                                                        }

                                                                                        {favIconError &&
                                                                                            favIconError.length > 0 &&
                                                                                            <p className="error-text">
                                                                                                {favIconError}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className='control col-md-6'>
                                                                                        <label htmlFor="bannerImage" className="mb-2 h6 text-primary col-lg-12">{t('bannerImage')}<span className="error-icon"> *</span></label>
                                                                                        <br></br>
                                                                                        <div className="position-relative">
                                                                                            <input value={bannerColor} readOnly className="form-control form-control-sm" onChange={bannerColorChangeHandler} onBlur={onBlurCheck} ref={bannerColorInputRef} ></input>
                                                                                            <button type="button" id="btnBannerColor" className="color-picker-btn btn gt-main-btn text-white" onClick={handleBannerClick}>{t('pickColor')}</button>
                                                                                        </div>
                                                                                        {displayBannerColorPicker ? <div style={popover}>
                                                                                            <div style={cover} onClick={handleBannerClose} />
                                                                                            <SketchPicker color={bannerColor}
                                                                                                onChange={bannerColorChangeHandler} disableAlpha={true} />
                                                                                        </div> : null}
                                                                                        {blankBannerColorError && <p className="error-text">{t('blankBannerImageValidation')}</p>}
                                                                                    </div>

                                                                                    <div className='control col-md-6'>
                                                                                        <label htmlFor="menuLabelColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('labelMenuLabelColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <br></br>
                                                                                        <div className="position-relative">
                                                                                            <input id="menuLabelColor"
                                                                                                value={menuLabelColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={handleMenuLabelColorChange}
                                                                                                onBlur={onBlurCheck}
                                                                                                ref={menuLabelColorInputRef} ></input>
                                                                                            <button type="button" className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleMenuLabelColorClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>
                                                                                        {displayMenuLabelColorPicker ? <div style={popover}>
                                                                                            <div style={cover} onClick={handleMenuLabelColorClick} />
                                                                                            <SketchPicker color={menuLabelColor}
                                                                                                onChange={handleMenuLabelColorChange} disableAlpha={true} />
                                                                                        </div> : null}
                                                                                        {menuLabelColorError && menuLabelColorError.length > 0 &&
                                                                                            <p className="error-text">
                                                                                                {menuLabelColorError}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">
                                                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                                                            <label htmlFor="showSupportSection"
                                                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                                                {t('labelShowSupportSection')}
                                                                                                <span className="error-icon"> </span>
                                                                                            </label>
                                                                                            <input id="showSupportSection"
                                                                                                className="form-check-input ms-1"
                                                                                                type="checkbox"
                                                                                                onChange={showSupportSectionChangeHandler}
                                                                                                checked={showSupportSection}
                                                                                                value={showSupportSection}
                                                                                                ref={showSupportSectionInputRef}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {showSupportSection &&
                                                                                    <Fragment>
                                                                                        <div className="row">
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="hdrSupportBgColor"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelHdrSupportBgColor')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="hdrSupportBgColor"
                                                                                                        value={hdrSupportBgColor}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleHdrSupportBgColorChange}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={hdrSupportBgColorInputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleHdrSupportBgColorClick}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayHdrSupportBgColorPicker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover}
                                                                                                            onClick={handleHdrSupportBgColorClick} />
                                                                                                        <SketchPicker color={hdrSupportBgColor}
                                                                                                            onChange={handleHdrSupportBgColorChange} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {hdrSupportBgColorError && hdrSupportBgColorError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {hdrSupportBgColorError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="hdrSupportLblColor"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelHdrSupportLblColor')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="hdrSupportLblColor"
                                                                                                        value={hdrSupportLblColor}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleHdrSupportLblColorChange}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={hdrSupportLblColorInputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleHdrSupportLblColorClick}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayHdrSupportLblColorPicker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover}
                                                                                                            onClick={handleHdrSupportLblColorClick} />
                                                                                                        <SketchPicker color={hdrSupportLblColor}
                                                                                                            onChange={handleHdrSupportLblColorChange} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {hdrSupportLblColorError && hdrSupportLblColorError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {hdrSupportLblColorError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="control col-md-6">
                                                                                                <label htmlFor="hdrSupportEmailID"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelHdrSupportEmailID')}<span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    minLength={1}
                                                                                                    maxLength={50}
                                                                                                    value={hdrSupportEmailID}
                                                                                                    ref={hdrSupportEmailIDInputRef}
                                                                                                    onChange={handleHdrSupportEmailIDChange}
                                                                                                    onBlur={onBlurCheck}
                                                                                                    id="hdrSupportEmailID"
                                                                                                    className="form-control form-control-sm" />

                                                                                                {hdrSupportEmailIDError &&
                                                                                                    hdrSupportEmailIDError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {hdrSupportEmailIDError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="control col-md-6">
                                                                                                <label htmlFor="hdrSupportEmailIDColor"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelHdrSupportEmailIDColor')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="hdrSupportEmailIDColor"
                                                                                                        value={hdrSupportEmailIDColor}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleHdrSupportEmailIDColorChange}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={hdrSupportEmailIDColorInputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleHdrSupportEmailIDColorClick}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayHdrSupportEmailIDColorPicker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover}
                                                                                                            onClick={handleHdrSupportEmailIDColorClick} />
                                                                                                        <SketchPicker color={hdrSupportEmailIDColor}
                                                                                                            onChange={handleHdrSupportEmailIDColorChange} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {hdrSupportEmailIDColorError && hdrSupportEmailIDColorError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {hdrSupportEmailIDColorError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                }

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">
                                                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                                                            <label htmlFor="showZoomSection"
                                                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                                                {t('labelShowZoomSection')}
                                                                                                <span className="error-icon"> </span>
                                                                                            </label>
                                                                                            <input id="showZoomSection"
                                                                                                className="form-check-input ms-1"
                                                                                                type="checkbox"
                                                                                                onChange={showZoomSectionChangeHandler}
                                                                                                checked={showZoomSection}
                                                                                                value={showZoomSection}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*General Section*/}
                                                        <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                            <div className="accordion" id="accordionGeneral">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                    <div className="accordion-item col-md-12">

                                                                        <h2 className="accordion-header" id="headingGeneral">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseGeneral" aria-expanded="true"
                                                                                aria-controls="collapseFooter">
                                                                                <span>
                                                                                    <i class="fas fa-vector-square"></i> {t('labelGeneral')}
                                                                                </span>
                                                                            </button>
                                                                        </h2>

                                                                        <div id="collapseGeneral"
                                                                            ref={collapseGeneralRef}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby="headingFooter" data-bs-parent="#accordionGeneral">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="buttonColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('buttonColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <div className="position-relative">
                                                                                            <input value={buttonColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={buttonColorChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                ref={buttonColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={handleClose} />
                                                                                                <SketchPicker color={buttonColor}
                                                                                                    onChange={buttonColorChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankButtonColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankButtonColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="backgroundColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('backgroundColor')}<span className="error-icon"> *</span></label>
                                                                                        <div className="position-relative">
                                                                                            <input value={backGroundColor} className="form-control form-control-sm"
                                                                                                onChange={backGroundColorChangeHandler}
                                                                                                onBlur={onBlurCheck} readOnly
                                                                                                ref={backGroundColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleBackgroundClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayBackgroundColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={handleBackgroundClose} />
                                                                                                <SketchPicker
                                                                                                    color={backGroundColor}
                                                                                                    onChange={backGroundColorChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankBackGroundColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankBackGroundColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="buttonColor2"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('buttonColorGradient')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <div className="position-relative">
                                                                                            <input value={buttonGradientColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={buttonColorGradientChangeHandler}
                                                                                                onBlur={onBlurCheck} ref={buttonGradientColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleButtonGradientClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayButtonGradientColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={handleButtonGradientClose} />
                                                                                                <SketchPicker color={buttonGradientColor}
                                                                                                    onChange={buttonColorGradientChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankButtonGradientColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankButtonGradientColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="textBackGroundColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('textBackGroundColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <div className="position-relative">
                                                                                            <input value={textBackGroundColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={textBackGroundColorChangeHandler}
                                                                                                onBlur={onBlurCheck} ref={textBackGroundColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleTextBackgroundColorClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayTextBackgroundColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={handleTextBackgroundColorClose} />
                                                                                                <SketchPicker color={textBackGroundColor}
                                                                                                    onChange={textBackGroundColorChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankTextBackGroundColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankTextBackGroundColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="textBorderColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('borderColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <div className="position-relative">
                                                                                            <input value={textBorderColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={textBorderColorChangeHandler}
                                                                                                onBlur={onBlurCheck}
                                                                                                ref={textBorderColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleTextBorderColorClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayTextBorderColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover} onClick={handleTextBorderColorClose} />
                                                                                                <SketchPicker color={textBorderColor}
                                                                                                    onChange={textBorderColorChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankTextBorderColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankTextBorderColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                    <div className='control col-md-6'>
                                                                                        <label htmlFor="loaderColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('labelLoaderColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <br></br>
                                                                                        <div className="position-relative">
                                                                                            <input id="loaderColor"
                                                                                                value={loaderColor} readOnly
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={handleLoaderColorChange}
                                                                                                onBlur={onBlurCheck}
                                                                                                ref={loaderColorInputRef} ></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleLoaderColorClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>
                                                                                        {displayLoaderColorPicker ? <div style={popover}>
                                                                                            <div style={cover} onClick={handleLoaderColorClick} />
                                                                                            <SketchPicker color={loaderColor}
                                                                                                onChange={handleLoaderColorChange} disableAlpha={true} />
                                                                                        </div> : null}
                                                                                        {loaderColorError && loaderColorError.length > 0 &&
                                                                                            <p className="error-text">
                                                                                                {loaderColorError}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Footer Section*/}
                                                        <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                            <div className="accordion" id="accordionFooter">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                    <div className="accordion-item col-md-12">

                                                                        <h2 className="accordion-header" id="headingFooter">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseFooter" aria-expanded="true"
                                                                                aria-controls="collapseFooter">
                                                                                <span>
                                                                                    <i class="fas fa-shoe-prints"></i> {t('labelFooter')}
                                                                                </span>
                                                                            </button>
                                                                        </h2>

                                                                        <div id="collapseFooter"
                                                                            ref={collapseFooterRef}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby="headingFooter" data-bs-parent="#accordionFooter">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="bottomText"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('bottomLeftText')}</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            maxLength={100}
                                                                                            ref={bottomTextInputRef}
                                                                                            id="bottomText"
                                                                                            className="form-control form-control-sm" />

                                                                                    </div>
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="footerBackGroundColor"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('footerBackGroundColor')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <div className="position-relative">
                                                                                            <input value={footerBackGroundColor}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={footerBackGroundColorChangeHandler}
                                                                                                onBlur={onBlurCheck} readOnly
                                                                                                ref={footerBackGroundColorInputRef}></input>
                                                                                            <button type="button"
                                                                                                className="color-picker-btn btn gt-main-btn text-white"
                                                                                                onClick={handleFooterBackgroundClick}>
                                                                                                {t('pickColor')}
                                                                                            </button>
                                                                                        </div>

                                                                                        {displayFooterBackgroundColorPicker ?
                                                                                            <div style={popover}>
                                                                                                <div style={cover}
                                                                                                    onClick={handleFooterBackgroundClose} />
                                                                                                <SketchPicker
                                                                                                    color={footerBackGroundColor}
                                                                                                    onChange={footerBackGroundColorChangeHandler} disableAlpha={true} />
                                                                                            </div> : null
                                                                                        }

                                                                                        {blankFooterBackgroundColorError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankFooterBackGroundColorValidation')}
                                                                                            </p>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="control col-md-6 d-none">
                                                                                        <label htmlFor="bottomRightText"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('bottomRightText')}</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            maxLength={100}
                                                                                            ref={bottomRightTextInputRef}
                                                                                            id="bottomRightText"
                                                                                            className="form-control form-control-sm" />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="phone"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('contactUsPhone')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={8}
                                                                                            maxLength={16}
                                                                                            ref={contactUsPhoneInputRef}
                                                                                            id="phone"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm" />
                                                                                        {blankContactUsPhoneError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankPhoneError')}
                                                                                            </p>
                                                                                        }
                                                                                        {contactUsPhoneInputError &&
                                                                                            <p className="error-text">{t('invalidInput')}
                                                                                            </p>
                                                                                        }
                                                                                        {phoneLengthError &&
                                                                                            <p className="error-text">
                                                                                                {t('invalidPhoneLength')}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="control col-md-6">
                                                                                        <label htmlFor="mail"
                                                                                            className="mb-2 h6 text-primary col-lg-12">
                                                                                            {t('contactUsEmail')}<span className="error-icon"> *</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={contactUsEmailInputRef}
                                                                                            id="mail"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm" />
                                                                                        {blankBContactUsEmailError &&
                                                                                            <p className="error-text">
                                                                                                {t('blankEmailError')}
                                                                                            </p>
                                                                                        }
                                                                                        {invalidContactUsEmailError &&
                                                                                            <p className="error-text">
                                                                                                {t('invalidEmailId')}
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">
                                                                                        <div className="form-switch form-switch-sm padding-left-0">
                                                                                            <label htmlFor="activePartner"
                                                                                                className="mb-2 h6 text-primary col-lg-12">
                                                                                                {t('labelPartnerSlider')}
                                                                                            </label>
                                                                                            <input id="activePartner"
                                                                                                className="form-check-input ms-1"
                                                                                                type="checkbox"
                                                                                                onChange={activePartnerChangeHandler}
                                                                                                checked={activePartner}
                                                                                                value={activePartner}
                                                                                                ref={activePartnerInputRef}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Links Section*/}
                                                        <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                            <div className="accordion" id="accordionLinks">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                                    <div className="accordion-item col-md-12">
                                                                        <h2 className="accordion-header" id="headingLinks">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseLinks" aria-expanded="true"
                                                                                aria-controls="collapseLinks"> <span><i class="fas fa-link"></i>  {t('Links')}</span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseLinks"
                                                                            ref={collapseLinksRef}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby="headingLinks" data-bs-parent="#accordionLinks">
                                                                            <div className="col-md-12">

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName1InputRef}
                                                                                            id="LinkName1"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName1Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">
                                                                                        {/* <label htmlFor="URL1" className="mb-2 h6 text-primary col-lg-12 d-none d-sm-block">&nbsp;</label> */}
                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL1InputRef}
                                                                                            id="URL1"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL1Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName2InputRef}
                                                                                            id="LinkName2"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />

                                                                                        {blankLinkName2Error && <p className="error-text">{t('blankLink')}</p>}
                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL2InputRef}
                                                                                            id="URL2"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL2Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName3InputRef}
                                                                                            id="LinkName3"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName3Error && <p className="error-text">{t('blankLink')}</p>}


                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL3InputRef}
                                                                                            id="URL3"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL3Error && <p className="error-text">{t('blankLinkValidation')}</p>}


                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName4InputRef}
                                                                                            id="LinkName4"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName4Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL4InputRef}
                                                                                            id="URL4"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL4Error && <p className="error-text">{t('blankLinkValidation')}</p>}


                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName5InputRef}
                                                                                            id="LinkName5"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName5Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL5InputRef}
                                                                                            id="URL5"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL5Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName6InputRef}
                                                                                            id="LinkName6"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName6Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL6InputRef}
                                                                                            id="URL6"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL6Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName7InputRef}
                                                                                            id="LinkName7"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName7Error && <p className="error-text">{t('blankLink')}</p>}


                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL7InputRef}
                                                                                            id="URL7"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL7Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName8InputRef}
                                                                                            id="LinkName8"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName8Error && <p className="error-text">{t('blankLink')}</p>}


                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL8InputRef}
                                                                                            id="URL8"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />

                                                                                        {blankLinkNameURL8Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName9InputRef}
                                                                                            id="LinkName9"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName9Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL9InputRef}
                                                                                            id="URL9"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL9Error && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={linkName10InputRef}
                                                                                            id="LinkName10"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankLinkName10Error && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={linkNameURL10InputRef}
                                                                                            id="URL10"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankLinkNameURL10Error && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Social Media Links Section*/}
                                                        <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                            <div className="accordion" id="accordionSocialLinks">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                                    <div className="accordion-item col-md-12">
                                                                        <h2 className="accordion-header" id="headingSocialLinks">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseSocialLinks" aria-expanded="true"
                                                                                aria-controls="collapseSocialLinks">
                                                                                <span><i class="fas fa-external-link-alt"></i>  {t('socialMediaLinks')}</span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseSocialLinks"
                                                                            ref={collapseSocialLinksRef}
                                                                            className="accordion-collapse collapse "
                                                                            aria-labelledby="headingSocialLinks" data-bs-parent="#accordionSocialLinks">
                                                                            <div className="col-md-12">

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">
                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={rightLinkName1InputRef}
                                                                                            id="socialMediaLink1"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />

                                                                                        {blankSocialMediaLinkNameError1 && <p className="error-text">{t('blankLink')}</p>}
                                                                                    </div>
                                                                                    <div className="control col-md-9">
                                                                                        {/* <label htmlFor="socialMediaURL1" className="mb-2 h6 text-primary col-lg-12 d-none d-sm-block">&nbsp;</label> */}
                                                                                        <input
                                                                                            type="text"
                                                                                            ref={rightLinkNameURL1InputRef}
                                                                                            id="socialMediaURL1"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankSocialMediaLinkUrlError1 && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={rightLinkName2InputRef}
                                                                                            id="socialMediaLink2"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankSocialMediaLinkNameError2 && <p className="error-text">{t('blankLink')}</p>}
                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={rightLinkNameURL2InputRef}
                                                                                            id="SocialMediaURL2"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankSocialMediaLinkUrlError2 && <p className="error-text">{t('blankLinkValidation')}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={rightLinkName3InputRef}
                                                                                            id="socialMediaLink3"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankSocialMediaLinkNameError3 && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={rightLinkNameURL3InputRef}
                                                                                            id="SocialMediaURL3"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankSocialMediaLinkUrlError3 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={rightLinkName4InputRef}
                                                                                            id="socialMediaLink4"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankSocialMediaLinkNameError4 && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={rightLinkNameURL4InputRef}
                                                                                            id="SocialMediaURL4"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankSocialMediaLinkUrlError4 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="control col-md-3">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={20}
                                                                                            ref={rightLinkName5InputRef}
                                                                                            id="socialMediaLink5"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="Link Name" />
                                                                                        {blankSocialMediaLinkNameError5 && <p className="error-text">{t('blankLink')}</p>}

                                                                                    </div>
                                                                                    <div className="control col-md-9">

                                                                                        <input
                                                                                            type="text"
                                                                                            minLength={1}
                                                                                            maxLength={100}
                                                                                            ref={rightLinkNameURL5InputRef}
                                                                                            id="SocialMediaURL5"
                                                                                            onBlur={onBlurCheck}
                                                                                            className="form-control form-control-sm"
                                                                                            placeholder="URL" />
                                                                                        {blankSocialMediaLinkUrlError5 && <p className="error-text">{t('blankLinkValidation')}</p>}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {allowLoginCustomization &&
                                                            <Fragment>
                                                                {/*Login Page Section*/}
                                                                <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                                    <div className="accordion" id="accordionLoginPage">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                            <div className="accordion-item col-md-12">

                                                                                <h2 className="accordion-header" id="headingLoginPage">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseLoginPage" aria-expanded="true"
                                                                                        aria-controls="collapseLoginPage">
                                                                                        <span>
                                                                                            <i class="fas fa-sign-in-alt me-1"></i>
                                                                                            {t('labelLoginPage')}
                                                                                        </span>
                                                                                    </button>
                                                                                </h2>

                                                                                <div id="collapseLoginPage"
                                                                                    ref={collapseLoginPageRef}
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingLoginPage" data-bs-parent="#accordionLoginPage">
                                                                                    <div className="col-md-12">
                                                                                        <div className="row gt-scroll-marker">
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="loginPageCompanyLogo"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('companyLogo')}<span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <span className="note">{t('logoImageFormat')}</span>
                                                                                                <input type="file"
                                                                                                    id="loginPageCompanyLogo"
                                                                                                    className="form-control form-control-sm"
                                                                                                    onChange={handleLoginPageCompanyLogoChange}
                                                                                                    accept=".jpg,.jpeg,.png"
                                                                                                    ref={loginPageCompanyLogoInputRef}>
                                                                                                </input>
                                                                                                {loginPageCompanyLogo.length > 0 &&
                                                                                                    <span>
                                                                                                        <div className="mt-2">
                                                                                                            <img className="border-blue-img gt-customize-h-55"
                                                                                                                src={loginPageCompanyLogo}
                                                                                                                alt="" />
                                                                                                        </div>
                                                                                                    </span>
                                                                                                }

                                                                                                {loginPageCompanyLogoError &&
                                                                                                    loginPageCompanyLogoError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageCompanyLogoError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="loginPageBgImage"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelLoginPageBgImage')}<span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <span className="note">{t('noteLoginPageBgImage')}</span>
                                                                                                <input type="file"
                                                                                                    id="loginPageBgImage"
                                                                                                    className="form-control form-control-sm"
                                                                                                    onChange={handleLoginPageBgImageChange}
                                                                                                    accept=".jpg,.jpeg,.png"
                                                                                                    ref={loginPageBgImageInputRef}>
                                                                                                </input>
                                                                                                {loginPageBgImage.length > 0 &&
                                                                                                    <span>

                                                                                                        <div className="mt-2">
                                                                                                            <img className="border-blue-img gt-customize-h-55"
                                                                                                                src={loginPageBgImage}
                                                                                                                alt="" />
                                                                                                        </div>
                                                                                                    </span>
                                                                                                }

                                                                                                {loginPageBgImageError &&
                                                                                                    loginPageBgImageError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageBgImageError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="control col-md-6">
                                                                                                <label htmlFor="loginPageName"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelLoginPageName')}<span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    minLength={1}
                                                                                                    maxLength={50}
                                                                                                    value={loginPageName}
                                                                                                    ref={loginPageNameInputRef}
                                                                                                    onChange={handlePageNameChange}
                                                                                                    onBlur={onBlurCheck}
                                                                                                    id="loginPageName"
                                                                                                    className="form-control form-control-sm" />

                                                                                                {loginPageNameError &&
                                                                                                    loginPageNameError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageNameError}
                                                                                                    </p>
                                                                                                }

                                                                                                {generatedLoginUrl && generatedLoginUrl.length > 0 &&
                                                                                                    <div className="fw-bold text-14px row mt-1"><div className="text-black col-md-12 col-12">{t('generatedLoginUrlNote')}</div><div className="col-md-12 col-12"><a href={generatedLoginUrl} className="text-sky-blue">{generatedLoginUrl}</a> </div>  </div>
                                                                                                }

                                                                                            </div>
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="loginPageForgotPwdBttnBgColor"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelLoginPageForgotPwdBttnBgColor')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="loginPageForgotPwdBttnBgColor"
                                                                                                        value={loginPageForgotPwdBttnBgColor}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleLoginPageForgotPwdBttnBgColorChange}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={loginPageForgotPwdBttnBgColorInputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleLoginPageForgotPwdBttnBgColorClick}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayLoginPageForgotPwdBttnBgColorPicker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover} onClick={handleLoginPageForgotPwdBttnBgColorClick} />
                                                                                                        <SketchPicker color={loginPageForgotPwdBttnBgColor}
                                                                                                            onChange={handleLoginPageForgotPwdBttnBgColorChange} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {loginPageForgotPwdBttnBgColorError && loginPageForgotPwdBttnBgColorError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageForgotPwdBttnBgColorError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="loginPageBttnGradientColor1"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelLoginPageBttnGradientColor1')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="loginPageBttnGradientColor1"
                                                                                                        value={loginPageBttnGradientColor1}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleLoginPageBttnGradientColor1Change}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={loginPageBttnGradientColor1InputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleLoginPageBttnGradientColor1Click}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayLoginPageBttnGradientColor1Picker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover} onClick={handleLoginPageBttnGradientColor1Click} />
                                                                                                        <SketchPicker color={loginPageBttnGradientColor1}
                                                                                                            onChange={handleLoginPageBttnGradientColor1Change} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {loginPageBttnGradientColor1Error && loginPageBttnGradientColor1Error.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageBttnGradientColor1Error}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="loginPageBttnGradientColor2"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelLoginPageBttnGradientColor2')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="loginPageBttnGradientColor2"
                                                                                                        value={loginPageBttnGradientColor2}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handleLoginPageBttnGradientColor2Change}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={loginPageBttnGradientColor2InputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleLoginPageBttnGradientColor2Click}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayLoginPageBttnGradientColor2Picker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover} onClick={handleLoginPageBttnGradientColor2Click} />
                                                                                                        <SketchPicker color={loginPageBttnGradientColor2}
                                                                                                            onChange={handleLoginPageBttnGradientColor2Change} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {loginPageBttnGradientColor2Error && loginPageBttnGradientColor2Error.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {loginPageBttnGradientColor2Error}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*Session Out Page Section*/}
                                                                <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                                    <div className="accordion" id="accordionSessionOutPage">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                            <div className="accordion-item col-md-12">

                                                                                <h2 className="accordion-header" id="headingSessionOutPage">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseSessionOutPage" aria-expanded="true"
                                                                                        aria-controls="collapseSessionOutPage">
                                                                                        <span>
                                                                                            <i class="fas fa-hourglass-end me-1"></i>
                                                                                            {t('labelSessionOutPage')}
                                                                                        </span>
                                                                                    </button>
                                                                                </h2>

                                                                                <div id="collapseSessionOutPage"
                                                                                    ref={collapseSessionOutPageRef}
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingSessionOutPage" data-bs-parent="#accordionSessionOutPage">
                                                                                    <div className="col-md-12">
                                                                                        <div className="row">
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="sessionOutImage"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelSessionOutImage')}<span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <span className="note">{t('noteSessionOutImage')}</span>
                                                                                                <input type="file"
                                                                                                    id="sessionOutImage"
                                                                                                    className="form-control form-control-sm"
                                                                                                    onChange={handleSessionOutImageChange}
                                                                                                    accept=".jpg,.jpeg,.png"
                                                                                                    ref={sessionOutImageInputRef}>
                                                                                                </input>
                                                                                                {sessionOutImage.length > 0 &&
                                                                                                    <span key={generateUUID(10)}>
                                                                                                        <div className="mt-2">
                                                                                                            <img className="border-blue-img gt-customize-h-55"
                                                                                                                src={sessionOutImage}
                                                                                                                alt="" />
                                                                                                        </div>
                                                                                                    </span>
                                                                                                }

                                                                                                {sessionOutImageError &&
                                                                                                    sessionOutImageError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {sessionOutImageError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*Forgot Password Page Section*/}
                                                                <div className="tour_details_boxed_inner mobile-toggle-btn">
                                                                    <div className="accordion" id="accordionForgotPasswordPage">
                                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">
                                                                            <div className="accordion-item col-md-12">

                                                                                <h2 className="accordion-header" id="headingForgotPasswordPage">
                                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseForgotPasswordPage" aria-expanded="true"
                                                                                        aria-controls="collapseForgotPasswordPage">
                                                                                        <span>
                                                                                            <i class="fas fa-unlock me-1"></i>
                                                                                            {t('labelForgotPwdPage')}
                                                                                        </span>
                                                                                    </button>
                                                                                </h2>

                                                                                <div id="collapseForgotPasswordPage"
                                                                                    ref={collapseForgotPasswordPageRef}
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingForgotPasswordPage" data-bs-parent="#accordionForgotPasswordPage">
                                                                                    <div className="col-md-12">
                                                                                        <div className="row">
                                                                                            <div className='control col-md-6'>
                                                                                                <label htmlFor="forgotPwdPageLblColor"
                                                                                                    className="mb-2 h6 text-primary col-lg-12">
                                                                                                    {t('labelForgotPwdLblColor')} <span className="error-icon"> *</span>
                                                                                                </label>
                                                                                                <br></br>
                                                                                                <div className="position-relative">
                                                                                                    <input id="forgotPwdPageLblColor"
                                                                                                        value={forgotPwdPageLblColor}
                                                                                                        readOnly
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={handledForgotPwdPageLblColorChange}
                                                                                                        onBlur={onBlurCheck}
                                                                                                        ref={forgotPwdPageLblColorInputRef} ></input>
                                                                                                    <button type="button"
                                                                                                        className="color-picker-btn btn gt-main-btn text-white"
                                                                                                        onClick={handleForgotPwdPageLblColorClick}>
                                                                                                        {t('pickColor')}
                                                                                                    </button>
                                                                                                </div>
                                                                                                {displayForgotPwdPageLblColorPicker ?
                                                                                                    <div style={popover}>
                                                                                                        <div style={cover} onClick={handleForgotPwdPageLblColorClick} />
                                                                                                        <SketchPicker color={forgotPwdPageLblColor}
                                                                                                            onChange={handledForgotPwdPageLblColorChange} disableAlpha={true} />
                                                                                                    </div> : null
                                                                                                }

                                                                                                {forgotPwdPageLblColorError && forgotPwdPageLblColorError.length > 0 &&
                                                                                                    <p className="error-text">
                                                                                                        {forgotPwdPageLblColorError}
                                                                                                    </p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }

                                                        <div className="tour_details_boxed_inner mobile-toggle-btn d-none">
                                                            <div className="accordion" id="accordionExampleNine">
                                                                <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                                    <div className="accordion-item col-md-12">
                                                                        <h2 className="accordion-header" id="headingNine">
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseNineCustomization" aria-expanded="true"
                                                                                aria-controls="collapseNineCustomization"> <span><i class="fas fa-heading"></i>   Customize Login
                                                                                </span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseNineCustomization" className="accordion-collapse collapse "
                                                                            aria-labelledby="headingNine" data-bs-parent="#accordionExampleNine">
                                                                            <div className="col-md-12">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="control col-md-9 d-none">
                                                                <div className="form-switch form-switch-sm padding-left-0">
                                                                    <label htmlFor="activeGDPR" className="mb-2 h6 text-primary col-lg-12">Show PCI DSS</label>
                                                                    <input id="activeGDPR"
                                                                        className="form-check-input ms-1"
                                                                        type="checkbox"
                                                                        onChange={activeGDPRChangeHandler}
                                                                        checked={activeGDPR}
                                                                        value={activeGDPR}
                                                                        ref={activeGDPRInputRef}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        <div className="row my-2">
                                                            <span className="h6 text-primary col-lg-12"> <span className="text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span>  After saving the record, please login again to see the changes.</span>
                                                        </div>
                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto"></div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    {(checkSecurityDetails("Settings", "Customize", "Settings_Customize_Update")) && <div>
                                                                        <button id='btnSave' type="button" onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>

                                                                        <button id='btnReset' type="button" onClick={setToDefault} className='btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1'>{t('setToDefault')}</button>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>


};
export default CustomizationForm;