import { Fragment } from 'react';

const BillingDetails = (props) => {
    return <Fragment>
        <div className="">
            <h5 className="accordion-header text-sky-blue fw-bold my-2" id="headingThree" style={{ color: " #38c1f5" }}>
                Payment
            </h5>
            <div className='col-md-12'>
                <div className='row' style={{ fontSize: "18px", fontWeight: '500' }}>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                        <div className='h6 print-primary-title' style={{ color: "#12688B", }}> Payment Mode</div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                        <div className='h6'>
                            <div className="h6 mb-2">{props.CarBookingJSON.data.carResults[0].vehRentalCore.paymentInfo.paymentMode[0].name === "CREDITCARD" ? "Credit Card" : props.CarBookingJSON.data.carResults[0].vehRentalCore.paymentInfo.paymentMode[0].name === "WALLET" ? "Wallet" : "Profile Credit Card "}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default BillingDetails;