import { Fragment, useEffect } from 'react';
import { getFormatDate, getFormatTime, numberWithCommas } from '../../../../utils/CommonFunction';
import useHttp from '../../../../services/use-http';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';
import { retrieveCar } from '../../../../services/Car-API';

const CarBookingItem = (props) => {

    const { sendRequest,
        data: loadedRetrieveTripDetails,
        error,
        status } = useHttp(retrieveCar);

    function openRetrieveBooking(event) {
        event.preventDefault();
        sendRequest({
            officeID: JSON.parse(localStorage.getItem('officeID')),
            referenceNumber: props.reportJSON.confirmationNumber,
            traceID: JSON.parse(localStorage.getItem('traceID'))
        });
    }

    function getRentalLength() {
        let rentalLength = '';
        let rentalHours = props.reportJSON.rentalHours ? parseInt(props.reportJSON.rentalHours) : 0;
        let rentalDays = props.reportJSON.rentalDays ? parseInt(props.reportJSON.rentalDays) : 0;
        if (rentalHours > 0) {
            if (rentalHours > 1) {
                rentalLength = rentalHours + ' hours';
            }
            else {
                rentalLength = rentalHours + ' hour';
            }
        }
        else {
            if (rentalDays > 1) {
                rentalLength = rentalDays + ' days';
            }
            else {
                rentalLength = rentalDays + ' day';
            }
        }

        return rentalLength;
    }

    useEffect(() => {
        if (status === 'completed') {
            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {

                sessionStorage.setItem("CarBookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCarCancelled",
                    loadedRetrieveTripDetails.data.carResults[0].isCanceled !== undefined ?
                        loadedRetrieveTripDetails.data.carResults[0].isCanceled : false);
                sessionStorage.removeItem("sourcePNR");
                
                window.open('/RetrieveCar?report=true', 'ViewTrip' + props.reportJSON.confirmationNumber);
            }
        }
    }, [status, loadedRetrieveTripDetails])

    let resultCurrRoundingValue = props.reportJSON.roundingValue !== undefined &&
        props.reportJSON.roundingValue !== null &&
        props.reportJSON.roundingValue !== '' ?
        props.reportJSON.roundingValue : 2;

    return <Fragment>
        <tr>
            <td align={'right'}>{props.reportJSON.sequenceNo}</td>
            <td>
                <a className='cursor-pointer d-flex'
                    href="/#"
                    onClick={openRetrieveBooking}>
                    {status === 'pending' ?
                        <i className="fa fa-spinner fa-spin" ></i> :
                        <>
                            <div className='min-width-75'>
                                {props.reportJSON.confirmationNumber} &nbsp;
                            </div>
                            <i className="fas fa-external-link-alt line-height-22px"></i>
                        </>
                    }
                </a>
            </td>
            <td>
                {getFormatDate(props.reportJSON.transactionDate.split('T')[0].replaceAll('-', ''))} {props.reportJSON.transactionDate.split('T')[1] !== undefined ? getFormatTime(props.reportJSON.transactionDate.split('T')[1].replaceAll(':', '').substring(0, 4)) : ''}
            </td>
            <td>{props.reportJSON.companyName}</td>
            <td>{props.reportJSON.vendorName}</td>
            <td>{props.reportJSON.vehCategory}</td>
            <td>{props.reportJSON.pickUpAddress}</td>
            <td>{props.reportJSON.dropOffAddress}</td>
            <td>{props.reportJSON.driverDetails}</td>
            <td>{getRentalLength()}</td>
            <td>{getFormatDate(props.reportJSON.pickUpDate)}</td>
            <td>{getFormatDate(props.reportJSON.dropOffDate)}</td>
            {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                <td>{props.reportJSON.source}</td>
            }
            <td>{props.reportJSON.pnr}</td>
            <td>{props.reportJSON.carConfirmationNumber}</td>
            <td>{props.reportJSON.status}</td>
            <td align={'center'}>{props.reportJSON.currencyCode}</td>
            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                <td align={'right'}>
                    {props.reportJSON.serviceFee !== '' &&
                        props.reportJSON.hideData !== true ?
                        numberWithCommas(parseFloat(props.reportJSON.serviceFee).toFixed(parseFloat(props.reportJSON.serviceFee) > 0 ?
                            resultCurrRoundingValue : 2)) : ''}
                </td>
            }
            <td align={'right'}>
                {props.reportJSON.txnCharges !== '' &&
                    props.reportJSON.hideData !== true ?
                    numberWithCommas(parseFloat(props.reportJSON.txnCharges).toFixed(parseFloat(props.reportJSON.txnCharges) > 0 ?
                        resultCurrRoundingValue : 2)) : ''}
            </td>
            <td align={'right'}>{props.reportJSON.totalRate !== '' &&
                props.reportJSON.hideData !== true ?
                numberWithCommas(parseFloat(props.reportJSON.totalRate).toFixed(parseFloat(props.reportJSON.totalRate) > 0 ?
                    resultCurrRoundingValue : 2)) : ''}
            </td>
            {getConfigurationByBoolen("ALLOW_COMISSION") &&
                <td align={'right'}>
                    {props.reportJSON.commission !== '' &&
                        props.reportJSON.hideData !== true ?
                        numberWithCommas(parseFloat(props.reportJSON.commission).toFixed(parseFloat(props.reportJSON.commission) > 0 ?
                            resultCurrRoundingValue : 2)) : ''}
                </td>
            }
            <td>{props.reportJSON.formOfPayment}</td>
            <td>{props.reportJSON.bookedBy}</td>
        </tr>
    </Fragment>
};

export default CarBookingItem;