import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { addWallet, searchhWallet, updateWallet } from "../../../services/Wallet-Api";
import useHttpGet from '../../../services/use-http'
import useHttp from '../../../services/use-http'
import LoadingSpinnerWithCard from "../../../components/Common/Loading/LoadingSpinnerWithCard";
import WalletForm from './Components/WalletForm'
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { validateEmail } from "../../../utils/Validation";

const UpdateWallet = (props) => {
    useDocumentTitle('Wallet')
    const { t } = useTranslation(['translation.Wallet']);
    const [isEdit, setEditState] = useState(false);
    const parms = useParams();
    console.log(parms)
    const [error1, setError] = useState();
    const { OfficeID, Action, Id } = parms;
    const [confirm, setConfirm] = useState();

    let varHttp;

    if (Id === undefined || Id === '0') {
        varHttp = addWallet
    } else {
        console.log("B")
        varHttp = updateWallet
    }
    const { sendRequest: sendRequestWallet, data: loadedWalletDetails, error } = useHttpGet(searchhWallet);
    const { sendRequest, status, data: loadedQuote } = useHttp(varHttp);
    const history = useHistory();
    const sucsssMessage = (Id !== undefined || Id !== 0) ? t('recordSaveUpdateMessage') : t('recordSaveUpdateMessage')

    if (Action === '0') {
        sessionStorage.setItem('isEditable', Action);
    } else if (Action === '1') {
        sessionStorage.setItem('isEditable', Action);
    } else {
        sessionStorage.setItem('isEditable', '100');
    }
    useEffect(() => {
        if (isEdit || Id === undefined || Id === '0') {
            if (status === 'completed') {
                if (loadedQuote && loadedQuote['errors'].status === 'TRUE') {
                    if (loadedQuote['errors'].error['code'] === '1016') {
                        setError({
                            title: "Message",
                            message: 'Wallet can not be in negative.',
                        });


                    }
                    else if (loadedQuote['errors'].error['code'] === '1017') {
                        setError({
                            title: "Message",
                            message: `${loadedQuote['errors'].error['description']}`,
                        });
                    }
                    else if (loadedQuote['errors'].error['code'] === '1009') {
                        setError({
                            title: "Message",
                            message: `${t('errorDuplicateRecordSave')}`,
                        });
                    }
                    else {
                        setError({
                            title: "Message",
                            message: `${t('errorRecordSave')}`,
                        });
                    }
                }
                else {
                    setError({
                        title: "Message",
                        message: `${sucsssMessage}`,
                    });
                }
                return;
            }
        }
        else {
            setEditState(true);
        }
    }, [status, history, Id, isEdit, sucsssMessage, loadedQuote, t])

    useEffect(() => {

        if (Id !== undefined || Id === '0') {
            sendRequestWallet({
                officeID: OfficeID,
                parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            })
        }
    }, [sendRequestWallet, Id]);

    useEffect(() => {
        window.scrollTo({
            top: 10,
            behavior: "smooth",
        });
    }, []);

    if (error) {
        return <p className='centered focused'>{t('errorRecordSave')}</p>
    }
    let loadedData = [];
    for (let index in loadedWalletDetails) {
        if (loadedWalletDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedWalletDetails[index];
            }
        }
    }
    const addWalletHandler = (requestData) => {
        const mail = requestData.thresholdEmail;
        console.log(mail);
        if (mail === undefined || mail === '') {
            sendRequest(requestData);
        } else {
            let mailArray = mail.split(',')
            console.log(mailArray);
            for (let email of mailArray) {
                console.log(validateEmail(email))
                if (validateEmail(email.trim()) === null) {
                    console.log("return")

                    return;
                }
            }
            sendRequest(requestData);
        }


    };
    console.log(loadedQuote);
    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE' || loadedQuote['errors'].error['code'] === '1017') {
            history.push('/SearchWallet');
        }


    };
    if ((Id !== undefined || Id === '0') && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    function okHandler() {


        // addWalletHandler({
        //     id: loadedQuote['data'].id,
        //     amount: loadedQuote['data'].oldAmount,
        //     transactionAmount: loadedQuote['data'].transactionAmount,
        //     currencyCode: loadedQuote['data'].currencyCode,
        //     service: 'MS',
        //     currencyName: '',
        //     isSubracted: loadedQuote['data'].isSubracted,
        //     traceID: JSON.parse(localStorage.getItem('traceID')),
        //     userID: JSON.parse(localStorage.getItem('userID')),
        //     officeID: JSON.parse(localStorage.getItem('officeID')),
        // })
        // props.addWalletHandler({
        //     id: props.loadedProfileDetails.id,
        //     traceID: JSON.parse(localStorage.getItem('traceID')),
        //     userID: JSON.parse(localStorage.getItem('userID')),
        //     officeID: props.loadedProfileDetails.officeID,
        //     currencyCode: currencyInputRef.current.value,
        //     currencyName: '',
        //     transactionAmount: updateWalletAmountInputRef.current.value !== '' ? updateWalletAmountInputRef.current.value : 0,
        //     remarks: remarksInputRef.current.value,
        //     thresholdAmount: thresholdInputRef.current.value,
        //     thresholdEmail: thresholdEmailInputRef.current.value,
        //     isSubracted: isSubtracted,
        //     //serverCreatedDate: ""
        // });
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };
    
    const WalletFormContent = ((Id !== undefined || Id === 0) && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <WalletForm loadedProfileDetails={loadedData} isLoading={status === 'pending'} onAddWallet={addWalletHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        {confirm && (
            <ConfirmationModal
                title={confirm.title}
                message={confirm.message}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}

        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {WalletFormContent}
    </Fragment>
};
export default UpdateWallet;