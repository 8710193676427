import { Fragment } from 'react';

const HomePageHotel = () => {
    return <Fragment>
        {/* /* <!-- Hotel Offer Area -->     /* <!-- imagination Area --> */}
        <section id="offer_area" className="section_padding_top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="offer_area_box  img_animation">
                            <img src="assets/img/offer/hotel33.png" alt="img" />
                            <div className="offer_area_content">
                                <h2 className="">Exclusive Hotel Benefits and Rates for Employees!</h2>
                                <p>Enjoy exclusive access to special hotel rates and benefits, available only to employees. Choose from over 60 million rooms across the globe, including top-rated hotels, resorts, and boutique properties. Whether you are traveling for business or leisure, you can book your stay at the best rates and enjoy additional perks along the way.</p>
                                {/* <a href="#" className="btn btn_theme btn_md">Explore More <i className="fas fa-long-arrow-alt-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="promotional_tours" class="section_padding_top pb-5 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="section_heading_center">
                            <h2>Go beyond your <span>imagination</span></h2>
                            <h5 className='mt-2'>Discover your ideal experience with us</h5>
                        </div>

                        <div className='row'>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/New-York-City/d687-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/newyork2.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/New-York-City/d687-ttd?mcid=58086&uid=U00556072" target="_blank">New York<span></span></a></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Sydney/d357-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/8.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Sydney/d357-ttd?mcid=58086&uid=U00556072" target="_blank">Sydney <span></span></a></h3>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Turkey/d70-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/9.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Turkey/d70-ttd?mcid=58086&uid=U00556072" target="_blank">Turkey <span></span></a></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Dubai/d828-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/6.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Dubai/d828-ttd?mcid=58086&uid=U00556072" target="_blank">Dubai <span></span></a></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Paris/d479-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/paris.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Paris/d479-ttd?mcid=58086&uid=U00556072" target="_blank">Paris<span> </span></a></h3>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Europe/d6-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/Europe.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Europe/d6-ttd?mcid=58086&uid=U00556072" target="_blank">Europe <span></span></a></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/Los-Angeles/d645-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/10.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/Los-Angeles/d645-ttd?mcid=58086&uid=U00556072" target="_blank">Los Angeles <span></span></a></h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <div className="imagination_boxed">
                                    <a href="https://www.viator.com/London/d737-ttd?mcid=58086&uid=U00556072" target="_blank">
                                        <img src="assets/img/imagination/7.png" alt="img" />
                                    </a>
                                    <h3 className='pb-2'><a href="https://www.viator.com/London/d737-ttd?mcid=58086&uid=U00556072" target="_blank">London <span></span></a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="go_beyond_area" className="section_padding_top">
            <div className="container">
                <div className="row align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="section_heading_center">
                                <h2>All-inclusive resorts</h2>
                                <h5 className='mt-2'>Experience paradise, all-inclusive and hassle-free</h5>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Cancun/6-Day-All-Inclusive-Tour-of-Cancun-and-Chichen-Itza/d631-262626P5?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/cancun.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Cancun/6-Day-All-Inclusive-Tour-of-Cancun-and-Chichen-Itza/d631-262626P5?mcid=58086&uid=U00556072" target="_blank">Cancun<span> </span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Palawan/3D2N-Luxury-Adventure-Hassle-Free-Seaplane-Transfer/d25945-348544P7?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/palawan.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Palawan/3D2N-Luxury-Adventure-Hassle-Free-Seaplane-Transfer/d25945-348544P7?mcid=58086&uid=U00556072" target="_blank">Palawan<span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Puerto-Princesa/5D4N-Luxury-Adventure-Hassle-Free-Seaplane-Transfer/d25948-348544P9?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/Puerto.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Puerto-Princesa/5D4N-Luxury-Adventure-Hassle-Free-Seaplane-Transfer/d25948-348544P9?mcid=58086&uid=U00556072" target="_blank">Puerto Princesa <span></span></a></h3>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Costa-Maya/VIP-Beach-Break-All-Inclusive/d4345-314649P1?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/Costa.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Costa-Maya/VIP-Beach-Break-All-Inclusive/d4345-314649P1?mcid=58086&uid=U00556072" target="_blank">Costa Maya <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Montego-Bay/5-Day-Mini-Vacation-Package-at-Jewel-Grande-Montego-Bay-Jamaica/d432-14302P385?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/motengo.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Montego-Bay/5-Day-Mini-Vacation-Package-at-Jewel-Grande-Montego-Bay-Jamaica/d432-14302P385?mcid=58086&uid=U00556072" target="_blank">Montego Bay <span></span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Pretoria/4-days-Sun-City-and-Pilanesberg-Game-Reserve-Experience/d22819-243569P17?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/Pretoria.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Pretoria/4-days-Sun-City-and-Pilanesberg-Game-Reserve-Experience/d22819-243569P17?mcid=58086&uid=U00556072" target="_blank">Pretoria<span> </span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Seoul/Escape-Seoul-All-Inclusive-3D2N-Alpensia-Ski-Resort-Pax-Up-To-3/d973-5488046P12?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/seoul.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Seoul/Escape-Seoul-All-Inclusive-3D2N-Alpensia-Ski-Resort-Pax-Up-To-3/d973-5488046P12?mcid=58086&uid=U00556072" target="_blank">Seoul<span> </span></a></h3>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                        <div className="imagination_boxed">
                            <a href="https://www.viator.com/tours/Hanoi/Bakhan-Village-Resort-Treasures-3-Days-Trip/d351-10776P19?mcid=58086&uid=U00556072" target="_blank">
                                <img src="assets/img/imagination/honai.png" alt="img" />
                            </a>
                            <h3 className='pb-2'><a href="https://www.viator.com/tours/Hanoi/Bakhan-Village-Resort-Treasures-3-Days-Trip/d351-10776P19?mcid=58086&uid=U00556072" target="_blank">Hanoi<span> </span></a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>

};

export default HomePageHotel;