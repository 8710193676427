import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import { Fragment, useEffect, useRef, useState } from "react";
import { searchCarBookingApi } from "../../../../services/Report-API";
import useHttp from "../../../../services/use-http";
import Dropdown from 'react-select';
import { useTranslation } from "react-i18next";

const SearchCarBookingForm = (props) => {
    const { t } = useTranslation(['translation.CreditLimit']);

    const statusOption = [
        { value: "1", label: "All" },
        { value: "2", label: "Booked" },
        { value: "3", label: "Booking Request" },
        { value: "4", label: "Canceled" }
    ];

    let companyOptions = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: JSON.parse(localStorage.getItem('officeID')),
            label: JSON.parse(localStorage.getItem('companyName'))
        }
    ]

    const defaultCompanyValue = JSON.parse(localStorage.getItem('officeID'));
    const defaultCompanyLabel = JSON.parse(localStorage.getItem('companyName'))

    const [companyNameInput, setCompanyNameInput] = useState(defaultCompanyValue);
    const [companyNameLabelInput, setCompanyNameLabelInput] = useState(defaultCompanyLabel);

    const [bookingStatus, setBookingStatus] = useState(statusOption[0].value);
    const [bookingStatusLabel, setBookingStatusLabel] = useState(statusOption[0].label);
    const [dateFromInputHasError, setDateFromInputHasError] = useState(false);
    const [dateToInputHasError, setDateToInputHasError] = useState(false);
    const [dateFromInvalidInputError, setDateFromInvalidInputError] = useState(false);
    const [dateToInvalidInputError, setDateToInvalidInputError] = useState(false);

    const enteredDateFromInputRef = useRef('');
    const enteredDateToInputRef = useRef('');

    const [error, setError] = useState(null);
    const [triggerReset, setTriggerReset] = useState(false);

    const { sendRequest,
        status,
        data: loadedCarBooking } = useHttp(searchCarBookingApi);

    function handleDateBlur(event) {
        if (enteredDateFromInputRef.current.value !== '') {
            setDateFromInputHasError(false);
        }

        if (enteredDateToInputRef.current.value !== '') {
            setDateToInputHasError(false);
        }
    }

    function handleCompanyChange(event) {
        setCompanyNameInput(event.value)
        setCompanyNameLabelInput(event.label)
    }

    function handleStatusChange(event) {
        setBookingStatus(event.value);
        setBookingStatusLabel(event.label);
    }

    function handleResetClick(event) {
        event.preventDefault();
        props.onResetForm();
    }

    function handleSearchClick(event) {
        event.preventDefault();

        if (enteredDateFromInputRef.current.value === '') {
            setDateFromInputHasError(true);
            return;
        }
        else {
            setDateFromInputHasError(false);
        }

        if (enteredDateToInputRef.current.value === '') {
            setDateToInputHasError(true);
            return;
        }
        else {
            setDateToInputHasError(false);
        }

        var today = new Date().toISOString().split('T')[0];
        if (enteredDateFromInputRef.current.value > today) {
            setDateFromInvalidInputError(true);
            return;
        }
        else {
            setDateFromInvalidInputError(false);
        }

        if (enteredDateToInputRef.current.value > today) {
            setDateToInvalidInputError(true);
            return;
        }
        else {
            setDateToInvalidInputError(false);
        }
        if (enteredDateToInputRef.current.value < enteredDateFromInputRef.current.value) {
            setError({
                title: "Message",
                message: 'Date To cannot be less than Date From.',
            });
            return;
        }

        const searchInput = {
            officeID: companyNameInput === 'All' ? JSON.parse(localStorage.getItem('officeID')) : companyNameInput,
            officeName: companyNameLabelInput,
            userID: "",
            dateFrom: enteredDateFromInputRef.current.value,
            dateTo: enteredDateToInputRef.current.value,
            status: bookingStatusLabel
        };

        sendRequest(searchInput);
    }

    function handleErrorOKClick() {
        setError(null);
        if (triggerReset) {
            setTriggerReset(!triggerReset);
            props.onResetForm();
        }
    }

    useEffect(() => {
        if (loadedCarBooking) {
            if (loadedCarBooking.errors.status === 'TRUE') {
                setError({
                    title: "Message",
                    message: t('ErrorMessage'),
                });
                setTriggerReset(true);
            }
            else {

                let tempReportData = JSON.parse(JSON.stringify(loadedCarBooking));
                tempReportData.data[0].dateFrom = enteredDateFromInputRef.current.value;
                tempReportData.data[0].dateTo = enteredDateToInputRef.current.value;
                tempReportData.data[0].companyNameInput = companyNameLabelInput;
                tempReportData.data[0].statusInput = bookingStatusLabel;
                sessionStorage.setItem("reportsData", JSON.stringify(tempReportData));
                props.onSetReportData(tempReportData);
            }
        }

    }, [loadedCarBooking])

    useEffect(() => {
        var today = new Date().toISOString().split('T')[0];
        document.getElementById("DateFrom").setAttribute('max', today);
        document.getElementById("DateTo").setAttribute('max', today);
        document.getElementById("DateFrom").setAttribute('value', today);
        document.getElementById("DateTo").setAttribute('value', today);
    }, []);

    return <Fragment>
        {error &&
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={handleErrorOKClick}
            ></ErrorModal>
        }
        <section id="theme_search_form_tour"
            className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            {status === 'pending' &&
                                <div className=''>
                                    <div className=''>
                                        <LoadingSpinner></LoadingSpinner>
                                    </div>
                                </div>
                            }
                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="form-group col-md-12">
                                        <label htmlFor='code' className="h5 text-primary mb-3 col-lg-12">
                                            <i className="fas fa-globe-asia"></i> Car Booking
                                        </label>
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="mb-2 h6 text-primary col-lg-12"
                                                            htmlFor='UpdateAmount'>Date From<span className="error-icon"> *</span>
                                                        </label>
                                                        <input maxLength="10" type='date'
                                                            ref={enteredDateFromInputRef}
                                                            className="form-control form-control-sm"
                                                            id='DateFrom'
                                                            onBlur={handleDateBlur} />
                                                        {dateFromInputHasError &&
                                                            <p className="error-text">
                                                                {'Date From is required.'}
                                                            </p>
                                                        }
                                                        {dateFromInvalidInputError &&
                                                            <p className="error-text">
                                                                {'Date From cannot be greater than current date.'}
                                                            </p>
                                                        }

                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="mb-2 h6 text-primary col-lg-12"
                                                            htmlFor='UpdateAmount'>Date To<span className="error-icon"> *</span>
                                                        </label>
                                                        <input maxLength="10" type='date'
                                                            ref={enteredDateToInputRef}
                                                            className="form-control form-control-sm"
                                                            id='DateTo'
                                                            onBlur={handleDateBlur} />
                                                        {dateToInputHasError &&
                                                            <p className="error-text">
                                                                {'Date To is required.'}
                                                            </p>
                                                        }
                                                        {dateToInvalidInputError &&
                                                            <p className="error-text">
                                                                {'Date To cannot be greater than current date.'}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>

                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2" htmlFor='city'>Company Name</label>
                                                        <Dropdown onChange={handleCompanyChange}
                                                            value={companyOptions.filter(option => option.value === companyNameInput)}
                                                            options={companyOptions}
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='control'>
                                                        <label className="h6 text-primary mb-2" htmlFor='city'>Status</label>
                                                        <Dropdown onChange={handleStatusChange}
                                                            value={statusOption.filter(option => option.value === bookingStatus)}
                                                            options={statusOption}
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="mt-3">
                                                <span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span>
                                            </p>
                                            <div className='mt-3 col-md-12 actions'>
                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1'
                                                    onClick={handleResetClick}>
                                                    {t('reset')}
                                                </button>


                                                <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end'
                                                    onClick={handleSearchClick}>
                                                    {t('search')}
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section>
    </Fragment>;
};

export default SearchCarBookingForm;