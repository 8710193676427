import React from "react";

function SpinnerLoader({ className, size }) {
  return (
    <div className={`cstmSpinner ${className}`}>
      <i className={`fa fa-spinner fa-spin ${size}`}></i>
    </div>
  );
}

export default SpinnerLoader;
