import { Fragment } from 'react';
import Guest from './SubComponents/Guest'
import { getCustomizeDetails } from '../../../utils/CustomizeUtils';
const DriverDetails = (props) => {
    return <Fragment>
        <div className="">
            <h5 className="accordion-header text-sky-blue fw-bold mb-3" style={{ color: " #38c1f5" }} >
                Driver Details
            </h5>
            <div
                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <table className="table table-bordered sm-first-col-width mb-0">
                                <thead style={{ background: getCustomizeDetails('buttonColor') }}>
                                    <tr>
                                        <th className='text-white w-15' scope='col'>#</th>
                                        <th scope="col" className='text-white'>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.carResults.traveler.map((guestInfo) =>
                                        <Guest key={guestInfo.customerIdentifierNumber}
                                            id={guestInfo.customerIdentifierNumber}
                                            guestInfo={guestInfo} ></Guest>)
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </Fragment>

};

export default DriverDetails;