import { Fragment } from 'react';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const RateDetails = (props) => {
    let hidePlusIcon = props.show === 'show' ? "tour_details_boxed_inner fare-summary-pop plus-icon-hide ms-0" : "tour_details_boxed_inner fare-summary-pop";
    let hidems4 = props.show === 'show' ? "col-md-7 h6 my-auto " : "ms-4 col-md-7 h6 my-auto "
    let totalTax = 0;

    if (props.hotelBookingJSON.data.onlineHotelDetails[0].fees !== undefined) {
        totalTax = props.hotelBookingJSON.data.onlineHotelDetails[0].taxes + props.hotelBookingJSON.data.onlineHotelDetails[0].fees;
    }
    else {
        totalTax = props.hotelBookingJSON.data.onlineHotelDetails[0].taxes;
    }

    return <Fragment>
        <div className="accordion-item pb-0">
            {props.hidePrice === undefined && <h5 className="accordion-header text-sky-blue fw-bold mb-2" id="headingOne">
                Price
            </h5>}
            <div className="mt-0">
                {props.hidePrice !== undefined && <p className="card-text mb-0 mt-1 text-primary"><strong><i className="fas fa-hotel"></i> Hotel </strong></p>}
                <label className="form-check-label text-14px " for="flexCheckDefaultf1">
                    <span className="area_flex_one text-muted">
                        <span className="fw-bold h6">1 X {props.hotelBookingJSON.data.onlineHotelDetails[0].roomType ? props.hotelBookingJSON.data.onlineHotelDetails[0].roomType : 'Room'}</span>

                    </span>
                </label>

                <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-8 h6'>Base Rate</div>
                    <div className='col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.hotelBookingJSON.data.onlineHotelDetails[0].currencyCode
                        }).format(props.hotelBookingJSON.data.onlineHotelDetails[0].baseFare)}</div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-8 col-8 h6'>Taxes & Fees</div>
                    <div className='col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break'>
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.hotelBookingJSON.data.onlineHotelDetails[0].currencyCode
                        }).format(totalTax)}
                    </div>
                </div>



                {props.hotelBookingJSON.data.onlineHotelDetails[0].agentMarkUp !== undefined && props.hotelBookingJSON.data.onlineHotelDetails[0].agentMarkUp !== '' && props.hotelBookingJSON.data.onlineHotelDetails[0].agentMarkUp !== 0 && <div className='row mt-2'>
                    <div className={hidePlusIcon}>
                        <div className="accordion" id="accordionExample">

                            <div className="tour_details_boxed_inner fare-summary">
                                <div className="accordion" id="accordionExampleRateDetails">
                                    <div className="accordion_flex_area ">
                                        <div className="accordion-item fare-toggle-btn">
                                            <h2 className="accordion-header" id="headingthreee">
                                                <button className="accordion-button collapsed text-16px  " type="button" data-bs-toggle="collapse" data-bs-target="#collapselast" aria-expanded="false" aria-controls="collapselast">
                                                    <div className={hidems4}> Others  </div>
                                                    <div className='right-0 position-absolute h6'>
                                                        {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: props.hotelBookingJSON.data.onlineHotelDetails[0].currencyCode
                                                        }).format(props.hotelBookingJSON.data.onlineHotelDetails[0].agentMarkUp)}
                                                    </div>
                                                </button>

                                            </h2>
                                            <div id="collapselast" class={"accordion-collapse collapse" + props.show}
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExampleRateDetails">
                                                <div className="accordion-body">
                                                    <div className="accordion_itinerary_list">
                                                        <label className="form-check-label h6 " for="flexCheckDefaultf1">
                                                            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <span className="area_flex_one ">
                                                                <span>Service Fee</span>
                                                                <span className='h6'>    {new Intl.NumberFormat('en-US', {
                                                                    style: 'currency',
                                                                    currency: props.hotelBookingJSON.data.onlineHotelDetails[0].currencyCode
                                                                }).format(props.hotelBookingJSON.data.onlineHotelDetails[0].agentMarkUp)}</span>
                                                            </span>}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <hr className='my-2'></hr>
                <div className="row"><div className="col-lg-3 col-md-3 col-sm-3 col-3"><div className="h5 mb-1 fw-bold text-primary">Total Rate </div></div><div className="col-lg-9 col-md-9 col-sm-9 col-9"><div className="h5 mb-2 fw-bold text-primary  float-lg-end text-end">
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: props.hotelBookingJSON.data.onlineHotelDetails[0].currencyCode
                    }).format(props.hotelBookingJSON.data.onlineHotelDetails[0].totalRate)}</div></div></div>

            </div>

        </div>
    </Fragment>

};

export default RateDetails;