export const ROOT = "/";
export const LOGIN = "/login";
export const LOGINWEB = "/LoginWeb";
export const WEBSTAUS = "/WebStatusCheck";
export const MYTRIPS = "/MyTrips";
export const MYQUEUE = "/MyQueue";
export const RETRIEVETRIP = "/RetrieveTrip";
export const RETRIEVESHOPPINGCART = "/TripDetails"
export const RETRIEVEGDSTRIP = "/RetrieveGdsTrip";

export const CHANGEPASSWORD = "/change-password";
export const PASSWORDCHANGE = "/changepassword";
export const FORGOTPASSWORD = "/forgot-password";
export const UPDATEPASSWORD = "/updatepassword";
export const REGISTRATION = "/registration";
export const ALLPROFILEDETAILS = "/allprofiledetails";
export const NEWPROFILEDETAILS = "/newprofiledetails";
export const NEWPROFILEDETAILSWITHQUERYSTRING = "/newprofiledetails/:Id";
//Continent
export const CONTINENT = "/AllContinent";
export const NEWCONTINENT = "/NewContinent";
export const NEWCONTINENTWITHQUERYSTRING = "/NewContinent/:Id";
//Country
export const ALLCOUNTRY = "/AllCountry";
export const NEWCOUNTRY = "/NewCountry";
export const NEWCOUNTRYWITHQUERYSTRING = "/NewCountry/:Id";

//Flight
export const FLIGHTSEARCH = "/FlightSearch";
export const FLIGHTBOOK = "/FlightBook";
export const BOOKINGCONFIRMATION = "/BookingConfirmation";
export const FLIGHTDETAILS = "/FlightDetails";
export const MYTRIP = "/MyTrip";

// FlightRecommendation
export const FLIGHTRECOMMENDATION = "/FlightRecommendation";
export const FLIGHTRECOMMENDATIONS = "/FlightRecommendations";

//HotelRecommendation
export const HOTELRECOMMENDATION = "/HotelRecommendation";
export const ROOMRECOMMENDATION = "/RoomRecommendation";

//Hotel
export const HOTELSEARCH = '/HotelSearch';
export const HOTELBOOKING = '/HotelBooking';
export const SEARCHHOTEL = '/SearchHotel';
export const HOTELDETAIL = '/HotelDetail';
export const HOTELDETAILS = '/HotelDetails';
export const HOTELBOOKINGS = '/HotelBookings';
export const HOTELCONFIRMATION = '/HotelConfirmation';
export const HOTELCONFIRMATIONS = '/HotelConfirmations';
export const RETRIEVEHOTEL = '/RetrieveHotel';


//City
export const ALLCITY = "/AllCity";
export const NEWCITY = "/NewCity";
export const NEWCITYWITHQUERYSTRING = "/NewCity/:Id/:Action";

//Airport
export const ALLAIRPORT = "/AllAirport";
export const NEWAIRPORT = "/NewAirport";
export const NEWAIRPORTWITHQUERYSTRING = "/NewAirport/:Id/:Action";


//Manage Users
export const ALLUSER = "/AllUser";
export const NEWUSER = "/NewUser";
export const NEWUSERWITHQUERYSTRING = "/NewUser/:Id/:Action";

//Airline
export const ALLAIRLINE = "/AllAirline";
export const NEWAIRLINE = "/NewAirline";
export const NEWAIRLINEWITHQUERYSTRING = "/NewAirline/:Id/:Action";

//Office
export const SEARCHOFFICE = "/SearchOffice";
export const UPDATEOFFICE = "/UpdateOffice";
export const UPDATEOFFICEWITHQUERYSTRING = "/UpdateOffice/:Id/:Action";

//Fee
export const SEARCHFEE = "/SearchFee";
export const UPDATEFEE = "/UpdateFee";
export const UPDATEFEEWITHQUERYSTRING = "/UpdateFee/:Id";

//Blank Interface
export const NEWBLANK = "/Blank";
export const ERROR = "/Error";
export const SESSION = "/Session";
export const UNAUTHORIZED = "/Unauthorized";

//2FA 
export const AUTHORIZETWOFACTOR = '/MFA';
export const ENABLETWOFACTOR = '/EnableMFA';

//Role
export const SEARCHROLE = "/SearchRole";
export const UPDATEROLE = "/UpdateRole";
export const UPDATEROLEWITHQUERYSTRING = "/UpdateRole/:Id/:Action";

//Manage Customize
export const UPDATECUSTOMIZEWITHQUERYSTRING = '/UpdateCustomize/:id';
export const UPDATECUSTOMIZE = '/UpdateCustomize';

//Region
export const SEARCHREGION = "/SearchRegion";
export const UPDATEREGION = "/UpdateRegion";
export const UPDATEREGIONWITHQUERYSTRING = "/UpdateRegion/:Id/:Action";

//Credit Limit
export const SEARCHCREDITLIMIT = "/SearchCreditLimit";
export const UPDATECREDITLIMIT = "/UpdateCreditLimit";
export const UPDATECREDITLIMITWITHQUERYSTRING = "/UpdateCreditLimit/:Id";

//Manage Content
export const SEARCHCONTENT = "/SearchContent";
export const UPDATECONTENT = "/UpdateContent";
export const UPDATECONTENTWITHQUERYSTRING = "/UpdateContent/:Id/:Action";



//Manage Customization
export const SEARCHCONFIGURATION = "/SearchConfiguration";
export const UPDATECONFIGURATION = "/UpdateConfiguration";
export const UPDATECONFIGURATIONWITHQUERYSTRING = "/UpdateConfiguration/:OfficeID/:Action/:Id?";

//Email Server
export const UPDATEEMAILSERVERWITHQUERYSTRING = '/UpdateEmailSever/:id';
export const UPDATEEMAILSERVER = '/UpdateEmailServer';

export const BOOKING = '/Booking';
export const CONFIRMATION = '/Confirmation';
export const PRINTCONFIRMATION = '/PrintConfirmation';

export const SEARCHTRAVELAGENT = '/SearchTravelAgent';

//Reports
//Flight Booking
export const SEARCHFLIGHTBOOKING = '/SearchFlightBooking';
export const DISPLAYFLIGHTBOOKING = '/DisplayFlightBooking';

export const SEARCHCARBOOKING = '/SearchCarBooking';

//Sales Summary
export const SEARCHSALES = '/SearchSales';
export const DISPLAYSALES = '/DisplaySales';

//Sales Summary
export const PRIVACYPOLICY = '/PrivacyPolicy';
export const TERMSCONDITIONS = '/TermsConditions';

//Home and B2C
export const LAUNCHING = '/Launching';
export const AUTHENTICATION = '/Authentication';
export const VALIDATE = '/Validate';
//Hotel Booking
export const SEARCHHOTELBOOKING = '/SearchHotelBooking';
export const DISPLAYHOTELBOOKING = '/DisplayHotelBooking';

//Hotel Commission
export const SEARCHHOTELCOMMISSION = '/SearchHotelCommission';
export const DISPLAYHOTELCOMMISSION = '/DisplayHotelCommission';

//Service Request
export const SEARCH_SERVICE_REQUEST = '/SearchServiceRequest';

//Travel Deviation
export const SEARCH_TRAVEL_DEVIATION = '/SearchTravelDeviation';

//Who is Where
export const SEARCH_WHO_IS_WHERE = '/SearchWhoIsWhere';

//Cryptic 
export const CRYPTIC = '/SabreCommand';



//DashBoard 
export const DASHBOARD = '/DashBoard';

//Import Flight 
export const IMPORTPNR = '/ImportPNR';
export const SEARCHPNR = '/SearchPNR';

//Fees
export const SEARCHFEES = "/SearchFees";
export const UPDATEFEES = "/UpdateFees";
export const UPDATEFEESWITHQUERYSTRING = "/UpdateFees/:Id/:Action";

// Single PNR Booking
export const SINGLEPNRBOOKING = '/Booking/Booking';
export const SINGLEPNRCONFIRMATION = '/Confirmation/Confirmation';

//User Friendly
export const SEARCHUSERFRIENDLY = "/SearchUserFriendly";
export const UPDATEUSERFRIENDLY = "/UpdateUserFriendly";
export const UPDATEUSERFRIENDLYWITHQUERYSTRING = "/UpdateUserFriendly/:Id/:Action";

export const LOADING = '/Loading'

//Wallet Process
export const SEARCHWALLET = "/SearchWallet";
export const UPDATEWALLET = "/UpdateWallet";
export const UPDATEWALLETWITHQUERYSTRING = "/UpdateWallet/:OfficeID/:Action/:Id?";

//Wallet Utilization Report
export const SEARCHWALLETUTILIZATION = '/SearchWalletUtilization';
export const DisplayWalletUtilization = '/DisplayWalletUtilization';

//Manage Rate Plan Allowed. 
export const SEARCHRATEPLANALLOWED = "/SearchRatePlanAllowed";
export const UPDATERATEPLANALLOWED = "/UpdateRatePlanAllowed";
export const UPDATERATEPLANALLOWEDWITHQUERYSTRING = "/UpdateRatePlanAllowed/:OfficeID/:CompanyName/:Action/:Id";

//Rate Plan
export const SEARCHRATEPLAN = "/SearchRatePlan";
export const UPDATERATEPLAN = "/UpdateRatePlan";
export const UPDATERATEPLANWITHQUERYSTRING = "/UpdateRatePlan/:Action/:Id";

//Sightseeing
export const SEARCHSIGHTSEEING = '/SearchSightseeing';
export const SIGHTSEEINGDETAILS = '/SightseeingDetails';
export const SIGHTSEEINGDETAILSTAB = '/SightseeingDetailsTab';

//Holidays
export const SEARCHHOLIDAYS = '/SearchHolidays';

// home
export const HOMEPAGE = '/HomePage';
export const HOMEPAGETEMP = '/HomePageTemp';

// Trip Search Reason
export const SEARCHTRIPREASON = '/SearchTripReason';
export const UPDATETRIPREASON = '/UpdateTripReason';
export const UPDATETRIPREASONWITHQUERYSTRING = '/UpdateTripReason/:Action/:Id';

//Travel Request
export const TRAVELREQUEST = '/TravelRequest';

// //ROE
// export const SEARCHROE = "/SearchRoe";
// export const UPDATEROE = "/UpdateRoe";
// export const UPDATEROEWITHQUERYSTRING = "/UpdateRoe/:Id/:Action";

//Rules
export const SEARCHRULES = "/SearchPolicy";
export const UPDATERULES = "/UpdatePolicy";
export const UPDATERULESWITHQUERYSTRING = "/UpdatePolicy/:Id/:Action";

// export const SEARCHRULES = "/SearchRules";
// export const UPDATERULES = "/UpdateRules";
// export const UPDATERULESWITHQUERYSTRING = "/UpdateRules/:Id/:Action";

export const TRAVELREQUESTEMAIL = "/TravelRequestEmail";

//Department
export const SEARCHDEPARTMENT = "/SearchDepartment";
export const UPDATEDEPARTMENT = "/Departmentnew";
export const UPDATEDEPARTMENTWITHQUERYSTRING = "/UpdateDepartment/:Id/:Action";

//Rules
export const SEARCHCOSTCENTER = "/SearchCostCenter";
export const UPDATECOSTCENTER = "/CostCenternew";
export const UPDATECOSTCENTERWITHQUERYSTRING = "/UpdateCostCenter/:Id/:Action";

//Position
export const SEARCHPOSITION = "/SearchPosition";
export const UPDATEPOSITION = "/Positionnew";
export const UPDATEPOSITIONWITHQUERYSTRING = "/UpdateRules/:Id/:Action";

//Travel Request
export const RETRIEVETRAVELREQUEST = "/RetrieveTravelRequest/:Id";

//My Trip Payment
export const PAYMENT = "/Payment";

// Corporate
export const SEARCHCORPORATEMASTER = "/SearchCorporateMaster/:KeyCode";
export const UPDATECORPORATEMASTER = "/UpdateCorporateMaster/:KeyCode";
export const UPDATECORPORATEMASTERWITHQUERYSTRING = "/UpdateCorporateMaster/:KeyCode/:Id/:Action";

//Approver
export const SEARCHAPPROVER = "/SearchApprover";
export const UPDATEAPPROVER = "/UpdateApprover";
export const UPDATEAPPROVERWITHQUERYSTRING = "/UpdateApprover/:Id/:Action/:Name";

//Mobile Menu
export const MASTERMENU = "/MasterMenu";
export const USERMENU = "/UserMenu";
export const SETTINGMENU = "/SettingMenu";

//Signup
export const SIGNUP = "/SignUp";

//visa
export const SEARCHVISA = "/SearchVisa";

//High Risk Destination
export const HIGHRISKDESTINATION = "/HighRiskDestination";
export const HIGHRISKDESTINATION_ADD = "/HighRiskDestination/Add";
export const HIGHRISKDESTINATION_VIEW = "/HighRiskDestination/View/:Id";
export const HIGHRISKDESTINATION_UPDATE = "/HighRiskDestination/Update/:Id";

//Approval
export const APPROVAL_THROUGH_MAIL = "/ApprovalThroughMail";


//View Travel Request Through Mail
export const VIEW_TRAVEL_REQUEST_THROUGH_MAIL = "/ViewTravelRequestThroughMail";

//DelegateApprover

export const DELEGATE_APPROVER = "/SearchDelegateApprover";

//Landing page
export const LANDING_PAGE = "/LandingPage";

//B2C Profile
export const PROFILE = '/Profile';

//Page Builder
export const UPDATESTATICPAGEWITHQUERYSTRING = '/UpdatePageBuilder/:Action/:Id/:PageId';
export const UPDATESTATICPAGE = '/UpdatePageBuilder';
export const SEARCHSTATICPAGE = "/SearchPageBuilder";

//Car
export const CARSEARCH = '/CarSearch';
export const CARBOOKING = '/CarBooking';
export const SEARCHCAR = '/SearchCar';
export const CARDETAIL = '/CarDetail';
export const CARDETAILS = '/CarDetails';
export const CARBOOKINGS = '/CarBookings';
export const CARCONFIRMATION = '/CarConfirmation';
export const CARCONFIRMATIONS = '/CarConfirmations';
export const RETRIEVECAR = '/RetrieveCar';

//CarRecommendation
export const CARRECOMMENDATION = "/CarRecommendation";