import { Fragment } from 'react';

const GuestInformation = (props) => {
    return (<tr>
        <td scope="" style={{ width: "8%" }}>1</td>
        <td scope="">{props.guestInfo.personName.namePrefix + " " + props.guestInfo.personName.givenName + " " + props.guestInfo.personName.middleName + " " + props.guestInfo.personName.surname}</td>
    </tr>
    )

};

export default GuestInformation;