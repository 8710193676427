import { useRef, useState, Fragment, useCallback, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { ChangePassword } from '../../../services/Authentication-API'
import { useTranslation } from 'react-i18next';
import { passwordValidation } from '../../../utils/Validation'
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import useHttp from '../../../services/use-http'
import { ENABLETWOFACTOR, LOGIN } from '../../../navigation/CONSTANTS';
import AuthContext from '../../../redux/contexts/auth-context';

import { getCustomizeDetails } from '../../../utils/CustomizeUtils'
import { Tooltip as ReactTooltip } from "react-tooltip";
import Login from './Login';
import { getCompanyLogo } from '../../../utils/CommonFunction';

const ChangePasswordForm = () => {
    useDocumentTitle('Change Password')
    const { t } = useTranslation(['translation.ForgotPassword']);
    const oldPasswordInputRef = useRef();
    const NewPasswordInputRef = useRef();
    const confirmPasswordInputRef = useRef();
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const { sendRequest, status, data: ChangePasswordDetails, error } = useHttp(ChangePassword);

    const [errors, setErrors] = useState();
    const [oldPasswordInputInputHasError, setOldPasswordInputInputHasError] = useState(false);
    const [oldPasswordValidInputInputHasError, setOldPasswordValidInputInputHasError] = useState(false);
    const [newPasswordInputInputHasError, setnewPasswordInputInputHasError] = useState(false);
    const [newPasswordValidInputInputHasError, setnewPasswordValidInputInputHasError] = useState(false);
    const [confirmPasswordInputInputHasError, setconfirmPasswordInputInputHasError] = useState(false);
    const [confirmPasswordValidInputInputHasError, setconfirmPasswordValidInputInputHasError] = useState(false);
    useEffect(() => {
        oldPasswordInputRef.current.focus();
    }, [])

    const errorHandler = () => {
        setErrors(null);
        oldPasswordInputRef.current.value = '';
        NewPasswordInputRef.current.value = '';
        confirmPasswordInputRef.current.value = '';
    };

    useEffect(() => {
        let loadedData = [];
        if (status === 'completed') {
            for (let i in ChangePasswordDetails) {
                if (ChangePasswordDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = ChangePasswordDetails[i];
                        // setErrors({
                        //     title: "Message",
                        //     message: `Password updated successfully.`,
                        // });
                        //redirect to mfa
                        window.location.href = `${ENABLETWOFACTOR}`;


                    }
                }
                else if (ChangePasswordDetails['errors'].status === 'TRUE') {

                    if (ChangePasswordDetails.errors.error.code === "1019") {
                        setErrors({
                            title: "Message",
                            message: 'You have already used that password, Please try another combination.',
                        });
                    }
                    else if (ChangePasswordDetails.errors.error.code === "1013") {
                        setErrors({
                            title: "Message",
                            message: 'Your old password is not correct.',
                        });
                    }
                    else {
                        setErrors({
                            title: "Message",
                            message: 'Sorry, an unexpected error has occurred. Please try again.',
                        });
                    }
                }
            }
        }
        if (error) {
            setErrors({
                title: "Message",
                message: "Sorry, an unexpected error has occurred. Please try again.",
            });
            //return <p className='centered focused'>{error}</p>
        }
    }, [status, ChangePasswordDetails, history])
    function onBlurCheck(event) {
        event.preventDefault();
        const enteredOldPassword = oldPasswordInputRef.current.value;
        const enteredNewPassword = NewPasswordInputRef.current.value;
        const enteredConfirmPassword = confirmPasswordInputRef.current.value;
        if (enteredOldPassword === '') {
            setOldPasswordInputInputHasError(true);
            setOldPasswordValidInputInputHasError(false);
            return;
        } else {
            setOldPasswordInputInputHasError(false);
        }
        if (!passwordValidation(enteredOldPassword)) {
            setOldPasswordInputInputHasError(false);
            setOldPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setOldPasswordValidInputInputHasError(false);
        }
        if (enteredNewPassword === '') {
            setnewPasswordInputInputHasError(true);
            setnewPasswordValidInputInputHasError(false);
            return;
        } else {
            setnewPasswordInputInputHasError(false);
        }
        if (!passwordValidation(enteredNewPassword)) {
            setnewPasswordInputInputHasError(false);
            setnewPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setnewPasswordValidInputInputHasError(false);
        }
        if (enteredConfirmPassword === '') {
            setconfirmPasswordInputInputHasError(true);
            setconfirmPasswordValidInputInputHasError(false);
            return;
        } else {
            setconfirmPasswordInputInputHasError(false);
        }
        if (!passwordValidation(enteredConfirmPassword)) {
            setconfirmPasswordInputInputHasError(false);
            setconfirmPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setconfirmPasswordValidInputInputHasError(false);
        }
        if (enteredConfirmPassword !== enteredNewPassword) {
            setconfirmPasswordInputInputHasError(false);
            setconfirmPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setconfirmPasswordValidInputInputHasError(false);
        }

    }
    const submitHandler = useCallback((event) => {
        event.preventDefault();
        const enteredOldPassword = oldPasswordInputRef.current.value;
        const enteredNewPassword = NewPasswordInputRef.current.value;
        const enteredConfirmPassword = confirmPasswordInputRef.current.value;
        // optional: Could validate here
        if (enteredOldPassword === '') {
            oldPasswordInputRef.current.focus();
            setOldPasswordInputInputHasError(true);
            setOldPasswordValidInputInputHasError(false);
            return;
        }
        if (!passwordValidation(enteredOldPassword)) {
            setOldPasswordInputInputHasError(false);
            setOldPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setOldPasswordValidInputInputHasError(false);
        }
        if (enteredNewPassword === '') {
            NewPasswordInputRef.current.focus();
            setnewPasswordInputInputHasError(true);
            setnewPasswordValidInputInputHasError(false);
            return;
        }
        if (!passwordValidation(enteredNewPassword)) {
            setnewPasswordInputInputHasError(false);
            setnewPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setnewPasswordValidInputInputHasError(false);
        }
        if (enteredConfirmPassword === '') {
            NewPasswordInputRef.current.focus();
            setconfirmPasswordInputInputHasError(true);
            setconfirmPasswordValidInputInputHasError(false);
            return;
        }
        if (!passwordValidation(enteredConfirmPassword)) {
            setconfirmPasswordInputInputHasError(false);
            setconfirmPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setconfirmPasswordValidInputInputHasError(false);
        }
        if (enteredConfirmPassword !== enteredNewPassword) {
            setconfirmPasswordInputInputHasError(false);
            setconfirmPasswordValidInputInputHasError(true);
            return;
        }
        else {
            setconfirmPasswordValidInputInputHasError(false);
        }
        if (localStorage.getItem('loginEmailIDLogin') !== null) {
            sendRequest({
                loginEmailID: localStorage.getItem('loginEmailIDLogin'),
                newPassword: enteredNewPassword,
                oldPassword: enteredOldPassword
            });
        }

        // optional: Add validation 
    }, [sendRequest]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                submitHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [submitHandler]);
    const logoutHandler = () => {
        authCtx.logout();
        history.go(LOGIN);
    };


    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
       <div className='position-relative main-responsive-menu'>

            <img src={getCompanyLogo()}  alt="" className='log-in-head-logo-bg position-absolute' />

            </div>
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <form className='form' onSubmit={submitHandler}>

                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-8 mb-30">
                                            <div className="card card-primary card-outline gt-common_author_boxed">
                                                {/* {props.isLoading && (
                                                    <div className='loading'>
                                                        <LoadingSpinner />
                                                    </div>
                                                )} */}
                                                <div className="card-body gt-change-pass">
                                                    <div className="col-md-10 col-sm-12 mx-auto ">
                                                        <div className="form-group col-md-12">
                                                            <label id="pageTitle" htmlFor="" className="h5 text-primary mb-3 col-lg-12 gt-heading_theme"><i className="fas fa-globe-asia"></i> Change Password</label>
                                                            <div className='control'>
                                                                <label  className="mb-2 h6 text-primary col-lg-12"> {t('oldPassword')}<span className="error-icon"> *</span></label>
                                                                <div className="input-group mb-1 col-lg-12">
                                                                    <input className="form-control form-control-sm pw col-sm-12"
                                                                        type='password' id='oldPassword'
                                                                        ref={oldPasswordInputRef}
                                                                        onBlur={onBlurCheck} />
                                                                    <ReactTooltip
                                                                        anchorId="oldPassword"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        className="tooltip"
                                                                        html={t('passwordHint')} />
                                                                    <span className="input-group-text w-50px h-50px">
                                                                        <i className="fa fa-eye-slash text-primary" id="togglePassword1"></i>
                                                                    </span>


                                                                </div>
                                                                {oldPasswordInputInputHasError && <p className="error-text">{t('validationOldPassword')}</p>}
                                                                {oldPasswordValidInputInputHasError && <p className="error-text">{t('validationValidOldpassword')}</p>}
                                                            </div>
                                                            <div className='control'>
                                                                <label htmlFor="airlineName" className="mb-2 h6 text-primary col-lg-12">{t('NewPassword')}<span className="error-icon"> *</span></label>
                                                                <div className="input-group mb-1 col-lg-12">
                                                                    <input className="form-control pw col-sm-12"
                                                                        type='password'
                                                                        id='NewPassword'
                                                                        ref={NewPasswordInputRef}
                                                                        onBlur={onBlurCheck}
                                                                    />
                                                                    <ReactTooltip
                                                                        anchorId="NewPassword"
                                                                        place="bottom"
                                                                        className="tooltip"
                                                                        variant="info"
                                                                        html={t('passwordHint')} />
                                                                    <span className="input-group-text w-50px h-50px">
                                                                        <i className="fa fa-eye-slash text-primary" id="togglePassword2"></i>
                                                                    </span>
                                                                </div>
                                                                {newPasswordInputInputHasError && <p className="error-text">{t('validationNewPassword')}</p>}
                                                                {newPasswordValidInputInputHasError && <p className="error-text">{t('validationValidNewpassword')}</p>}
                                                            </div>
                                                            <div className='control'>
                                                                <label htmlFor="airlineName" className="mb-2 h6 text-primary col-lg-12">{t('confirmPassword')}<span className="error-icon"> *</span></label>
                                                                <div className="input-group mb-1 col-lg-12">
                                                                    <input className="form-control pw col-sm-12"
                                                                        type='password' id='confirmPassword'
                                                                        ref={confirmPasswordInputRef} onBlur={onBlurCheck} />
                                                                    <ReactTooltip
                                                                        anchorId="confirmPassword"
                                                                        place="bottom"
                                                                        variant="info"
                                                                        multiline={true}
                                                                        className="tooltip"
                                                                        html={t('passwordHint')} />
                                                                    <span className="input-group-text w-50px h-50px">
                                                                        <i className="fa fa-eye-slash text-primary" id="togglePassword3"></i>
                                                                    </span>
                                                                </div>
                                                                {confirmPasswordInputInputHasError && <p className="error-text col-md-12">{t('validationConfirmpassword')}</p>}
                                                                {confirmPasswordValidInputInputHasError && <p className="error-text col-md-12">{t('validationMatchConfirmpassword')}</p>}
                                                            </div>
                                                            <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    <a href="/Login" onClick={logoutHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn-WithoutAuth-MFA d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        <button className='btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA  gt-btn_md ms-1' onChange={submitHandler}>
                                                                            {status === 'pending' ? <i className="fa fa-spinner fa-spin" ></i> : ' Submit '}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>

        </Fragment>
    );
};

export default ChangePasswordForm;