import { Fragment, useEffect, useState, useContext, Redirect } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import { checkAuthenticationB2C } from '../../../services/Authentication-API'
import useHttp from '../../../services/use-http';
import { useHistory } from 'react-router-dom';
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import AuthContext from '../../../redux/contexts/auth-context'
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST, GET_ALL_SIGHTSEEING_LIST } from '../../../services/CONSTANTS';
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import secureLocalStorage from "react-secure-storage";
import LoadingNeutral from '../../../components/Common/Loading/LoadingNeutral';

const Launching = () => {
    useDocumentTitle('Launching')
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(checkAuthenticationB2C);
    const [errors, setErrors] = useState();
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        secureLocalStorage.removeItem("CustomerLoginEmailID");
        secureLocalStorage.removeItem("isCustomerAuthentication");

        localStorage.clear();
        sessionStorage.clear();

        if (status === 'completed') {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + loadedAuthenticationDetails['errors'].idToken,
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });

            let sightseeingSuggestionResutTemp = [];
            fetch(GET_ALL_SIGHTSEEING_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + loadedAuthenticationDetails['errors'].idToken,
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            sightseeingSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
                });
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + loadedAuthenticationDetails['errors'].idToken,
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));

                });
        }
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];

                        const expirationTime = new Date(
                            new Date().getTime() + +loadedData.expireIn * 1000
                        );


                        authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                            loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                            loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                            loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                            loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                            loadedData.defaultCity, loadedData.isCorporate, loadedData.isTravelAgent,
                            loadedData.email, loadedData.phoneCode, loadedData.phoneNumber, loadedData);
                        sessionStorage.setItem("LoaderHeader", "0");
                        window.location.pathname = '/FlightSearch';
                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') { }
            }
        }
    }, [status, loadedAuthenticationDetails, authCtx, history])

    useEffect(() => {
        const request = {
            "loginEmailID": window.location.href
        }
        sendRequest(request);
    }, []);

    const errorHandler = () => {
        setErrors(null);
    };
    return (
        <Fragment>
            {errors && (
                <ErrorModal
                    title={errors.title}
                    message={errors.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            <section id="">
                <div className="container">
                    <div className="row">
                        <div className="full-page-loader">
                            <LoadingNeutral></LoadingNeutral>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 full-page-loader">
                            <LoadingSpinner></LoadingSpinner>
                        </div>
                    </div>
                </div>
            </section> */}
        </Fragment>)

};



export default Launching;

