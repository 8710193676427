import { Fragment, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import Dropdown from 'react-select';
import GuestDetails from './Components/GuestDetails';
import CarItem from './Components/CarItem';
import ContactPerson from './Components/ContactPerson';
import AdditionalServices from './Components/AdditionalServices';
import AddMarkup from './Components/AddMarkup';
import { CARCONFIRMATIONS } from '../../navigation/CONSTANTS';
import { useHistory, useLocation } from 'react-router-dom';
import BookingDetails from './Components/BookingDetails';
import Pricesummary from './Components/Pricesummary';
import CountryDropDownList from '../../components/Common/DropDownList/CountryDropDownList';
import StateDropDownList from '../../components/Common/DropDownList/StateDropDownList';
import { useTranslation } from 'react-i18next';
import { carBooking, carPriceCheck } from '../../services/Car-API';
import { checkSecurityDetails } from '../../utils/RoleUtils';

//import BookingLoadingSpinner from '../../pages/Booking/Components/BookingLoadingSpinner'
import ErrorModal from "../../components/Common/Error/ErrorModal";
import useHttp from '../../services/use-http';
import { getCreditCardDetails } from '../../services/CommonListData-API';
import { dateList, monthList, expiryYearList } from '../../utils/DropdownData';
import { validateByLuhn, calculateRoeAmountAdditional, dateDiff } from '../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import ConfirmationModal from "../../components/Common/Confirmation/ConfirmationModal";
import LoadingCar from '../Cars/Components/LoadingCar';
import TermsConditionsPopup from '../CompanyPolicy/TermsConditionsPopup';
import { encrypted } from '../../utils';
import { searchRoe } from '../../services/ROE-API';
import secureLocalStorage from 'react-secure-storage';
import { searchStaticPage } from '../../services/StaticPage-API';
import moment from 'moment';

const Booking = () => {
    const { t } = useTranslation(['translation.CarBooking']);
    useDocumentTitle('Car Book');
    const { sendRequest, status, data: loadedBookingResponse } = useHttp(carBooking);
    const { sendRequest: sendRequestPriceCheck, status: statusPriceCheck, data: loadedCarPriceCheck, error } = useHttp(carPriceCheck);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const [confirm, setConfirm] = useState('');
    const [termsCondition, setTermsCondition] = useState('');
    const history = useHistory();
    const location = useLocation();
    const [cardType, setCardType] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const postalCodeInputRef = useRef('');
    const cardNumberInputRef = useRef('');
    const cardHolderFirstNameInputRef = useRef('');
    const cardHolderLastNameInputRef = useRef('');
    const cityNameInputRef = useRef('');
    const dateOfExpiryInputRef = useRef('');
    const addressInputRef = useRef('');
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [cardTypeInputHasError, setCardTypeInputHasError] = useState(false);
    const [expiryMonthInputHasError, setExpiryMonthInputHasError] = useState(false);
    const [expiryYearInputHasError, setExpiryYearInputHasError] = useState(false);
    const [stateNameInputHasError, setStateNameInputHasError] = useState(false);
    const [postalCodeInputHasError, setPostalCodeInputHasError] = useState(false);
    const [postalCodeInvalidInputError, setPostalCodeInvalidInputError] = useState(false);

    const [dateofExpiryInputHasError, setDateofExpiryInputHasError] = useState(false);
    const [dateofExpiryInvalidInputError, setDateofExpiryInvalidInputError] = useState(false);
    const [cardHolderFirstNameInputHasError, setCardHolderFirstNameInputHasError] = useState(false);
    const [cardHolderFirstNameInvalidInputError, setCardHolderFirstNameInvalidInputError] = useState(false);
    const [cardHolderLastNameInputHasError, setCardHolderLastNameInputHasError] = useState(false);
    const [cardHolderLastNameInvalidInputError, setCardHolderLastNameInvalidInputError] = useState(false);
    const [cardNumberInputHasError, setCardNumberInputHasError] = useState(false);
    const [cardNumberInvalidInputError, setCardNumberInvalidInputError] = useState(false);
    const [cityNameInputHasError, setCityNameInputHasError] = useState(false);
    const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [stateCodeInputHasError, setStateCodeInputHasError] = useState(false);
    const [stateNameInvalidInputError, setStateNameInvalidInputError] = useState(false);
    const [controlType, setControlType] = useState('');
    const [addressInvalidInputError, setAddressInvalidInputError] = useState(false);
    const [addressInputHasError, setAddressInputHasError] = useState(false);
    const [address, setAddress] = useState('');
    const [profileLocaterNumber, setProfileLocaterNumber] = useState(null);
    const [confirmModel, setConfirmModel] = useState();
    const [cardName, setCardName] = useState('');
    const [error1, setError] = useState();
    const [refreshStateNameText, setRefreshStateNameText] = useState(false);
    const [refreshTermConditions, setRefreshTermConditions] = useState(false);

    let carBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));
    //USER STORY 2334: Wallet changes
    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let currentWalletAmount = sessionStorage.getItem("currentWalletAmount");
    let isWalletActive = sessionStorage.getItem("activeWallet") !== null ? "" + sessionStorage.getItem("activeWallet") : 'false';
    let totalPrice = carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount;
    let isWalletAmountIsSufficient = 'false';
    let isWalletRadiobuttonChecked = 'false';
    let fareCurrency = carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode;
    var valid = require("card-validator");

    let totalTax = 0;
    // carBookingJSON.data.carResults[0].roomRateDetails.forEach(item => {
    //     if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
    //         totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
    //     }
    //     else {
    //         totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
    //     }
    // });

    if ((parseFloat(currentWalletAmount) < parseFloat(calculateRoeAmountAdditional(fareCurrency, totalPrice, false)))) {
        isWalletAmountIsSufficient = 'true';
    }

    let RemainingWalletAmount = currentWalletAmount - calculateRoeAmountAdditional(fareCurrency, totalPrice, false);
    const countryCodeList = ['PR', 'US', 'VI'];

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
        //setRefresh(true);
        setIsCountryChanged(true);
        setCityCode('');
        setCityName('');
        setCityList(cityList);
        setStateCode('');
        setStateList(stateList);
        setStateName('');
        setCountryCodeInputHasError(false);
    };
    //Code for Bind Airline Program Data
    const { sendRequest: sendRequestCreditCardData, data: loadedCreditCardData } = useHttp(getCreditCardDetails);

    // User Story 9767: Display Dynamic Term and condition
    const { sendRequest: getTermsAndConditions, status: termsAndConditionStatus, data: loadedTermsAndConditions, error: termsAndConditionErroor } = useHttp(searchStaticPage);
    const [termsAndConditionData, setTermsAndConditionData] = useState(null);
    useEffect(() => {
        const searchInput = ({
            "id": "",
            "traceID": "",
            "userID": "",
            "ipAddress": "",
            "termAndCondition": "",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "pageID": "1",
            "pageTitle": ""


        });
        getTermsAndConditions(searchInput);

    }, [])
    useEffect(() => {
        if (termsAndConditionStatus === 'completed') {
            if (loadedTermsAndConditions['errors'].status === 'FALSE' && loadedTermsAndConditions.data[0].isActive) {
                setTermsAndConditionData(loadedTermsAndConditions.data[0]);
            }
        }
    }, [termsAndConditionStatus]);



    useEffect(() => {
        sendRequestCreditCardData();
    }, [sendRequestCreditCardData])

    let creditCardOptions = []
    let creditCardArrayData = [];

    if (loadedCreditCardData !== null && loadedCreditCardData.errors.status === 'FALSE') {
        creditCardArrayData = loadedCreditCardData.data;
        for (let index = 0; index < creditCardArrayData.length; index++) {
            creditCardOptions = [...creditCardOptions, { value: creditCardArrayData[index].code, label: creditCardArrayData[index].name }]
        }
    }

    const stateCodeValue = (stateCode) => {
        setStateCode(stateCode);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        setStateNameInputHasError(false);
        setIsStateChanged(true);
    };

    const stateNameText = (stateName) => {
        if (stateName.length > 0) {
            if (!stateName.match('^[a-zA-Z ]+$')) {
                setStateNameInvalidInputError(true);
                //return;
            }
            else {
                setStateNameInvalidInputError(false);
            }
        }
        else {
            setRefreshStateNameText(true);
        }
        setStateName(stateName);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        if (stateName.trim() === '') {
            //setStateNameInputHasError(true);
        }
        else {
            setStateNameInputHasError(false);
        }
        setIsStateChanged(true);
    };
    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };


    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);
        setIsCountryChanged(false);
        // setCityCodeInputHasError(false);
        if (stateCode === '' && stateName === '') {
            setIsStateChanged(true);
        }
        else {
            setIsStateChanged(false);
        }

        if (postalCodeInputRef.current.value.trim() === '') {
            setPostalCodeInputHasError(true);
            setPostalCodeInvalidInputError(false);
            return;
        }
        else {
            setPostalCodeInputHasError(false);
        }
    };

    useEffect(() => {
        setProfileLocaterNumber(JSON.parse(localStorage.getItem("profileLocaterNumber")));

        if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
            onChangeCardType("1");
        }
        else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
            onChangeCardType("2");
        }
        else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
            onChangeCardType("3");
        }

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }
    }, [])

    const errorHandler = () => {
        setError(null);
        setTermsCondition(null);
    };

    function onContinueButton(tabID) {
        // code to hide rate summary on car booking page
        if (document.querySelector('#PriceSummaryCollapse') !== null) {
            if (document.querySelector('#PriceSummaryCollapse').classList.contains('show')) {
                document.querySelector('#btnPriceSummaryCollapse').click();
            }
        }

        if (tabID === "1") {
            document.getElementById("completeOne").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button");
            document.getElementById("headingTwo").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingThree").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "2") {
            document.getElementById("completeTwo").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button ");
            document.getElementById("headingThree").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "3") {
            document.getElementById("completeThree").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingFour").setAttribute("class", "accordion-button");
            document.getElementById("headingFour").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse show");
        }
    }

    function SendRequest(event) {
        event.preventDefault();
        onBookPayment("1")
    }
    function OnBook(event) {
        event.preventDefault();
        onBookPayment("2")
    }
    //   User Story 2338: Add remarks to the Sabre PNR
    function passHiddenRemarksToBookingJson(text) {
        const hiddenRemarks = ({

            "code": "",
            "text": text,
            "remarkType": "Hidden"

        });
        return hiddenRemarks;
    }

    async function onBookPayment(id) {

        if (document.getElementById("checkCreditCard") !== null && document.getElementById("checkCreditCard").checked) {
            if (cardType === '') {
                setCardTypeInputHasError(true);
                return;
            } else {
                setCardTypeInputHasError(false);
            }

            if (cardNumberInputRef.current.value.trim() === '') {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(true);
                setCardNumberInvalidInputError(false);
                return;
            }
            else {
                setCardNumberInputHasError(false);
            }

            // let isValid =
            //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
            //         cardNumberInputRef.current.value.trim().length == 15 &&
            //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
            //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
            //     (cardNumberInputRef.current.value.trim().length == 16 &&
            //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
            //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

            // if (!isValid) {
            //     cardNumberInputRef.current.focus();
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(true);
            //     return;
            // }
            // else {
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(false);
            // }

            var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
            if (!numberValidation.isValid) {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }

            if (numberValidation.isValid) {
                if (numberValidation.card.niceType !== cardName) {
                    cardNumberInputRef.current.focus();
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(true);
                    return;
                }
                else {
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(false);
                }
            }

            if (cardHolderFirstNameInputRef.current.value.trim() === '') {
                cardHolderFirstNameInputRef.current.focus();
                setCardHolderFirstNameInputHasError(true);
                setCardHolderFirstNameInvalidInputError(false);
                return;
            }
            else {
                setCardHolderFirstNameInputHasError(false);
            }

            if (cardHolderFirstNameInputRef.current.value.trim() !== '') {
                if (!cardHolderFirstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                    cardHolderFirstNameInputRef.current.focus();
                    setCardHolderFirstNameInvalidInputError(true);
                    setCardHolderFirstNameInputHasError(false);
                    return;
                }
                else {
                    setCardHolderFirstNameInvalidInputError(false);
                }
            }

            if (cardHolderLastNameInputRef.current.value.trim() === '') {
                cardHolderLastNameInputRef.current.focus();
                setCardHolderLastNameInputHasError(true);
                setCardHolderLastNameInvalidInputError(false);
                return;
            }
            else {
                setCardHolderLastNameInputHasError(false);
            }

            if (cardHolderLastNameInputRef.current.value.trim() !== '') {
                if (!cardHolderLastNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                    cardHolderLastNameInputRef.current.focus();
                    setCardHolderLastNameInvalidInputError(true);
                    setCardHolderLastNameInputHasError(false);
                    return;
                }
                else {
                    setCardHolderLastNameInvalidInputError(false);
                }
            }

            if (expiryMonth === '') {
                setExpiryMonthInputHasError(true);
                return;
            }
            else {
                setExpiryMonthInputHasError(false);
            }

            if (expiryYear === '') {
                setExpiryYearInputHasError(true);
                return;
            }
            else {
                setExpiryYearInputHasError(false);
            }

            if (expiryMonth !== '' && expiryYear !== '') {
                setExpiryMonthInputHasError(false);
                setExpiryYearInputHasError(false);

                var formattedExpiryDate = new Date(expiryYear, expiryMonth - 1, new Date().getDate());
                var dateDiffValue = dateDiff(new Date(), formattedExpiryDate);
                let isExpiryDateValid = false;

                if (dateDiffValue.years < 0) {
                    isExpiryDateValid = true;
                }
                else if (dateDiffValue.months < 0) {
                    isExpiryDateValid = true;
                }
                else if (dateDiffValue.days < 0) {
                    isExpiryDateValid = true;
                }

                if (moment(`${expiryYear}-${expiryMonth}-${new Date().getDate()}`).isValid() && !isExpiryDateValid) {
                    setDateofExpiryInvalidInputError(true);
                    return;
                }
                else {
                    setDateofExpiryInvalidInputError(false);
                }
            }
            else {
                setDateofExpiryInvalidInputError(false);
            }

            if (address.trim() === '') {
                addressInputRef.current.focus();
                setAddressInputHasError(true);
                setAddressInvalidInputError(false);
                return;
            }
            else {
                setAddressInputHasError(false);
            }

            if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
                addressInputRef.current.focus();
                setAddressInvalidInputError(true);
                setAddressInputHasError(false);
                return;
            }
            else {
                setAddressInvalidInputError(false);
            }

            if (countryCode === '') {
                setCountryCodeInputHasError(true);
                return;
            }
            else {
                setCountryCodeInputHasError(false);
            }

            if (controlType !== '') {
                if (controlType === 'txt') {
                    setStateCode('');
                    setStateCodeInputHasError(false);
                    if (stateName.trim() === '') {
                        setStateNameInputHasError(true);
                        return;
                    }
                    else {
                        setStateNameInputHasError(false);
                    }
                }
                else {
                    setStateName('');
                    setStateNameInputHasError(false);
                    if (stateCode === '') {
                        setStateCodeInputHasError(true);
                        return;
                    }
                    else {
                        setStateCodeInputHasError(false);
                    }
                }
            }

            if (isCountryChanged === true || (stateCode === '' && stateName.trim() === '')) {
                setStateCodeInputHasError(true);
                return
            }

            // if (isCountryChanged === true || (isCountryChanged === false && address.trim() !== '')) {
            //     if (cityCode === '' && stateCodeInputHasError === false && stateNameInputHasError === false && stateNameInvalidInputError === false) {
            //         setCityCodeInputHasError(true);
            //         return
            //     }
            // }

            if (stateName.length > 0) {
                if (!stateName.match('^[a-zA-Z ]+$')) {
                    setStateNameInvalidInputError(true);
                    return;
                }
                else {
                    setStateNameInvalidInputError(false);
                }
            }

            const enteredCityName = cityNameInputRef.current.value;
            if (enteredCityName.trim() === '') {
                cityNameInputRef.current.focus();
                setCityNameInputHasError(true);
                setCityNameInvalidInputError(false);
                return;
            }
            else {
                setCityNameInputHasError(false);
            }

            if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
                cityNameInputRef.current.focus();
                setCityNameInvalidInputError(true);
                setCityNameInputHasError(false);
                return;
            }
            else {
                setCityNameInvalidInputError(false);
            }

            if (postalCodeInputRef.current.value.trim() === '') {
                postalCodeInputRef.current.focus();
                setPostalCodeInputHasError(true);
                setPostalCodeInvalidInputError(false);
                return;
            }
            else {
                setPostalCodeInputHasError(false);
            }

            if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
                postalCodeInputRef.current.focus();
                setPostalCodeInvalidInputError(true);
                setPostalCodeInputHasError(false);
                return;
            }
            else {
                if (countryCodeList.includes(countryCode)) {
                    if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                        setPostalCodeInvalidInputError(false);
                    }
                    else {
                        postalCodeInputRef.current.focus();
                        setPostalCodeInvalidInputError(true);
                        setPostalCodeInputHasError(false);
                        return;
                    }
                }
                else {
                    setPostalCodeInvalidInputError(false);
                }
            }

            let newStateCode = '';
            let newStateName = '';

            if (controlType === '') {
                if (stateCode !== '') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }
            else {
                if (controlType === 'ddl') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }

            if (postalCodeInputRef.current.value !== '') {
                var inputs = document.querySelectorAll('.tandc-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the car policies and the terms and conditions to proceed.";
                        return;
                    }
                }
            }
        }
        else if ((document.querySelector('#checkSabreCreditCard') !== null && document.querySelector('#checkSabreCreditCard').checked) || (document.querySelector('#checkWallet') !== null && document.querySelector('#checkWallet').checked)) {
            var inputs = document.querySelectorAll('.tandc-payment-option');
            for (var i = 0; i < inputs.length; i++) {
                if (!inputs[i].checked) {
                    inputs[i].focus();
                    document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the car policies and the terms and conditions to proceed.";
                    return;
                }
            }
        }

        if (document.querySelector('#btnSendRequest') !== null) {
            document.querySelector('#btnSendRequest').style.display = "none";
        }
        if (document.querySelector('#btnOnBook') !== null) {
            document.querySelector('#btnOnBook').style.display = "none";
        }

        //User Story 1073: Booking Summary for Single PNR
        let currencySymbolLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: fareCurrency }).format(0).toString().split('.')[0].length <= 2 ? 1 : 3;
        let markupAmount = document.querySelector('#spnMarkupAmountCar') !== null ? parseFloat(document.querySelector('#spnMarkupAmountCar').innerHTML.length > 0 ? document.querySelector('#spnMarkupAmountCar').innerHTML.replaceAll('&nbsp;', '').replaceAll(' ', '').substring(currencySymbolLength, document.querySelector('#spnMarkupAmountCar').innerHTML.length) : 0) : 0;
        let carBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));

        let cardNumber = "";
        if (cardNumberInputRef.current.value.trim() !== '') {
            cardNumber = await encrypted(cardNumberInputRef.current.value.trim());
        }

        carBookingJSON.data.requestType = id === '1' ? 'OF' : 'ON';
        carBookingJSON.data.carResults[0].vehRentalCore.paymentInfo.paymentMode.forEach(elementPaymentMode => {
            if (document.getElementById("checkSabreCreditCard") !== null && document.getElementById("checkSabreCreditCard").checked === true) {
                elementPaymentMode.name = "PROFILE";
            }
            else if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked) {
                elementPaymentMode.name = "WALLET";
            }
            else {
                elementPaymentMode.name = "CREDITCARD";
            }

            elementPaymentMode.amount = carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount.toString().length > 0 ? parseFloat(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount) > 0 ? parseFloat(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount - markupAmount).toFixed(2) : parseFloat(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount).toFixed(2) : parseFloat(0);

            elementPaymentMode.paymentModeInfo.forEach(elementPaymentModeInfo => {
                if (elementPaymentModeInfo.fieldName === "CARDTYPE")
                    elementPaymentModeInfo.fieldValue = cardType;
                if (elementPaymentModeInfo.fieldName === "CARDNO")
                    elementPaymentModeInfo.fieldValue = cardNumber;
                if (elementPaymentModeInfo.fieldName === "FIRSTNAME")
                    elementPaymentModeInfo.fieldValue = cardHolderFirstNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.fieldName === "LASTNAME")
                    elementPaymentModeInfo.fieldValue = cardHolderLastNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.fieldName === "EXPIRYEAR") {
                    elementPaymentModeInfo.fieldValue = expiryYear;
                }
                if (elementPaymentModeInfo.fieldName === "EXPIRMONTH") {
                    elementPaymentModeInfo.fieldValue = expiryMonth;
                }
                if (elementPaymentModeInfo.fieldName === "ADDRESS")
                    elementPaymentModeInfo.fieldValue = address.trim();
                if (elementPaymentModeInfo.fieldName === "COUNTRY")
                    elementPaymentModeInfo.fieldValue = countryCode;
                if (elementPaymentModeInfo.fieldName === "STATE")
                    elementPaymentModeInfo.fieldValue = stateCode !== '' ? stateCode : stateName.trim();
                if (elementPaymentModeInfo.fieldName === "CITY")
                    elementPaymentModeInfo.fieldValue = cityNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.fieldName === "POSTALCODE")
                    elementPaymentModeInfo.fieldValue = postalCodeInputRef.current.value.trim();
            });
        });
        //User Story 2338: Add remarks to the Sabre PNR
        if (localStorage.getItem('profileLocaterNumber') !== null && JSON.parse(localStorage.getItem('profileLocaterNumber')) !== '') {
            let text = "Sabre Profile Locator- " + JSON.parse(localStorage.getItem('profileLocaterNumber'))
            carBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('companyName') !== null && localStorage.getItem('companyName') !== '') {
            let text = "Office- " + JSON.parse(localStorage.getItem('companyName'));
            carBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('loginEmailID') !== null && localStorage.getItem('loginEmailID') !== '') {
            let text = "Login Email ID- " + JSON.parse(localStorage.getItem('loginEmailID'))
            carBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('displayName') !== null && localStorage.getItem('displayName') !== '') {
            let text = "Created By- " + JSON.parse(localStorage.getItem('displayName'))
            carBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        let text = carBookingJSON.data.carResults[0].vehRentalCore.paymentInfo.paymentMode[0].name;
        if (text !== '' || text !== undefined) {
            carBookingJSON.data.carMiscellaneousInfo.miscellaneousInfo.push(passHiddenRemarksToBookingJson("FOP- " + text));
        }

        carBookingJSON.data.roundingValue = document.querySelector('#spnTotalRateTop') !== null ? document.querySelector('#spnTotalRateTop').innerHTML.includes('.') ? document.querySelector('#spnTotalRateTop').innerHTML.split('.')[1].length.toString() : "2" : "2";
        carBookingJSON.data.travelerInfo.traveler[0].gender = "M";
        carBookingJSON.data.travelerInfo.traveler[0].telephone[0].phoneNumber = carBookingJSON.data.travelerInfo.traveler[0].telephone[0].mobile;
        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJSON));

        if (id === '1') {
            implementRoeFunction();
            let carBookingJsonModified = JSON.parse(sessionStorage.getItem("CarBookingData"));
            sendRequest(carBookingJsonModified);
        }
        else {
            //sendRequestPriceCheck(carBookingJSON);            
            implementRoeFunction();
            let carBookingJsonModified = JSON.parse(sessionStorage.getItem("CarBookingData"));
            sendRequest(carBookingJsonModified);
        }

        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJSON));
        //document.querySelector('#theme_search_form_tour').style.display = "none";
    }

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
        if (status === 'completed') {
            if (loadedBookingResponse !== null && loadedBookingResponse.data !== null) {

                if (loadedBookingResponse.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }

                    if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
                        onChangeCardType("1");
                    }
                    else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
                        onChangeCardType("2");
                    }
                    else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
                        onChangeCardType("3");
                    }

                    setConfirm({
                        title: "Message",
                        message: loadedBookingResponse.data.errors.error.description
                    });
                }
                else {
                    sessionStorage.setItem("CarBookingData", JSON.stringify(loadedBookingResponse));
                    sessionStorage.setItem("myTripIsCarCancelled", '');
                    sessionStorage.removeItem("CarIntialTotalAmount");
                    window.location.pathname = `${CARCONFIRMATIONS}`;
                }
            }
            else {
                if (document.querySelector('#btnSendRequest') !== null) {
                    document.querySelector('#btnSendRequest').style.display = "";
                }
                if (document.querySelector('#btnOnBook') !== null) {
                    document.querySelector('#btnOnBook').style.display = "";
                }

                if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
                    onChangeCardType("1");
                }
                else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
                    onChangeCardType("2");
                }
                else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
                    onChangeCardType("3");
                }

                setConfirm({
                    title: "Message",
                    message: "Some error is occured. Please try later."
                });
            }
            return;
        }
    }, [status, history, loadedBookingResponse])

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
        if (statusPriceCheck === 'completed') {
            if (loadedCarPriceCheck !== null && loadedCarPriceCheck.data !== null) {

                if (loadedCarPriceCheck.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }

                    if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
                        onChangeCardType("1");
                    }
                    else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
                        onChangeCardType("2");
                    }
                    else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
                        onChangeCardType("3");
                    }

                    setConfirm({
                        title: "Message",
                        message: loadedCarPriceCheck.data.errors.error.description,
                    });
                }
                else {
                    let carBookingJSON = JSON.parse(sessionStorage.getItem("CarBookingData"));

                    if (loadedCarPriceCheck.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.priceCheckInfo.convertedPriceChange) {
                        carBookingJSON.data.carResults.forEach(elementCarResults => {
                            elementCarResults.vehRentalCore.vehVendorAvail.vehAvail.rentalRate = loadedCarPriceCheck.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.rentalRate;
                            elementCarResults.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate = loadedCarPriceCheck.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate;
                        });

                        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJSON));

                        if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
                            onChangeCardType("1");
                        }
                        else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
                            onChangeCardType("2");
                        }
                        else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
                            onChangeCardType("3");
                        }

                        setConfirmModel({
                            title: "Message",
                            message: `The car price has changed from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode }).format(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: loadedCarPriceCheck.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode }).format(loadedCarPriceCheck.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJSON));
                        implementRoeFunction();
                        let carBookingJsonModified = JSON.parse(sessionStorage.getItem("CarBookingData"));
                        sendRequest(carBookingJsonModified);
                    }
                }
            }
            else {
                if (document.querySelector('#btnSendRequest') !== null) {
                    document.querySelector('#btnSendRequest').style.display = "";
                }
                if (document.querySelector('#btnOnBook') !== null) {
                    document.querySelector('#btnOnBook').style.display = "";
                }

                if (getConfigurationByBoolen("CAR_CREDIT_CARD") === true) {
                    onChangeCardType("1");
                }
                else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true) {
                    onChangeCardType("2");
                }
                else if (getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) {
                    onChangeCardType("3");
                }

                setConfirm({
                    title: "Message",
                    message: `Some error is occured. Please try later.`,
                });
            }
            return;
        }
    }, [statusPriceCheck, history, loadedCarPriceCheck])

    function okConfirmHandler() {
        setConfirmModel('');
        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJSON));
        implementRoeFunction();
        let carBookingJsonModified = JSON.parse(sessionStorage.getItem("CarBookingData"));
        sendRequest(carBookingJsonModified);
    };

    function cancelHandler() {
        setConfirm(false);
        setConfirmModel(false);
    };

    function onBlurCheck() {
        if (cardType === '') {
            setCardTypeInputHasError(true);
            return;
        } else {
            setCardTypeInputHasError(false);
        }

        if (cardNumberInputRef.current.value.trim() === '') {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(true);
            setCardNumberInvalidInputError(false);
            return;
        }
        else {
            setCardNumberInputHasError(false);
        }

        // let isValid =
        //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
        //         cardNumberInputRef.current.value.trim().length == 15 &&
        //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
        //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
        //     (cardNumberInputRef.current.value.trim().length == 16 &&
        //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
        //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

        // if (!isValid) {
        //     //cardNumberInputRef.current.focus();
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(true);
        //     return;
        // }
        // else {
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(false);
        // }

        var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
        if (!numberValidation.isValid) {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(true);
            return;
        }
        else {
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(false);
        }

        if (numberValidation.isValid) {
            if (numberValidation.card.niceType !== cardName) {
                //cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }
        }

        if (cardHolderFirstNameInputRef.current.value.trim() === '') {
            //cardHolderFirstNameInputRef.current.focus();
            setCardHolderFirstNameInputHasError(true);
            setCardHolderFirstNameInvalidInputError(false);
            return;
        }
        else {
            setCardHolderFirstNameInputHasError(false);
        }

        if (cardHolderFirstNameInputRef.current.value.trim() !== '') {
            if (!cardHolderFirstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                //cardHolderFirstNameInputRef.current.focus();
                setCardHolderFirstNameInvalidInputError(true);
                setCardHolderFirstNameInputHasError(false);
                return;
            }
            else {
                setCardHolderFirstNameInvalidInputError(false);
            }
        }

        if (cardHolderLastNameInputRef.current.value.trim() === '') {
            //cardHolderLastNameInputRef.current.focus();
            setCardHolderLastNameInputHasError(true);
            setCardHolderLastNameInvalidInputError(false);
            return;
        }
        else {
            setCardHolderLastNameInputHasError(false);
        }

        if (cardHolderLastNameInputRef.current.value.trim() !== '') {
            if (!cardHolderLastNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                //cardHolderLastNameInputRef.current.focus();
                setCardHolderLastNameInvalidInputError(true);
                setCardHolderLastNameInputHasError(false);
                return;
            }
            else {
                setCardHolderLastNameInvalidInputError(false);
            }
        }

        if (expiryMonth === '') {
            setExpiryMonthInputHasError(true);
            return;
        }
        else {
            setExpiryMonthInputHasError(false);
        }

        if (expiryYear === '') {
            setExpiryYearInputHasError(true);
            return;
        }
        else {
            setExpiryYearInputHasError(false);
        }

        if (address.trim() === '') {
            setAddressInputHasError(true);
            setAddressInvalidInputError(false);
            return;
        }
        else {
            setAddressInputHasError(false);
        }

        if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
            setAddressInvalidInputError(true);
            setAddressInputHasError(false);
            return;
        }
        else {
            setAddressInvalidInputError(false);
        }

        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (controlType !== '') {
            if (controlType === 'txt') {
                setStateCode('');
                setStateCodeInputHasError(false);
                if (stateName.trim() === '') {
                    //setStateNameInputHasError(true);
                    //return;
                }
                else {
                    setStateNameInputHasError(false);
                }
            }
            else {
                setStateName('');
                setStateNameInputHasError(false);
                if (stateCode === '') {
                    //setStateCodeInputHasError(true);
                    //return;
                }
                else {
                    setStateCodeInputHasError(false);
                }
            }
        }
        const enteredCityName = cityNameInputRef.current.value;
        if (enteredCityName.trim() === '') {
            setCityNameInputHasError(true);
            setCityNameInvalidInputError(false);
            return;
        }
        else {
            setCityNameInputHasError(false);
        }

        if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
            setCityNameInvalidInputError(true);
            setCityNameInputHasError(false);
            return;
        }
        else {
            setCityNameInvalidInputError(false);
        }

        if (postalCodeInputRef.current.value.trim() === '') {
            //postalCodeInputRef.current.focus();
            setPostalCodeInputHasError(true);
            setPostalCodeInvalidInputError(false);
            return;
        }
        else {
            setPostalCodeInputHasError(false);
        }

        if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
            //postalCodeInputRef.current.focus();
            setPostalCodeInvalidInputError(true);
            setPostalCodeInputHasError(false);
            return;
        }
        else {
            if (countryCodeList.includes(countryCode)) {
                if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                    setPostalCodeInvalidInputError(false);
                }
                else {
                    //postalCodeInputRef.current.focus();
                    setPostalCodeInvalidInputError(true);
                    setPostalCodeInputHasError(false);
                    return;
                }
            }
            else {
                setPostalCodeInvalidInputError(false);
            }
        }
    }

    function onChangeCardType(id) {
        if (document.getElementById("dvTotalPriceTop") !== null) {
            document.getElementById("dvTotalPriceTop").style.display = "block";
        }

        if (id === "2") {
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = true;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnBook') !== null) {
                document.querySelector('#btnOnBook').style.display = "";
            }
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }
            if (document.getElementById("requiredIndicatorPaymentSection") !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }


        }
        else if (id === "3") {
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = true;
            }
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWalletEror") !== null) {

                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWalletEror").style.display = "block";
                    isWalletRadiobuttonChecked = true;
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "none";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "none";
                    }
                } else {
                    document.getElementById("divWalletEror").style.display = "none";
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }
                }
            }
            if (document.getElementById("divWallet") !== null) {
                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWallet").style.display = "none";
                } else {
                    document.getElementById("divWallet").style.display = "block";
                    if (document.getElementById("dvTotalPriceTop") !== null) {
                        document.getElementById("dvTotalPriceTop").style.display = "none";
                    }
                }


            }


        }
        else {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "block";
            }
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = true;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnBook') !== null) {
                document.querySelector('#btnOnBook').style.display = "";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "";
            }
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }
        }
    }

    const exposedControlType = (controlType) => {
        setControlType(controlType);
    };

    // const cityNameText = (cityName) => {
    //     setCityName(cityName.split('(')[0].trim());
    // };

    const cardTypeChangeHandler = (event) => {
        setCardType(event.value);
        setCardName(event.label);
        setCardTypeInputHasError(false);
    };

    const expireYearChangeHandler = (event) => {
        setExpiryYear(event.value);
        setExpiryYearInputHasError(false);
        setDateofExpiryInvalidInputError(false);
    };

    const expireMonthChangeHandler = (event) => {
        setExpiryMonth(event.value);
        setExpiryMonthInputHasError(false);
        setDateofExpiryInvalidInputError(false);
    };

    function okHandler() {
        setConfirm('');
        if (location.search !== undefined && location.search !== '') {
            history.push({
                pathname: location.pathname,
                search: localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? '?car=partial&travelreq=true&src=car' : ''
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
            history.go();
        }
    };

    useEffect(() => {
        if (document.getElementById("cardNumber") !== null) {
            const yOffset = -150;
            const element2 = document.getElementById("cardNumber");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }, [cardType, expiryMonth,
        cardNumberInputHasError, cardNumberInvalidInputError,
        cardHolderFirstNameInputHasError, cardHolderFirstNameInvalidInputError, cardHolderLastNameInputHasError,
        cardHolderLastNameInvalidInputError, expiryMonthInputHasError, expiryYearInputHasError, dateofExpiryInvalidInputError])

    useEffect(() => {
        if (document.getElementById("address") !== null) {
            const yOffset = -300;
            const element2 = document.getElementById("address");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }, [countryCode, countryCodeInputHasError, stateCode, cityCode, cityNameInputRef,
        postalCodeInputHasError, postalCodeInvalidInputError,
        stateCodeInputHasError, stateNameInputHasError, stateName,
        stateNameInvalidInputError, cityNameInputHasError, cityNameInvalidInputError,
        address, addressInputHasError, addressInvalidInputError, expiryYear, refreshStateNameText])

    useEffect(() => {
        if (document.getElementById("chkTandcPayment") !== null) {
            const yOffset = -150;
            const element2 = document.getElementById("chkTandcPayment");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }, [refreshTermConditions])

    const onTandcPaymentChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-payment-option:checked');
        if (inputs.length === 1) {
            document.querySelector('#spnTandCPaymentMessage').innerHTML = "";
        }
    }

    function openTermAndCondtion() {
        setTermsCondition({
            title: "Message",
            message: <TermsConditionsPopup data={termsAndConditionData}></TermsConditionsPopup>
        });

    };

    function implementRoeFunction() {
        let carBookingJsonRoe = JSON.parse(sessionStorage.getItem("CarBookingData"));
        // if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked === true) {
        //     let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

        //     carBookingJsonRoe.data.carResults.forEach(elementCarResults => {
        //         elementCarResults.price.forEach(elementPrice => {
        //             elementPrice.equivalentCurrencyCode = walletCurrency;
        //             elementPrice.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementPrice.offeredPrice.priceOffered, false);
        //             elementPrice.equivalentAgentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUp !== null && elementPrice.agentMarkUp !== '' ? elementPrice.agentMarkUp : '0', false);
        //             elementPrice.equivalentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.markUp !== undefined && elementPrice.markUp !== null && elementPrice.markUp !== '' ? elementPrice.markUp : '0', false);
        //         });

        //         // elementCarResults.roomRateDetails.forEach(elementRoomRateDetails => {
        //         //     elementRoomRateDetails.price.equivalentCurrencyCode = walletCurrency;
        //         //     elementRoomRateDetails.price.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementRoomRateDetails.price.offeredPrice.priceOffered, false);

        //         //     elementRoomRateDetails.price.taxes !== undefined && elementRoomRateDetails.price.taxes.tax !== undefined && elementRoomRateDetails.price.taxes.tax.forEach(elementTax => {
        //         //         elementTax.equivalentCurrencyCode = walletCurrency;
        //         //         elementTax.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementTax.amount, false));
        //         //     });

        //         //     elementRoomRateDetails.price.fees !== undefined && elementRoomRateDetails.price.fees.forEach(elementFees => {
        //         //         elementFees.equivalentCurrencyCode = walletCurrency;
        //         //         elementFees.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementFees.amount, false));
        //         //     });
        //         // });
        //     });

        //     carBookingJsonRoe.data.paymentInfo.paymentMode[0].equivalentAmount = calculateRoeAmountAdditional(fareCurrency, carBookingJsonRoe.data.paymentInfo.paymentMode[0].amount, false);
        //     carBookingJsonRoe.data.paymentInfo.paymentMode[0].conversionRate = calculateRoeAmountAdditional(fareCurrency, carBookingJsonRoe.data.paymentInfo.paymentMode[0].amount, true).toString();
        // }
        // else {
        //     let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

        //     carBookingJsonRoe.data.carResults.forEach(elementCarResults => {
        //         //elementCarResults.vehRentalCore.vehVendorAvail.convertedRentalRate.vehicleCharges.totalFare.equivalentAmount
        //         elementCarResults.vehRentalCore.vehVendorAvail.convertedRentalRate.vehicleCharges.vehicleCharge.forEach(elementPrice => {
        //             elementPrice.equivalentCurrencyCode = walletCurrency;
        //             elementPrice.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementPrice.offeredPrice.priceOffered, false);
        //             elementPrice.equivalentAgentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUp !== null && elementPrice.agentMarkUp !== '' ? elementPrice.agentMarkUp : '0', false);
        //             elementPrice.equivalentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.markUp !== undefined && elementPrice.markUp !== null && elementPrice.markUp !== '' ? elementPrice.markUp : '0', false);
        //         });

        //         // elementCarResults.roomRateDetails.forEach(elementRoomRateDetails => {
        //         //     elementRoomRateDetails.price.equivalentCurrencyCode = walletCurrency;
        //         //     elementRoomRateDetails.price.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementRoomRateDetails.price.offeredPrice.priceOffered, false);

        //         //     elementRoomRateDetails.price.taxes !== undefined && elementRoomRateDetails.price.taxes.tax !== undefined && elementRoomRateDetails.price.taxes.tax.forEach(elementTax => {
        //         //         elementTax.equivalentCurrencyCode = walletCurrency;
        //         //         elementTax.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementTax.amount, false));
        //         //     });

        //         //     elementRoomRateDetails.price.fees !== undefined && elementRoomRateDetails.price.fees.forEach(elementFees => {
        //         //         elementFees.equivalentCurrencyCode = walletCurrency;
        //         //         elementFees.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementFees.amount, false));
        //         //     });
        //         // });
        //     });

        //     carBookingJsonRoe.data.paymentInfo.paymentMode[0].EquivalentAmount = calculateRoeAmountAdditional(fareCurrency, carBookingJsonRoe.data.paymentInfo.paymentMode[0].amount, false);
        //     carBookingJsonRoe.data.paymentInfo.paymentMode[0].ConversionRate = calculateRoeAmountAdditional(fareCurrency, carBookingJsonRoe.data.paymentInfo.paymentMode[0].amount, true).toString();
        // }
        sessionStorage.setItem("CarBookingData", JSON.stringify(carBookingJsonRoe));
    }

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    const errorHandlerTermsCondition = () => {
        setTermsCondition(null);
        setRefreshTermConditions(true);
    };

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        {confirmModel && (
            <ConfirmationModal
                title={confirmModel.title}
                message={confirmModel.message}
                onOk={okConfirmHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {termsCondition && (
            <TermsConditionsPopup
                title={termsCondition.title}
                message={termsCondition.message}
                onConfirm={errorHandlerTermsCondition}
            ></TermsConditionsPopup>
        )}



        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        {(status === 'pending' || statusPriceCheck === 'pending') &&
            <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mt-5">
                                <div className="row  position-relative gt-h-255 my-auto">
                                    {
                                        carBookingJSON.data.requestType === "OF" &&
                                        <LoadingCar action='requestTypeOf' ></LoadingCar>
                                    }
                                    {
                                        carBookingJSON.data.requestType === "ON" &&
                                        <LoadingCar action='booking' ></LoadingCar>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        {
            (status !== 'pending' && statusPriceCheck !== 'pending') &&
            <section id="theme_search_form_tour" className='mb-2'>
                <div className="container">
                    <div className="theme_search_form_area mb-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-md-12"><label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Car Summary</label></div>
                                <div className="tour_search_form">
                                    <div className="row">
                                        <div className="col-lg-12 position-relative">
                                            <div className="row div-reverse bbb">
                                                <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                                    <div className="col-md-12 col-md-push-3 tour_details_boxed_inner">
                                                        <CarItem
                                                            key={1}
                                                            id={1}
                                                            echoToken={''}
                                                            carResults={carBookingJSON.data.carResults[0]}
                                                        />
                                                        <hr className=''></hr>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                    <BookingDetails guestInfo={carBookingJSON.data}></BookingDetails>

                                                </div>
                                                <div className='col-md-12 d-none'>
                                                    <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                        <div className="accordion" id="accordionExampleNine">
                                                            <div className="accordion_flex_area  mt-2  ">

                                                                <div className="accordion-item col-md-12">
                                                                    <h2 className="accordion-header" id="headingNine">
                                                                        <button id='btnPriceSummaryCollapse' className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                            data-bs-target="#PriceSummaryCollapse" aria-expanded="true"
                                                                            aria-controls="PriceSummaryCollapse"> <span><i class="fas fa-money-check-alt me-2"></i>
                                                                                <span>Price Summary</span>
                                                                            </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="PriceSummaryCollapse" className="accordion-collapse collapse "
                                                                        aria-labelledby="headingNine" data-bs-parent="#accordionExampleNine">
                                                                        <div className="col-md-12">
                                                                            {/* <Pricesummary guestInfo={carBookingJSON.data}></Pricesummary> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div className="tour_details_boxed_inner">
                                                        <div className="accordion" id="accordionExample">
                                                            <GuestDetails guestInfo={carBookingJSON.data} onContinueButton={onContinueButton}></GuestDetails>
                                                            <ContactPerson guestInfo={carBookingJSON.data} onContinueButton={onContinueButton}></ContactPerson>
                                                            <AdditionalServices guestInfo={carBookingJSON.data} onContinueButton={onContinueButton}></AdditionalServices>
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side text-muted">
                                                                <h5>4</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button id="headingFour" className="text-muted accordion-button collapsed" type="button"
                                                                        data-bs-target="#collapseFour"
                                                                        aria-expanded="false" aria-controls="collapseFour">
                                                                        Payment
                                                                    </button>
                                                                </h2>

                                                                <div id="collapseFour" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">

                                                                    <div className="payment_filed_wrapper">
                                                                        <div className='row my-2'>
                                                                            {getConfigurationByBoolen("CAR_CREDIT_CARD") === true &&
                                                                                <div className="col-lg-4 col-md-6 col-sm-6  text-muted">
                                                                                    <div className="form-check" onClick={() =>
                                                                                        onChangeCardType("1")}>
                                                                                        <input className="form-check-input" type="radio" onClick={() =>
                                                                                            onChangeCardType("1")} name="flexRadioDefault" id="checkCreditCard" value="white" />
                                                                                        <label className="form-check-label" for="checkCreditCard">
                                                                                            Specify Credit Card
                                                                                        </label>
                                                                                    </div>
                                                                                </div>}
                                                                            {profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true ? <div className="col-lg-5 col-md-6 col-sm-6  text-muted" >
                                                                                <div className="form-check" onClick={() =>
                                                                                    onChangeCardType("2")}>
                                                                                    <input className="form-check-input" type="radio" onClick={() =>
                                                                                        onChangeCardType("2")} name="flexRadioDefault" id="checkSabreCreditCard" value="white" />
                                                                                    <label className="form-check-label" for="checkSabreCreditCard">
                                                                                        Sabre Profile Credit Card
                                                                                    </label>
                                                                                </div>
                                                                            </div> : <div className="col-lg-2 col-md-6 col-sm-12 text-muted d-none" ><span id="checkSabreCreditCard"></span></div>}
                                                                            {getConfigurationByBoolen("CAR_WALLET_PAYMENT") && isWalletActive === 'true' ? <div className="col-lg-2 col-md-6 col-sm-6  text-muted" >
                                                                                <div className="form-check" onClick={() =>
                                                                                    onChangeCardType("3")}>
                                                                                    <input className="form-check-input" type="radio" onClick={() =>
                                                                                        onChangeCardType("3")} name="flexRadioDefault" id="checkWallet" value="white" />
                                                                                    <label className="form-check-label" for="checkWallet">
                                                                                        Wallet
                                                                                    </label>
                                                                                </div>
                                                                            </div> : <span id="checkWallet"></span>}
                                                                        </div>
                                                                        <div id='dvTotalPriceTop' className="col-lg-12 my-3 d-none" >
                                                                            <div className="col-md-12 h5 text-muted"> Price Summary</div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Base Rate:</div>
                                                                                <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnBaseRateTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.baseRateFare.currencyCode,
                                                                                    }).format(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.baseRateFare.amount)}
                                                                                </span></div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Taxes & Fees: </div>
                                                                                <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnTaxesTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode,
                                                                                    }).format(totalTax)}
                                                                                </span></div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-7">
                                                                                    <hr className="my-1">
                                                                                    </hr>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4 ">  Total Rate: </div>
                                                                                <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnTotalRateTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode,
                                                                                    }).format(carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.baseRateFare.amount)}
                                                                                </span></div>
                                                                            </div>
                                                                            {
                                                                                carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode !== undefined && carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode !== '' && carBookingJSON.data.carResults[0].vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3 col-sm-4 col-4">  Eq. Rate: </div>
                                                                                    <div className="col-md-4 text-end h6 col-sm-8 col-8"><span id="spnEqRateTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                            style: 'currency',
                                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                        }).format(calculateRoeAmountAdditional(fareCurrency, totalPrice, false))}
                                                                                    </span></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (getConfigurationByBoolen("CAR_CREDIT_CARD") === true || getConfigurationByBoolen("CAR_SABRE_PROFILE_CREDIT_CARD") === true || getConfigurationByBoolen("CAR_WALLET_PAYMENT") === true) &&
                                                                            <>
                                                                                <div style={{ display: "block" }} id="divCreditCard">
                                                                                    <div className="row"  >
                                                                                        <div className="col-lg-6">
                                                                                            <div className='control'>
                                                                                                <div className="form-group">
                                                                                                    <label className='h6 text-primary mb-2' htmlFor='cardType'> Card Type<span className="error-icon"> *</span></label>
                                                                                                    <Dropdown
                                                                                                        placeholder="Please Select"
                                                                                                        onChange={cardTypeChangeHandler}
                                                                                                        options={creditCardOptions}
                                                                                                        value={cardType !== "" ? creditCardOptions.filter(option => option.value === cardType) : ''}
                                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                                        controlClassName='form-control control form-select bg_input mb-0'
                                                                                                    />
                                                                                                    {cardTypeInputHasError &&
                                                                                                        <p className="error-text">{t('validationCardType')}</p>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="col-lg-6">
                                                                                            <div className='control'>
                                                                                                <div className="form-group">
                                                                                                    <label className='h6 text-primary mb-2' htmlFor='Card Number'> Card Number<span className="error-icon"> *</span></label>

                                                                                                    <input maxLength={16} className="form-control bg_input" type='password' autoComplete='new-password' required
                                                                                                        id='cardNumber'
                                                                                                        ref={cardNumberInputRef}
                                                                                                        onBlur={onBlurCheck}
                                                                                                    />
                                                                                                    {cardNumberInputHasError &&
                                                                                                        <p className="error-text">{t('validationCardNumber')}</p>
                                                                                                    }
                                                                                                    {cardNumberInvalidInputError &&
                                                                                                        <p className="error-text">{t('validationCardNumberInvalidData')}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                                            <div className='control'>
                                                                                                <div className="form-group">
                                                                                                    <label className='h6 text-primary mb-2' htmlFor='cardHolderFirstName'>First Name<span className="error-icon"> *</span></label>
                                                                                                    <input maxLength={30} className="form-control bg_input" type='text' required
                                                                                                        id='cardHolderFirstName'
                                                                                                        ref={cardHolderFirstNameInputRef}
                                                                                                        onBlur={onBlurCheck}
                                                                                                    />
                                                                                                    {cardHolderFirstNameInputHasError &&
                                                                                                        <p className="error-text">{t('validationCardHolderFirstName')}</p>
                                                                                                    }
                                                                                                    {cardHolderFirstNameInvalidInputError &&
                                                                                                        <p className="error-text">{t('validationCardHolderFirstNameInvalidData')}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                                            <div className='control'>
                                                                                                <div className="form-group">
                                                                                                    <label className='h6 text-primary mb-2' htmlFor='cardHolderName'>Last Name<span className="error-icon"> *</span></label>
                                                                                                    <input maxLength={30} className="form-control bg_input" type='text' required
                                                                                                        id='cardHolderLastName'
                                                                                                        ref={cardHolderLastNameInputRef}
                                                                                                        onBlur={onBlurCheck}
                                                                                                    />
                                                                                                    {cardHolderLastNameInputHasError &&
                                                                                                        <p className="error-text">{t('validationCardHolderLastName')}</p>
                                                                                                    }
                                                                                                    {cardHolderLastNameInvalidInputError &&
                                                                                                        <p className="error-text">{t('validationCardHolderLastNameInvalidData')}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6">
                                                                                            <div className='control'>
                                                                                                <div className="form-group">
                                                                                                    <label className='h6 text-primary mb-2' htmlFor='dateOfExpiry'> Date of Expiry<span className="error-icon"> *</span></label>
                                                                                                    <div className='row'>

                                                                                                        <div className='col-md-6'>
                                                                                                            <Dropdown
                                                                                                                placeholder="Month"
                                                                                                                onChange={expireMonthChangeHandler}
                                                                                                                options={monthList}
                                                                                                                //value={expiryMonth!==''?monthList.filter(option => option.value === expiryMonth):''}

                                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                            />
                                                                                                            {expiryMonthInputHasError &&
                                                                                                                <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className='col-md-6 margin-top-15'>
                                                                                                            <Dropdown
                                                                                                                placeholder="Year"
                                                                                                                onChange={expireYearChangeHandler}
                                                                                                                options={expiryYearList}
                                                                                                                //value={expiryYearList.filter(option => option.value === expiryYear)}

                                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                                            />
                                                                                                            {expiryYearInputHasError &&
                                                                                                                <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                            }
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    {dateofExpiryInputHasError &&
                                                                                                        <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                    }
                                                                                                    {dateofExpiryInvalidInputError &&
                                                                                                        <p className="error-text">{t('validationDateofExpiryInvalidData')}</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='control input-placeholder'>
                                                                                            <label htmlFor='address' className='h6 text-primary mb-2'>Street Address<span className="error-icon"> *</span></label>
                                                                                            <textarea id='address'
                                                                                                value={address}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => setAddress(e.target.value)}
                                                                                                rows={2} cols={100} maxLength={300}
                                                                                                onBlur={onBlurCheck}
                                                                                                ref={addressInputRef}
                                                                                            />
                                                                                        </div>
                                                                                        {addressInputHasError &&
                                                                                            <span className="error-text col-lg-12 mt-3">{t('validationAddress')}</span>
                                                                                        }
                                                                                        {addressInvalidInputError &&
                                                                                            <span className="error-text col-lg-12 mt-3">{t('validationAddressInvalidData')}</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='row mt-4'>
                                                                                        <div className='col-md-6'>
                                                                                            <div className='control'>
                                                                                                <label id='lblCountry ' className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}<span className="error-icon"> *</span></label>
                                                                                                <CountryDropDownList countryCodeValue={countryCodeValue}
                                                                                                    countryNameText={countryNameText}
                                                                                                    exposedControlType={exposedControlType}
                                                                                                    currentInputValue={countryCode} />
                                                                                                {countryCodeInputHasError &&
                                                                                                    <p className="error-text">{t('validationCountryCode')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-6'>
                                                                                            <div className='control'>
                                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>{t('state')}<span className="error-icon"> *</span></label>
                                                                                                <StateDropDownList stateCodeValue={stateCodeValue}
                                                                                                    stateNameText={stateNameText}
                                                                                                    currentInputValue={stateCode}
                                                                                                    currentInputText={stateName}
                                                                                                    exposedControlType={exposedControlType}
                                                                                                    countryCode={countryCode} />
                                                                                                {stateCodeInputHasError &&
                                                                                                    <p className="error-text">{t('validationStateCode')}</p>
                                                                                                }
                                                                                                {stateNameInputHasError &&
                                                                                                    <p className="error-text">{t('validationStateName')}</p>
                                                                                                }
                                                                                                {stateNameInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationStateNameInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        {/*Task 4949: Change city dropdown and textbox on flight, single PNR and car booking.*/}
                                                                                        <div className='col-md-6'>
                                                                                            <div className='control'>
                                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}<span className="error-icon"> *</span></label>
                                                                                                <input maxLength={100} className="form-control form-control-sm" type='text'
                                                                                                    id='cityName'
                                                                                                    ref={cityNameInputRef}
                                                                                                    onBlur={onBlurCheck}

                                                                                                />
                                                                                                {cityNameInputHasError &&
                                                                                                    <p className="error-text">{t('validationCityName')}</p>
                                                                                                }
                                                                                                {cityNameInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-6 col-sm-12'>
                                                                                            <div className='control input-placeholder'>
                                                                                                <label htmlFor='postalCode' className='h6 text-primary mb-2'>{t('postalCode')}<span className="error-icon"> *</span></label>
                                                                                                <input maxLength={12} className="form-control form-control-sm" type='text' required
                                                                                                    id='postalCode'
                                                                                                    ref={postalCodeInputRef}
                                                                                                    onChange={onBlurCheck}
                                                                                                />
                                                                                                {postalCodeInputHasError &&
                                                                                                    <p className="error-text">{t('validationPostalCode')}</p>
                                                                                                }
                                                                                                {postalCodeInvalidInputError &&
                                                                                                    <p className="error-text">{t('validationPostalCodeInvalidData')}</p>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: "block" }} id="divWallet" className='mt-4'>
                                                                                    <div className="col-lg-12">
                                                                                        <div className='h6 text-primary ' ><i className="fa-solid fa-circle text-12px"></i> Current Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                            style: 'currency',
                                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                        }).format(currentWalletAmount !== undefined && currentWalletAmount !== null && currentWalletAmount !== '' ? currentWalletAmount : '0')}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12">
                                                                                        <div className='h6 text-primary mb-2' > <i className="fa-solid fa-circle text-12px"></i> Total Price: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                            style: 'currency',
                                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                        }).format(calculateRoeAmountAdditional(fareCurrency, totalPrice, false))}</span></div>
                                                                                    </div>
                                                                                    <div className="col-lg-12">
                                                                                        <div className='h6 text-primary mb-2' ><i className="fa-solid fa-circle text-12px"></i>  Remaining Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                            style: 'currency',
                                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                        }).format(parseFloat(RemainingWalletAmount))}</span></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: "block" }} id="divWalletEror" className='mt-4'>
                                                                                    <div className="col-lg-12">
                                                                                        <p className="error-text">{t('inSufficientWalletBalance')}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className="col-lg-5 col-md-8 col-sm-8 my-auto">
                                                                                        <br></br>
                                                                                        <p id='requiredIndicatorPaymentSection'><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                                    </div>
                                                                                    <div className='d-inline-flex'>
                                                                                        <div className="me-3 margin-sm-10" style={{ width: "2%" }}> <input className="form-check-input tandc-payment-option" type="checkbox" id="chkTandcPayment" onChange={(e) => onTandcPaymentChangeHandler(e)}></input></div>
                                                                                        <div className="" style={{ width: "98%" }}>  <span>I have read and agree to the car policies and the</span>
                                                                                            <span><a className="footer_link_area cursor-pointer text-sky-blue" onClick={openTermAndCondtion}> terms and conditions.</a></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span className='error-text mt-2' id='spnTandCPaymentMessage'></span>
                                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                        <div className="float-end text-end">

                                                                                            <br></br>
                                                                                            {checkSecurityDetails("Book", "Car", "Book_Car_Book") && getConfigurationByBoolen("CAR_ALLOW_BOOKING_ITINERARY") ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15" id='btnSendRequest' onClick={SendRequest}>
                                                                                                Send Request
                                                                                            </button> : <span id='btnSendRequest'></span>}


                                                                                            {checkSecurityDetails("Book", "Car", "Book_Car_Book") && getConfigurationByBoolen("CAR_ALLOW_BOOKING") === true
                                                                                                ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15" id='btnOnBook' onClick={OnBook}>

                                                                                                    Book
                                                                                                </button> : <sapn id='btnOnBook'></sapn>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

    </Fragment>
};

export default Booking;