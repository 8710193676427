

import { Fragment, useState } from 'react';
import { CarDetailsJSON } from './../../../utils/CarDetails';
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import { FLIGHTSEARCH } from '../../../navigation/CONSTANTS.js';
import ErrorModal from '../../../components/Common/Error/ErrorModal.js';

const CarItem = (props) => {

    const [error, setError] = useState(null);

    const getVehicleImage = (vehicle) => {
        const defaultCarImage = process.env.REACT_APP_CAR_LOG_URL + "default-confirm-car.png"
        // Safely access the array of images using optional chaining
        const images = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.images?.image;

        // Use Array.find to locate the first image of type 'LARGE'
        const largeImage = images?.find(image => image.type === 'LARGE' || image.type === 'Large' || image.type === 'large' || image.type === undefined || image.type === null || image.type === '');
        // Return the found image URL or the default car image
        return largeImage?.url || defaultCarImage;
    };

    const getSeats = (vehicle) => {
        // Safely access the seat quantity using optional chaining
        const noOfSeats = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.seatBeltsInfo?.seatBelts?.quantity;

        // Return JSX or null if no seats are defined
        return noOfSeats ? (
            <span className="fw-bold mt-2 me-4">
                <i className="fas fa-user text-primary"></i> {`${noOfSeats} Seats`}
            </span>
        ) : null;
    };

    const getTransMissionType = (vehicle) => {
        // Safely access the seat quantity using optional chaining
        const type = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.transmissionType;

        // Return JSX or null if no transmission are defined
        return type ? (
            <span className="fw-bold mt-2 me-4"> <i
                className="fas fa-cog text-primary" aria-hidden="true"></i><span> {`${type}`}</span>
            </span>
        ) : null;
    };

    const getBaggage = (vehicle) => {
        // Safely access the baggage array using optional chaining
        const bags = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.baggageInfo?.baggage;
        // Find the first 'Large' bag using Array.find
        const largeBag = bags?.find(bag => bag.size === 'Large' || bag.size === 'LARGE' || bag.size === 'large');

        // Determine bag information if a large bag exists
        const bagInfo = largeBag && parseInt(largeBag.quantity) > 0
            ? `${largeBag.quantity} Large bag${parseInt(largeBag.quantity) > 1 ? 's' : ''}`
            : null;

        // Return JSX or null if no large bag is found
        return bagInfo ? (
            <span className="fw-bold mt-2 me-4">
                <i className="fa fa-suitcase text-primary" aria-hidden="true"></i>
                <span> {bagInfo}</span>
            </span>
        ) : null;
    };

    const getMileage = (vehicle) => {
        // Safely access the mileage array using optional chaining
        const mileage = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.convertedRentalRate?.vehicleCharges?.vehicleCharge?.[0]?.mileageAllowance;
        // Return JSX or null if no large bag is found
        return mileage ? (
            <div className=" text-muted text-14px mt-2"><span className="fw-bold"> <i
                className="fas fa-tachometer-alt text-primary"></i>
                <span> Unlimited Mileage</span></span> &nbsp;
                &nbsp;
            </div>
        ) : '';
    };

    const getRentalDays = (vehicle) => {
        const rentalDays = vehicle?.vehRentalCore?.vehLocationInfo?.rentalDays;
        const rentalHours = vehicle?.vehRentalCore?.vehLocationInfo?.rentalHours;
        if (rentalDays && rentalDays !== '0') {
            return `Price for ${rentalDays} day${parseInt(rentalDays) > 1 ? 's' : ''}`
        } else if (rentalHours && rentalHours !== '0') {
            return `Price for ${rentalHours} hour${parseInt(rentalHours) > 1 ? 's' : ''}`
        }
    }

    const getVechileCompanyLogo = (vehicle) => {
        //const defaultLogo = 'https://uat.fareladder.com/assets/logos/cars/comapny-car.png'
        const defaultLogo = process.env.REACT_APP_CAR_LOG_URL + "comapny-car.png";
        const mileage = vehicle?.vehRentalCore?.vehVendorAvail?.vendorInfo?.logo;
        return mileage ? mileage : defaultLogo;

    }
    const getFuelType = (vehicle) => {
        const fuelType = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.fuelType;
        return fuelType ? (
            <span class="fw-bold mt-2"> <i class="fa-solid fa-gas-pump text-primary"></i><span>&nbsp;
                {fuelType}</span>
            </span>
        ) : '';

    }
    const onBookNow = (id) => {
        sessionStorage.setItem('carMailSend', 'false');
        if (getConfigurationByValue("ACCESS_GLOBETROTTER") === '3' && localStorage.getItem('SessionLoginDateTime') === null) {
            window.location.pathname = `${FLIGHTSEARCH}`;
        }
        else {

            let detailsJSON = CarDetailsJSON(props.carResults)
            sessionStorage.setItem("CarDetails", JSON.stringify(detailsJSON))
            if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
                openInNewTab('CarDetails?travelreq=true');
            }
            else {
                openInNewTab('CarDetails');
            }
        }
    }

    const openInNewTab = url => {
        window.open(url);
    };

    const getSpecialTag = (vehicle) => {
        // Safely access the special code using optional chaining
        const specialCode = vehicle?.vehRentalCore?.vehVendorAvail?.vehAvail?.convertedRentalRate?.corporateDiscountApplied;
        // Return JSX or null if no special code is found
        return specialCode ? (
            <span class="badge bg-primary gt-exclusive-text">Special Fare</span>
        ) : '';
    }
    const dummyFunction = (id) => {
    }


    const getLocation = (vehicle) => {
        const pickUp = vehicle?.vehRentalCore?.vehLocationInfo?.pickUpLocation?.locationInfo?.addressDetail;
        const dropOff = vehicle?.vehRentalCore?.vehLocationInfo?.returnLocation?.locationInfo?.addressDetail;

        // Compare pickup and drop-off details
        if (
            pickUp?.city?.name !== dropOff?.city?.name ||
            pickUp?.address1 !== dropOff?.address1 ||
            pickUp?.address2 !== dropOff?.address2
        ) {
            return (<>
                <div className="hotel-star-address">
                    <address className="hotel-address"><span className="fw-bold text-muted"> Pick-up </span>&nbsp;
                        {`${pickUp?.address1 || ''}, `}
                        {`${pickUp?.address2 || ''}${pickUp.address2 === undefined ? '' : ', '}`}
                        {`${pickUp?.city?.name || ''}, `}
                        {`${pickUp?.country?.name || ''}`}
                    </address>
                </div>
                <div className="hotel-star-address">
                    <address className="hotel-address">
                        <span className="fw-bold text-muted">Drop-off</span>&nbsp;
                        {`${dropOff?.address1 || ''}, `}
                        {`${dropOff?.address2 || ''}${dropOff.address2 === undefined ? '' : ', '}`}
                        {`${dropOff?.city?.name || ''}, `}
                        {dropOff?.country?.name || ''}
                    </address>
                </div>
            </>)

        } else {

            return (
                <div className="hotel-star-address">
                    <address className="hotel-address">
                        <span className="fw-bold text-muted">Pick-up & Drop-off</span>&nbsp;
                        {`${dropOff?.address1 || ''}, `}
                        {`${dropOff?.address2 || ''}${dropOff.address2 === undefined ? '' : ', '}`}
                        {`${dropOff?.city?.name || ''}, `}
                        {dropOff?.country?.name || ''}
                    </address>
                </div>
            );


        }
    };
    function errorHandler() {
        setError(null);
    }

    return <Fragment>

        {error &&
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        }

        <div className="hotel-list-cn clearfix gt-car-section carListItemDetails">
            <div className="carListItem hotel-list-item position-relative perk-border light-yellow-bg hotel-list-item2">
                <figure className="hotel-img float-left"><a className='gt-car-img' title="">
                    <img
                        src={getVehicleImage(props.carResults)}
                        alt="" lazy={'load'} /> </a>
                    {getSpecialTag(props.carResults)}
                </figure>
                <span className="hotel-flag cursor-pointer" id="gtExclusive15">
                    <img
                        src={getVechileCompanyLogo(props.carResults)}
                        lazy={'load'}
                        alt=""
                    />
                </span>
                <div className="hotel-text">
                    <div class="hotel-name mb-2"><a id={'carName' + props.id} class="col-md-9">
                        {props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.category || ''} {props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.type || ''}
                    </a>                     
                        <span class="h6 text-muted">{props.carResults?.vehRentalCore?.vehVendorAvail?.vehAvail?.vehicle?.vehMakeAndModel || ''}</span>
                    </div>
                    <div className=" text-muted text-14px CarItemFeaturesIcon">
                        {getSeats(props.carResults)}
                        {getTransMissionType(props.carResults)}
                        {getBaggage(props.carResults)}
                        {getFuelType(props.carResults)}
                    </div>
                    {getMileage(props.carResults)}
                    {/* <div className="hotel-star-address">
                        <address className="hotel-address"><span className="fw-bold text-muted"> Pick-up </span>&nbsp;
                            {props.carResults?.vehRentalCore?.vehLocationInfo?.pickUpLocation?.locationInfo?.addressDetail?.city?.name} - &nbsp;
                            {`${props.carResults?.vehRentalCore?.vehLocationInfo?.pickUpLocation?.locationInfo?.addressDetail?.address1} `}
                            {props.carResults?.vehRentalCore?.vehLocationInfo?.pickUpLocation?.locationInfo?.addressDetail?.address2}
                        </address>
                    </div> */}
                    {getLocation(props.carResults)}
                    <div className="row">
                        <div className="col-md-7 col-sm-12 my-auto"><label className="mt-2 h5 text-primary"><span
                            className="badge d-none h6 rounded-pill mb-0 bg-warning"><i
                                className="fa-solid fa-circle-info"></i><span className="ms-1"></span>Important
                            Info</span></label>
                            <div className="text-primary fw-bold"></div>
                        </div>
                        <div className="col-md-5  col-sm-12 hotel-service text-end">
                            <div className="price special-price text-primary fw-bold h3 mb-0">
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: props.carResults.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.currencyCode
                                }).format(props.carResults.vehRentalCore.vehVendorAvail.vehAvail.convertedRentalRate.vehicleCharges.totalFare.amount)}
                            </div>
                            <div className="text-muted text-14px">{getRentalDays(props.carResults)}<br></br></div>
                        </div>
                        <div className="col-md-9  col-sm-12 my-auto">
                            <div className="btn btn-primary d-none btn-sm px-2 text-14px  text-white abx" id="sendMail3"><i
                                className="fas fa-envelope"></i> Email Quote</div>
                        </div>

                        <div className='col-md-3  col-sm-12 text-end'>
                            {!props.isFromDetailPage && props.showData === '1' &&
                                checkSecurityDetails("Book", "Car", "Book_Car_Details") &&
                                <button className="my-2 btn btn_theme btn_sm" onClick={() =>
                                    onBookNow(props.id)}>
                                    Book
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="row"></div>
            </div>
        </div>
    </Fragment>

};
export default CarItem;
