import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";
import { getConfigurationByBoolen, getConfigurationByValue } from "../../../../utils/ConfigurationsFunction";
const RulesItem = (props) => {
    const { t } = useTranslation(['translation.Rules']);
    const [confirm, setConfirm] = useState();
    const [isServiceRequestEnabled] = useState(getConfigurationByBoolen("ALLOW_SERVICE_REQUEST"));

    let postionValue = "";
    if (props.positionArray.length !== 0) {
        props.positionArray.forEach(element => {
            if (postionValue === '') {
                postionValue = element.positionName;
            } else {
                postionValue = postionValue + ", " + element.positionName;
            }

        });
    }

    function deleteFormHandler(props) {
        props.onDelete(
            {
                "id": props.id,
                "traceID": "",
                "userID": "",
                "createdDate": "2024-04-25T07:49:34.408Z",
                "ipAddress": "",
                "position": [
                    {
                        "positionID": ""
                    }
                ],
                "tripReasonID": "",
                "isFlight": true,
                "isHotel": true,
                "isServiceRequest": true,
                "recommendations": "",
                "highestClassCabin": "",
                "fromRegion": "",
                "toRegion": "",
                "rbdDetails": [
                    {
                        "rbdName": ""
                    }
                ],
                "preferredAirline": [
                    {
                        "preferredAirlineCode": ""
                    }
                ],
                "maxEmployeesAllowedFlight": "",
                "lowestFareRule": "",
                "marginRule": true,
                "marginRuleType": "",
                "cheapestFlightWithLowestFare": "",
                "allowTravelerToUpgradeClass": "",
                "allowToUpgradeClass": "",
                "fastestFilghtType": "",
                "allowFastFlightWithMargin": "",
                "timeBeforeMeetingStartTime": "",
                "timeRange": "",
                "hotelRegion": "",
                "rating": "",
                "lowestRateRule": "",
                "hotelMarginRule": true,
                "hotelMarginRuleType": "",
                "cheapestHotelWithLowestRate": "",
                "preferredHotel": [
                    {
                        "preferredHotelCode": ""
                    }
                ],
                "tripReasonApprovalProcess": "",
                "officeID": ""
            }

        );

    }
    function onConfirm() {
        setConfirm(true);
    }

    function okHandler() {
        deleteFormHandler(props);
        setConfirm(false);
    };

    function cancelHandler() {
        setConfirm(false);
    };
    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        <div className="">
            <div className="row" key={props.id}>
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-1">
                    <div className="card text-dark card-bg-blue mb-2">
                        <div className="card-body">
                            <div className="col-md-12 my-auto">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.tripReasonName}
                                </h6>

                            </div>
                            <hr className="my-1" />
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p className="card-text mb-0"><strong>Flight: </strong>{props.isFlight ? "Yes" : "No"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="card-text mb-0"><strong>Hotel: </strong>{props.isHotel ? "Yes" : "No"}</p>
                                        </div>
                                        {isServiceRequestEnabled &&
                                            <div className="col-md-4">
                                                <p className="card-text mb-0"><strong>Service Request: </strong>{props.isServiceRequest ? "Yes" : "No"}</p>
                                            </div>
                                        }
                                    </div>
                                    {props.isFlight && <p className="card-text mb-0"><strong>From Region: </strong>{props.fromRegion}</p>}
                                    {props.isFlight && <p className="card-text mb-0"><strong>To Region: </strong>{props.toRegion}</p>
                                    }
                                    {props.isHotel && props.hotelRegion !== '' && <p className="card-text mb-0"><strong>Hotel Region: </strong>{props.hotelRegion}</p>}

                                    {postionValue !== "" && <p className="card-text mb-0"><strong>{getConfigurationByValue(getConfigurationByValue('POLICY_TYPE'))}: </strong>{postionValue}</p>}
                                </div>
                                <div className="col-md-2 my-auto">
                                    <div className='float-lg-end float-md-end'>
                                        {checkSecurityDetails("Settings", "Policy", "Settings_Policy_View")
                                            && <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdatePolicy/${props.id}/0`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>}
                                        {checkSecurityDetails("Settings", "Policy", "Settings_Policy_Update") && <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdatePolicy/${props.id}/1`, edit: 1, }}>
                                            <i className="fa-solid fa-pen"></i>
                                        </Link>}
                                        {checkSecurityDetails("Settings", "Policy", "Settingst_Policy_Delete") &&
                                            <button title="Delete" className="btn btn-sm btn-danger ms-1 gt-border-radius-50 gt-red-btn text-white" onClick={() => { onConfirm() }}><i className="fas fa-trash-alt"></i></button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </Fragment>
}

export default RulesItem;