import { Fragment } from "react";

const LoadingNeutral = () => {
    return <Fragment>
        <div className="loader-login">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className='loader-login-text'>
            Please wait while we prepare your experience...
        </div>
    </Fragment>
};

export default LoadingNeutral;