import sorter from "sort-nested-json";
import {
    DECRYPT_DATA,
    ENCRYPT_DATA
} from './services/CONSTANTS';
import { decompress } from '@amoutonbrady/lz-string';
//dompurify strips raw html of any xss vulnerabilities
import purify from 'dompurify';
import { getAuthToken } from "./services/Authentication-API";
import { getConfigurationByBoolen } from '../../WebBookingEngine/src/utils/ConfigurationsFunction';

export function computeDollars(btc, btcRate) {
    return btc * btcRate;
}

export function formatDollars(dollars) {
    dollars = (Math.round(dollars * 100) / 100).toFixed(2);
    dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return dollars;
}

export async function encrypted(strPlainText) {
    const onEncrypted = ({
        data: strPlainText
    });

    const response = await fetch(ENCRYPT_DATA(), {
        method: 'POST',
        body: JSON.stringify(onEncrypted),
        headers: {
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    let loadedData = [];

    for (let i in data) {
        if (data['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = data[i];

            }
        }
    }
    strPlainText = loadedData.data;
    return strPlainText.toString();
}



export async function decrypted(strDecrypted) {
    const onEncrypted = ({
        data: strDecrypted
    });

    const response = await fetch(DECRYPT_DATA(), {
        method: 'POST',
        body: JSON.stringify(onEncrypted),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });
    const data = await response.json();
    let loadedData = [];

    for (let i in data) {

        if (data['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = data[i];

            }
        }
    }
    strDecrypted = loadedData.data;
    return await strDecrypted.toString();
}

//The previous decrypt function doesn't have error handling in place (For e.g., if the encrypted string is incorrect).
//This function handles that situation by returning empty string from the function instead of crashing the page.
export async function decryptWithErrorHandling(encrypted) {

    let decryptResponse = {
        data: '',
        errors: null
    };

    const request = ({
        data: encrypted
    });

    const response = await fetch(DECRYPT_DATA(), {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
        },
    });

    const httpResponse = await response;

    if (httpResponse.ok) {
        const responseData = await httpResponse.json();

        decryptResponse.errors = responseData.errors;

        if (responseData.errors.status === 'FALSE') {
            decryptResponse.data = responseData.data.data;
        }
    }
    //For non OK condition, we can't write/include the error response because the format usually is not in json format

    //empty string will be returned for data property if there's error
    return decryptResponse;
}

export function blobToUint8Array(b) {
    var
        xhr = new XMLHttpRequest(),
        i,
        ui8;

    xhr.open('GET', b, false);
    xhr.send();

    URL.revokeObjectURL(b);

    ui8 = new Uint8Array(xhr.response.length);

    for (i = 0; i < xhr.response.length; ++i) {
        ui8[i] = xhr.response.charCodeAt(i);
    }

    return ui8;
}

export const appendScript = (scriptToAppend, idValue = 'test', asyncValue = false, deferValue = false) => {
    const script = document.createElement("script");
    script.type = 'text/javascript';
    script.id = idValue;
    script.async = asyncValue;
    script.defer = deferValue;
    script.src = scriptToAppend;
    document.body.appendChild(script);
}

export const removeScript = (scriptToremove) => {
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
        if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
            && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
}

export const getBase64 = file => {
    return new Promise(resolve => {

        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object               
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL);
        };
    });
};

export const generateUUID = (digits) => {

    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
    let uuid = [];
    for (let i = 0; i < digits; i++) {
        uuid.push(str[Math.floor(Math.random() * str.length)]);
    }
    return uuid.join('');
};


export const sortData = (data, columnName, ascending) => {
    if (document.querySelector("#sortBy") !== null) {
        if (document.querySelector("#sortBy").selectedIndex === 0) {
            return data.sort((A, B) => {

                return A[columnName].toLowerCase().localeCompare(B[columnName].toLowerCase()) < 0 ? -1 : 1;
            });
        }
        else {
            return data.sort((A, B) => {
                return A[columnName].toLowerCase().localeCompare(B[columnName].toLowerCase()) < 0 ? 1 : -1;
            });
        }
    }
    else {
        return data.sort((A, B) => {
            if (ascending) {
                return A[columnName].toLowerCase().localeCompare(B[columnName].toLowerCase()) < 0 ? -1 : 1;
            } else {
                return A[columnName].toLowerCase().localeCompare(B[columnName].toLowerCase()) < 0 ? 1 : -1;
            }
        });
    }
};


export const sortWithTwoData = (data, columnName, columnName2) => {

    return data.sort((a, b) => (a[columnName].localeCompare(b[columnName]) || a[columnName2] - b[columnName2]));

};

export const maxSelectFile = (event) => {
    let files = event.target.files // create file object
    if (files.length > 1) {
        //  const msg = 'Only 3 images can be uploaded at a time'
        event.target.value = null // discard selected file
        // console.log(msg)
        return false;
    }
    return true;
}

//allowedExtensions is mandatory.
//It is an array containing all file extensions that will be checked / verified.
//e.g. ['jpg', 'jpeg', 'png', 'gif', 'ico']
export const checkMimeType = (event, allowedExtensions) => {

    if (typeof allowedExtensions === 'undefined' || allowedExtensions.length === 0) {
        return false;
    }

    //add more mime types in future as needed
    const mimeTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        ico: 'image/x-icon'
    };

    //getting file object
    let files = event.target.files
    //define message container
    let err = ''
    // list allow mime type
    const types = [];

    allowedExtensions.forEach(extension => {
        if (mimeTypes[extension]) {
            types.push(mimeTypes[extension]);
        }
    });

    // loop access array
    for (let x = 0; x < files.length; x++) {
        // compare file type find doesn't matach
        if (types.every(type => files[x].type !== type)) {
            // create error message and assign to container   
            err += files[x].type + ' is not a supported format\n';
        }
    };

    if (err !== '') { // if message not same old that mean has error 
        //event.target.value = null // discard selected file

        return false;
    }
    return true;
}
export const filterFlightResults = (filterOptions, perPersonFare, isOutboundSegment, outboundTakeOffTime, outboundLandingTime, outboundDuration, outboundStopoverDuration, inboundTakeOffTime, inboundLandingTime, inboundDuration, inboundStopoverDuration, sortValue, sortBy) => {
    let pricedItinerary = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));

    let pricedItineraryTemp = filterNumberOfStops(pricedItinerary);

    if (getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE")) {
        pricedItineraryTemp = filterFareTypes(pricedItineraryTemp);
    }

    pricedItineraryTemp = filterRefundableTypes(pricedItineraryTemp);

    ////pricedItineraryTemp = filterNoBaggage(pricedItineraryTemp);

    pricedItineraryTemp = filterSegment(pricedItineraryTemp);

    if (perPersonFare !== undefined && perPersonFare.length > 1) {
        pricedItineraryTemp = filterPerPersonPrice(pricedItineraryTemp, perPersonFare);
    }

    pricedItineraryTemp = filterClass(pricedItineraryTemp);

    if (isOutboundSegment === true) {

        if (outboundTakeOffTime.start !== undefined) {
            outboundTakeOffTime = formatDateForFlightFilter(outboundTakeOffTime);
            pricedItineraryTemp = filterOutboundTakeOffTime(pricedItineraryTemp, outboundTakeOffTime);
        }

        if (outboundLandingTime.start !== undefined) {
            outboundLandingTime = formatDateForFlightFilter(outboundLandingTime);
            pricedItineraryTemp = filterOutboundLandingTime(pricedItineraryTemp, outboundLandingTime);
        }

        if (outboundDuration !== undefined && outboundDuration.length > 1) {
            pricedItineraryTemp = filterOutBoundDuration(pricedItineraryTemp, outboundDuration);
        }

        if (outboundStopoverDuration !== undefined && outboundStopoverDuration.length > 1) {
            pricedItineraryTemp = filterStopoverDuration(pricedItineraryTemp, outboundStopoverDuration);
        }
    }
    else {

        if (inboundTakeOffTime.start !== undefined) {
            inboundTakeOffTime = formatDateForFlightFilter(inboundTakeOffTime);
            pricedItineraryTemp = filterInboundTakeOffTime(pricedItineraryTemp, inboundTakeOffTime);
        }

        if (inboundLandingTime.start !== undefined) {
            inboundLandingTime = formatDateForFlightFilter(inboundLandingTime);
            pricedItineraryTemp = filterInboundLandingTime(pricedItineraryTemp, inboundLandingTime);
        }

        if (inboundDuration !== undefined && inboundDuration.length > 1) {
            pricedItineraryTemp = filterInBoundDuration(pricedItineraryTemp, inboundDuration);
        }

        if (inboundStopoverDuration !== undefined && inboundStopoverDuration.length > 1) {
            pricedItineraryTemp = filterStopoverDuration(pricedItineraryTemp, inboundStopoverDuration);
        }
    }

    pricedItineraryTemp = filterAirline(pricedItineraryTemp);

    pricedItineraryTemp = filterAlliance(pricedItineraryTemp);

    pricedItineraryTemp = filterSource(pricedItineraryTemp);

    //pricedItineraryTemp = filterOffice(pricedItineraryTemp);  

    pricedItineraryTemp = filterCommission(pricedItineraryTemp);

    pricedItineraryTemp = sortFlightResult(sortValue, pricedItineraryTemp, sortBy);

    return pricedItineraryTemp;
}

export const sortFlightResult = (sortOption, pricedItinerary, sortBy) => {
    if (sortOption === '1') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
            if (!found) {
                arrayTemp.push({
                    totalFare: parseFloat(element1.airItineraryPricingInfo.totalFare.amount),
                    sequenceNumber: element1.sequenceNumber
                });
            }
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("totalFare") : sorter.sort(arrayTemp).desc("totalFare");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '2') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            if (element1.directionInd === "O") {
                let techStopsGroup1 = 0;
                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });

                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                if (!found) {
                    arrayTemp.push({
                        numberOfStops: element1.airItinerary.originDestinationOptions.originDestinationOption.length + techStopsGroup1,
                        sequenceNumber: element1.sequenceNumber
                    });
                }
            }
            else if (element1.directionInd === "R") {
                let techStopsGroup1 = 0;
                let techStopsGroup2 = 0;

                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                    if (originDestinationOptionElement.group === "2") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup2 = techStopsGroup2 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });

                let depStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1;
                let arrivalStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1;
                let higherStopValue = 0;
                if (parseInt(depStopsCount) > parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(depStopsCount);
                }
                else if (parseInt(depStopsCount) < parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(arrivalStopsCount);
                }
                else {
                    higherStopValue = parseInt(depStopsCount);
                }

                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                if (!found) {
                    arrayTemp.push({
                        numberOfStops: element1.airItinerary.originDestinationOptions.originDestinationOption.length + higherStopValue,
                        sequenceNumber: element1.sequenceNumber
                    });
                }
            }
            else if (element1.directionInd === "M") {
                let higherStopValue = 0;
                let depStopsCount = [];

                for (let index = 1; index < 6; index++) {
                    let techStopsGroup1 = 0;
                    element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                        if (originDestinationOptionElement.group === index.toString()) {
                            originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                                if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                    techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                                }
                            });
                        }
                    });

                    if (sessionStorage.getItem("txtFrom" + index.toString()) !== null) {
                        depStopsCount.push(parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1));
                    }
                }

                higherStopValue = depStopsCount.reduce((a, b) => Math.max(a, b), -Infinity);

                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                if (!found) {
                    arrayTemp.push({
                        numberOfStops: element1.airItinerary.originDestinationOptions.originDestinationOption.length + higherStopValue,
                        sequenceNumber: element1.sequenceNumber
                    });
                }
            }
        });

        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("numberOfStops") : sorter.sort(arrayTemp).desc("numberOfStops");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '3') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O" || element2.directionInd === "M") {
                    element2.flightSegment.forEach(element3 => {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            arrayTemp.push({
                                departureTime: element3.departureTime,
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    });
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("departureTime") : sorter.sort(arrayTemp).desc("departureTime");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '4') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            let rowCounter = 1;
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O") {
                    element2.flightSegment.forEach(element3 => {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "O").length === rowCounter) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                arrayTemp.push({
                                    arrivalTime: element3.arrivalTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                    rowCounter = rowCounter + 1;
                }
                else if (element2.directionInd === "M") {
                    element2.flightSegment.forEach(element3 => {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "M").length === rowCounter) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                arrayTemp.push({
                                    arrivalTime: element3.arrivalTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                    rowCounter = rowCounter + 1;
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("arrivalTime") : sorter.sort(arrayTemp).desc("arrivalTime");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '5') {
        //sortedFlightResult = sorter.sort(pricedItinerary).asc("airItinerary.totalOutBoundDuration");
        //pricedItinerary = sortedFlightResult;

        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O") {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        arrayTemp.push({
                            totalOutBoundDuration: parseInt(element1.airItinerary.totalOutBoundDuration),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
                else if (element2.directionInd === "M") {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        arrayTemp.push({
                            totalOutBoundDuration: parseInt(element1.airItinerary.totalItineraryDuration),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("totalOutBoundDuration") : sorter.sort(arrayTemp).desc("totalOutBoundDuration");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '6') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "O" || element2.directionInd === "M") {
                    element2.flightSegment.forEach(element3 => {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            arrayTemp.push({
                                name: element3.marketingAirline.name,
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    });
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("name") : sorter.sort(arrayTemp).desc("name");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '7') {
        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            let rowCounter = 1;
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "I") {
                    element2.flightSegment.forEach(element3 => {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "I").length === rowCounter) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                arrayTemp.push({
                                    arrivalTime: element3.arrivalTime,
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                    rowCounter = rowCounter + 1;
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("arrivalTime") : sorter.sort(arrayTemp).desc("arrivalTime");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }
    else if (sortOption === '8') {
        //sortedFlightResult = sorter.sort(pricedItinerary).asc("airItinerary.totalInBoundDuration");
        //pricedItinerary = sortedFlightResult;

        var arrayTemp = [];
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                if (element2.directionInd === "I") {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        arrayTemp.push({
                            totalInBoundDuration: parseInt(element1.airItinerary.totalInBoundDuration),
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });
        let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc("totalInBoundDuration") : sorter.sort(arrayTemp).desc("totalInBoundDuration");

        let pricedItineraryTemp = [];
        list.forEach(element => {
            pricedItineraryTemp.push(
                pricedItinerary.filter(x => x.sequenceNumber === element.sequenceNumber)[0]
            );
        });

        pricedItinerary = pricedItineraryTemp;
    }

    return pricedItinerary;
}

export const filterNumberOfStops = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.numberofstopsoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    if (filterOptions.length !== 0) {
        pricedItinerary.forEach(element1 => {
            if (element1.directionInd === "O") {
                let techStopsGroup1 = 0;

                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });

                if (filterOptions.includes(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 0 ? "Non-stop" : element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 1 ? "1 stop" : "Multi stop")) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
            //Bug 893: Flight Result- Filters- max stop calculation logic implemented
            else if (element1.directionInd === "R") {
                let techStopsGroup1 = 0;
                let techStopsGroup2 = 0;

                element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                    if (originDestinationOptionElement.group === "1") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                    if (originDestinationOptionElement.group === "2") {
                        originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                            if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                techStopsGroup2 = techStopsGroup2 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                            }
                        });
                    }
                });

                let depStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1;
                let arrivalStopsCount = element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1;
                let higherStopValue = 0;
                if (parseInt(depStopsCount) > parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(depStopsCount);
                }
                else if (parseInt(depStopsCount) < parseInt(arrivalStopsCount)) {
                    higherStopValue = parseInt(arrivalStopsCount);
                }
                else {
                    higherStopValue = parseInt(depStopsCount);
                }

                filterOptions.forEach(item => {
                    if (item === 'Non-stop') {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 === 0 && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 === 0 && higherStopValue === 0) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                    else if (item === '1 stop') {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 <= 1 && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 <= 1 && higherStopValue === 1) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                    else if (item === 'Multi stop') {
                        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "1").length + techStopsGroup1 - 1 >= 0 && element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2").length + techStopsGroup2 - 1 >= 0 && higherStopValue > 1) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
            }
            else if (element1.directionInd === "M") {
                let segmentCounter = 0;
                let isSameNumberOfStops_NonStop = 0;
                let isSameNumberOfStops_OneStop = 0;
                let isSameNumberOfStops_MultiStop = 0;
                let higherStopValue = 0;
                let depStopsCount = [];

                for (let index = 1; index < 6; index++) {
                    let techStopsGroup1 = 0;
                    element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                        if (originDestinationOptionElement.group === index.toString()) {
                            originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                                if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                    techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                                }
                            });
                        }
                    });

                    if (sessionStorage.getItem("txtFrom" + index.toString()) !== null) {
                        depStopsCount.push(parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1));
                    }
                }

                higherStopValue = depStopsCount.reduce((a, b) => Math.max(a, b), -Infinity);

                for (let index = 1; index < 6; index++) {
                    if (sessionStorage.getItem("txtFrom" + index.toString()) !== null) {
                        segmentCounter = index;
                        let techStopsGroup1 = 0;

                        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(originDestinationOptionElement => {
                            if (originDestinationOptionElement.group === index.toString()) {
                                originDestinationOptionElement.flightSegment.forEach(flightSegmentElement => {
                                    if (flightSegmentElement.technicalStops !== undefined && flightSegmentElement.technicalStops !== null && flightSegmentElement.technicalStops.noOfStops !== undefined) {
                                        techStopsGroup1 = techStopsGroup1 + parseInt(flightSegmentElement.technicalStops.noOfStops);
                                    }
                                });
                            }
                        });

                        filterOptions.forEach(item => {
                            if (item === 'Non-stop') {
                                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 === 0 && higherStopValue === 0) {
                                    isSameNumberOfStops_NonStop = isSameNumberOfStops_NonStop + 1;
                                }
                            }
                            else if (item === '1 stop') {
                                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 <= 1 && higherStopValue === 1) {
                                    isSameNumberOfStops_OneStop = isSameNumberOfStops_OneStop + 1;
                                }
                            }
                            else if (item === 'Multi stop') {
                                if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === index.toString()).length + techStopsGroup1 - 1 >= 0 && higherStopValue > 1) {
                                    isSameNumberOfStops_MultiStop = isSameNumberOfStops_MultiStop + 1;
                                }
                            }
                        });
                    }
                }

                if (isSameNumberOfStops_NonStop > 1) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }

                if (isSameNumberOfStops_OneStop > 1) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }

                if (isSameNumberOfStops_MultiStop > 1) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
        });
    }
    else {
        pricedItineraryTemp = pricedItinerary;
    }

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterFareTypes = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.faretypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
            element2.fareInfos.fareInfo.forEach(element3 => {
                if (filterOptions.includes(element3.fareType)) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterRefundableTypes = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.refundabletypeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            //filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItineraryPricingInfo.passengerTypeQuantity.filter(a => a.code === 'ADT').forEach(element2 => {
            if (element2.refundableType !== undefined) {
                if (element2.refundableType === 0 || element2.refundableType === "0") {
                    if (filterOptions.includes('Non Refundable')) {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            pricedItineraryTemp.push(element1);
                            arrayTemp.push({
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                }
                else if (element2.refundableType === 1 || element2.refundableType === "1") {
                    if (filterOptions.includes('Fully Refundable')) {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            pricedItineraryTemp.push(element1);
                            arrayTemp.push({
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                }
                else if (element2.refundableType === 2 || element2.refundableType === "2") {
                    if (filterOptions.includes('Partially Refundable')) {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            pricedItineraryTemp.push(element1);
                            arrayTemp.push({
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                }
            }
            else {
                element2.fareInfos.fareInfo.forEach(element3 => {
                    if (element3.ruleInfo !== undefined && element3.ruleInfo.chargesRules !== undefined) {
                        if (element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0] === undefined) {
                            if (filterOptions.includes('Non Refundable')) {
                                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                                if (!found) {
                                    pricedItineraryTemp.push(element1);
                                    arrayTemp.push({
                                        sequenceNumber: element1.sequenceNumber
                                    });
                                }
                            }
                        }
                        else if (parseFloat(element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0]) === 0) {
                            if (filterOptions.includes('Fully Refundable')) {
                                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                                if (!found) {
                                    pricedItineraryTemp.push(element1);
                                    arrayTemp.push({
                                        sequenceNumber: element1.sequenceNumber
                                    });
                                }
                            }
                        }
                        else if (parseFloat(element3.ruleInfo.chargesRules.voluntaryRefunds.penalty.filter(x => x.departureStatus === 'Before').map(y => y.amount)[0]) > 0) {
                            if (filterOptions.includes('Partially Refundable')) {
                                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                                if (!found) {
                                    pricedItineraryTemp.push(element1);
                                    arrayTemp.push({
                                        sequenceNumber: element1.sequenceNumber
                                    });
                                }
                            }
                        }
                    }
                });
            }
        });
    });

    if (filterOptions.length !== 0) {
        pricedItinerary = pricedItineraryTemp;
    }

    return pricedItinerary;
}

export const filterNoBaggage = (pricedItinerary) => {
    var arrayTemp = [];
    var pricedItineraryTemp = [];

    if (document.querySelector('#chkNoBaggage') !== null && document.querySelector('#chkNoBaggage').checked) {
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.segmentRefDetail.forEach(element3 => {
                    element3.pax.forEach(element4 => {
                        element4.baggageAllowance.forEach(element5 => {
                            if (element5.numberOfPieces === "0" || element5.maxWeight === "0") {
                                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                                if (!found) {
                                    pricedItineraryTemp.push(element1);
                                    arrayTemp.push({
                                        sequenceNumber: element1.sequenceNumber
                                    });
                                }
                            }
                        });
                    });
                });
            });
        });
        pricedItinerary = pricedItineraryTemp;
    }

    return pricedItinerary;
}

export const filterPerPersonPrice = (pricedItinerary, perPersonFare) => {
    let minPerPersonPrice = parseFloat(perPersonFare[0]);
    let maxPerPersonPrice = Math.ceil(perPersonFare[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        if (element1.airItineraryPricingInfo.totalFare.amount >= minPerPersonPrice && element1.airItineraryPricingInfo.totalFare.amount <= maxPerPersonPrice) {
            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
            if (!found) {
                pricedItineraryTemp.push(element1);
                arrayTemp.push({
                    sequenceNumber: element1.sequenceNumber
                });
            }
        }
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterOutboundTakeOffTime = (pricedItinerary, takeOffTime) => {
    let minTakeOffTime = parseInt(takeOffTime[0]);
    let maxTakeOffTime = parseInt(takeOffTime[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        if (element1.airItinerary.originDestinationOptions.originDestinationOption[0].directionInd === "O" || element1.airItinerary.originDestinationOptions.originDestinationOption[0].directionInd === "M") {
            if (parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].departureTime) >= minTakeOffTime && parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].departureTime) <= maxTakeOffTime) {
                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                if (!found) {
                    pricedItineraryTemp.push(element1);
                    arrayTemp.push({
                        sequenceNumber: element1.sequenceNumber
                    });
                }
            }
        }
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterInboundTakeOffTime = (pricedItinerary, takeOffTime) => {
    let minTakeOffTime = parseInt(takeOffTime[0]);
    let maxTakeOffTime = parseInt(takeOffTime[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2")[0].directionInd === "I") {
            if (parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2")[0].flightSegment[0].departureTime) >= minTakeOffTime && parseInt(element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.group === "2")[0].flightSegment[0].departureTime) <= maxTakeOffTime) {
                const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                if (!found) {
                    pricedItineraryTemp.push(element1);
                    arrayTemp.push({
                        sequenceNumber: element1.sequenceNumber
                    });
                }
            }
        }
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterOutboundLandingTime = (pricedItinerary, landingTime) => {
    let minlandingTime = parseInt(landingTime[0]);
    let maxlandingTime = parseInt(landingTime[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        let rowCounter = 1;
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            if (element2.directionInd === "O") {
                element2.flightSegment.forEach(element3 => {
                    if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "O").length === rowCounter) {
                        if (parseInt(element3.arrivalTime) >= minlandingTime && parseInt(element3.arrivalTime) <= maxlandingTime) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
                rowCounter = rowCounter + 1;
            }
            else if (element2.directionInd === "M") {
                element2.flightSegment.forEach(element3 => {
                    if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "M").length === rowCounter) {
                        if (parseInt(element3.arrivalTime) >= minlandingTime && parseInt(element3.arrivalTime) <= maxlandingTime) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
                rowCounter = rowCounter + 1;
            }
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterInboundLandingTime = (pricedItinerary, landingTime) => {
    let minlandingTime = parseInt(landingTime[0]);
    let maxlandingTime = parseInt(landingTime[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        let rowCounter = 1;
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            if (element2.directionInd === "I") {
                element2.flightSegment.forEach(element3 => {
                    if (element1.airItinerary.originDestinationOptions.originDestinationOption.filter(x => x.directionInd === "I").length === rowCounter) {
                        if (parseInt(element3.arrivalTime) >= minlandingTime && parseInt(element3.arrivalTime) <= maxlandingTime) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
                rowCounter = rowCounter + 1;
            }
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterOutBoundDuration = (pricedItinerary, duration) => {
    let minDuration = parseInt(duration[0]);
    let maxDuration = parseInt(duration[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            if (element2.directionInd === "O") {
                if (parseInt(element1.airItinerary.totalOutBoundDuration) >= minDuration && parseInt(element1.airItinerary.totalOutBoundDuration) <= maxDuration) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
            else if (element2.directionInd === "M") {
                if (parseInt(element1.airItinerary.totalItineraryDuration) >= minDuration && parseInt(element1.airItinerary.totalItineraryDuration) <= maxDuration) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterInBoundDuration = (pricedItinerary, duration) => {
    let minDuration = parseInt(duration[0]);
    let maxDuration = parseInt(duration[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            if (element2.directionInd === "I") {
                if (parseInt(element1.airItinerary.totalInBoundDuration) >= minDuration && parseInt(element1.airItinerary.totalInBoundDuration) <= maxDuration) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterStopoverDuration = (pricedItinerary, stopoverDuration) => {
    let minStopoverDuration = Math.round(stopoverDuration[0]);
    let maxStopoverDuration = Math.round(stopoverDuration[1]);

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            if (element2.directionInd === "O" || element2.directionInd === "M") {
                if (element1.airItinerary.totalOutBoundDuration >= minStopoverDuration && element1.airItinerary.totalOutBoundDuration <= maxStopoverDuration) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            }
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterSegment = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.segmentoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            element2.flightSegment.forEach(element3 => {
                element3.equipment.forEach(element4 => {
                    if (filterOptions.includes(element4.airEquipType === "BUS" ? "Bus" : "Air")) {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            pricedItineraryTemp.push(element1);
                            arrayTemp.push({
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                });
            });
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterClass = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.classoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            element2.flightSegment.forEach(element3 => {
                element3.bookingClassAvails.forEach(element4 => {
                    if (filterOptions.includes(element4.cabinName)) {
                        const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                        if (!found) {
                            pricedItineraryTemp.push(element1);
                            arrayTemp.push({
                                sequenceNumber: element1.sequenceNumber
                            });
                        }
                    }
                });
            });
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterAirline = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.airlineoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
            element2.flightSegment.forEach(element3 => {
                if (filterOptions.includes(element3.marketingAirline.name)) {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                }
            });
        });
    });

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterAlliance = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.allianceoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    if (filterOptions.length !== 0) {
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    if (element3.alliances !== undefined && element3.alliances !== null) {
                        if (filterOptions.includes(element3.alliances)) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
            });
        });
    }
    else {
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                });
            });
        });
    }

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

export const filterSource = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.sourceoption');

    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    if (filterOptions.length === 0) {
        for (var i = 0; i < inputs.length; i++) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        if (filterOptions.includes(element1.sourceName)) {
            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
            if (!found) {
                pricedItineraryTemp.push(element1);
                arrayTemp.push({
                    sequenceNumber: element1.sequenceNumber
                });
            }
        }
    });

    if (filterOptions.length !== 0) {
        pricedItinerary = pricedItineraryTemp;
    }

    return pricedItinerary;
}

export const filterOffice = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.officeoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    if (filterOptions.length !== 0) {
        pricedItinerary.forEach(element1 => {
            element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
                if (element2.commisionInfo !== undefined) {
                    element2.commisionInfo.forEach(element3 => {
                        if (filterOptions.includes(element3.sourcePCC)) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    });
                }
            });
        });
    }
    else {
        pricedItinerary.forEach(element1 => {
            element1.airItinerary.originDestinationOptions.originDestinationOption.forEach(element2 => {
                element2.flightSegment.forEach(element3 => {
                    const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                    if (!found) {
                        pricedItineraryTemp.push(element1);
                        arrayTemp.push({
                            sequenceNumber: element1.sequenceNumber
                        });
                    }
                });
            });
        });
    }

    pricedItinerary = pricedItineraryTemp;
    return pricedItinerary;
}

function formatDateForFlightFilter(timeObject) {
    if (timeObject.start.split(':')[0].length === 1 && timeObject.start.split(':')[1].length === 1) {
        timeObject.start = "0" + timeObject.start.split(':')[0] + ":0" + timeObject.start.split(':')[1];
    }
    else if (timeObject.start.split(':')[0].length === 1) {
        timeObject.start = "0" + timeObject.start.split(':')[0] + ":" + timeObject.start.split(':')[1];
    }
    else if (timeObject.start.split(':')[1].length === 1) {
        timeObject.start = timeObject.start.split(':')[0] + ":0" + timeObject.start.split(':')[1];
    }

    if (timeObject.end.split(':')[0].length === 1 && timeObject.end.split(':')[1].length === 1) {
        timeObject.end = "0" + timeObject.end.split(':')[0] + ":0" + timeObject.end.split(':')[1];
    }
    else if (timeObject.end.split(':')[0].length === 1) {
        timeObject.end = "0" + timeObject.end.split(':')[0] + ":" + timeObject.end.split(':')[1];
    }
    else if (timeObject.end.split(':')[1].length === 1) {
        timeObject.end = timeObject.end.split(':')[0] + ":0" + timeObject.end.split(':')[1];
    }

    let timeObjectTemp = [];
    timeObjectTemp.push(
        timeObject.start.replace(':', ''), timeObject.end.replace(':', '')
    );
    timeObject = timeObjectTemp;
    return timeObject;
}

export const sortMyTripsResult = (myTrips, sortOption) => {
    var arrayTemp = [];
    let sortByField = '';
    let sortBy = '';

    if (sortOption === 0) {
        sortByField = 'bookDate';
        sortBy = 'asc';
        myTrips.forEach(elementMyTrips => {
            arrayTemp.push({
                bookDate: new Date(elementMyTrips.bookDate),
                id: parseInt(elementMyTrips.id)
            });
        });
    }
    else if (sortOption === 1) {
        sortByField = 'bookDate';
        sortBy = 'desc';
        myTrips.forEach(elementMyTrips => {
            arrayTemp.push({
                bookDate: new Date(elementMyTrips.bookDate),
                id: parseInt(elementMyTrips.id)
            });
        });
    }
    else if (sortOption === 2) {
        sortByField = 'paxFname';
        sortBy = 'asc';
        myTrips.forEach(elementMyTrips => {
            if (elementMyTrips.product.flightDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.flightDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            } else if (elementMyTrips.product.hotelDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.hotelDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            } else if (elementMyTrips.product.carDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.carDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }
            else {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.serviceRequest.paxFirstName.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }
        });
    }
    else if (sortOption === 3) {
        sortByField = 'paxFname';
        sortBy = 'desc';
        myTrips.forEach(elementMyTrips => {
            if (elementMyTrips.product.flightDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.flightDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            } else if (elementMyTrips.product.hotelDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.hotelDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            } else if (elementMyTrips.product.carDetails) {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.carDetails.paxFname.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }
            else {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.serviceRequest.paxFirstName.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }

        });
    }
    else if (sortOption === 4) {
        sortByField = 'paxLname';
        sortBy = 'asc';
        myTrips.forEach(elementMyTrips => {
            if (elementMyTrips.product.flightDetails !== undefined) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.flightDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            } else if (elementMyTrips.product.hotelDetails) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.hotelDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            } else if (elementMyTrips.product.carDetails) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.carDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            }
            else {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.serviceRequest.paxLastName.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }
        });
    }
    else if (sortOption === 5) {
        sortByField = 'paxLname';
        sortBy = 'desc';
        myTrips.forEach(elementMyTrips => {
            if (elementMyTrips.product.flightDetails) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.flightDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            } else if (elementMyTrips.product.hotelDetails) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.hotelDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            } else if (elementMyTrips.product.carDetails) {
                arrayTemp.push({
                    paxLname: elementMyTrips.product.carDetails.paxLname.toString().trim().toLowerCase(),
                    id: parseInt(elementMyTrips.id)
                });
            }
            else {
                arrayTemp.push({
                    paxFname: elementMyTrips.product.serviceRequest.paxLastName.toString().trim().toLowerCase(),
                    id: elementMyTrips.id
                });
            }
        });
    }

    let list = sortBy === 'asc' ? sorter.sort(arrayTemp).asc(sortByField) : sorter.sort(arrayTemp).desc(sortByField);

    let myTripsTemp = [];
    list.forEach(element => {
        myTripsTemp.push(
            myTrips.filter(x => x.id === element.id)[0]
        );
    });

    return myTripsTemp;
};

export const filterCommission = (pricedItinerary) => {
    let filterOptions = [];
    var inputs = document.querySelectorAll('.commissionoption');
    for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
            filterOptions.push(inputs[i].getAttribute("data"));
        }
    }

    // if (filterOptions.length === 0) {
    //     for (var i = 0; i < inputs.length; i++) {
    //         filterOptions.push(inputs[i].getAttribute("data"));
    //     }
    // }

    var arrayTemp = [];
    var pricedItineraryTemp = [];

    pricedItinerary.forEach(element1 => {
        element1.airItineraryPricingInfo.passengerTypeQuantity.forEach(element2 => {
            if (element2.commisionInfo !== undefined) {
                element2.commisionInfo.forEach(element3 => {
                    if (element3.earnedCommissionAmount !== undefined && element3.earnedCommissionAmount !== '' && element3.earnedCommissionAmount !== '0.00') {
                        if (filterOptions.includes('agentCommission')) {
                            const found = arrayTemp.find(el => el.sequenceNumber === element1.sequenceNumber);
                            if (!found) {
                                pricedItineraryTemp.push(element1);
                                arrayTemp.push({
                                    sequenceNumber: element1.sequenceNumber
                                });
                            }
                        }
                    }
                });
            }
        });
    });

    if (filterOptions.length !== 0) {
        pricedItinerary = pricedItineraryTemp;
    }

    return pricedItinerary;
}

export const setSanitizedHtml = (inputHtml) => {
    return <div dangerouslySetInnerHTML={{ __html: purify.sanitize(inputHtml) }} />;
};

export const getToken = () => {
    let token = localStorage.getItem('token');
    if (!token) {
        token = localStorage.getItem('tokenForNonAuth');
    }

    return token;
};

//Get properties of an uploaded image
export const getImageProperties = (file) => {
    let reader = new FileReader();

    return new Promise((resolve, reject) => {
        //Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                //add more properties as needed in future
                resolve({
                    width,
                    height
                });
            };
        };

        reader.onerror = function (e) {
            reader.abort();
            reject({
                error: e
            });
        };

    });
};

export const scrollToElement = (elementId) => {
    let targetElement = document.getElementById(elementId)?.closest('.gt-scroll-marker');

    if (!targetElement) {
        targetElement = document.getElementById(elementId);
    }

    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth'
        });
    }
};

export const scrollToLoader = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    let frozenHeaderHeight = document.getElementsByClassName("main-navbar")[0]?.offsetHeight;
    if (!frozenHeaderHeight || frozenHeaderHeight === 0) {
        //case of mobile layout
        frozenHeaderHeight = 0;
    }
    else {
        frozenHeaderHeight = frozenHeaderHeight * 2;
    }

    // Calculate the position to scroll to
    let scrollTo = 0;
    if (frozenHeaderHeight > 0) {
        //Calculate scrollTo only for normal layout. 
        //In mobile layout, the loader is shown/generated at the top by default. 
        //Therefore, scrolling will be done to the top of the page.
        scrollTo = ((documentHeight - windowHeight) / 2) - frozenHeaderHeight;
    }

    // Scroll smoothly
    window.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
    });
};

export const isValidFileSize = (desiredFileSizeInKB, fileBase64String) => {
    try {
        const maxTotalDocSizeinKiloBytes = desiredFileSizeInKB;
        //When file is coverted to base64, the size increases by 33%
        const bufferSizeForBase64Conversion = (maxTotalDocSizeinKiloBytes * 33) / 100
        const maxAllowedDocSizeInBytes = (maxTotalDocSizeinKiloBytes + bufferSizeForBase64Conversion) * 1024;

        const totalBytes = Buffer.from(fileBase64String).length;

        return totalBytes <= maxAllowedDocSizeInBytes;
    }
    catch (e) {
        return false;
    }
}